import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaymentService } from '../services/payment.service';
import { UserPaymentData } from '../interfaces/user-payment-data';
import { ReferralComponent } from '../../module-subscription/referral/referral.component';
import { AddressService} from '../../module-address/services/address.service';
import { Address } from '../../module-address/models/address';
import {AlertService} from '../../core/alert/alert.service';
import { Router} from '@angular/router';
import { ReferralService } from '../../module-subscription/referral/services/referral.service';
import { ReferralModel } from '../../module-subscription/referral/models/referralModel';
import {Subscription} from 'rxjs';
import {CookieService} from '../../core/services/cookie.service';
import {UserService} from '../../module-user/services/user.service';
import {AuthenticationService} from '../../module-user/services/authentication.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',

})
export class PaymentComponent implements OnInit, OnDestroy {
@ViewChild(StripeCardComponent) card: StripeCardComponent;
  referralSubscription: Subscription;

  loading = false;
  activatedSuccessfullyMsg = '';
  referrals: Array<ReferralModel>;
  stripeErrorMessage: string = null;
  userAddress: Address;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };
  chosenLanguage = 'en';
  elementsOptions = {
    locale: 'en'
  };

  stripeForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<PaymentComponent>,
              @Inject(MAT_DIALOG_DATA) public userData: UserPaymentData,
              private fb: FormBuilder,
              public dialog: MatDialog,
              private route: Router,
              private stripeService: StripeService,
              private paymentService: PaymentService,
              private addressService: AddressService,
              private alertService: AlertService,
              private referralService: ReferralService,
              private cookieService: CookieService,
              private userService: UserService,
              private authenticationService: AuthenticationService,
              translate: TranslateService) {
    this.chosenLanguage = this.cookieService.LanguageCookie;
    this.elementsOptions = {
      locale: this.chosenLanguage.toString()
    };
    translate.get('subscriptionsComponent.activatedSuccessfully').subscribe((res: string) => {
      this.activatedSuccessfullyMsg = res;
    });
  }




  ngOnInit(): void {
    // Get user address to set it on the payment for billing
    this.addressService.getUserAddresses().subscribe(addressesResponse => {
      if (addressesResponse.body.data.length === 0 ){
        // Go to user account section
        this.dialogRef.close();
        this.route.navigate(['/user']);
        this.alertService.error('You must set your billing address first', true);
      }
      // get first user address
      this.userAddress = addressesResponse.body.data[0];

    }, error => {
      this.dialogRef.close();
      this.route.navigate(['/']);
      this.alertService.error(error.error, true);
    });

    this.stripeForm = this.fb.group({
      name: ['', [Validators.required]]
    });

    this.referralSubscription = this.referralService.getReferrals().subscribe(response => this.referrals = response.body.data,
        error => {
          this.dialogRef.close();
          this.route.navigate(['/']);
          this.alertService.error(error.error, true);
        });

  }

  createToken(): void {

    this.loading = true;

    const name = this.stripeForm.get('name').value;
    this.stripeService.createPaymentMethod({
      type: 'card',
      card: this.card.element,
      billing_details: {
        name: `${name}`,
        address: {
          city:         this.userAddress.city,
          country:      this.userAddress.country,
          line1:        this.userAddress.line1,
          line2:        this.userAddress.line2,
          postal_code:  this.userAddress.postcode,
          state:        this.userAddress.state
        },
        phone: this.userAddress.phone_number
      }
    }).subscribe(result => {
      // console.log(result);

      this.paymentService.createStripeSubscription(
        this.userData.subscriberCustomerId,
        result.paymentMethod.id,
        this.userData.priceId,
        this.userData.userSubscriptionUI
        ).subscribe(response => {
          // console.log(response.body.data.subscription);

          const stripeSubscriptionObject = response.body.data.subscription;

          if (stripeSubscriptionObject.status === 'active'){
            // Subscription successful no action required
            this.goToReferralDialog();
          }

          this.handlePaymentThatRequiresCustomerAction(stripeSubscriptionObject, this.userData.priceId, result.paymentMethod.id);

      }, backendError => {
        // The card had an error when trying to attach it to a customer.
        this.stripeErrorMessage = backendError.error.error;
      });

    }, stripeErrorResult => this.stripeErrorMessage = stripeErrorResult.error.message);

  }

  handlePaymentThatRequiresCustomerAction(subscription: any,  priceId: string, paymentMethodId: string, isRetry?: boolean, invoice ?: any) {
    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;
    // isRetry === true &&
    if (paymentIntent.status === 'requires_action' ||
      ( paymentIntent.status === 'requires_payment_method')
    ) {
      this.stripeService.confirmCardPayment(paymentIntent.client_secret, {payment_method: paymentMethodId}).subscribe( result => {
        // console.log('confirm card');
        // console.log(result);
        if (result.error){
          // Start code flow to handle updating the payment details.
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc).
          // console.error(result.error.message);
          this.stripeErrorMessage = result.error.message;

          // this.alertService.error(result.error.message);
          // throw result.error.message;
        }else {
          if (result.paymentIntent.status === 'succeeded'){
            // Show a success message to your customer.
            this.goToReferralDialog();
          }
        }}, stripeErrorResult => this.stripeErrorMessage = stripeErrorResult.error.message );
    }

  }


  goToReferralDialog() {

    this.refreshTheUserInformation();
    this.dialogRef.close();
    if (this.referrals.length > 0) {
      setTimeout(() => {
        this.route.navigate(['/']);
        this.alertService.success(this.activatedSuccessfullyMsg, true);
        this.loading = false;
      }, 3500);
    } else {
      this.dialog.open(ReferralComponent, {
        maxWidth: '450px',
        width: '100%',
        data: {
          redirectToHomepage: true,
          exitMessage: this.activatedSuccessfullyMsg,
          showExitMessageOnCancellation: true
        }
      });

    }
  }

refreshTheUserInformation(exit= true){
    // reset the user information
    if (exit) {
      return;
    }
    this.userService.getUserProfile().subscribe(
      resp => {
        if (resp.body.data.has_valid_subscription === true){
          this.refreshTheUserInformation(true);
          // console.log('calling');
          // console.log(resp.body.data);
        } else { this.authenticationService.setCurrentUser(resp.body.data); }
      }, error => console.error(error.message));

  }

  ngOnDestroy() {this.referralSubscription.unsubscribe(); }

}

