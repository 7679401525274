export class Alert {
  static SUCCESS = 'success';
  static ERROR = 'error';

  type: string;
  message: string;

  constructor(type: string, message: string) {
    this.type = type;
    this.message = message;
  }



}

