import { Component, EventEmitter,  OnDestroy, OnInit, Output } from '@angular/core';
import { EntityService } from '../../services/entity.service';
import { EntityClass } from '../../models/entityClass';
import { CategoryService } from '../../services/category.service';
import { Category } from '../../models/category';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FieldsValidators } from '../../../module-user/validators/fields-validators';
import { EntityAdditionService } from '../service/entity-addition.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',

})
export class AddCategoryComponent implements OnInit, OnDestroy{
  categorySubscription: Subscription;
  entitySubscription: Subscription;

  @Output() currentComponent: EventEmitter<any> = new EventEmitter<any>();

  addCatForm: FormGroup;
  formSubmitted = false;

  entityClasses: Array<EntityClass>;
  categories: Array<Category>;

  selectableEntityClasses: Array<EntityClass>;

  selectCategoryText = '';
  selectMainCategoryText = '';

  constructor(private entityService: EntityService,
              private categoryService: CategoryService,
              private formBuilder: FormBuilder,
              private entityAdditionService: EntityAdditionService,
              translate: TranslateService) {
    // Getting translation for selection
    translate.get('entityComponent.addEntity.steps.category.inputFields.category.selectText').subscribe((res: string) => {
      this.selectCategoryText = res;
    });
    translate.get('entityComponent.addEntity.steps.category.inputFields.mainCategory.selectText').subscribe((res: string) => {
      this.selectMainCategoryText = res;
    });
  }

  ngOnInit(): void {
    /* Build the current form */
    this.addCatForm = this.formBuilder.group({
      category: [ this.selectCategoryText, [
        FieldsValidators.oneValueHasToBeChangedValidator(this.selectCategoryText, { sameValue: true })
      ]],
      entityClass: [this.selectMainCategoryText, [
        FieldsValidators.oneValueHasToBeChangedValidator(this.selectMainCategoryText, { sameValue: true })
      ]],
    });
    /* Build the current form */

    this.loadCategoriesAndClasses();

    // Listen to category selection changes in order to update the list of entity classes available
    this.addCatForm.get('category').valueChanges.subscribe(val => this.filterEntityClasses(val));
    this.addCatForm.get('entityClass').valueChanges.subscribe(val => console.log(val));

  }

  loadCategoriesAndClasses(){
    this.entityService.getEntityClasses();
    this.entityService.entityClasses.subscribe( entityClasses => {
      this.entityClasses = entityClasses;
      if (entityClasses !== undefined && entityClasses !== null){
        // this.loadEditEntity();
        if (this.entityAdditionService.currentEntity.entity_class !== undefined){
          this.entityAdditionService.setEntityClass = this.entityClasses.filter(entityClass =>
            entityClass.id === this.entityAdditionService.currentEntity.entity_class.id)[0];
        }}}, error => console.error(error));

    // Set the categories and the classes options
    if ( this.categoryService.currentCategories === null ){
      // Getting the current categories. If they are already loaded we get them from the service subject
      this.categorySubscription = this.categoryService.getCategories().subscribe(response => {
        this.categoryService.category = response.body.data;
        this.categories = response.body.data;
        this.loadEditEntity();
      }, error => console.error(error));
    }else{
      // categories are already loaded by a different component
      this.categories = this.categoryService.currentCategories;
      this.loadEditEntity();
    }
  }

  loadEditEntity(){
    if (this.entityAdditionService.currentEntity.categories !== undefined){
      // In case of entity update we set the default values to the entity values
      // console.log(this.entityClasses);
      this.addCategoryFormControls.category.setValue(this.entityAdditionService.currentEntity.categories[0].id);
      this.filterEntityClasses(this.entityAdditionService.currentEntity.categories[0].id,
        this.entityAdditionService.currentEntity.entity_class.id);
    }
  }

  filterEntityClasses(categoryId: number, entityClassId?: number){
    // console.log(categoryId);
    // console.log(this.entityClasses);
    // console.log(this.categories);
    if ( this.categories !== undefined && this.categories !== null &&
      this.entityClasses !== null && this.entityClasses !== undefined){
      const currentCategory = this.categories.filter(category => category.id === categoryId)[0];
      // console.log(this.entityClasses);
      this.selectableEntityClasses = [];
      this.entityClasses.forEach(entityClass => {
        if (currentCategory.numchild !== 0) {
          currentCategory.children.forEach(subCategory1 => {
            // console.log(entityClass);
            if (entityClass.name.toLowerCase() === subCategory1.name.toLowerCase()) {this.selectableEntityClasses.push(entityClass); }
          });
        }
      });

      if (entityClassId !== undefined){
        this.addCategoryFormControls.entityClass.setValue(entityClassId);
      }
    }
    // console.log(this.selectableEntityClasses);
  }

  // convenience getter for easy access to form fields
  get addCategoryFormControls() { return this.addCatForm.controls; }



  onSubmit(){
    this.formSubmitted = true;

    // console.log('Setting category ');
    // console.log(this.addCategoryFormControls.category.value);
    // console.log('Setting entity class ');
    // console.log(this.addCategoryFormControls.entityClass.value);

    // stop here if form is invalid
    if (this.addCatForm.invalid) {
      return;
    }
    // set the categories on the entity
    this.entityAdditionService.categories = {
      categories: [this.addCategoryFormControls.category.value],
      entity_class: this.addCategoryFormControls.entityClass.value
    };
    // set the entity class

    this.entityAdditionService.setEntityClass = this.entityClasses.filter(entityClass =>
      entityClass.id === parseInt(this.addCategoryFormControls.entityClass.value, 10) )[0];

    // console.log('Setting entity class subject ');
    // console.log(this.entityAdditionService.currentEntityClass);
    // console.log('Current entity ');
    // console.log(this.entityAdditionService.entity.value);

    // change the screen
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.currentComponent.emit('showAddProperties');
  }


  ngOnDestroy() {
    if ( this.categorySubscription ){ this.categorySubscription.unsubscribe(); }
    if ( this.entitySubscription ){ this.entitySubscription.unsubscribe(); }
  }
}
