import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-real-esatte-slider',
  templateUrl: './real-esatte-slider.component.html',
  styleUrls: ['./real-esatte-slider.component.css']
})
export class RealEsatteSliderComponent implements OnInit {
  @Input() title;
  @Input() data: any;
  loading: boolean = true;
  filterData: any;
  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.loading = false;
    }), 15000;
  }

  ngOnChanges(changes: SimpleChanges) {

    this.filterData = this.data.filter(item => item.categories[0].slug == 'real-estate');

    if (this.data[0]) {
      $(document).ready(function () {
        var sliderName = $('.rs-slider');
        $('.rs-slider-left').click(function () {
          sliderName.slick('slickPrev');
        })
        $('.rs-slider-right').click(function () {
          sliderName.slick('slickNext');
        })
        sliderName.slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          draggable: true,
          autoplay: false,
          arrows: false,
          dots: false,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 4,
              }
            },
            {
              breakpoint: 881,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 580,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
        });
      });
    }
  }


}