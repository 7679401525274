<h1 mat-dialog-title class="text-center">{{'subscriptionsComponent.referralPage.modifyReferral.title' | translate }}     </h1>
<h3 mat-dialog-title class="text-center text-danger">{{message}}</h3>
<h3 mat-dialog-title class="text-center text-danger">{{errorMessage}}</h3>
<form novalidate (ngSubmit)="modifyReferral()" [formGroup]="modifyReferralForm">
  <label for="login-oldEmail-field">{{'subscriptionsComponent.referralPage.modifyReferral.oldEmail' | translate }}</label>
  <div class="input-group mb-4 custom-input-group-height" >
    <!-- Email 1-->
    <input id='login-oldEmail-field'
           type="text"
           formControlName="oldEmail"
           class="form-control custom-input-group-height"
           [ngClass]="{ 'is-invalid': submitted && form.oldEmail.errors }"
           placeholder="{{'subscriptionsComponent.referralPage.placeholderOldEmail' | translate }}"
    />
    <div class="input-group-append">
         <span class="input-group-text custom-span-input-group" >
           <i class="fas fa-user-circle"></i>
         </span>
    </div>
    <div *ngIf="submitted && form.oldEmail.errors" class="invalid-feedback">
      <div *ngIf="form.oldEmail.errors.required">Referral email is required</div>
      <div *ngIf="form.oldEmail.errors.email">Referral email address is invalid</div>
    </div>
  </div>
  <!--  Email 2 -->
  <label for="login-newEmail-field">{{'subscriptionsComponent.referralPage.modifyReferral.newEmail' | translate }}</label>
  <div class="input-group mb-4 custom-input-group-height" >

    <input id='login-newEmail-field'
           type="text"
           formControlName="newEmail"
           class="form-control custom-input-group-height"
           [ngClass]="{ 'is-invalid': submitted && form.newEmail.errors }"
           placeholder="{{'subscriptionsComponent.referralPage.modifyReferral.placeholderNewEmail' | translate }}"
    />
    <div class="input-group-append">
         <span class="input-group-text custom-span-input-group" >
           <i class="fas fa-user-circle"></i>
         </span>
    </div>
    <div *ngIf="submitted && form.newEmail.errors" class="invalid-feedback">
      <div *ngIf="form.newEmail.errors.required">{{'subscriptionsComponent.referralPage.modifyReferral.emailRequired' | translate }}</div>
      <div *ngIf="form.newEmail.errors.email">{{'subscriptionsComponent.referralPage.modifyReferral.emailNotValid' | translate }}</div>
    </div>
  </div>
  <button type="submit"  class="btn btn-warning rounded small float-right" >{{'subscriptionsComponent.referralPage.modifyReferral.modify' | translate }}</button>
  <a (click)="exit()"  class="btn btn-primary rounded small float-left">{{'subscriptionsComponent.referralPage.modifyReferral.cancel' | translate }}</a>
</form>




