<div class="mobile-menu-second">

  <!-- Pricing & Packages -->
  <section class="section-padding price-package">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <div class="text-wrapper text-center">
              <h2 class="fw-500 no-margin font-weight-bolder">
                {{'pricingPage.title' | translate }}
              </h2>
              <span class="line-grey"></span>
              <span class="line-blue"></span>
              <span class="line-grey"></span>
              <p class="text-dark-grey"> {{'pricingPage.subtitle' | translate }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">

          <!-- tab-box -->


          <div class="row" *ngIf="currentPlans">
            <div class="col-lg-6 col-md-6 " *ngFor="let plan of currentPlans">
              <div class="pricing-box shadow-sm rounded">
                <div class="pricing-header">
                  <div class="package-type" style="height: 65px;">
                    <p style="font-size: 17px;" class="no-margin text-custom-white">{{ plan.plan_name }}</p>
                  </div>
                  <div class="package-price" style="height: 55px;">
                    <p class="no-margin">{{ plan.current_price }} € /  {{plan.interval.interval_name}}</p>
                  </div>
                </div>
                <div class="pricing-content">
                  <ul class="list">
                    <li *ngFor="let option of plan.options">
                      {{option.option_name}}: <span>{{ option.option_value}}</span>
                    </li>

                  </ul>
                </div>
                <div class="pricing-footer" *ngIf="currentUser">
                  <button class="btn-second blue-btn rounded" (click)="openVoucher(plan) ">{{'pricingPage.getNow' | translate }}</button>
                </div>
              </div>
            </div>

          </div>

          <!-- tab-box -->
        </div>
      </div>
    </div>
  </section>
  <!-- Pricing & Packages -->

</div>
