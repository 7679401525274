
<div class="step-tab-panel" id="step3">
  <div class="tab-from-content">
    <div class="title-icon">
      <h4 class="title rubik fw-600">
        <i class="flaticon-location-pin"></i>
        {{'entityComponent.addEntity.steps.location.subtitle' | translate }}
      </h4>
    </div>
    <form #addLocation [formGroup]="addLocationForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-lg-12">
          <div>
            <h4 class="title rubik fw-600">
              {{'entityComponent.addEntity.steps.location.inputFields.country.label' | translate }}
            </h4>
          </div>
          <mat-form-field style="width: 100%;"  >
            <mat-label>
              {{ selectCountryText }}
            </mat-label>
            <mat-select required
              formControlName="country"
              panelClass="example-panel-blue">
              <mat-option  *ngFor="let ctr of availableCountries" [value]="ctr.iso_3166_1_a2">
                {{ ctr.name }}
              </mat-option>
            </mat-select>
            <mat-error >
              {{'entityComponent.addEntity.steps.location.inputFields.country.errors.required' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-12" *ngIf="addLocationFormControls.country.value !== selectCountryText && addLocationFormControls.country.value !== ''">
          <div>
            <h4 class="title rubik fw-600">
              {{'entityComponent.addEntity.steps.location.inputFields.city.label' | translate }}
            </h4>
          </div>
          <mat-form-field style="width: 100%;"  >
            <mat-label>
              {{ selectCityText }}
            </mat-label>
            <mat-select required
                         formControlName="city"
                         panelClass="example-panel-blue">
              <mat-option  *ngFor="let city of availableCities" [value]="city.id">
                {{ city.name }}
              </mat-option>
            </mat-select>
            <mat-error>
              {{'entityComponent.addEntity.steps.location.inputFields.city.errors.required' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="step-footer step-tab-pager mt-5 justify-content-center">

        <input type="submit" class="btn-second blue-btn" style="width: 200px;"
               value="{{'entityComponent.addEntity.buttons.next' | translate }}"/>
      </div>
    </form>
  </div>
</div>
