

<div class="modal fade"  id="register">

  <div class="modal-dialog" style="height:  calc(100vh - 210px); min-height: 100%;">
    <div class="modal-content">
      <div class="modal-header" style="height: 50px;">
        <h4 class="modal-title text-left no-margin " style="font-family: Helvetica; font-weight: bold; font-size: larger;" >
         <!--  <i class="fas fa-unlock-alt mr-2"></i> -->
          <span>{{ modalTitle }}</span>
        </h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

        <app-login *ngIf="showSignIn" (switchComponent)="changeComponent($event)"></app-login>

        <app-register *ngIf="showSignUp" (switchComponent)="changeComponent($event)" ></app-register>

        <app-recover-password *ngIf="showRecover" (switchComponent)="changeComponent($event)"></app-recover-password>

    </div>
  </div>


</div>



