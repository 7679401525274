import { Component, OnInit,  ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  encapsulation: ViewEncapsulation.None
})
export class UserModalComponent implements OnInit{
  modalTitle = 'Sign in';
  showSignIn = true;
  showSignUp = false;
  showRecover = false;

  signInText = '';
  signUpText = '';
  recoverPassText = '';

  constructor(translate: TranslateService) {
    // Getting translation for selection
    translate.get('userModal.loginPage.title').subscribe((res: string) => {
      this.signInText = res;
      this.modalTitle = res;
    });
    translate.get('userModal.signUpPage.title').subscribe((res: string) => {
      this.signUpText = res;
    });
    translate.get('userModal.forgotPasswordPage.title').subscribe((res: string) => {
      this.recoverPassText = res;
    });
  }

  ngOnInit(): void {

  }


  changeComponent(event: string){
    /* This function is listening to the events emitted by the children of this component.
    * It is used in order to change the content of the modal dinamically
    * @param event, it represents the name of the component that needs to be changed */

    switch (event) {
      case 'recover_password': {
        this.modalTitle = this.recoverPassText;
        this.showSignIn = false;
        this.showSignUp = false;
        this.showRecover = true;
        break;
      }
      case 'sign_up': {
        this.modalTitle = this.signUpText;
        this.showSignIn = false;
        this.showSignUp = true;
        this.showRecover = false;
        break;
      }
      case 'sign_in': {
        this.modalTitle = this.signInText;
        this.showSignIn = true;
        this.showSignUp = false;
        this.showRecover = false;
        break;
      }
    }

  }



}
