<div [formGroup]="form" class="row" *ngIf="!formField.key.includes('-parent')" >
  <div class="col-lg-12">
    <h5 class="title rubik fw-600">
      <ng-container *ngIf="formField.key.includes('min')"> <b>{{'entityComponent.browse.sideBar.search.inputFields.search.from' | translate }}</b> </ng-container>

      <ng-container *ngIf="formField.key.includes('max')"> <b>{{'entityComponent.browse.sideBar.search.inputFields.search.to' | translate }}</b>  </ng-container>
      <ng-container *ngIf="!formField.key.includes('max') && !formField.key.includes('min')">{{formField.label}}</ng-container>

    </h5>
  </div>
  <div [ngSwitch]="formField.controlType" class="form-group p-relative col-lg-12" >

    <input *ngSwitchCase="'textbox'" [formControlName]="formField.key" class="form-control"
           [id]="formField.key" [type]="formField.type">

    <!--      <select [id]="formField.key" *ngSwitchCase="'dropdown'" [formControlName]="formField.key">-->
    <!--        <option *ngFor="let opt of formField.options" [value]="opt.key">{{opt.value}}</option>-->
    <!--      </select>-->

    <mat-form-field style="width: 100%;" *ngSwitchCase="'dropdown'"  >
      <mat-label>{{'buttons.select' | translate }}   {{formField.label.toLowerCase()}}</mat-label>
      <mat-select  id="entityClass-field"
                   formControlName="{{formField.key}}"
                   panelClass="example-panel-blue">
        <mat-option *ngFor="let opt of formField.options" [value]="opt.key">
          {{opt.value}}
        </mat-option>
      </mat-select>
      <mat-error >This field is required</mat-error>
    </mat-form-field>

    <input  *ngSwitchCase="'file'" [formControlName]="formField.key" class="form-control"
            [id]="formField.key" [type]="formField.type">

    <input  *ngSwitchCase="'number'" [formControlName]="formField.key" class="form-control"
            [id]="formField.key"
            [type]="formField.type"
            [min]="formField.min"
            [step]="formField.step"
            [max]="formField.max">

    <mat-checkbox  *ngSwitchCase="'CheckBox'"
                   [id]="formField.key"
                   [formControlName]="formField.key"></mat-checkbox>


    <ng-container *ngIf="radioKey">
      <input  *ngSwitchCase="'radio'"
              formControlName="9-parent"
              [id]="formField.key"
              [value]="formField.key"
              type="radio" >
    </ng-container>

    <input  *ngSwitchCase="'date'"
            [formControlName]="formField.key"
            [type]="formField.type"
            class="form-control form-control-custom">
  </div>

  <div class="errorMessage" *ngIf="!isValid">{{formField.label}} is required</div>
</div>




