<!-- Start Mobile Menu -->
<div class="mobile-menu d-block d-md-none">
  <div class="top-bar">
    <div class="container">
      <div class="mobile-topbar-row d-flex align-items-center justify-content-between">
        <!-- Left -->
        <div class="left--space">
          <div class="top-contact">
            <ul>
              <!-- Button Toggler -->
              <li>
                <a href="javascript:void(0);" id="categoryheadaccordionbtn">
                  <span class="icon-menu-lines" (click)="openMenu()"></span>
                  <span class="icon-close d-none" (click)="closeMenu()"></span>
                </a>
              </li>
              <!-- User Profile -->
              <li class="dropdown">
                <a *ngIf="userProfile" style=" cursor: pointer;" class="text-dark-grey" data-toggle="dropdown">
                  <span class="icon-user"></span>
                </a>
                <ul class="dropdown-menu" style="width: 180px;">
                  <li>
                    <a routerLink="/user" class="text-dark-grey">
                      <i class="fas fa-user-circle menu-icons-alignment"></i>{{'userMenu.accountSettings' | translate
                      }}</a>
                  </li>
                  <li>
                    <a routerLink="/user/password/change" class="text-dark-grey">
                      <i class="fas fa-unlock-alt"></i> &nbsp;&nbsp;{{'userMenu.changePassword' | translate }}</a>
                  </li>

                  <li>
                    <a routerLink="/terms-of-use" class="text-dark-grey">
                      <i class="fas fa-bookmark menu-icons-alignment"></i>{{'userMenu.terms' | translate }}</a>
                  </li>
                  <li>
                    <a routerLink="/subscriptions" class="text-dark-grey">
                      <i class="far fa-credit-card menu-icons-alignment"></i> {{'userMenu.mySubscriptions' | translate
                      }}</a>
                  </li>
                  <ng-container *ngIf="userProfile">
                    <li *ngIf="userProfile.has_valid_subscription">
                      <a routerLink="/referrals" class="text-dark-grey">
                        <i class="fas fa-user-friends"></i> &nbsp;{{'userMenu.referrals' | translate }}</a>
                    </li>
                  </ng-container>
                  <li>
                    <a href="javascript:void(0)" class="text-dark-grey" routerLink="/user/listing">
                      <i class="fas fa-object-ungroup menu-icons-alignment"></i> {{'userMenu.myItems' | translate }}</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="text-dark-grey" routerLink="/user/logout">
                      <i class="fas fa-sign-out-alt menu-icons-alignment"></i> {{'userMenu.logout' | translate }}</a>
                  </li>

                </ul>
              </li>
              <!-- Login -->
              <li>
                <a *ngIf="!userProfile" style=" cursor: pointer;" class="text-dark-grey" data-target="#register"
                  data-toggle="modal">
                  <span class="icon-user"></span>
                </a>
              </li>
            </ul>
          </div>

        </div>
        <!-- Middle -->
        <div class="mid--space">
          <a href="javascript:void(0)" routerLink="/" class="site-logo">
            <img src="assets/images/logo/sm-logo.png" alt="Logo">
          </a>
        </div>
        <!-- Right -->
        <div class="right--space">
          <app-dropdown-menu [userProfile]="userProfile"></app-dropdown-menu>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Mobile Menu -->

<div class="mobile-nav">
  <div id="mobile-mySidenav" class="sidenav" [class.fixedTop]="fixedTop">
    <div class="side-nav-wrapper">
      <ul>
        <li>
          <a href="javascript:void(0)" routerLink="/" routerLinkActive="active">
            {{'header.home' | translate }}
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" routerLink="/pricing" routerLinkActive="active">
            {{'header.pricing' | translate }}
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" routerLink="/about-us" routerLinkActive="active">
            {{'header.aboutUs' | translate }}
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" routerLink="/download" routerLinkActive="active">
            {{'header.download' | translate }}
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" routerLink="/contact" routerLinkActive="active">
            {{'header.contact' | translate }}
          </a>
        </li>
      </ul>
    </div>
    <div class="side-nav-bottom">
      <a href="javascript:void(0)" routerLinkActive="active">
        Terms and Conditions
      </a>
      <a href="javascript:void(0)" routerLinkActive="active">
        Privacy Policy
      </a>
      <a href="javascript:void(0)" routerLinkActive="active">
        Cookie Policy
      </a>
    </div>
  </div>
</div>