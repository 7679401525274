import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-luxury-slider',
  templateUrl: './luxury-slider.component.html',
  styleUrls: ['./luxury-slider.component.css']
})
export class LuxurySliderComponent implements OnInit {
  @Input() title;
  @Input() data: any;
  loading: boolean = true;
  filterData: any;
  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.loading = false;
    }), 15000;
  }
  ngOnChanges(changes: SimpleChanges) {

    this.filterData = this.data.filter(item => item?.categories[0].slug == 'luxury');

    if (this.data[0]) {
      $(document).ready(function () {
        var sliderName = $('.luxury-slider');
        $('.luxury-slider-left').click(function () {
          sliderName.slick('slickPrev');
        })
        $('.luxury-slider-right').click(function () {
          sliderName.slick('slickNext');
        })

        sliderName.slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          draggable: true,
          autoplay: false,
          arrows: false,
          dots: false,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 4,
              }
            },
            {
              breakpoint: 881,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 580,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
        });
      });
    }
  }
}
