import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ConstantsResponse } from './responses/constantsResponse';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {
  private apiBaseURL = environment.configSettings.apiURL;
  public constant: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient) { }

  public get currentConstants() {
    if (this.constant.value != null) {
      return this.constant.value;
    }
    return null;
  }
  set constants(val: any){
    this.constant.next(val);
  }

  getConstants(){
    return this.http.get<ConstantsResponse>(`${(this.apiBaseURL)}v1/constant/`,  { observe: 'response' });
  }


}
