<h1 mat-dialog-title class="text-center">Voucher</h1>
<h3 mat-dialog-title class="text-center text-danger">{{message}}</h3>
<form novalidate (ngSubmit)="setupUserVoucher()" [formGroup]="voucherForm">
  <div class="input-group mb-4 " >

    <input id='login-email-field'
           type="text"
           required
           formControlName="hashKey"
           class="form-control custom-input-group-height"
           placeholder= "{{'paymentComponent.buttons.enterVoucherKey' | translate }}" />
    <div class="input-group-append">
       <span class="input-group-text custom-span-input-group" >
         <i class="fas fa-ticket-alt"></i>
       </span>
    </div>
  </div>

</form>
  <button (click)="continueToPayment('')"  class="btn btn-warning rounded small float-left" >{{'paymentComponent.buttons.continueVoucher' | translate }}</button>
  <button (click)="setupUserVoucher()"  class="btn btn-primary rounded small float-right">{{'paymentComponent.buttons.addVoucher' | translate }}</button>

