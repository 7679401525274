<div class="dropdown">
  <a class="notification-toggler" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
    title="{{'header.notificationCenterToolPit' | translate }}">
    <i class="icon-Notification m-0"></i>


  <span class="pro-value bg-blue-notificatios" *ngIf="unreadCount!==0">{{unreadCount}}</span>
  </a>

  <div class="dropdown-menu dropdown-menu-right notification-dropdown" aria-labelledby="dropdownMenuButton"
    (scroll)="onScroll($event)">

    <div class="dropdown-item float-right">
      <a href="javascript:void(0);" (click)="markAllCorrespondenceAsRead()">
        {{'communicationComponent.notificationCenter.buttons.markAllRead' | translate }}
      </a>
    </div>

    <div *ngFor="let notification of notifications">
      <div class="dropdown-item" [ngStyle]="{'background-color': !notification.date_read ? '#a3ecff': 'transparent'}">
        <ng-container *ngIf="getLengthOfDict(notification.referenced_object) !== 0">
          <!--Correspondence type-->
          <div *ngIf="notification.related_model == 'Correspondence'">
            <div *ngIf="userProfile.username === notification.referenced_object.sender.username">
              <!-- Sender -->
              <a [routerLink]="['/item/detail/', notification.referenced_object.entity.hash_1]"
                (click)="markNotificationAsRead(notification.id)">
                <p class="notification-text">
                  <ng-container *ngIf="notification.referenced_object.approval === 'Approved'">
                    <i class="fa fa-check-circle text-success"></i>
                  </ng-container>
                  <ng-container *ngIf="notification.referenced_object.approval === 'Disapproved'">
                    <i class="fa fa-times-circle text-danger"></i>
                  </ng-container>
                  <ng-container *ngIf="notification.referenced_object.approval === 'Unknown'">
                    <i class="fa fa-exclamation-circle"></i>
                  </ng-container>
                  {{ notification.body_text }}
                </p>
              </a>
              <p>
                <small class="text-dark">{{notification.date_sent | date:'h:mm:ss a MM/dd' }}</small>
            </div>
            <div *ngIf="userProfile.username !== notification.referenced_object.sender.username ">
              <!--Recipient-->
              <p class="notification-text">
                {{ notification.body_text }}
                <ng-container *ngIf="notification.referenced_object.approval === 'Approved'">
                  <i class="fa fa-check-circle text-success"></i>
                </ng-container>
                <ng-container *ngIf="notification.referenced_object.approval === 'Disapproved'">
                  <i class="fa fa-times-circle text-danger"></i>
                </ng-container>
                <ng-container *ngIf="notification.referenced_object.approval === 'Unknown'">
                  <i class="fa fa-exclamation-circle"></i>
                </ng-container>
              </p>
              <p class="mb-1">
                <a class="text-dark ml-1 mr-2" href="javascript:void(0);" data-toggle="tooltip" data-placement="bottom"
                  title="{{'communicationComponent.notificationCenter.toolPits.seeProfile' | translate }}"
                  [routerLink]="['/user/profile', notification.referenced_object.sender.username, notification.referenced_object.entity.hash_1]"
                  (click)="markNotificationAsRead(notification.id)" style="display: inline-block;">
                  <i class="fas fa-user-alt"></i>
                </a>
                <a class="mr-2 text-dark" href="javascript:void(0);" data-toggle="tooltip" data-placement="bottom"
                  title="{{'communicationComponent.notificationCenter.toolPits.seeItem' | translate }}"
                  (click)="markNotificationAsRead(notification.id)"
                  [routerLink]="['/item/detail/', notification.referenced_object.entity.hash_1 ]"
                  style="display: inline-block;">
                  <i class="far fa-hand-pointer"></i>
                </a>
                <ng-container *ngIf="notification.referenced_object.approval === 'Unknown'">
                  <a class="mr-2" href="javascript:void(0);" data-toggle="tooltip" data-placement="bottom"
                    title="{{'communicationComponent.notificationCenter.toolPits.approveRequest' | translate }}"
                    (click)="modifyApprovalCorrespondence(notification.id, notification.referenced_object.id, 'Approved')"
                    style="display: inline-block;">
                    <i class="fa fa-check-circle text-success"></i>
                  </a>
                  <a href="javascript:void(0);" data-toggle="tooltip" data-placement="bottom"
                    title="{{'communicationComponent.notificationCenter.toolPits.disapproveRequest' | translate }}"
                    (click)="modifyApprovalCorrespondence(notification.id, notification.referenced_object.id, 'Disapproved')"
                    style="display: inline-block;">
                    <i class="fa fa-times-circle text-danger"></i>
                  </a>
                </ng-container>
              </p>
              

              <small>{{notification.date_sent | date:'h:mm:ss a MM/dd' }}</small>
            </div>
          </div>
          <!--Entity type-->
          <div *ngIf="notification.related_model == 'Entity'">
            <a [routerLink]="['/item/detail/', notification.referenced_object.hash_1 ]"
              (click)="markNotificationAsRead(notification.id)">
              <p class="notification-text">
                {{ notification.body_text }}
              </p>
              <small class="text-dark">{{notification.date_sent | date:'h:mm:ss a MM/dd' }}</small>
            </a>
          </div>
        </ng-container>
        <ng-container *ngIf="getLengthOfDict(notification.referenced_object) === 0">
          <a href="javascript:void(0)" (click)="showEntityRemoved(notification.id)">
            <p class="notification-text">
              {{ notification.body_text }}
            </p>
            <small class="text-dark">{{notification.date_sent | date:'h:mm:ss a MM/dd' }}</small>
          </a>
        </ng-container>
      </div>
    </div>


  </div>


</div>