import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VoucherService } from '../voucher/services/voucher.service';
import { AlertService } from '../../core/alert/alert.service';
import { Router } from '@angular/router';
import { ReferralService } from './services/referral.service';
import { ReferralDialogData } from './interfaces/referral-dialog-data';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html'
})
export class ReferralComponent implements OnInit {
  message: string = null;
  errorMessage: string = null;
  referralForm: FormGroup;
  submitted = false;


  constructor(public dialogRef: MatDialogRef<ReferralComponent>,
              @Inject(MAT_DIALOG_DATA) public inputData: ReferralDialogData,
              private fb: FormBuilder,
              private voucherService: VoucherService,
              private alertService: AlertService,
              private route: Router,
              private referralService: ReferralService) {}


  ngOnInit(): void {
    this.referralForm = this.fb.group({
      email1: ['', [Validators.required, Validators.email]],
      email2: ['', [Validators.required, Validators.email]],
      email3: ['', [Validators.required, Validators.email]],
      email4: ['', [Validators.required, Validators.email]],
      email5: ['', [Validators.required, Validators.email]]
    });
  }

  get form() { return this.referralForm.controls; }


  createReferrals(){
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    const usersEmails = [
      {
        email: this.form.email1.value.trim()
      },
      {
        email: this.form.email2.value.trim()
      },
      {
        email: this.form.email3.value.trim()
      },
      {
        email: this.form.email4.value.trim()
      },
      {
        email: this.form.email5.value.trim()
      }
    ];

    this.referralService.setupReferrals(usersEmails).subscribe(response => this.exit(true),
        error => this.errorMessage = error.error.error);

  }

  exit(showExitMessage?: boolean){




    this.dialogRef.close();
    if (showExitMessage === true){
      // Show message
      this.alertService.success(this.inputData.exitMessage, true);
    }

    // Go to home
    if (this.inputData.redirectToHomepage === true){
      this.route.navigate(['/']);
    }
  }

}
