<div class="tab-pane" id="profile-notifications">
  <h2 *ngIf="!isMobileScreen"  class="pane-heading">{{'userComponent.profile.communicationTab.title' | translate }}</h2>

  <div class="row tab-pane-content">
    <div class="col-12">
      <p *ngIf="userPreference === undefined" class="text-dangerr">{{'userModal.loginPage.fillPreferencesMessage' | translate }}</p>
      <h4 > {{'userComponent.profile.communicationTab.desc' | translate }}<br>
      </h4>
    </div>
    <div class="col-sm-12 col-md-7 col-lg-7">
      <form  [formGroup]="preferenceForm" (ngSubmit)="onPreferenceFormSubmit()">
        <div class="row">
          <div class="col-auto">
            <h4 class="pane-section-heading">General</h4>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-10">
            <label class="check-label" for="emailCommunication-field">{{'userComponent.profile.communicationTab.inputFields.emailCommunication.label' | translate }}</label>
          </div>
          <div class="col-2">
            <mat-slide-toggle
            id='emailCommunication-field'
                           formControlName="emailCommunication">
            </mat-slide-toggle>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-10">
            <label class="check-label" for="showEmail-field">{{'userComponent.profile.communicationTab.inputFields.showEmail.label' | translate }}</label>
          </div>
          <div class="col-2">
            <mat-slide-toggle id='showEmail-field'
            formControlName="showEmail">
            </mat-slide-toggle>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-10">
            <label class="check-label" for="showAddressPhoneNumber-field">{{'userComponent.profile.communicationTab.inputFields.showAddressPhoneNumber.label' | translate }}</label>
          </div>
          <div class="col-2">
            <mat-slide-toggle d='showAddressPhoneNumber-field'
            formControlName="showAddressPhoneNumber">
            </mat-slide-toggle>
          </div>
        </div>
  
        <div class="row mt-2">
          <div class="col-10">
            <label class="check-label" for="showLastName-field">{{'userComponent.profile.communicationTab.inputFields.showLastName.label' | translate }}</label>
          </div>
          <div class="col-2">
            <mat-slide-toggle  id='showLastName-field'
            formControlName="showLastName">
            </mat-slide-toggle>
          </div>
        </div>
  
        <div class="row mt-2">
          <div class="col-10">
            <label class="check-label" for="showFirstName-field">{{'userComponent.profile.communicationTab.inputFields.showFirstName.label' | translate }}</label>
          </div>
          <div class="col-2">
            <mat-slide-toggle id='showFirstName-field'
            formControlName="showFirstName">
            </mat-slide-toggle>
          </div>
        </div>
  
        <div class="row mt-2">
          <div class="col-10">
            <label class="check-label" for="showPostCode-field">{{'userComponent.profile.communicationTab.inputFields.showPostCode.label' | translate }}</label>
          </div>
          <div class="col-2">
            <mat-slide-toggle   id='showPostCode-field'
            formControlName="showPostCode">
            </mat-slide-toggle>
          </div>
        </div>
  
        <div class="row mt-2 ">
          <div class="col-10">
            <label class="check-label" for="showCountry-field">{{'userComponent.profile.communicationTab.inputFields.showCountry.label' | translate }}</label>
          </div>
          <div class="col-2">
            <mat-slide-toggle  id='showCountry-field'
            formControlName="showCountry">
            </mat-slide-toggle>
          </div>
        </div>
  
        <div class="row mt-2">
          <div class="col-10">
            <label class="check-label" for="showState-field">{{'userComponent.profile.communicationTab.inputFields.showState.label' | translate }}</label>
          </div>
          <div class="col-2">
            <mat-slide-toggle   id='showState-field'
            formControlName="showState">
            </mat-slide-toggle>
          </div>
        </div>
  
        <div class="row mt-2">
          <div class="col-10">
            <label class="check-label" for="showAddressLine1-field">{{'userComponent.profile.communicationTab.inputFields.showAddressLine1.label' | translate }}</label>
          </div>
          <div class="col-2">
            <mat-slide-toggle   id='showAddressLine1-field'
            formControlName="showAddressLine1">
            </mat-slide-toggle>
          </div>
        </div>
  
        <div class="row mt-2">
          <div class="col-10">
            <label class="check-label" for="showAddressLine1-field">{{'userComponent.profile.communicationTab.inputFields.showEmploymentStatus.label' | translate }}</label>
          </div>
          <div class="col-2">
            <mat-slide-toggle   id='showEmploymentStatus-field'
            formControlName="showEmploymentStatus">
            </mat-slide-toggle>
          </div>
        </div>
  
        <div class="row mt-4">
          <div class="col-md-6 col-sm-12">
            <button [disabled]="loading" class="btn btn-primary">{{'userComponent.profile.communicationTab.confirmationButton' | translate }}</button>
          </div>
        </div>
    </form>
    </div>
    <div class="col-5 tab-pane-image" *ngIf="!isMobileScreen">
      <div>
        <img src="assets/images/profile/notification.svg"/>
      </div>
    </div>
  </div>


</div>
