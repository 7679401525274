import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '../../core/alert/alert.service';
import { ContactusService } from '../services/contactus.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})

export class ContactComponent implements OnInit {

  contactusForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private contactusService: ContactusService,
    private alertService: AlertService) { window.scrollTo(0, 0); }


  get form() { return this.contactusForm.controls; }

  ngOnInit(): void {
    this.contactusForm = this.formBuilder.group({

      full_name: ['', [Validators.required]],
      sender_email: ['', [Validators.compose([
        // 1. Email Field is Required
        Validators.required,
        // 2. Email Field is an email
        Validators.email
      ])]],
      message: ['', [Validators.required]],
      subject: ['', [Validators.required]],
    });
  }

  onSubmit() {
    this.submitted = true;
    // reset alerts on submit
    this.alertService.clear();
    // stop here if form is invalid
    if (this.contactusForm.invalid) {
      return;
    }

    this.loading = true;
    this.contactusService.sendContactus(this.contactusForm.value)
      .subscribe(
        response => {
          /* 1. stop the loading
           * 2. stop the submission
           * 3. show the response message on the screen
           * 4. reset the form
          */
          this.loading = false;
          this.submitted = false;
          this.alertService.success(response.body.message, true);
          this.contactusForm.reset();
          this.router.navigate(['/contact']);
        },
        error => {
          console.log(error);
          console.log(error.error);
          console.log(error.error.error[0]);
          this.alertService.error(error.error.error[0]);
          this.loading = false;
        });

  }
}
