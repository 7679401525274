<div class="tab-pane" *ngIf="userProfile">
  <h2 class="pane-heading" *ngIf="!isMobileScreen">{{'userComponent.profile.generalTab.title' | translate }}</h2>
  <div class="row tab-pane-content">
    <div class="col-sm-12 col-md-7 col-lg-7">
      <form #profi [formGroup]="profileForm" (ngSubmit)="onProfileFormSubmit()">
        <div class="form-group" [ngStyle]="{'text-align':isMobileScreen?'center':'unset'}">
        <div class="user-img">

          <input type="file" (change)="uploadProfilePicture($event)" id='profile-image' name="#"  class="none">
          <label for="profile-image" style="cursor: pointer;" >
              <img *ngIf="!userProfile.profile.profile_picture" src="assets/images/user/user-default.jpg" class="rounded-circle profile-img" alt="#">
              <img *ngIf="userProfile.profile.profile_picture" src="{{ userProfile.profile.profile_picture }}" class="rounded-circle profile-img" alt="#">
          </label>
      </div>

        </div>

        <div class="form-group">
          <label class="field-label" for="profile-fist-name-field">{{'userComponent.profile.generalTab.inputFields.firstName.label' | translate }}</label>
          <input id="profile-fist-name-field"
          name="firstName"
          [(ngModel)]="userProfile.first_name"
          formControlName="firstName"
          [ngClass]="{ 'is-invalid': profileFormSubmitted && userProfileForm.firstName.errors }"
          class="form-control"
          type="text"
          value = "userProfile.profile.birthDate"
          placeholder="{{'userComponent.profile.generalTab.inputFields.firstName.placeholder' | translate}}"
          >
          
            <div *ngIf="profileFormSubmitted && userProfileForm.firstName.errors" class="invalid-feedback">
              <div *ngIf="userProfileForm.firstName.errors.required">{{'userComponent.profile.generalTab.inputFields.firstName.errors.required' | translate }}</div>
              <div *ngIf="userProfileForm.firstName.errors.minLength">{{'userComponent.profile.generalTab.inputFields.firstName.errors.minLength' | translate }}</div>
            </div>
        </div>

        <div class="form-group">
          <label class="field-label" for="profile-last-name-field">{{'userComponent.profile.generalTab.inputFields.lastName.label' | translate }}</label>
          <input id="profile-last-name-field"
                 name="lastName"
                 formControlName="lastName"
                 [(ngModel)]="userProfile.last_name"
                 [ngClass]="{ 'is-invalid': profileFormSubmitted && userProfileForm.lastName.errors }"
                 class="form-control"
                 type="text"
                 placeholder="{{'userComponent.profile.generalTab.inputFields.lastName.placeholder' | translate}}"
        >
          <div *ngIf="profileFormSubmitted && userProfileForm.lastName.errors" class="invalid-feedback">
            <div *ngIf="userProfileForm.lastName.errors?.required">{{'userComponent.profile.generalTab.inputFields.lastName.errors.required' | translate }}</div>
            <div *ngIf="userProfileForm.firstName.errors?.minLength">{{'userComponent.profile.generalTab.inputFields.lastName.errors.minLength' | translate }}</div>
          </div>
        </div>
        
        <div class="form-group">
          <label class="field-label" for="profile-email-field">{{'userComponent.profile.generalTab.inputFields.email.label' | translate }}</label>
          <input id='profile-email-field'
          [ngClass]="{ 'is-invalid': profileFormSubmitted && userProfileForm.email.errors }"
          formControlName="email"
          [(ngModel)]="userProfile.email"
          name="email"
          class="form-control"
          placeholder="{{'userComponent.profile.generalTab.inputFields.email.placeholder' | translate}}"
   

          type="email" readonly>
          <div *ngIf="profileFormSubmitted && userProfileForm.email.errors" class="invalid-feedback">
            <div *ngIf="userProfileForm.email.errors.required">{{'userComponent.profile.generalTab.inputFields.email.errors.required' | translate }}</div>
            <div *ngIf="userProfileForm.email.errors.email">{{'userComponent.profile.generalTab.inputFields.email.errors.email' | translate }}</div>
          </div>
        </div>
          

        <div class="form-group row">
          <div class="col-sm-6">
          <label for="gender" class="field-label">{{'userComponent.profile.generalTab.inputFields.gender.label' | translate }}</label>
          <mat-form-field style="width: 100%;">
            <mat-label>{{'userComponent.profile.generalTab.inputFields.gender.selectText' | translate }}</mat-label>
            <mat-select 
            [(ngModel)]="userProfile.profile.gender"
            formControlName="gender"
                        panelClass="example-panel-blue">
              <mat-option *ngFor="let gender of genderChoices" [value]="gender.abbr">
                {{ gender.name }}
              </mat-option>
            </mat-select>
<!--                <mat-error >{{'userComponent.profile.generalTab.inputFields.gender.errors.required' | translate }}</mat-error>-->
          </mat-form-field>
        </div>

        <div class="col-sm-6">
          <label for="employeeStatus" class="field-label">{{'userComponent.profile.generalTab.inputFields.employmentStatus.label' | translate }}</label>
        
          <mat-form-field style="width: 100%;">
            <mat-label>{{'userComponent.profile.generalTab.inputFields.employmentStatus.selectText' | translate }}</mat-label>
            <mat-select 
            [(ngModel)]="userProfile.profile.employment_status"
            formControlName="employmentStatus"
                        panelClass="example-panel-blue">
              <mat-option *ngFor="let employmentChoice of employmentChoices" [value]="employmentChoice.abbr">
                {{ employmentChoice.name }}
              </mat-option>
            </mat-select>
           <mat-error >{{'userComponent.profile.generalTab.inputFields.employmentStatus.errors.required' | translate }}</mat-error>
          </mat-form-field>
        </div>
        </div>


        <div class="form-group">
          <label class="field-label" for="profile-birth-date-field">{{'userComponent.profile.generalTab.inputFields.birthDate.label' | translate }}</label>
          <input type="date"
              class="form-control"
              formControlName="birthDate"
              id="profile-birth-date-field"
              name="birthDate"
              [(ngModel)]="userProfile.profile.birth_date"
              [ngClass]="{ 'is-invalid': profileFormSubmitted && userProfileForm.birthDate.errors }" >
              
          <div *ngIf="profileFormSubmitted && userProfileForm.birthDate.errors" class="invalid-feedback">
              <div *ngIf="userProfileForm.birthDate.errors.required">{{'userComponent.profile.generalTab.inputFields.birthDate.errors.required' | translate }}</div>
              <div *ngIf="userProfileForm.birthDate.errors">{{'userComponent.profile.generalTab.inputFields.birthDate.errors.invalid' | translate }}</div>
          </div>
          
        </div>

        <div class="row">
          <div class="col-md-6 col-sm-12 mt-2">
            <a style="cursor: pointer;" (click)="closeAccount()" class="btn btn-danger">{{'userComponent.profile.generalTab.closeAccountButton' | translate }}</a>
          </div>
          <div class="col-md-6 col-sm-12 mt-2">
            <button [disabled]="loading" class="btn btn-primary">{{'userComponent.profile.generalTab.confirmationButton' | translate }}</button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-5 tab-pane-image" *ngIf="!isMobileScreen">
      <div>
        <img src="assets/images/profile/general-info.svg"/>
      </div>
    </div>
  </div>
</div>
