

<div class="tab-pane" >
  <h2 *ngIf="!isMobileScreen" class="pane-heading">{{'userComponent.changePassword.tabTitle' | translate }}</h2>
  <div class="row tab-pane-content">
    <div class="col-12 text-center mb-3" *ngIf="isMobileScreen">
      <div>
        <img src="assets/images/profile/change-pass.svg"/>
      </div>
    </div>
    <div class="col-sm-12 col-md-7 col-lg-7" *ngIf="{ old_p: false, new_p: false, repeat_p: false } as visible_p">
      <form #passwordReset [formGroup]="passwordChangeForm" (ngSubmit)="onSubmit()">


        <!-- Old password field -->
        <div class="form-group">
          <label class="filed-label" for="old-password-field">
            {{'userComponent.changePassword.inputFields.oldPassword.label' | translate }}
          </label>
          <div class="input-group">
            <input id='old-password-field'
                   [type]="visible_p.old_p ? 'text' : 'password'"
                   formControlName="oldPassword"
                   class="form-control"
                   [ngClass]="{ 'is-invalid': submitted && form.oldPassword.errors }"
                   placeholder="{{'userComponent.changePassword.inputFields.oldPassword.placeholder' | translate }}"
            />
            <div class="input-group-append">
                <span class="input-group-text custom-span-input-group" (click)="visible_p.old_p=!visible_p.old_p;">
                  <i
                    class="fa"
                    [ngClass]="{
                                  'fa-eye-slash': !visible_p.old_p,
                                  'fa-eye': visible_p.old_p
                                }"
                    
                  ></i>
                </span >
            </div>
            <div *ngIf="submitted && form.oldPassword.errors" class="invalid-feedback">
  
              <div *ngIf="form.oldPassword.errors.required">
                {{'userComponent.changePassword.inputFields.oldPassword.errors.required' | translate }}
              </div>
    
              <ng-container *ngIf="form.oldPassword.errors.backendError">
                <div>{{ form.oldPassword.errors.backendError }}</div>
              </ng-container>
    
            </div>
          </div>
        </div>

        <!-- New password 1 -->
        <div class="form-group">
          <label class="filed-label" for="reset-password-field">
            {{'userComponent.changePassword.inputFields.password.label' | translate }}
          </label>
          <div class="input-group">

            <input id='reset-password-field'
                   [type]="visible_p.new_p ? 'text' : 'password'"
                   formControlName="password"
                   class="form-control"
                   [ngClass]="{ 'is-invalid': submitted && form.password.errors }"
                   placeholder="{{'userComponent.changePassword.inputFields.password.placeholder' | translate }}"
            />
            <div class="input-group-append">
                <span class="input-group-text custom-span-input-group" (click)="visible_p.new_p=!visible_p.new_p;">
                  <i
                    class="fa"
                    [ngClass]="{
                                  'fa-eye-slash': !visible_p.new_p,
                                  'fa-eye': visible_p.new_p
                                }"
                    
                  ></i>
                </span>
            </div>
            <div *ngIf="submitted && form.password.errors" class="invalid-feedback">
              <div *ngIf="form.password.errors.required">{{'userComponent.changePassword.inputFields.password.errors.required' | translate }}</div>
              <div *ngIf="form.password.errors.hasNumber">{{'userComponent.changePassword.inputFields.password.errors.hasNumber' | translate }}</div>
              <div *ngIf="form.password.errors.hasCapitalCase">{{'userComponent.changePassword.inputFields.password.errors.hasCapitalCase' | translate }}</div>
              <div *ngIf="form.password.errors.hasSmallCase">{{'userComponent.changePassword.inputFields.password.errors.hasSmallCase' | translate }}</div>
              <div *ngIf="form.password.errors.minLength">{{'userComponent.changePassword.inputFields.password.errors.minLength' | translate }}</div>
              <div *ngIf="form.password.errors.NoPasswordMatch">{{'userComponent.changePassword.inputFields.password.errors.NoPasswordMatch' | translate }}</div>
  
  
              <ng-container *ngIf="form.password.errors.backendError">
                <div *ngFor="let errorMessage of form.password.errors.backendError">{{ errorMessage }}</div>
              </ng-container>
  
            </div>
          </div>

        </div>
        

        <!-- New password 2 -->
        <div class="form-group">
          <label class="filed-label" for="reset-password1-field">
            {{'userComponent.changePassword.inputFields.password1.label' | translate }}
          </label>
          <div class="input-group">
            <input id='reset-password1-field'
                   [type]="visible_p.repeat_p ? 'text' : 'password'"
                   formControlName="password1"
                   class="form-control"
                   [ngClass]="{ 'is-invalid': submitted && form.password1.errors }"
                   placeholder="{{'userComponent.changePassword.inputFields.password1.placeholder' | translate }}"
            />
            <div class="input-group-append">
                <span class="input-group-text custom-span-input-group" (click)="visible_p.repeat_p=!visible_p.repeat_p;">
                  <i
                    class="fa"
                    [ngClass]="{
                                  'fa-eye-slash': !visible_p.repeat_p,
                                  'fa-eye': visible_p.repeat_p
                                }"
                    
                  ></i>
                </span>
            </div>
            <div *ngIf="submitted && form.password1.errors" class="invalid-feedback">
              <div *ngIf="form.password1.errors.required">{{'userComponent.changePassword.inputFields.password1.errors.required' | translate }}</div>
              <div *ngIf="form.password1.errors.NoPasswordMatch">{{'userComponent.changePassword.inputFields.password1.errors.NoPasswordMatch' | translate }}</div>
  
              <ng-container *ngIf="form.password1.errors.backendError">
                <div *ngFor="let errorMessage of form.password1.errors.backendError">{{ errorMessage }}</div>
              </ng-container>
  
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-md-6 col-sm-12">
            <button [disabled]="loading" class="btn btn-primary">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                {{'userComponent.changePassword.buttons.confirmationButton' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-5 tab-pane-image" *ngIf="!isMobileScreen">
      <div>
        <img src="assets/images/profile/change-pass.svg"/>
      </div>
    </div>
  </div>
</div>


