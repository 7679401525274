import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VoucherService } from './services/voucher.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html'
})
export class VoucherComponent implements OnInit {
  message: string = null;
  voucherForm: FormGroup;
  submitted = false;


  constructor(public dialogRef: MatDialogRef<VoucherComponent>,
              private fb: FormBuilder,
              private voucherService: VoucherService) {}


  ngOnInit(): void {
    this.voucherForm = this.fb.group({
      hashKey: ['', [Validators.required]]
    });
  }

  get form() { return this.voucherForm.controls; }


  setupUserVoucher(){

    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.voucherService.attachVoucherToUser(this.form.hashKey.value).subscribe(response => {
        this.message = response.body.message;
        this.continueToPayment(response.body.message);
    }, error => this.message = error.error.error);
  }

  continueToPayment(message: string){
    this.dialogRef.close(message);
  }


}
