<div class="page-404">

  <section>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="404-img">
            <img src="assets/images/404-img.png" alt="image">
          </div>
          <div class="">
            <h5 class="text-custom-white rubik full-width">{{'404Page.message' | translate }}</h5>
            <a routerLink="/" class="btn-second blue-btn mt-4">{{'404Page.homeButton' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>
