<form [formGroup]="searchForm">

    <!-- New Primary Menu -->
    <div class="primary-menu">
      <div class="container">
        <div class="primary-menu-container">
          <!-- Desktop -->
          <!-- Desktop and Mobile -->
          <ul class="primary-parent-menu d-flex ">
            <li class="dropdown" *ngFor="let category of categories">
              <a href="javascript:void(0)" class="text-dark-grey" data-toggle="dropdown">
                <i class="icon-home mr-1" *ngIf="category.name == 'Real estate'"></i>
                <i class="icon-jobs mr-1" *ngIf="category.name == 'Jobs'"></i>
                <i class="icon-luxery mr-1" *ngIf="category.name == 'Luxury'"></i>
                <i class="icon-vehicle mr-0" *ngIf="category.name == 'Vehicle'"></i>
                {{ category.name }}
                <i class="icon-arrow-down ml-0"></i>
              </a>
              <ul class="dropdown-menu">
                <li *ngFor="let child of category.children">
                  <a href="javascript:void(0)" (click)="searchEntityClass( child.name )">
                    <i class="{{child.icon}}"></i>&nbsp;{{ child.name }}
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="javascript:void(0)" class="text-dark-grey" href="javascript:void(0)"
                (click)="getDealsAroundLocation()">
                <i class="icon-deals mr-2"></i>
                {{'header.dealsAroundMeFeature.textHeader' | translate }}
                
              </a>
            </li>
          </ul>
          <!-- Mobile -->
          <!-- <div class="mobile-primary-menu d-md-none d-flex py-3">
            <a href="javascript:void(0)" class="text-dark-grey" href="javascript:void(0)"
              (click)="getDealsAroundLocation()">
              <i class="icon-deals mr-2"></i>
              {{'header.dealsAroundMeFeature.textHeader' | translate }}
            </a>

          </div> -->

        </div>
      </div>
    </div>
  </form>

<div class="mobile-menu-second">
  <!-- Logo Section -->
  <section class="p-relative">
    <!-- <div class="main-slider home-2">
      <div class="slider-item">
        <img *ngIf="chosenLanguage === 'de'" src="assets/images/banner/home_banner_de.jpg" alt="Slider"
          class="rounded full-width">
        <img *ngIf="chosenLanguage === 'en'" src="assets/images/banner/home_banner_en.jpg" alt="Slider"
          class="rounded full-width">
      </div>
    </div> -->
    <!-- New Banner -->
    <div class="container">
      <div class="home-banner">
        <h2>   {{'header.dealsAroundMeFeature.CoverText' | translate }}</h2>
        <p>
          {{'header.dealsAroundMeFeature.CoverDescription' | translate }}
        </p>

        <div class="d-block d-md-none">
          <app-header-search></app-header-search>
        </div>
      </div>
    </div>
  </section>


  <!-- Header Banner Search -->
  <div class="header-banner-search d-none d-md-block">
    <div class="container">
      <app-header-search></app-header-search>
    </div>
  </div>


  <!-- Other's Sections -->
  <app-about-app-section></app-about-app-section>
  <app-explore-products-section></app-explore-products-section>

  <app-real-esatte-slider title="{{'header.realEsate' | translate }}" [data]="entities"></app-real-esatte-slider>
  <div class="my-100">
    <app-luxury-slider title="{{'header.luxury' | translate }}" [data]="entities"></app-luxury-slider>
    
  </div>
  <div class="my-100">
    <app-vehicle-slider title="{{'header.vehicle' | translate }}" [data]="entities"></app-vehicle-slider>
  </div>
  <div class="my-100">
    <app-jobs-slider title="{{'header.jobs' | translate }}" [data]="entities"></app-jobs-slider>
  </div>


  <app-become-seller-section></app-become-seller-section>

  <!-- <app-swapapp-section></app-swapapp-section> -->


</div>