import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ConstantsService } from './core/services/constants.service';
import {
  NgcCookieConsentService,
  NgcInitializeEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent
} from 'ngx-cookieconsent';
import { Subscription} from 'rxjs';
import { CategoryService } from './module-entity/services/category.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from './core/services/cookie.service';
import { GlobalLoadingService } from './core/global-loading/global-loading.service';

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {
  // keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;
  //
  private constantSubscription: Subscription;
  //
  private categorySubscription: Subscription;

  showHeader: boolean; // used to show the header
  showFooter: boolean; // used to show the footer
  title = 'swap-app';  // used to show the title of the app

  location: any; // used to process router location
  routerSubscription: any; // used to store router subscription

  stripePublicKey = null;

  chosenLang: string;

  globalLoading = false;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private constantsService: ConstantsService,
              private categoryService: CategoryService,
              private ccService: NgcCookieConsentService,
              private cookieService: CookieService,
              translate: TranslateService,
              private globalLoadingService: GlobalLoadingService) {
    translate.addLangs(['en', 'de']);
    const lang = this.cookieService.LanguageCookie;
    this.cookieService.chosenLanguage.subscribe(chosenLang => this.chosenLang = chosenLang);
    translate.use(this.cookieService.LanguageCookie);
    document.documentElement.lang = this.chosenLang;

    translate.get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
      .subscribe(data => {
        this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
        // Override default messages with the translated ones
        this.ccService.getConfig().content.header = data['cookie.header'];
        this.ccService.getConfig().content.message = data['cookie.message'];
        this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
        this.ccService.getConfig().content.allow = data['cookie.allow'];
        this.ccService.getConfig().content.deny = data['cookie.deny'];
        this.ccService.getConfig().content.link = data['cookie.link'];
        this.ccService.getConfig().content.cookiePolicyLink = data['cookie.policy'];

        this.ccService.destroy();
        this.ccService.init(this.ccService.getConfig())
         // update config with translated messages
      });
  }



  ngOnInit(){
    this.globalLoadingService.globalLoading.subscribe(globalLoading => this.globalLoading = globalLoading);

    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });
    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });
    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });
    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });
    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });
    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        
      });




    // get the constants
    this.constantSubscription = this.constantsService.getConstants().subscribe(response => {
      this.constantsService.constants = response.body.data;
      this.stripePublicKey = response.body.data.stripe_public_key;
      // console.log(this.stripePublicKey);
    }, error => {
      window.location.reload();
      console.error(error);
    });
    // get the categories
    this.categorySubscription = this.categoryService.getCategories().subscribe(response => {
      this.categoryService.category = response.body.data;
    }, error => console.error(error) );

    // Whenever the path(route) changes, the subscription to the events property of the reference router(this.router.events) fires.
    this.router.events.pipe(
        // 1. We are filtering the above list of events to check when when the NavigationEnd event is triggered
        filter(events => events instanceof NavigationEnd),
        // 2. Next we are mapping the result of the filter to the ActivatedRoute reference.
        map(evt => this.activatedRoute),
        // 3. In the next step we are checking if the current active route has any child route.
        map(route => {
          while (route.firstChild) {
            // firstChild is a property of the active route(AppComponent) which indicates
            // the first child of this route in the router state tree
            route = route.firstChild;
          }
          return route;
        }))
      .pipe(
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
        /*  we are checking if the outlet property of the component is primary.
         *  If yes, we are extracting the data property of the route
         */
      ).subscribe(x => {
        /* we are subscribing to the result i.e data property of route and checking if the header property of data
         * object is true or not. If true,we are setting visibility as true and the header, footer will be visible
         * else they wont be visible.
         */
        x.header === false ? this.showHeader = false : this.showHeader = true;
        x.footer === false ? this.showFooter = false : this.showFooter = true;
    }
    );
  }


  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
    this.constantSubscription.unsubscribe();
    this.categorySubscription.unsubscribe();
  }
}


