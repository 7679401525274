<div class="mobile-menu-second">
  <section class="section-padding">
    <div class="container" *ngIf="!loading">
      <!-- //you have start work form here -->

      <div class="mylistingmain">
        <div>
          <h1 class="mylisting">
            {{ "userComponent.itemsListing.title" | translate }}
          </h1>
        </div>

        <div class="listinginputside">
          <button
            *ngIf="userProfile"
            [routerLink]="['../../item/add']"
            class="postnew"
          >
            <img
              src="../../../assets/images/listing/postnewplusbtn.svg"
              alt=""
            />
            {{ "buttons.offer" | translate }}
          </button>
        </div>
        <button
          *ngIf="userProfile"
          [routerLink]="['../../item/add']"
          class="postnewresponsive"
        >
          <img src="../../../assets/images/listing/postnewplusbtn.svg" alt="" />
          {{ "buttons.offer" | translate }}
        </button>
      </div>

      <div class="listingsflex" *ngFor="let entity of entities">
        <div class="listingschildflex">
          <div class="smartwatch">
            <!-- <a href=""><img class="heart" src="../../../assets/images/listing/Heart.svg" alt=""></a> 
           
             -->
            <a [routerLink]="['/item/detail', entity.hash_1]">
              <img
                src="https://staging-api.swapapp.de{{ entity.cover_image }}"
                class="smartwatchimg"
                alt="cover image"
                *ngIf="entity.cover_image !== null"
              />
              <img
                src="https://staging-api.swapapp.de/media/{{ entity.entity_class.default_cover_image }}"
                class="smartwatchimg"
                alt="cover image"
                *ngIf="entity.cover_image === null"
              />
            </a>
          </div>

          <div class="ddmyformargin">
            <p class="ddmy">{{ entity.date_created | date : "medium" }}</p>
            <h1 class="producttitle">{{ entity.title }}</h1>

            <div class="money">
              <img src="../../../assets/images/listing/moneys.svg" alt="" />
              <p>€ {{ entity.price }}</p>
            </div>

            <div class="viewsinterestedmain">
              <div class="views">
                <img
                  src="../../../assets/images/listing/categoryicon.svg"
                  alt=""
                />
                <p>{{ entity.categories[0].name }}</p>
              </div>
              <div class="interested">
                <img
                  src="../../../assets/images/listing/categoryicon.svg"
                  alt=""
                />
                <p>{{ entity.entity_class.name }}</p>
              </div>
            </div>

            <div class="location">
              <img
                src="../../../assets/images/listing/locationlist.svg"
                alt=""
              />
              <p>{{ entity.city.name }}, {{ entity.city.country }}</p>
            </div>
          </div>
        </div>

        <div class="allbtn">
          <div class="active">
            <a href="javascript:void(0)" class="text-dark-grey">
              <img src="../../../assets/images/listing/activeball.svg" alt="" />
            </a>
            <a
              href="javascript:void(0);"
              *ngIf="!entity.is_public"
              (click)="toggleEntityVisibility(entity.hash_1, true)"
              class="text-danger ml-2"
            >
              {{
                "userComponent.itemsListing.itemsFeatures.hidden" | translate
              }}
              <i class="fas fa-lg"></i>
            </a>
            <a
              href="javascript:void(0);"
              *ngIf="entity.is_public"
              (click)="toggleEntityVisibility(entity.hash_1, false)"
              class="text-success ml-2"
            >
              {{
                "userComponent.itemsListing.itemsFeatures.public" | translate
              }}
              <i class="fas fa-lg"></i>
            </a>
          </div>
          <div class="editbtnflex">
            <div>
              <button
                [routerLink]="['/item/edit', entity.hash_1]"
                class="text-dark mr-2"
                class="editview"
              >
                <img
                  src="../../../assets/images/listing/editbtn.svg"
                  alt=""
                /><span class="responsivebtn">{{
                  "userComponent.itemsListing.buttons.edit" | translate
                }}</span>
              </button>
            </div>
            <div>
              <button
                [routerLink]="['/item/detail', entity.hash_1]"
                class="editview"
              >
                <img
                  src="../../../assets/images/listing/eye2.svg"
                  alt=""
                /><span class="responsivebtn">View</span>
              </button>
            </div>
            <div>
              <button
                href="javascript:void(0);"
                (click)="deleteItem(entity.hash_1)"
                class="delete"
              >
                <img src="../../../assets/images/listing/trash.svg" alt="" />
                <span class="responsivebtn">{{
                  "userComponent.itemsListing.buttons.remove" | translate
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12" *ngIf="totalPages.length > 1">
        <nav class="custom-pagination">
          <ul class="pagination">
            <li class="page-item" *ngIf="previousLink">
              <a
                class="page-link"
                href="javascript:void(0)"
                (click)="goToPage(currentPage - 1)"
                >{{ "pagination.previous" | translate }}</a
              >
            </li>
            <li
              class="page-item"
              *ngFor="let page of totalPages"
              [ngClass]="{ active: currentPage == page }"
            >
              <a
                class="page-link"
                href="javascript:void(0)"
                (click)="goToPage(page)"
                >{{ page }}</a
              >
            </li>
            <li class="page-item" *ngIf="nextLink">
              <a
                class="page-link"
                href="javascript:void(0)"
                (click)="goToPage(currentPage + 1)"
                >{{ "pagination.next" | translate }}</a
              >
            </li>
          </ul>
        </nav>
      </div>
      <div
        class="alert-warning-listing text-center"
        role="alert"
        *ngIf="entities.length === 0"
      >
        <span>{{
          "userComponent.itemsListing.messages.noItems" | translate
        }}</span>
      </div>
    </div>

    <!-- Loading ball -->
    <div class="ball-container" *ngIf="loading" style="height: 500px">
      <div class="ball ball-one"></div>
      <div class="shadow shadow-one"></div>
    </div>
    <div class="clearfix"></div>
  </section>
</div>
