import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { GetReferralsResponse } from '../responses/getReferralsResponse';
import { SetupReferralsResponse } from '../responses/setupReferralsResponse';
import { ModifyReferral } from '../models/modifyReferral';
import { UpdateReferralResponse } from '../responses/updateReferralResponse';


@Injectable({
  providedIn: 'root'
})
export class ReferralService {

  private apiBaseURL = environment.configSettings.apiURL;
  constructor(private http: HttpClient) { }


  modifyReferral(modifiedReferralObj: ModifyReferral){
    return this.http.patch<UpdateReferralResponse>(`${this.apiBaseURL}v1/subscription/referral/`, modifiedReferralObj,
      { observe: 'response' });
  }

  getReferrals() {
    return this.http.get<GetReferralsResponse>(`${this.apiBaseURL}v1/subscription/referral/`,
      { observe: 'response' });
  }
  setupReferrals(referrals: Array<{email: string}>) {
    return this.http.post<SetupReferralsResponse>(`${this.apiBaseURL}v1/subscription/referral/`, referrals,
      { observe: 'response' });
  }
}
