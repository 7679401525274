<div class="mobile-menu-second">

  <!-- Adding Listing -->
  <section class="section-padding add-listing-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <div class="text-wrapper text-center">
              <h2 class="fw-500 no-margin">
                {{'entityComponent.addEntity.title' | translate }}
              </h2>
              <span class="line-grey"></span><span class="line-blue"></span><span class="line-grey"></span>
              <p class="text-dark-grey">
                {{'entityComponent.addEntity.subtitle' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div id="add-listing-tab" class="step-app">
            <ul class="step-steps">
              <li [ngClass]="{'active' : displayComponents['showAddCategory'], 'done': !displayComponents['showAddCategory']}">
                <a href="javascript:void(0)"  (click)="changeComponent('showAddCategory')"> <span class="number"></span>
                  <span class="step-name">
                    {{'entityComponent.addEntity.steps.category.title' | translate }}
                  </span>
                </a>
              </li>
              <li [ngClass]="{'active' : displayComponents['showAddProperties'], 'done': displayComponents['showAddGallery']
              || displayComponents['showAddLocation'] || displayComponents['showAddConfirmation'] }">
                <a href="javascript:void(0)"  (click)="changeComponent('showAddProperties')"> <span class="number"></span>
                  <span class="step-name">
                    {{'entityComponent.addEntity.steps.properties.title' | translate }}
                  </span>
                </a>
              </li>
              <li [ngClass]="{'active' : displayComponents['showAddGallery'], 'done': displayComponents['showAddLocation'] ||
              displayComponents['showAddConfirmation']}" >
                <a href="javascript:void(0)" (click)="changeComponent('showAddGallery')"> <span class="number"></span>
                  <span class="step-name">
                    {{'entityComponent.addEntity.steps.gallery.title' | translate }}
                  </span>
                </a>
              </li>
              <li [ngClass]="{'active' : displayComponents['showAddLocation'], 'done': displayComponents['showAddConfirmation']}" >
                <a href="javascript:void(0)" (click)="changeComponent('showAddLocation')"> <span class="number"></span>
                  <span class="step-name">
                    {{'entityComponent.addEntity.steps.location.title' | translate }}
                  </span>
                </a>
              </li>
              <li [ngClass]="{'active' : displayComponents['showAddConfirmation']}" >
                <a href="javascript:void(0)" (click)="changeComponent('showAddConfirmation')"> <span class="number"></span>
                  <span class="step-name">
                    {{'entityComponent.addEntity.steps.confirmation.title' | translate }}
                  </span>
                </a>
              </li>
            </ul>
            <div class="step-content" *ngIf="editMode">
              <ng-container *ngIf="entityToEdit">
                <app-add-category [hidden]="!displayComponents['showAddCategory']" (currentComponent)="changeComponent($event)"></app-add-category>
                <app-add-properties [hidden]="!displayComponents['showAddProperties']" (currentComponent)="changeComponent($event)" [entityClass]="entityClass"></app-add-properties>
                <app-add-gallery [hidden]="!displayComponents['showAddGallery']" (currentComponent)="changeComponent($event)"></app-add-gallery>
                <app-add-location [hidden]="!displayComponents['showAddLocation']" (currentComponent)="changeComponent($event)"></app-add-location>
                <app-add-confirmation [hidden]="!displayComponents['showAddConfirmation']" (currentComponent)="changeComponent($event)"></app-add-confirmation>
              </ng-container>
            </div>
            <div class="step-content" *ngIf="!editMode">
              <app-add-category [hidden]="!displayComponents['showAddCategory']" (currentComponent)="changeComponent($event)"></app-add-category>
              <app-add-properties [hidden]="!displayComponents['showAddProperties']" (currentComponent)="changeComponent($event)" [entityClass]="entityClass"></app-add-properties>
              <app-add-gallery [hidden]="!displayComponents['showAddGallery']" (currentComponent)="changeComponent($event)"></app-add-gallery>
              <app-add-location [hidden]="!displayComponents['showAddLocation']" (currentComponent)="changeComponent($event)"></app-add-location>
              <app-add-confirmation [hidden]="!displayComponents['showAddConfirmation']" (currentComponent)="changeComponent($event)"></app-add-confirmation>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Adding Listing -->
</div>
