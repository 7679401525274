<div class="tab-pane">
  <h2 *ngIf="!isMobileScreen" class="pane-heading">
    {{ "userComponent.profile.searchPreferenceTab.title" | translate }}
  </h2>
  <div class="row tab-pane-content">
    <div class="col-12 text-center mb-3" *ngIf="isMobileScreen">
      <div>
        <img src="assets/images/profile/search-prefereces.svg" />
      </div>
    </div>
    <div class="col-sm-12 col-md-7 col-lg-7">
      <form
        [formGroup]="searchPreferenceForm"
        (ngSubmit)="onPreferenceFormSubmit()"
      >
        <!--Categories-->
        <div class="form-group row">
          <div class="col-sm-6">
            <label class="field-label" for="category-field">
              {{
                "userComponent.profile.searchPreferenceTab.inputFields.category.label"
                  | translate
              }}
            </label>
            <mat-form-field style="width: 100%">
              <mat-label>
                {{ selectCategoryText }}
              </mat-label>
              <mat-select formControlName="category">
                <mat-option *ngFor="let ctr of categories" [value]="ctr.id">
                  {{ ctr.name }}
                </mat-option>
              </mat-select>
              <mat-error>{{
                "userComponent.profile.searchPreferenceTab.inputFields.category.errors.required"
                  | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!--Main Category-->
          <div
            class="col-sm-6"
            *ngIf="
              searchPreferenceFormControls.category.value !== selectCategoryText
            "
          >
            <label class="field-label" for="entityClass-field">
              {{
                "userComponent.profile.searchPreferenceTab.inputFields.mainCategory.label"
                  | translate
              }}
            </label>
            <mat-form-field style="width: 100%">
              <mat-label>
                {{ selectMainCategoryText }}
              </mat-label>
              <mat-select
                formControlName="entityClass"
                panelClass="example-panel-blue"
              >
                <mat-option
                  *ngFor="let ent of selectableEntityClasses"
                  [value]="ent.id"
                >
                  {{ ent.name }}
                </mat-option>
              </mat-select>
              <mat-error>{{
                "userComponent.profile.searchPreferenceTab.inputFields.mainCategory.errors.required"
                  | translate
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <!--Country-->
        <div class="form-group">
          <label class="field-label" for="country-field">
            {{
              "userComponent.profile.searchPreferenceTab.inputFields.country.label"
                | translate
            }}
          </label>
          <mat-form-field style="width: 100%">
            <mat-label>
              {{ selectCountryText }}
            </mat-label>
            <mat-select
              required
              formControlName="country"
              panelClass="example-panel-blue"
            >
              <mat-option
                *ngFor="let ctr of availableCountries"
                [value]="ctr.iso_3166_1_a2"
              >
                {{ ctr.name }}
              </mat-option>
            </mat-select>
            <mat-error>
              {{
                "userComponent.profile.searchPreferenceTab.inputFields.country.errors.required"
                  | translate
              }}
            </mat-error>
          </mat-form-field>
        </div>

        <!--City-->
        <div
          class="form-group"
          *ngIf="
            searchPreferenceFormControls.country.value !== selectCountryText &&
            searchPreferenceFormControls.country.value !== ''
          "
        >
          <label class="field-label" for="city-field">
            {{
              "userComponent.profile.searchPreferenceTab.inputFields.city.label"
                | translate
            }}
          </label>
          <mat-form-field style="width: 100%">
            <mat-label>
              {{ selectCityText }}
            </mat-label>
            <mat-select
              required
              formControlName="city"
              panelClass="example-panel-blue"
            >
              <mat-option
                *ngFor="let city of availableCities"
                [value]="city.id"
              >
                {{ city.name }}
              </mat-option>
            </mat-select>
            <mat-error>
              {{
                "userComponent.profile.searchPreferenceTab.inputFields.city.errors.required"
                  | translate
              }}
            </mat-error>
            <div class="invalid-feedback">
              <!--             <div >-->
              <!--               {{'userComponent.profile.searchPreferenceTab.inputFields.city.errors.required' | translate }}-->
              <!--             </div>-->
            </div>
          </mat-form-field>
        </div>

        <!--City-->
        <div
          class="form-group"
          *ngIf="
            searchPreferenceFormControls.city.value !== selectCityText &&
            searchPreferenceFormControls.city.value !== ''
          "
        >
          <label class="field-label">
            {{
              "userComponent.profile.searchPreferenceTab.inputFields.radius.label"
                | translate
            }}
          </label>
          <input
            id="radius-field"
            name="radius"
            formControlName="radius"
            [ngClass]="{
              'is-invalid':
                searchPreferenceFormSubmitted &&
                searchPreferenceFormControls.radius.errors
            }"
            class="form-control"
            type="number"
            min="0"
          />
          <div
            *ngIf="
              searchPreferenceFormSubmitted &&
              searchPreferenceFormControls.radius.errors
            "
            class="invalid-feedback"
          >
            <div *ngIf="searchPreferenceFormControls.radius.errors.required">
              {{
                "userComponent.profile.searchPreferenceTab.inputFields.radius.errors.required"
                  | translate
              }}
            </div>
          </div>
        </div>

        <ng-container *ngIf="dynamicSearchPreferenceForm !== null">
          <form
            [formGroup]="dynamicSearchPreferenceForm"
            (ngSubmit)="onPreferenceFormSubmit()"
          >
            <!-- Dynamic form  -->
            <div
              *ngFor="let attr of entityFormBase; let i = index"
              class="text-center ml-auto"
            >
              <div
                class="col-lg-6 float-left"
                *ngIf="attr.key.includes('min') || attr.key.includes('max')"
              >
                <app-dynamic-form
                  [formField]="attr"
                  [form]="dynamicSearchPreferenceForm"
                ></app-dynamic-form>
              </div>
              <div
                class="col-lg-12 float-left"
                *ngIf="attr.key.includes('text')"
              >
                <app-dynamic-form
                  [formField]="attr"
                  [form]="dynamicSearchPreferenceForm"
                ></app-dynamic-form>
              </div>
            </div>

            <div class="text-center" *ngIf="searchPreferences === null">
              <button type="submit" class="btn-second blue-btn">
                {{
                  "userComponent.profile.searchPreferenceTab.confirmationButton"
                    | translate
                }}
              </button>
            </div>
            <div class="text-center" *ngIf="searchPreferences">
              <a
                href="javascript:void(0)"
                class="mr-5"
                (click)="removeSearchPreference()"
                ><i class="fas fa-trash-alt fa-2x text-danger"></i
              ></a>
              <button type="submit">
                <i class="fas fa-edit fa-2x text-success"></i>
              </button>
            </div>
          </form>
        </ng-container>
      </form>
    </div>
    <div class="col-5 tab-pane-image" *ngIf="!isMobileScreen">
      <div>
        <img src="assets/images/profile/search-prefereces.svg" />
      </div>
    </div>
  </div>
</div>
