import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { GetLanguageResponse } from './responses/getLanguageResponse';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private apiBaseURL = environment.configSettings.apiURL;
  constructor(private http: HttpClient) { }

  getAvailableLanguages() {
    return this.http.get<GetLanguageResponse>(`${this.apiBaseURL}v1/language/`, { observe: 'response' });
  }


}
