import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from './toast.service';

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(private angularFireMessaging: AngularFireMessaging,
              private notifyService: ToastService) {
    this.angularFireMessaging.messages.subscribe(
      (message: any) => {
        if (this.currentMessage.value !== message.data){
          this.currentMessage.next(message.data);
          this.notifyService.showSuccess(message.notification.body.body_text, message.notification.title);
        }
      },
      (error) => {
        // console.error('failed to subscribe to firebase messaging');
      }
    );
  }

}
