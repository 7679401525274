<!-- Recover passowrd form -->
<div class="modal-body " >
  <form #recoverForm [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
    <label for="recover-email-field">{{'userModal.forgotPasswordPage.inputFields.email.label' | translate }}</label>
    <div class="input-group mb-4 custom-input-group-height">

      <input
        id='recover-email-field'
        type="text"
        formControlName="email"
        class="form-control custom-input-group-height"
        [ngClass]="{ 'is-invalid': submitted && form.email.errors }"
        placeholder="{{'userModal.forgotPasswordPage.inputFields.email.placeholder' | translate }}"/>

      <div class="input-group-append">
         <span class="input-group-text custom-span-input-group" >
           <i class="fas fa-user-circle"></i>
         </span>
      </div>

      <div *ngIf="submitted && form.email.errors" class="invalid-feedback">
        <div *ngIf="form.email.errors.required">{{'userModal.forgotPasswordPage.inputFields.email.errors.required' | translate }}</div>
        <div *ngIf="form.email.errors.email">{{'userModal.forgotPasswordPage.inputFields.email.errors.email' | translate }}</div>
      </div>
    </div>

    <div class="form-group">
      <button [disabled]="loading" class="btn-second blue-btn full-width">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{'buttons.recoverPassword' | translate }}
      </button>

    </div>
  </form>
  <div class="form-excerpts">
    <table class="m-auto w-100 table-borderless text-center" style="color: black;"  >
      <tr>
        <td class="text-center"><a (click)="onSwitchComponent('sign_in')" href="javascript:void(0)" id="signup-btn">{{'userModal.buttons.signUpText' | translate }}</a></td>
      </tr>
    </table>
  </div>


</div>





