import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../models/user';
import { ActivationToken } from '../models/ativation_token';
import { environment } from '../../../environments/environment';

import { ProfileResponse} from './responses/profileResponse';
import { EntityOwnerInformationResponse } from './responses/entityOwnerInformationResponse';
import { BaseResponse } from '../../core/services/responses/baseResponse';
import { PasswordReset} from '../models/passwordReset';
import { PasswordChange } from '../models/passwordChange';



@Injectable({ providedIn: 'root' })
export class UserService {
  private apiBaseURL = environment.configSettings.apiURL;

  constructor(private http: HttpClient) { }

  passwordChange(passwordChangeData: PasswordChange){
    return this.http.post<BaseResponse>(`${this.apiBaseURL}v1/user/password/change/`, passwordChangeData, { observe: 'response' });
  }

  getUserProfile() {
    return this.http.get<ProfileResponse>(`${this.apiBaseURL}v1/user/user/`, { observe: 'response' });
  }

  getUserInformation(userId: number, entityHash: string) {
    return this.http.get<EntityOwnerInformationResponse>(`${this.apiBaseURL}v1/user/user/${userId}/${entityHash}`,
      {observe: 'response'});
  }

  updateUserProfile(profile: any) {
    return this.http.patch<ProfileResponse>(`${this.apiBaseURL}v1/user/user/`, profile, {observe: 'response'});
  }

  closeAccount() {
    return this.http.delete<BaseResponse>(`${this.apiBaseURL}v1/user/user/`,  {observe: 'response'});
  }

  register(user: User) {
    return this.http.post<any>(`${this.apiBaseURL}v1/user/register/`, user, { observe: 'response' });
  }

  logout(){
    return this.http.post<any>(`${(this.apiBaseURL)}v1/user/logout/`, {}, { observe: 'response' });
  }

  activate(activationToken: ActivationToken) {
    return this.http.post<any>(`${this.apiBaseURL}v1/user/activate/`, activationToken, { observe: 'response' });
  }

  resetPassword(passwordReset: PasswordReset){
    return this.http.post<any>(`${this.apiBaseURL}v1/user/password-reset/confirm/`, passwordReset, { observe: 'response' });
  }
}
