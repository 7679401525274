import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-module-page',
  templateUrl: './module-page.component.html',
})
export class ModulePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
