import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ModuleUserComponent } from './module-user/module-user.component';
import { ContactComponent } from './module-page/contact-page/contact.component';
import { RegisterComponent } from './module-user/user-modal/register/register.component';
import { ActivateComponent } from './module-user/activate/activate.component';
import { AboutusPageComponent } from './module-page/aboutus-page/aboutus-page.component';
import { DownloadPageComponent } from './module-page/download-page/download-page.component';
import { ErrorComponent } from './core/error/error.component';
import { TermsOfUseComponent } from './module-page/terms-of-use/terms-of-use.component';
import { PasswordResetComponent } from './module-user/password-reset/password-reset.component';
import { AuthGuardService } from './module-user/services/auth-guard.service';
import { PricingComponent } from './module-subscription/pricing/pricing.component';
import { FaqPageComponent } from './module-page/faq-page/faq-page.component';
import { BrowseComponent } from './module-entity/browse/browse.component';
import { EntityDetailComponent } from './module-entity/entity-detail/entity-detail.component';

import { LogoutComponent } from './module-user/logout/logout.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { UserListingComponent } from './module-user/user-listing/user-listing.component';
import { EntityEditComponent } from './module-entity/entity-edit/entity-edit.component';
import { EntityCreateComponent } from './module-entity/entity-create/entity-create.component';
import { ProfileDetailComponent } from './module-user/profile/profile-detail/profile-detail.component';
import { ListingItemGuardService } from './module-entity/add-entity/service/listing-item-guard.service';
import { SubscriptionGuardService } from './module-subscription/services/subscription-guard.service';
import { SubscriptionListingComponent } from './module-subscription/subscription-listing/subscription-listing.component';
import { ReferralListingComponent } from './module-subscription/referral/referral-listing/referral-listing.component';
import { PrivacyPolicyComponent } from './module-page/privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './module-page/cookie-policy/cookie-policy.component';
import { PasswordChangeComponent } from './module-user/profile/password-change/password-change.component';
import { SearchResultComponent } from './module-entity/search-entity/search-result/search-result.component';
import { SearchComponent } from './module-entity/search-entity/search/search.component';

const routes: Routes = [
  // Routing Definition
  {
    path: 'coming-soon',
    component: ComingSoonComponent,
    pathMatch: 'full',
    data: { header: false, footer: false }
  },
  // Module page
  {
    path: 'contact',
    component: ContactComponent,
    pathMatch: 'full'
  },
  {
    path: 'about-us',
    component: AboutusPageComponent,
    pathMatch: 'full'
  },
  {
    path: 'download',
    component: DownloadPageComponent,
    pathMatch: 'full'
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent,
    pathMatch: 'full'
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    pathMatch: 'full'
  },
  {
    path: 'cookie-policy',
    component: CookiePolicyComponent,
    pathMatch: 'full'
  },
  {
    path: 'faq',
    component: FaqPageComponent,
    pathMatch: 'full'
  },
  // Module subscription
  {
    path: 'pricing',
    component: PricingComponent,
    pathMatch: 'full'
  },
  {
    path: 'subscriptions',
    component: SubscriptionListingComponent,
    pathMatch: 'full'
  },
  {
    path: 'referrals',
    component: ReferralListingComponent,
    pathMatch: 'full'
  },
  // Module entity
  {
    path: '',
    component: BrowseComponent,
    pathMatch: 'full'
  },
  {
    path: 'search',
    component: SearchComponent,
    pathMatch: 'full'
  },
  {
    path: 'search-result',
    component: SearchResultComponent,
    pathMatch: 'full'
  },
  {
    path: 'item/detail/:hash',
    component: EntityDetailComponent,
    pathMatch: 'full',
    canActivate: [SubscriptionGuardService]
  },
  {
    path: 'item/add',
    component: EntityCreateComponent,
    pathMatch: 'full',
    canActivate: [AuthGuardService, ListingItemGuardService, SubscriptionGuardService]
  },
  {
    path: 'item/edit/:hash',
    component: EntityEditComponent,
    pathMatch: 'full',
    canActivate: [AuthGuardService]
  },
  // Module user
  {
    path: 'user',
    component: ModuleUserComponent,
    pathMatch: 'full',
    canActivate: [AuthGuardService]
  },
  {
    path: 'user/register',
    component: RegisterComponent,
    pathMatch: 'full'
  },
  {
    path: 'user/password/change',
    component: PasswordChangeComponent,
    pathMatch: 'full',
    canActivate: [AuthGuardService]
  },
  {
    path: 'user/logout',
    component: LogoutComponent,
    pathMatch: 'full'
  },
  {
    path: 'user/listing',
    component: UserListingComponent,
    pathMatch: 'full'
  },
  {
    path: 'user/profile/:username/:entityHash',
    component: ProfileDetailComponent,
    pathMatch: 'full'
  },
  {
    path: 'user/activate/:uid64/:token',
    component: ActivateComponent,
    pathMatch: 'full'
  },
  {
    path: 'user/password-reset/:uid64/:token',
    component: PasswordResetComponent,
    pathMatch: 'full',
    data: { header: false, footer: false }
  },
  // 404 error
  {
    path: '**',
    component: ErrorComponent,
    data: { header: false, footer: false }
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
