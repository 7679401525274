import { Component, OnDestroy, OnInit } from '@angular/core';
import { EntityService } from '../services/entity.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Entity } from '../models/entity';
import { Subscription } from 'rxjs';
import { AlertService } from '../../core/alert/alert.service';
import { CorrespondenceService } from '../../module-communication/services/correspondence.service';
import { Profile } from '../../module-user/models/profile';
import { AuthenticationService } from '../../module-user/services/authentication.service';
import { UserInformation } from '../../module-user/models/userInformation';
import { UserService } from '../../module-user/services/user.service';
import { MessagingService } from '../../module-communication/services/messaging.service';
import { Notification } from '../../module-communication/models/notification';
import { ConfirmationDialogService } from '../../core/confirmation-dialog/confirmation-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionService } from '../../module-subscription/services/subscription.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-entity-detail',
  templateUrl: './entity-detail.component.html',
  styleUrls: ['./entity-detail.component.css'],
})
export class EntityDetailComponent implements OnInit, OnDestroy {
  userProfile: Profile = null; // current user
  correspondence = null; // used to check if the user has a correspondence with the entity
  entity: Entity = null;
  entities: Array<Entity> = [];
  routerSubscription: Subscription;


  link = window.location.href; // Replace with your actual link
  isPopupVisible = false;
  isCopied: boolean = false;

  showPopup() {
    this.isPopupVisible = true;
  }

  closePopup() {
    this.isPopupVisible = false;
  }

 copyToClipboard() {
  const textarea = document.createElement('textarea');
  textarea.value = this.link;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
  this.isCopied = true; // add a boolean variable to track whether the link has been copied
}

  entityOwnerInformation: UserInformation = null;

  formGroupMap: Map<
    string,
    Array<{
      choice: string;
      value: string;
    }>
  > = new Map<string, Array<{ choice: string; value: string }>>();

  numberOfItemsExceededErr = '';
  validSubscriptionNeededErr = '';

  confirmationModalTitle = '';
  confirmationText = '';
  confirmationButtonAcceptText = '';
  confirmationButtonCancelText = '';

  constructor(
    private entityService: EntityService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private correspondenceService: CorrespondenceService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private subscriptionService: SubscriptionService,
    private confirmationDialogService: ConfirmationDialogService,
    translate: TranslateService,
    private location: Location,
    private messagingService: MessagingService
  ) { window.scrollTo(0, 0); 
    translate.get('errors.numberOfItemsExceeded').subscribe((res: string) => {
      this.numberOfItemsExceededErr = res;
    });
    translate.get('errors.validSubscriptionNeeded').subscribe((res: string) => {
      this.validSubscriptionNeededErr = res;
    });
    translate
      .get('userComponent.itemsListing.confirmationModal.title')
      .subscribe((res: string) => {
        this.confirmationModalTitle = res;
      });
    translate
      .get('userComponent.itemsListing.confirmationModal.confirmationText')
      .subscribe((res: string) => {
        this.confirmationText = res;
      });

    translate
      .get('userComponent.itemsListing.confirmationModal.buttons.accept')
      .subscribe((res: string) => {
        this.confirmationButtonAcceptText = res;
      });
    translate
      .get('userComponent.itemsListing.confirmationModal.buttons.cancel')
      .subscribe((res: string) => {
        this.confirmationButtonCancelText = res;
      });
  }

    goBack() {
    this.location.back();
  }
  
  
  ngOnInit(): void {
    /* 1. Get the user profile
     * 2. Get the entity
     *   2.1 check the match of the user
     *   2.2 Get the form groups of the entity in a Map so we can interpolate it
     * */
    // 1.
    this.authenticationService.currentUserSubject.subscribe(
      (userProfile) => (this.userProfile = userProfile)
    );
    this.routerSubscription = this.route.params.subscribe((params) => {
      // 2.
      this.entityService.getEntityByHash(params.hash).subscribe(
        (response) => {
          // 2.1
          // console.log(response);
          this.entity = response.body.data;
          this.entityService.addToRecentlyViewed(response.body.data);
          // console.log(response.body.data);
          this.checkMatch();
          // 2.2 We get a map of the boolean form values
          response.body.data.attribute_values.forEach((attr) => {
            if (
              attr.attribute.type === 'boolean' &&
              attr.attribute.choices !== null
            ) {
              this.formGroupMap.set(attr.attribute.name, []);
              const choices = attr.attribute.choices.split(',');
              const values = attr.value_boolean.split(',');
              choices.forEach((c, index) => {
                if (index !== 0) {
                  this.formGroupMap.get(attr.attribute.name).push({
                    choice: c,
                    value: values[index - 1],
                  });
                }
              });
            }
          });
        },
        (error) => {
          console.error(error);
          this.router.navigate(['/']);
        }
      );
    });

    this.messagingService.currentMessage.subscribe((message) => {
      console.log(message);
      /* For each notification it checks the matches */
      if (message !== undefined && message !== null) {
        const notification = new Notification(JSON.parse(message));
        if (notification.related_model === 'Correspondence') {
          this.checkMatch();
        }
      }
    });
  }
  // used to render the map
  getKeys() {
    return Array.from(this.formGroupMap.keys());
  }
  getValues(key) {
    return this.formGroupMap.get(key);
  }

  getNoTrueValues(key) {
    let count = 0;
    this.formGroupMap.get(key).forEach((value) => {
      if (value.value === '1') {
        count++;
      }
    });
    return count;
  }

  checkMatch(unmatch = false) {
    this.correspondenceService
      .getCorrespondenceByEntityHash(this.entity.hash_1)
      .subscribe(
        (response) => {
          this.correspondence = response.body.data;

          if (this.correspondence !== null && this.entity !== null) {
            // Check the permissions on the owner information if the correspondence is approved
            if (this.correspondence.approval === 'Approved') {
              this.getOwnerInformation();
            }
          } else if (this.entity !== null) {
            // Recheck the  permissions on the owner information
            if (unmatch === true) {
              this.getOwnerInformation();
            }
          } else {
            // In case of unmatch, we set the owner back to null
            this.entityOwnerInformation = null;
          }
        },
        (error) => console.error(error)
      );
  }

  deleteItem(entityHash: string) {
    this.confirmationDialogService
      .confirm(
        this.confirmationModalTitle,
        this.confirmationText,
        this.confirmationButtonAcceptText,
        this.confirmationButtonCancelText
      )
      .then((confirmed) => {
        // User confirmed:
        // console.log('User confirmed:', confirmed);
        if (confirmed === true) {
          this.entityService.deleteEntityByHash(entityHash).subscribe(
            (response) => {
              this.alertService.success(response.body.message, false);
              // decrement the number of items owned by the user on deletion
              this.authenticationService.decrementNoItemsOwned();
            },
            (errorResponse) => {
              this.alertService.error(errorResponse.body.message);
            }
          );
        }
      })
      .catch(() => {
        // User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
      });
  }
  getOwnerInformation(){
    // @ts-ignore
    this.userService.getUserInformation(this.entity.creator.username, this.entity.hash_1).subscribe(resp => {
      // console.log(resp.body.data);
      this.entityOwnerInformation = resp.body.data;
    }, error => this.entityOwnerInformation = null);
  }

  toggleCorrespondence(hash: string, create: boolean = true) {
    /* Function used to create/delete the correspondence object between the user and the owner of the entity*/
    if (create === true) {
      this.correspondenceService.createCorrespondence(hash).subscribe(
        (response) => {
          this.alertService.success(response.body.message);
          this.checkMatch();
        },
        (errorResponse) => {
          this.alertService.error(errorResponse.error.error);
        }
      );
    } else {
      this.correspondenceService.deleteCorrespondence(hash).subscribe(
        (response) => {
          this.alertService.success(response.body.message);
          this.checkMatch(true);
        },
        (errorResponse) => this.alertService.error(errorResponse.error.error[0])
      );
    }
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    fade: false,
    centerPadding: '50px',
    variableWidth: false,
    adaptiveHeight: true,
    
  };
}
