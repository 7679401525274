import { Component, Input, OnInit} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldBase } from '../models/forms/base/form-field-base';
import { MatFormFieldControl } from '@angular/material/form-field';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
})
export class DynamicFormComponent implements OnInit {
  @Input() formField: FormFieldBase<any>;
  @Input() form: FormGroup;
  @Input() radioKey = null;
  @Input() fontSize = 12;
  constructor() { }

  ngOnInit(): void {
  }

  get isValid() { return this.form.controls[this.formField.key].valid; }

}
