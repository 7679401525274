
<div class="item-thumb">
  
  <a [routerLink]="['/item/detail', item.hash_1 ]" >
  <img *ngIf="item?.cover_image !== null " src="{{ item?.cover_image }}" alt="image">
  <img *ngIf="item?.cover_image === null " src="https://staging-api.swapapp.de/media/{{ item.entity_class.default_cover_image }}" alt="">
</a>

  <!-- Like Button -->
  <!-- <a class="action-button like-button"> -->
   <ng-container *ngIf="userProfile !== null">
    
      <ng-container
        *ngIf="
          item.creator.pk !== userProfile.profile.user
        "
      > 
        <div
          class="car-description-bottom"
          *ngIf="!item.correspondence"
        >
          <a
            (click)="toggleCorrespondence(item.hash_1)"
            href="javascript:void(0)"
            class="action-button like-button"
          >
            <i class="fa fa-heart"></i>
            {{
              "entityComponent.browse.buttons.match"
                | translate
            }}
          </a>
        </div>

        <div
          class="car-description-bottom"
          *ngIf="item.correspondence"
        >
          <a
            (click)="
              toggleCorrespondence(item.hash_1, false)
            "
            href="javascript:void(0)"
            class="action-button like-button"
          >
            <i class="fa fa-heart"></i>
            {{
              "entityComponent.browse.buttons.unMatch"
                | translate
            }}
          </a>
        </div>
      </ng-container>
    </ng-container>
  <!-- </a> -->
  
  <!-- Liked -->
  <!-- <a href="javascript:void(0)" class="action-button like-button">
          <img src="assets/images/icon/fill-Heart.png" alt="">
        </a> -->
</div>
<div class="item-content">
  <h2>
    {{ item.title }}
  </h2>
  <p> <i class="icon-location"></i> {{ item.city.name }}, {{ item.city.country.iso_3166_1_a2 }}</p>
</div>
<div class="item-meta">
  <div class="item-content">
    <p> {{item.description}}</p>
  </div>
</div>
<div class="item-price">
  <i class="icon-moneys mr-2"></i>  € {{item.price}}
</div>