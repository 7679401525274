<div class="swapapp-section my-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-12 mb-2 mb-md-5">
        <small>USP’s of</small>
        <h2>Swapapp</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur. Lacus egestas eget sed gravida morbi amet arcu. Aliquet suspendisse
          dolor nullam feugiat condimentum id commodo mi. Egestas velit amet nulla hendrerit at adipiscing. Parturient
          in tincidunt sollicitudin purus feugiat tortor dictum. Sit sed pharetra leo sed fringilla. Risus duis
          penatibus enim ante volutpat.
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <div class="swapapp-box">
          <div class="sb-icon">
            <img src="assets/images/app/product-filtering.png" alt="">
          </div>
          <div class="sb-content">
            Product Filtering
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="swapapp-box">
          <div class="sb-icon">
            <img src="assets/images/app/payment-protection.png" alt="">
          </div>
          <div class="sb-content">
            Payment Protection
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="swapapp-box">
          <div class="sb-icon">
            <img src="assets/images/app/nationwide-delivery.png" alt="">
          </div>
          <div class="sb-content">
            Nationwide Delivery
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="swapapp-box">
          <div class="sb-icon">
            <img src="assets/images/app/support.png" alt="">
          </div>
          <div class="sb-content">
            24/7 Support
          </div>
        </div>
      </div>
    </div>
  </div>
</div>