import { Component, HostListener, OnInit } from '@angular/core';
import { Profile } from '../../module-user/models/profile';
import { AuthenticationService } from '../../module-user/services/authentication.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public YEAR = new Date().getFullYear();
  public DOUNAMI_HREF = 'https://dounami.co.uk/home/';

  showClass = false;

  // profile
  
  userProfile: Profile = null; // the actual user profile from backend




  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.authenticationService.currentUserSubject.subscribe(userProfile => this.userProfile = userProfile);
    var clientWidth = document.documentElement.clientWidth;
    if (clientWidth > 575.98) {
      this.showClass = true;
    }
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  // Window Resize Functions
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 575) {
      this.showClass = false;
    } else {
      this.showClass = true;
    }
  }


}
