<!-- Footer Ad -->
<div class="footer-register-section" *ngIf="userProfile === null">
  <div class="container">
    <div class="footer-register-card">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h2>{{'footer.doNotMiss' | translate }}</h2>
          <p>{{'footer.setProfile' | translate }} </p>
        </div>
        <div class="col-md-4 text-md-right text-center">
          <!-- <a  class="register-btn" (click)="onSwitchComponent('sign_in')" href="javascript:void(0)">
            Register Now
          </a> -->
          <button class="register-btn"  data-target="#register" data-toggle="modal">
            {{'buttons.login' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Start Footer -->
<footer class="site-footer" id="footer-style-2">
  <div class="container">
    <!-- Links -->
    <div class="row justify-content-between" id="accordionExample">
      <!-- <div class="col my-sm-5">
        <div class="f-accordion-header">
          <a data-toggle="collapse" data-target="#realEstate" aria-expanded="false" aria-controls="realEstate">
           
            <i class="icon-arrow-right"></i>
          </a>
        </div>
        <div class="f-accordion-body collapse " [class.show]="showClass" id="realEstate"
          data-parent="#accordionExample">
          <ul>
            <li><a href="javascript:void(0)">Farm</a></li>
            <li><a href="javascript:void(0)">Barn</a></li>
            <li><a href="javascript:void(0)">Land</a></li>
            <li><a href="javascript:void(0)">Apartment</a></li>
            <li><a href="javascript:void(0)">House</a></li>
          </ul>
        </div>
      </div> -->

      <!-- <div class="col my-sm-5">
        <div class="f-accordion-header">
          <a data-toggle="collapse" data-target="#luxuryTab" aria-expanded="false" aria-controls="luxuryTab">
              {{'header.luxury' | translate }}
            <i class="icon-arrow-right"></i>
          </a>
        </div>
        <div class="f-accordion-body collapse " [class.show]="showClass" id="luxuryTab" data-parent="#accordionExample">
          <ul>
            <li><a href="javascript:void(0)">Watch</a></li>
            <li><a href="javascript:void(0)">Jewellery</a></li>
            <li><a href="javascript:void(0)">Gemstones</a></li>
            <li><a href="javascript:void(0)">Exclusive Spirits</a></li>
            <li><a href="javascript:void(0)">Antique Items</a></li>
          </ul>
        </div>
      </div> -->

      <!-- <div class="col my-sm-5">
        <div class="f-accordion-header">
          <a data-toggle="collapse" data-target="#VehicleTab" aria-expanded="false" aria-controls="VehicleTab">
              {{'header.vehicle' | translate }}
            <i class="icon-arrow-right"></i>
          </a>
        </div>
        <div class="f-accordion-body collapse " [class.show]="showClass" id="VehicleTab"
          data-parent="#accordionExample">
          <ul>
            <li><a href="javascript:void(0)">Car</a></li>
            <li><a href="javascript:void(0)">Motorcycle</a></li>
            <li><a href="javascript:void(0)">Boat</a></li>
            <li><a href="javascript:void(0)">Construction Machinery</a></li>
          </ul>
        </div>
      </div> -->
      <div class="container footer-container-1">
        <div class="row justify-content-end">
          <div class="col my-sm-3 ml-auto">
            <div class="f-accordion-header">
              <a data-toggle="collapse" data-target="#HelpTab" aria-expanded="false" aria-controls="HelpTab">
                {{'footer.imprint' | translate }}
                <i class="icon-arrow-right"></i>
              </a>
            </div>
            <div class="f-accordion-body collapse " [class.show]="showClass" id="HelpTab" data-parent="#accordionExample">
              <p class="text-dark">{{'footer.imprint1' | translate }}</p>
              <p class="text-dark ">{{'footer.imprint2' | translate }}</p>
              <p class="text-dark ">{{'footer.imprint3' | translate }}</p>
            </div>
          </div>
          <div class="col my-sm-3 ">
            <!-- first column content here -->
            <div class="f-accordion-header">
              <a data-toggle="collapse" data-target="#CorporateTab" aria-expanded="false" aria-controls="CorporateTab">
                {{'footer.address' | translate }}
                <i class="icon-arrow-right"></i>
              </a>
            </div>
            <div class="f-accordion-body collapse" [class.show]="showClass" id="CorporateTab" data-parent="#accordionExample">
    
              <p class="text-dark ">HADAD GMBH
                <br>Ahltener Str.15C
                <br>31275, Lehrte
                <br>Germany
              </p>
              <label class="text-dark fw-600 ">{{'footer.email' | translate }}:</label>
              <p><a href="mailto:" class="text-dark ">info@swapapp.de</a>
              </p>
              <label class="text-dark fw-600 ">{{'footer.phone' | translate }}:</label>
              <p><a href="tel:" class="text-dark ">+49(0)1786600660</a></p>
              <p><a href="tel:" class="text-dark">+49(0)51328579181</a></p>
            </div>
          </div>
          <div class="col my-sm-3 ">
            <!-- first column content here -->
            <div class="f-accordion-header">
              <a data-toggle="collapse" data-target="#CorporateTab" aria-expanded="false" aria-controls="CorporateTab">
                {{'footer.quickLinks' | translate }}
                <i class="icon-arrow-right"></i>
              </a>
            </div>
            <div class="f-accordion-body collapse" [class.show]="showClass" id="CorporateTab" data-parent="#accordionExample">
    
              <ul>
                <li>
                  <a routerLink="/about-us" class="text-dark-white scrollLink ">{{'footer.aboutUs' | translate }}</a>
                </li>
                <li>
                  <a routerLink="/contact" class="text-dark-white scrollLink">{{'footer.contact' | translate }}</a>
                </li>
                <li>
                  <a routerLink="/terms-of-use" class="text-dark-white scrollLink ">{{'footer.terms' | translate
                    }}</a>
                </li>
                <li>
                  <a routerLink="/privacy-policy" class="text-dark-white scrollLink">{{'footer.privacy' | translate
                    }}</a>
                </li>
                <li>
                  <a routerLink="/cookie-policy" class="text-dark-white scrollLink">{{'cookie.policy' | translate
                    }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      
      

    </div>
    <!-- Social Media -->
    <!-- <div class="footer-social-media-links">

      <a href="javascript:void(0)" class="footer-logo d-sm-none d-inline-block">
        <img src="assets/images/logo/sm-logo.png" alt="Logo" style="max-width:35px">
      </a>

      <a href="javascript:void(0)">
        <span class="icon-instagram"></span>
      </a>

      <a href="javascript:void(0)">
        <span class="icon-twitter"></span>
      </a>

      <a href="javascript:void(0)">
        <span class="icon-linkedin"></span>
      </a>

      <a href="javascript:void(0)">
        <span class="icon-youtube"></span>
      </a>
    </div> -->

    <!-- Footer Bottom -->
    <div class="footer-bottom">
      <div class="row justify-content-between align-items-center">
        <div class="col-sm-4 my-3 d-none d-sm-block">
          <a href="javascript:void(0)" class="footer-logo">
            <img src="assets/images/logo/logo-with-text.png" alt="" class="img-fluid">
          </a>
        </div>
        <div class="col-sm-4 my-3 text-center">
     
          {{'footer.copyrightText' | translate }} {{ YEAR }} | {{'footer.allRightText' | translate }} |
          <!-- {{'footer.developedText' | translate }} Dounami LTD -->
        </div>
        <div class="col-sm-4 my-3 d-none d-sm-block">

        </div>
      </div>
    </div>
  </div>
</footer>
<!-- End Footer -->