<div class="aboutusbackgroundcolor">
  <div class="container">
    <div class="aboutusheadflex">
      <div class="aboutusheadmain">

        <img class="aboutusarrow" src="../../../assets/images/about/aboutusarrow.svg" alt="">
        <p class="aboutusheading">
          {{'aboutus.aboutUs' | translate }}
        </p>
        <p class="aboutuspara"> {{'aboutus.companyDescription1' | translate }}</p>
        <p class="aboutuspara">
          {{'aboutus.companyDescription1.1' | translate }}</p>
      </div>
      <div class="aboutusimagesflex">
        <div>
          <div>
            <img class="aboutuskey" src="../../../assets/images/about/aboutuskey.svg" alt="">
          </div>
          <div>
          <img class="aboutuswatch" src="../../../assets/images/about/aboutuswatch.svg" alt="">
          </div>
        </div>

        <div>
          <img class="aboutusmoney" src="../../../assets/images/about/aboutusmoney.svg" alt="">
        </div>

      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="swapappvideoflex">
    <div class="swapappthumbnailmain">
      <video class="aboutusswapappvideo  swapappthumbindex"  [muted]="'muted'" loop controls autoplay>
        <source src="assets/logo_video_de.mp4" type="video/mp4" *ngIf="chosenLanguage === 'de' ">
        <source src="assets/logo_video_en.mp4" type="video/mp4" *ngIf="chosenLanguage === 'en' ">
      </video>
      <!-- <video class="swapappthumbindex" poster="../../../assets/images/about/swapappthumbnail.svg" src=""></video> -->
      <!-- <img class="playbuttonthumbnail" src="../../../assets/images/about/playbutton.svg" alt=""> -->
      <img class="swapappthumbcircle" src="../../../assets/images/about/swapappthumbcircle.svg" alt="">
    </div>

    <div>
      <p class="aboutthe">About The</p>
      <h1 class="swapappteam">SWAPAPP Team</h1>
      <p class="swapappteampara"> {{'aboutus.openMarket' | translate }}</p>
    </div>
  </div>

</div>

<div class="downloadourappbackground">
  <div class="container">
    <div class="downloadourappflex">
      <div class="downloadourappmain">
        <img class="downloadourapptriangle" src="../../../assets/images/about/downloadourapptriangle.svg" alt="">
        <p class="aboutthe">{{'aboutus.download' | translate }}</p>
        <h1 class="swapappteam"> {{'aboutus.ourApp' | translate }}</h1>
        <p class="swapappteampara visitusmaxwidth">{{'aboutus.visitUs' | translate }} <a href=""><span class="swapsite"> www.swapapp.de </span></a> and
          {{'aboutus.browse' | translate }}</p>

        <div class="checkflex">
          <div>
            <p><img src="../../../assets/images/about/check.svg" alt=""> {{'aboutus.noCommission' | translate }}</p>
            <p><img src="../../../assets/images/about/check.svg" alt="">  {{'aboutus.247Agent' | translate }}</p>
          </div>
          <div>
            <p><img src="../../../assets/images/about/check.svg" alt="">  {{'aboutus.noProvision' | translate }}</p>
            <p><img src="../../../assets/images/about/check.svg" alt="">  {{'aboutus.globalPlatform' | translate }}</p>
          </div>
        </div>

        <div class="storeflex">
          <div>
            <a href=""><img src="../../../assets/images/about/Playstore.svg" alt=""></a>
          </div>

          <div>
            <a href=""><img src="../../../assets/images/about/Applestore.svg" alt=""></a>
          </div>
        </div>
      </div>
      <div>
        <img class="iphone" src="../../../assets/images/about/iphone.png" alt="">
      </div>
      <div class="storeflexresponsive">
        <div>
          <a href=""><img src="../../../assets/images/about/Playstore.svg" alt=""></a>
        </div>

        <div>
          <a href=""><img src="../../../assets/images/about/Applestore.svg" alt=""></a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="howitworksmain">
    <p class="aboutthe">{{'aboutus.getRegisteredToday' | translate }}</p>
    <h1 class="swapappteam">{{'aboutus.howItWorks' | translate }}</h1>
    <p class="howitworkspara">
       {{'aboutus.companyDescription3' | translate }}
    </p>
  </div>

  <div class="tryforfreeflex">
    <div class="tryforfreemain">
      <img class="numberone" src="../../../assets/images/about/1.svg" alt="">
      <img class="iconimage" src="../../../assets/images/about/tryforfree.svg" alt="">
      <p class="tryforfreesectionheading"> {{'aboutus.try' | translate }}</p>
      <p class="tryforfreesectionpara">{{'aboutus.tryFree' | translate }}</p>
    </div>
    <div class="unlockallfeaturesflex">
      <img class="numbers" src="../../../assets/images/about/2.svg" alt="">
      <img class="iconimage" src="../../../assets/images/about/unlockallfeatures.svg" alt="">
      <p class="tryforfreesectionheading">{{'aboutus.unlock' | translate }}</p>
      <p class="tryforfreesectionpara unlockallfeaturesmaxwidth">
          {{'aboutus.unlockPotential' | translate }}
      </p>
    </div>
    <div class="downloadnbrowse">
      <img class="numbers" src="../../../assets/images/about/3.svg" alt="">
      <img class="iconimage" src="../../../assets/images/about/downloadnbrowse.svg" alt="">
      <p class="tryforfreesectionheading">{{'aboutus.download' | translate }}</p>
      <p class="tryforfreesectionpara downloadnbrowsemaxwidth">
         {{'aboutus.browse' | translate }}
      </p>
    </div>
  </div>

</div>