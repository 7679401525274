/* tslint:disable */
export class Entity {
  correspondence: boolean;
  active: boolean;
  creator: {
    username: string
    pk: number
  };
  // add category
  categories: Array<any>;
  entity_class: any;
  // add properties
  cover_image: any;
  is_public: true;
  title: string;
  slug: string;
  description: string;
  price: number;
  attribute_values: Array<{
    value_text: string,
    value_integer: number
    value_boolean: string,
    value_float: number,
    value_richtext: string,
    value_date: Date,
    value_datetime: Date,
    attribute: any
  }>;
  // add gallery
  images: Array<any>;
  // add location
  city: any;
  rating: number;
  structure: string;
  date_created: Date;
  date_updated: Date;
  hash_1: string;

  constructor(entity?: Entity) {
    if (entity) { Object.assign(this, entity); }
  }

  set Categories(val: {categories: Array<number>, entity_class: number}){
    this.categories = val.categories;
    this.entity_class = val.entity_class;
  }

  initAttributes(){
    this.attribute_values = [];
  }

  set Attribute(val: {id: number, type: string, value: any}){
    if (this.attribute_values === undefined){
      this.attribute_values = [];
    }
    if(val !== null){
      let obj = {
        value_text: '',
        value_integer: null,
        value_boolean: '0',
        value_float: null,
        value_richtext: '',
        value_date: null,
        value_datetime: null,
        attribute: null
      }
      switch (val.type) {
        case "text": {
          obj.value_text = val.value;
          break;
        }
        case "integer": {
          obj.value_integer = parseInt(val.value);
          break;
        }
        case "float": {
          obj.value_float = parseFloat(val.value);
          break;
        }
        case "date": {
          obj.value_date = val.value;
          break;
        }
        case "boolean": {
          if(val.value === '' || val.value === 'false' || val.value === false){ obj.value_boolean = '0'; }
          else if (val.value === '1' || val.value === true){ obj.value_boolean = '1'; }
          else {obj.value_boolean = val.value; }
          break;
        }
        case "datetime": {
          obj.value_datetime = val.value;
          break;
        }
      }
      obj.attribute = val.id;
      this.attribute_values.push(obj);
    }else { this.attribute_values = []; }


  }

  set Cover_image(val: any){
    this.cover_image = val;
  }

  set Title(val: string){
    this.title = val;
  }

  set Description(val: string){
    this.description = val;
  }
  set Price(val: number){
    this.price = val;
  }

  set Image(val: any){
    if (this.images === undefined || this.images === null){
      this.images = [];
    }
    this.images.push(val);
  }

  set City(val: number){
    this.city = val;
  }
}

