import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {NotificationListingResponse} from './responses/notificationListingResponse';
import {BaseResponse} from '../../core/services/responses/baseResponse';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private apiBaseURL = environment.configSettings.apiURL;
  constructor(private http: HttpClient) { }

  getNotifications(page?: number) {
    if (page) {
      return this.http.get<NotificationListingResponse>(`${this.apiBaseURL}v1/communication/notification/?page=${page}`,
        {observe: 'response'});
    }
    return this.http.get<NotificationListingResponse>(`${this.apiBaseURL}v1/communication/notification/`,
      {observe: 'response'});
  }
  markAsRead(id: number, dateRead: Date){
    return this.http.put<BaseResponse>(`${this.apiBaseURL}v1/communication/notification/notification/${id}`,
      { date_read: dateRead },  {observe: 'response'});
  }
  markAllAsRead(notificationIds: Array<number>){
    return this.http.post<BaseResponse>(`${this.apiBaseURL}v1/communication/notification/`,
      { notifications_ids: notificationIds },  {observe: 'response'});
  }


}
