import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { CrystalLightboxModule } from '@crystalui/angular-lightbox';
import { SlickCarouselModule } from 'ngx-slick-carousel';


import { AlertService } from './core/alert/alert.service';
import { AuthenticationService } from './module-user/services/authentication.service';
import { JwtInterceptor } from './core/_helpers/jwt.interceptor';


import { AppComponent } from './app.component';
import { ModuleUserComponent } from './module-user/module-user.component';
import { ModulePageComponent } from './module-page/module-page.component';
import { HeaderComponent } from './core/header/header.component';
import { ContactComponent } from './module-page/contact-page/contact.component';
import { LoginComponent } from './module-user/user-modal/login/login.component';
import { RegisterComponent } from './module-user/user-modal/register/register.component';
import { FooterComponent } from './core/footer/footer.component';
import { AlertComponent } from './core/alert/alert.component';
import { ActivateComponent } from './module-user/activate/activate.component';
import { AboutusPageComponent } from './module-page/aboutus-page/aboutus-page.component';
import { DownloadPageComponent } from './module-page/download-page/download-page.component';
import { MobileMenuComponent } from './core/header/mobile-menu/mobile-menu.component';
import { DesktopMenuComponent } from './core/header/desktop-menu/desktop-menu.component';
import { ErrorComponent } from './core/error/error.component';

import { TermsOfUseComponent } from './module-page/terms-of-use/terms-of-use.component';
import { UserModalComponent } from './module-user/user-modal/user-modal.component';
import { RecoverPasswordComponent } from './module-user/user-modal/recover-password/recover-password.component';
import { PasswordResetComponent } from './module-user/password-reset/password-reset.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PricingComponent } from './module-subscription/pricing/pricing.component';
import { FaqPageComponent } from './module-page/faq-page/faq-page.component';
import { BrowseComponent } from './module-entity/browse/browse.component';
import { EntityDetailComponent } from './module-entity/entity-detail/entity-detail.component';

import { PublicEntityDetailComponent } from './module-entity/public-entity-detail/public-entity-detail.component';
import { PaymentComponent } from './module-payment/payment/payment.component';
import { AddEntityComponent } from './module-entity/add-entity/add-entity.component';
import { LogoutComponent } from './module-user/logout/logout.component';
import { ProfileGeneralComponent } from './module-user/profile/profile-general/profile-general.component';
import { ProfileAddressComponent } from './module-user/profile/profile-address/profile-address.component';
import { ProfileNotificationComponent } from './module-user/profile/profile-notification/profile-notification.component';
import { UserListingComponent } from './module-user/user-listing/user-listing.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { ProfilePreferencesComponent } from './module-user/profile/profile-preferences/profile-preferences.component';
import { AddCategoryComponent } from './module-entity/add-entity/add-category/add-category.component';
import { AddLocationComponent } from './module-entity/add-entity/add-location/add-location.component';
import { AddGalleryComponent } from './module-entity/add-entity/add-gallery/add-gallery.component';
import { AddPropertiesComponent } from './module-entity/add-entity/add-properties/add-properties.component';
import { AddConfirmationComponent } from './module-entity/add-entity/add-confirmation/add-confirmation.component';
import { DynamicFormComponent } from './core/dynamic-form/dynamic-form.component';
import { EntityEditComponent } from './module-entity/entity-edit/entity-edit.component';
import { EntityCreateComponent } from './module-entity/entity-create/entity-create.component';
import { DropdownMenuComponent } from './core/header/common/dropdown-menu/dropdown-menu.component';
import { AddressService } from './module-address/services/address.service';
import { EntityService } from './module-entity/services/entity.service';
import { EntityAdditionService } from './module-entity/add-entity/service/entity-addition.service';
import { UserService } from './module-user/services/user.service';
import { AuthGuardService } from './module-user/services/auth-guard.service';
import { FormControlService } from './core/services/form-control.service';
import { NotificationCenterComponent } from './module-communication/notification-center/notification-center.component';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AsyncPipe } from '@angular/common';
import { PaginationComponent } from './core/pagination/pagination.component';
import { ToastrModule } from 'ngx-toastr';
import { ProfileDetailComponent } from './module-user/profile/profile-detail/profile-detail.component';
import { MessagingService } from './module-communication/services/messaging.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';

import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';


import { DynamicSearchFieldComponent } from './module-search/dynamic-search-field/dynamic-search-field.component';
import { NgxStripeModule } from 'ngx-stripe';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { SubscriptionListingComponent } from './module-subscription/subscription-listing/subscription-listing.component';
import { MatTableModule } from '@angular/material/table';
import { VoucherComponent } from './module-subscription/voucher/voucher.component';
import { ReferralComponent } from './module-subscription/referral/referral.component';
import { ReferralListingComponent } from './module-subscription/referral/referral-listing/referral-listing.component';
import { ModifyReferralComponent } from './module-subscription/referral/modify-referral/modify-referral.component';
import { PrivacyPolicyComponent } from './module-page/privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './module-page/cookie-policy/cookie-policy.component';
import { PasswordChangeComponent } from './module-user/profile/password-change/password-change.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ConfirmationDialogComponent } from './core/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './core/confirmation-dialog/confirmation-dialog.service';
import { GlobalLoadingComponent } from './core/global-loading/global-loading.component';
import { AboutAppSectionComponent } from './core/components/about-app-section/about-app-section.component';
import { ExploreProductsSectionComponent } from './core/components/explore-products-section/explore-products-section.component';
import { JobSliderSectionComponent } from './core/components/job-slider-section/job-slider-section.component';
import { BecomeSellerSectionComponent } from './core/components/become-seller-section/become-seller-section.component';
import { SwapappSectionComponent } from './core/components/swapapp-section/swapapp-section.component';
import { RealEsatteSliderComponent } from './core/components/slider-sections/real-esatte-slider/real-esatte-slider.component';
import { LuxurySliderComponent } from './core/components/slider-sections/luxury-slider/luxury-slider.component';
import { JobsSliderComponent } from './core/components/slider-sections/jobs-slider/jobs-slider.component';
import { VehicleSliderComponent } from './core/components/slider-sections/vehicle-slider/vehicle-slider.component';
import { HeaderSearchComponent } from './core/header/header-search/header-search.component';
import { ListingItemComponent } from './core/components/a-common/listing-item/listing-item.component';
import { SearchResultComponent } from './module-entity/search-entity/search-result/search-result.component';
import { SearchComponent } from './module-entity/search-entity/search/search.component';
import { NpnSliderModule } from "npn-slider";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { SubscriptionsComponent } from './module-user/profile/subscriptions/subscriptions.component';
import {MatSidenavModule} from '@angular/material/sidenav';


const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    // domain: environment.configSettings.domainName
    //domain: 'localhost'
    domain: 'swapapp.de'
  },
  position: 'top',
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    'my-custom-layout': '{{messagelink}}{{compliance}}'
  },
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}}
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" >{{cookiePolicyLink}}</a>
    </span>
    `,
  },
  content: {
    policy: 'Unser Cookie-Hinweis',
    message: 'By using our site, you acknowledge that you have read and understand our ',

    cookiePolicyLink: 'Unser Cookie-Hinweis',
    cookiePolicyHref: '/cookie-policy',

    privacyPolicyLink: 'Privacy Policy',
    privacyPolicyHref: '/privacy-policy',

    tosLink: 'Terms of Service',
    tosHref: '/terms-of-use',
  }
};


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ModuleUserComponent,
    ModulePageComponent,
    HeaderComponent,
    ContactComponent,
    LoginComponent,
    RegisterComponent,
    FooterComponent,
    AlertComponent,
    ActivateComponent,
    AboutusPageComponent,
    DownloadPageComponent,
    MobileMenuComponent,
    DesktopMenuComponent,
    ErrorComponent,
    TermsOfUseComponent,
    UserModalComponent,
    RecoverPasswordComponent,
    PasswordResetComponent,
    PricingComponent,
    FaqPageComponent,
    BrowseComponent,
    EntityDetailComponent,
    PublicEntityDetailComponent,
    PaymentComponent,
    AddEntityComponent,
    LogoutComponent,
    ProfileGeneralComponent,
    ProfileAddressComponent,
    ProfileNotificationComponent,
    UserListingComponent,
    ComingSoonComponent,
    ProfilePreferencesComponent,
    AddCategoryComponent,
    AddLocationComponent,
    AddGalleryComponent,
    AddPropertiesComponent,
    AddConfirmationComponent,
    DynamicFormComponent,
    EntityEditComponent,
    EntityCreateComponent,
    DropdownMenuComponent,
    NotificationCenterComponent,
    PaginationComponent,
    ProfileDetailComponent,
    DynamicSearchFieldComponent,
    SubscriptionListingComponent,
    VoucherComponent,
    ReferralComponent,
    ReferralListingComponent,
    ModifyReferralComponent,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    PasswordChangeComponent,
    ConfirmationDialogComponent,
    GlobalLoadingComponent,
    AboutAppSectionComponent,
    ExploreProductsSectionComponent,
    JobSliderSectionComponent,
    BecomeSellerSectionComponent,
    SwapappSectionComponent,
    RealEsatteSliderComponent,
    LuxurySliderComponent,
    JobsSliderComponent,
    VehicleSliderComponent,
    HeaderSearchComponent,
    ListingItemComponent,
    SearchResultComponent,
    SearchComponent,
    SubscriptionsComponent
  ],
  imports: [
    NgxStripeModule.forRoot(environment.configSettings.stripePublicKey),
    CrystalLightboxModule,
    BrowserModule,
    SlickCarouselModule,

    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    SweetAlert2Module.forRoot(),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    // Firebase messaging cloud
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    MatAutocompleteModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatInputModule,
    MatSliderModule,
    MatDialogModule,
    MatTableModule,
    MatChipsModule,
    MatGridListModule,
    MatIconModule,
    NpnSliderModule,
    MatSlideToggleModule,
    MatSidenavModule,
    // Translation
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
    MessagingService,
    AsyncPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
    provide: HttpClient
},
    AlertService,
    AuthenticationService,
    AddressService,
    EntityService,
    EntityAdditionService,
    UserService,
    AuthGuardService,
    FormControlService,
    ConfirmationDialogService,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
  ],
  entryComponents: [ConfirmationDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
