import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

import { GetSearchPreferenceResponse } from './responses/getSearchPreferenceResponse';
import { SearchPreference } from '../models/searchPreference';
import { CreateSearchPreferenceResponse } from './responses/createSearchPreferenceResponse';
import { UpdateSearchPreferenceResponse } from './responses/updateSearchPreferenceResponse';

@Injectable({
  providedIn: 'root'
})
export class SearchPreferenceService {


  private apiBaseURL = environment.configSettings.apiURL;

  constructor(private http: HttpClient) { }


  getSearchPreferences() {
    return this.http.get<GetSearchPreferenceResponse>(`${this.apiBaseURL}v1/user/search-preference/`, { observe: 'response' });
  }

  createSearchPreference(searchPreference: SearchPreference) {
    return this.http.post<CreateSearchPreferenceResponse>(`${this.apiBaseURL}v1/user/search-preference/`, searchPreference, { observe: 'response' });
  }

  updateSearchPreference(hashPref: string, searchPreference: SearchPreference) {
    return this.http.put<UpdateSearchPreferenceResponse>(`${this.apiBaseURL}v1/user/search-preference/${hashPref}`,
      searchPreference, { observe: 'response' });
  }

  deleteSearchPreference(hashPref: string) {
    return this.http.delete<UpdateSearchPreferenceResponse>(`${this.apiBaseURL}v1/user/search-preference/${hashPref}`,  { observe: 'response' });
  }

}
