import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',

})
export class FaqPageComponent implements OnInit {

  constructor() {  window.scrollTo(0, 0); }

  ngOnInit(): void {
  }

}
