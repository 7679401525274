<div class="modal-body " >
  <div class="form-excerpts">
    <table class="m-auto w-100 table-borderless">
      <tr>
        <td>{{'userModal.buttons.alreadyMember' | translate }}
          
          <a  style="font-weight: bolder;" (click)="onSwitchComponent('sign_in')" href="javascript:void(0)">{{'buttons.signIn' | translate }}</a>
        </td>
      </tr>

    </table>
  </div>
  <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">

    <!-- Username -->
    <label style="margin-top: 15px;" for="username-field">{{'userModal.signUpPage.inputFields.username.label' | translate }}</label>
    <div class="input-group mb-4 custom-input-group-height">
      <input
        id='username-field'
        type="text"
        formControlName="username"
        class="form-control custom-input-group-height"
        [ngClass]="{ 'is-invalid': submitted && form.username.errors }"
        placeholder="{{'userModal.signUpPage.inputFields.username.placeholder' | translate }}"/>

      <div class="input-group-append">
         <span class="input-group-text custom-span-input-group" >
           <i class="fas fa-user-alt"></i>
         </span>
      </div>

      <div *ngIf="submitted && form.username.errors" class="invalid-feedback">
        <div *ngIf="form.username.errors.required">{{'userModal.signUpPage.inputFields.username.errors.required' | translate }}</div>

        <ng-container *ngIf="form.username.errors.backendError">
          <div *ngFor="let errorMessage of form.username.errors.backendError">{{ errorMessage }}</div>
        </ng-container>

      </div>

    </div>
    <!-- -------  -->

    <!-- First name -->

    <label for="first_name-field">{{'userModal.signUpPage.inputFields.firstName.label' | translate }}</label>

    <div class="input-group mb-4 custom-input-group-height">

      <input id='first_name-field'
             type="text"
             formControlName="first_name"
             class="form-control custom-input-group-height"
             [ngClass]="{ 'is-invalid': submitted && form.first_name.errors }"
             placeholder="{{'userModal.signUpPage.inputFields.firstName.placeholder' | translate }}"/>

      <div class="input-group-append">
         <span class="input-group-text custom-span-input-group" >
           <i class="fas fa-signature"></i>
         </span>
      </div>

      <div *ngIf="submitted && form.first_name.errors" class="invalid-feedback">
        <div *ngIf="form.first_name.errors.required">{{'userModal.signUpPage.inputFields.firstName.errors.required' | translate }}</div>

        <ng-container *ngIf="form.first_name.errors.backendError">
          <div *ngFor="let errorMessage of form.first_name.errors.backendError">{{ errorMessage }}</div>
        </ng-container>

      </div>

    </div>

    <!-- -------  -->

    <!-- Last name -->

    <label for="last_name-field">{{'userModal.signUpPage.inputFields.lastName.label' | translate }}</label>

    <div class="input-group mb-4 custom-input-group-height">

      <input id='last_name-field'
             type="text"
             formControlName="last_name"
             class="form-control custom-input-group-height"
             [ngClass]="{ 'is-invalid': submitted && form.last_name.errors }"
             placeholder="{{'userModal.signUpPage.inputFields.lastName.placeholder' | translate }}"/>

      <div class="input-group-append">
         <span class="input-group-text custom-span-input-group" >
           <i class="fas fa-signature"></i>
         </span>
      </div>

      <div *ngIf="submitted && form.last_name.errors" class="invalid-feedback">
        <div *ngIf="form.last_name.errors.required">{{'userModal.signUpPage.inputFields.lastName.errors.required' | translate }}</div>

        <ng-container *ngIf="form.last_name.errors.backendError">
          <div *ngFor="let errorMessage of form.last_name.errors.backendError">{{ errorMessage }}</div>
        </ng-container>

      </div>

    </div>

    <!-- -------  -->

    <!-- Email -->

    <label for="email-field">{{'userModal.signUpPage.inputFields.email.label' | translate }}</label>

    <div class="input-group mb-4 custom-input-group-height">

      <input
        id='email-field'
        type="email"
        formControlName="email"
        class="form-control custom-input-group-height"
        [ngClass]="{ 'is-invalid': submitted && form.email.errors }"
        placeholder="{{'userModal.signUpPage.inputFields.email.placeholder' | translate }}"
      />

      <div class="input-group-append">
         <span class="input-group-text custom-span-input-group" >
           <i class="fas fa-user-circle"></i>
         </span>
      </div>

      <div *ngIf="submitted && form.email.errors" class="invalid-feedback">
        <div *ngIf="form.email.errors.required">{{'userModal.signUpPage.inputFields.email.errors.required' | translate }}</div>
        <div *ngIf="form.email.errors.email">{{'userModal.signUpPage.inputFields.email.errors.email' | translate }}</div>

        <ng-container *ngIf="form.email.errors.backendError">
          <div *ngFor="let errorMessage of form.email.errors.backendError">{{ errorMessage }}</div>
        </ng-container>

      </div>

    </div>

    <!-- -------  -->

    <!-- Password -->

    <label for="password-field">{{'userModal.signUpPage.inputFields.password.label' | translate }}</label>

    <div class="input-group mb-4 custom-input-group-height">

      <input id='password-field'
             [type]="fieldTextType ? 'text' : 'password'"
             formControlName="password"
             class="form-control custom-input-group-height"
             [ngClass]="{ 'is-invalid': submitted && form.password.errors }"
             placeholder="{{'userModal.signUpPage.inputFields.password.placeholder' | translate }}"/>
      <div class="input-group-append">
         <span class="input-group-text text-center custom-span-input-group" >
           <i class="fa" [ngClass]="{
                           'fa-eye-slash': !fieldTextType,
                           'fa-eye': fieldTextType
                         }"
              (click)="toggleFieldTextType()"
           ></i>
         </span>
      </div>

      <div *ngIf="submitted && form.password.errors" class="invalid-feedback">
        <div *ngIf="form.password.errors.required">{{'userModal.signUpPage.inputFields.password.errors.required' | translate }}</div>
        <div *ngIf="form.password.errors.hasNumber">{{'userModal.signUpPage.inputFields.password.errors.hasNumber' | translate }}</div>
        <div *ngIf="form.password.errors.hasCapitalCase">{{'userModal.signUpPage.inputFields.password.errors.hasCapitalCase' | translate }}</div>
        <div *ngIf="form.password.errors.hasSmallCase">{{'userModal.signUpPage.inputFields.password.errors.hasSmallCase' | translate }}</div>
        <div *ngIf="form.password.errors.minlength">{{'userModal.signUpPage.inputFields.password.errors.minlength' | translate }}</div>
        <div *ngIf="form.password.errors.NoPasswordMatch">{{'userModal.signUpPage.inputFields.password.errors.NoPasswordMatch' | translate }}</div>

        <ng-container *ngIf="form.password.errors.backendError">
          <div *ngFor="let errorMessage of form.password.errors.backendError">{{ errorMessage }}</div>
        </ng-container>

      </div>



    </div>

    <!-- -------  -->

    <!-- Repeat password -->

    <label for="password1-field">{{'userModal.signUpPage.inputFields.password2.label' | translate }}</label>

    <div class="input-group mb-4 custom-input-group-height">

      <input id='password1-field'
             [type]="fieldTextType ? 'text' : 'password'"
             formControlName="password1"
             class="form-control custom-input-group-height"
             [ngClass]="{ 'is-invalid': submitted && form.password.errors }"
             placeholder="{{'userModal.signUpPage.inputFields.password2.placeholder' | translate }}"/>

      <div class="input-group-append">
         <span class="input-group-text text-center custom-span-input-group" >
           <i class="fa" [ngClass]="{
                           'fa-eye-slash': !fieldTextType,
                           'fa-eye': fieldTextType
                         }"
             (click)="toggleFieldTextType()"
           ></i>
         </span>
      </div>

      <div *ngIf="submitted && form.password1.errors" class="invalid-feedback">
        <div *ngIf="form.password1.errors.required">{{'userModal.signUpPage.inputFields.password.errors.required' | translate }}</div>
        <div *ngIf="form.password1.errors.NoPasswordMatch">{{'userModal.signUpPage.inputFields.password.errors.noPasswordMatch' | translate }}</div>

        <ng-container *ngIf="form.password1.errors.backendError">
          <div *ngFor="let errorMessage of form.password1.errors.backendError">{{ errorMessage }}</div>
        </ng-container>

      </div>

    </div>


    <!-- -------  -->

    <!-- Agree privacy -->

     <br>
    <div class="row">
      <div class="col-2">
        <mat-checkbox
          id='over18'
          formControlName="over18"
          name="over18"
        ></mat-checkbox>
      </div>
      <div class=" col-10 " style="margin-left: -10%;">
        <label class="labels" for="terms">{{'buttons.policyButtons.over18' | translate }}
          </label>
      </div>
      <mat-error class="ml-3 small m-0" *ngIf="submitted && form.over18.errors">{{'errors.booleanRequired' | translate }}</mat-error>
    </div>

<div class="row">
  <div class="col-2 ">
    <mat-checkbox
      id='privateUse'
      formControlName="privateUse"
      name="privateUse"
    ></mat-checkbox>
  </div>
      <div class=" col-10 " style="margin-left: -10%;">
        <label class="labels" for="terms">{{'buttons.policyButtons.privateUse' | translate }}
          </label>
      </div>
      <mat-error class="ml-3 small m-0" *ngIf="submitted && form.privateUse.errors">{{'errors.booleanRequired' | translate }}</mat-error>
    </div>


    <div class="row ">
      <div class="col-2">
        <mat-checkbox
          id='policy'
          formControlName="policy"
          name="policy"
        ></mat-checkbox>
      </div>
      <div class="col-10" style="margin-left: -10%;">
        <label class="labels" for="policy">{{'buttons.policyButtons.privacyPolicyPartOne' | translate }}
          <a routerLink="/terms-of-use" (click)="closeModal()">{{'buttons.policyButtons.privacyPolicyPartTwoClickable' | translate }}</a>
        {{'buttons.policyButtons.privacyPolicyPartThree' | translate }}</label>
      </div>
      <div *ngIf="submitted && form.policy.errors" class="invalid-feedback">
        <div *ngIf="form.policy.errors.required">{{'errors.required' | translate }}</div>
      </div>
      <mat-error class="ml-3 small m-0" *ngIf="submitted && form.policy.errors">{{'errors.booleanRequired' | translate }}</mat-error>
    </div>
    <div class="row mb-2">
      <div class="col-2 ">
        <mat-checkbox
          id='terms'
          formControlName="terms"
          name="terms"
        ></mat-checkbox>
      </div>
      <div class=" col-10 " style="margin-left: -10%;">
        <label class="labels" for="terms">{{'buttons.policyButtons.termsOfServicePartOne' | translate }}
          <a routerLink="/terms-of-use" (click)="closeModal()">{{'buttons.policyButtons.termsOfServicePartTwoClickable' | translate }}</a>
        {{'buttons.policyButtons.termsOfServicePartThree' | translate }}</label>
      </div>

      <mat-error class="ml-3 small m-0" *ngIf="submitted && form.terms.errors">{{'errors.booleanRequired' | translate }}</mat-error>
    </div>

    <!-- -------  -->

    <!-- Agree terms -->

    <div class="form-group">
      <button [disabled]="loading" class="btn-second blue-btn full-width">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{'buttons.register' | translate }}
      </button>
      <!--<p>{{'userModal.buttons.loginText' | translate }}
        <em>{{'buttons.register' | translate }}</em> {{'userModal.buttons.acceptanceTextLogin' | translate }}
        <a routerLink="/terms-of-use" (click)="closeModal()" >{{'buttons.policyButtons.termsService' | translate }}</a>
      </p>-->
    </div>

    <!-- -------  -->

  </form>
  
    <!-- ------- 
<div class="form-excerpts">
    <table class="m-auto w-100 table-borderless">
      <tr>
        <td>{{'userModal.buttons.forgotPasswordText' | translate }}</td>
        <td class="float-right">
          <a (click)="onSwitchComponent('recover_password')" href="javascript:void(0)">{{'buttons.recoverPassword' | translate }}</a>
        </td>
      </tr>
    </table>
  </div>
   -->
</div>
