import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GetSubscriptionPlanResponse } from './responses/getSubscriptionPlanResponse';
import { SetupSubscriptionResponse } from './responses/setupSubscriptionResponse';
import { GetUserSubscriptionsResponse } from './responses/getUserSubscriptionsResponse';
import { ValidSubscriptionResponse } from './responses/validSubscriptionResponse';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private apiBaseURL = environment.configSettings.apiURL;

  constructor(private http: HttpClient) { }

  getUserSubscriptions(page?: number) {
    if (page) {
      return this.http.get<GetUserSubscriptionsResponse>(`${this.apiBaseURL}v1/subscription/subscription?page=${page}`,
        {observe: 'response'});
    }
    return this.http.get<GetUserSubscriptionsResponse>(`${this.apiBaseURL}v1/subscription/subscription`, {observe: 'response'});
  }

  getValidSubscription() {
    return this.http.get<ValidSubscriptionResponse>(`${this.apiBaseURL}v1/subscription/valid_subscription/`, { observe: 'response' });
  }

  getSubscriptionPlans() {
    return this.http.get<GetSubscriptionPlanResponse>(`${this.apiBaseURL}v1/subscription/plan/`, { observe: 'response' });
  }
  setupSubscription(planUi: string){
    return this.http.post<SetupSubscriptionResponse>(`${this.apiBaseURL}v1/subscription/setup-subscription/`, { ui: planUi }, { observe: 'response' });
  }

}
