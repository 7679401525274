import { Injectable } from '@angular/core';
import { JWTToken } from '../models/jwt-token';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  static tokenNameJWT = 'currentJWT';

  constructor() { }

  get getJwtToken(){
    /* This function gets the JWT from the local storage */
    return JSON.parse(localStorage.getItem(LocalStorageService.tokenNameJWT));
  }

  setJwtToken(jwtToken: JWTToken): void{
    /* This function sets the JWT in the local storage */
    localStorage.setItem(LocalStorageService.tokenNameJWT, JSON.stringify(jwtToken));
  }

  removeJwt(): void {
    /* This function removes the JWT from the local storage if exists.*/
    if (this.getJwtToken){
      localStorage.removeItem(LocalStorageService.tokenNameJWT);
    }

  }
}
