import { Component, OnDestroy, OnInit } from '@angular/core';
import { SearchService } from '../../module-search/services/search.service';
import { CorrespondenceService } from '../../module-communication/services/correspondence.service';
import { AlertService } from '../../core/alert/alert.service';
import { Profile } from '../../module-user/models/profile';
import { AuthenticationService } from '../../module-user/services/authentication.service';
import { Observable, Subscription } from 'rxjs';
import { SearchModel } from '../../module-search/models/searchModel';
import { ConstantsService } from '../../core/services/constants.service';
import { City } from '../../module-address/models/city';
import { Country } from '../../module-address/models/country';
import { EntityService } from '../services/entity.service';
import { EntityClass } from '../models/entityClass';
import { CategoryService } from '../services/category.service';
import { Category } from '../models/category';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AddressService } from '../../module-address/services/address.service';
import { Constant } from '../../module-user/models/constant';
import { DropdownInput } from '../../core/models/forms/dropdown-input';
import { NumberInput } from '../../core/models/forms/number-input';
import { CheckboxInput } from '../../core/models/forms/checkbox-input';
import { RadioInput } from '../../core/models/forms/radio-input';
import { DateInput } from '../../core/models/forms/date-input';
import { TextboxInput } from '../../core/models/forms/textbox-input';
import { FormControlService } from '../../core/services/form-control.service';
import { Entity } from '../models/entity';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from '../../core/services/cookie.service';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.css'],
})
export class BrowseComponent implements OnInit, OnDestroy {
  // Subscriptions
  entityServiceSubscription: Subscription;
  searchServiceSubscription: Subscription;
  entityClassSearchSubscription: Subscription;
  constantSubscription: Subscription;
  categorySubscription: Subscription;
  entityClassesSubscription: Subscription;
  countriesSubscription: Subscription;
  dealsAroundMeSubscription: Subscription;

  countries: Array<Country>;
  cities: Array<City>;

  filteredCities: Observable<Array<City>>;
  searchForm: FormGroup;

  allCities: Array<City>;

  userProfile: Profile = null;

  entityHashes = [];

  entities: Array<SearchModel> = [];
  totalPages: Array<number> = [];
  previousLink: string;
  nextLink: string;
  currentPage: number;

  masterSearchKeyword: string;

  entityClasses: Array<EntityClass> = [];
  selectableEntityClasses: Array<EntityClass> = [];
  categories: Array<Category>;

  filteringForm: FormGroup;

  ordering: Array<Constant>;

  entityFormBase = [];
  attributeForm = null;
  groupOfForm: Array<{ id: string; name: string }> = [];

  queryResultsNumber: number = null;

  recentlyViewed: Array<Entity> = new Array<Entity>();

  countrySearchedFor = null;

  selectCategoryText = '';
  selectMainCategoryText = '';
  selectCountryText = '';
  selectCityText = '';

  dealsAroundMeShortText = '';
  dealsAroundMeMessage1Text = '';
  dealsAroundMeMessage2xText = '';

  chosenLanguage = 'en';

  constructor(
    private searchService: SearchService,
    private correspondenceService: CorrespondenceService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private constantsService: ConstantsService,
    private entityService: EntityService,
    private categoryService: CategoryService,
    private formBuilder: FormBuilder,
    private addressService: AddressService,
    private formControlService: FormControlService,
    private router: Router,
    private cookieService: CookieService,
    translate: TranslateService
  ) {
    window.scrollTo(0, 0);
    this.chosenLanguage = this.cookieService.LanguageCookie;
    // Getting translation for selection
    translate
      .get('entityComponent.browse.inputFields.category.selectText')
      .subscribe((res: string) => {
        this.selectCategoryText = res;
      });
    translate
      .get('entityComponent.browse.inputFields.mainCategory.selectText')
      .subscribe((res: string) => {
        this.selectMainCategoryText = res;
      });
    translate
      .get('entityComponent.browse.inputFields.country.selectText')
      .subscribe((res: string) => {
        this.selectCountryText = res;
      });
    translate
      .get('entityComponent.browse.inputFields.city.selectText')
      .subscribe((res: string) => {
        this.selectCityText = res;
      });

    translate
      .get('header.dealsAroundMeFeature.shortText')
      .subscribe((res: string) => {
        this.dealsAroundMeShortText = res;
      });
    translate
      .get('header.dealsAroundMeFeature.1message')
      .subscribe((res: string) => {
        this.dealsAroundMeMessage1Text = res;
      });
    translate
      .get('header.dealsAroundMeFeature.2xMessage')
      .subscribe((res: string) => {
        this.dealsAroundMeMessage2xText = res;
      });
  }

  ngOnInit(): void {

    let matchUnmatch:boolean;
    this.searchService.matchUnmatchBoolean.subscribe(boolean => {
      matchUnmatch = boolean;
    })
    this.searchService.mySubject.subscribe(hash => {
      console.log('hash', hash)
      console.log('boolean', matchUnmatch)

        this.toggleCorrespondence(hash, matchUnmatch)

    })

    this.searchForm = this.formBuilder.group({
      keyword: ['', []],
      city: ['', []],
    });

    this.filteredCities = this.formControls.city.valueChanges.pipe(
      startWith(''),
      map((city) => (city ? this._filterCities(city) : this.cities.slice()))
    );

    // Deals around me feature
    this.dealsAroundMeSubscription =
      this.searchService.dealsAroundMeLocation.subscribe(
        (locationCoordinates) => {
          if (locationCoordinates !== null) {
            this.searchService
              .doSearch(
                null,
                null,
                {
                  radius: locationCoordinates.radius,
                  lat: locationCoordinates.latitude,
                  lon: locationCoordinates.longitude,
                },
                null,
                null,
                null
              )
              .subscribe(
                (resp) => {
                  this.constructTheListing(resp);
                  this.queryResultsNumber = resp.body.data.total;
                  if (
                    resp.body.data.total === 1 ||
                    resp.body.data.total === 0
                  ) {
                    this.alertService.success(
                      `${this.dealsAroundMeShortText} ${resp.body.data.total} ${this.dealsAroundMeMessage1Text}`
                    );
                  } else {
                    this.alertService.success(
                      `${this.dealsAroundMeShortText} ${resp.body.data.total} ${this.dealsAroundMeMessage1Text}`
                    );
                  }
                  this.searchService.dealsAroundMeLocation.next(null);
                },
                (error) => console.error(error)
              );
          }
        }
      );

    this.recentlyViewed = this.entityService.recentlyViewedItems;

    // 1.
    this.authenticationService.currentUserSubject.subscribe((userProfile) => {
      this.userProfile = userProfile;
      // console.log(this.userProfile);
      if (this.userProfile !== null) {
        this.checkMatches(this.entityHashes);
      }
    });

    // 2.
    this.getEntities();

    // Build the current form
    this.filteringForm = this.formBuilder.group({
      mainSearch: [],
      category: [this.selectCategoryText],
      entityClass: [this.selectMainCategoryText],
      country: [this.selectCountryText],
      city: [this.selectCityText],
      radius: ['', []],
      sortBy: ['', []],
    });

    this.addressService.getAvailableCountriesAsSubject();

    this.countriesSubscription =
      this.addressService.availableCountries.subscribe(
        (availableCountries) => (this.countries = availableCountries)
      );

    // Listen to country changes and add cities accordingly
    this.filteringForm.get('country').valueChanges.subscribe((val) => {
      if (val !== this.selectCountryText) {
        this.countrySearchedFor = val;
        this.addressService.getCities(val).subscribe(
          (response) => (this.cities = response.body.data),
          (errorResponse) => console.error(errorResponse)
        );
      }
    });

    // Loading all of the cities
    this.constantSubscription = this.constantsService.constant.subscribe(
      (constants) => {
        if (constants !== null) {
          this.allCities = constants.city;
          this.ordering = constants.filtering_order;
        }
      }
    );

    // Listen to the filtering changes
    this.filteringForm.valueChanges.subscribe((formValues) => {
      // console.log('changes');
      // console.log(formValues);

      // Setting keyword
      const mainSearchSearchFor = formValues.mainSearch;
      let entityClassSearchedFor = null;
      let ordering = null;
      let citySearchedFor: {
        radius: string;
        lat: string;
        lon: string;
      } = null;
      let attributesFilter: Map<string, { gte: any; lte: any; exact: any }> =
        new Map<string, { gte: any; lte: any; exact: any }>();

      // Setting entityClass
      if (
        formValues.entityClass !== undefined &&
        formValues.entityClass !== this.selectMainCategoryText &&
        formValues.entityClass !== ''
      ) {
        this.attributeForm.valueChanges.subscribe((attributeValues) => {
          attributesFilter = new Map<
            string,
            { gte: any; lte: any; exact: any }
          >();

          this.searchService
            .doSearch(
              mainSearchSearchFor,
              entityClassSearchedFor,
              citySearchedFor,
              this.countrySearchedFor,
              ordering,
              attributesFilter
            )
            .subscribe(
              (resp) => {
                this.constructTheListing(resp);
                this.queryResultsNumber = resp.body.data.total;
                // this.alertService.success(`Found ${resp.body.data.total} results`);
              },
              (error) => console.error(error)
            );
        });
      }

      // Setting city
      if (
        formValues.city !== undefined &&
        formValues.city !== this.selectCityText &&
        formValues.city !== ''
      ) {
        const filteredCity = this.allCities.filter(
          (city) => city.id === formValues.city
        )[0];
        // console.log(filteredCity);
        citySearchedFor = {
          radius: formValues.radius,
          lat: filteredCity.location_field_indexing.lat,
          lon: filteredCity.location_field_indexing.lon,
        };
        // console.log(citySearchedFor);
      }
      // console.log(attributesFilter);
      // Setting ordering
      if (formValues.sortBy !== undefined && formValues.sortBy !== '') {
        ordering = formValues.sortBy;
        // console.log(entityClassSearchedFor);
      }
      this.searchService
        .doSearch(
          mainSearchSearchFor,
          entityClassSearchedFor,
          citySearchedFor,
          this.countrySearchedFor,
          ordering
        )
        .subscribe(
          (resp) => {
            this.constructTheListing(resp);
            // console.log(resp);
            this.queryResultsNumber = resp.body.data.total;
            // this.alertService.success(`Found ${resp.body.data.total} results`);
          },
          (error) => console.error(error)
        );
    });

    // Entity class subscription, used for choosing a category and search after it
    this.searchService.currentEntityClassSearch = null;
    this.entityClassSearchSubscription =
      this.searchService.entityClassSearch.subscribe((entityClassName) => {
        /* In case of selecting from the top menu, the filtering menu changes accordingly*/
        if (entityClassName !== null) {
          // console.log(this.entityClasses);
          let filteredCategory;
          this.categories.forEach((category) => {
            if (category.children !== null) {
              category.children.forEach((child) => {
                if (
                  child.name.toLowerCase() === entityClassName.toLowerCase()
                ) {
                  filteredCategory = category;
                }
              });
            }
          });
          this.filteringFormControls.category.setValue(filteredCategory.id);
          this.getFilteredEntityClassesForCategory(filteredCategory);
          let filteredEntityClass;
          this.entityClasses.forEach((entityClass) => {
            if (
              entityClass.name.toLowerCase() === entityClassName.toLowerCase()
            ) {
              filteredEntityClass = entityClass;
            }
          });
          this.filteringFormControls.entityClass.setValue(
            filteredEntityClass.id
          );
        }
      });

    // Master search subscription, used for doing a master search
    this.searchServiceSubscription = this.searchService.masterSearch.subscribe(
      (masterSearch) => {
        if (masterSearch !== null) {
          this.filteringFormControls.mainSearch.setValue(masterSearch.keyword);
          if (
            masterSearch.city !== '' &&
            masterSearch.city !== undefined &&
            masterSearch.city !== null
          ) {
            // Change the filter accordingly to the master search
            const citySearchedFor = this.allCities.filter(
              (city) => city.name === masterSearch.city
            )[0];
            this.filteringFormControls.country.setValue(
              citySearchedFor.country
            );
            this.filteringFormControls.city.setValue(citySearchedFor.id);
            this.filteringFormControls.radius.setValue(5);
          }
        }
      }
    );

    // Getting entity classes
    this.entityService.getEntityClasses();
    this.entityClassesSubscription = this.entityService.entityClasses.subscribe(
      (entityClasses) => (this.entityClasses = entityClasses)
    );

    // Set the categories and the classes options
    if (this.categoryService.currentCategories === null) {
      // Getting the current categories. If they are already loaded we get them from the service subject
      this.categorySubscription = this.categoryService
        .getCategories()
        .subscribe(
          (response) => {
            this.categoryService.category = response.body.data;
            this.categories = response.body.data;
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      // categories are already loaded by a different component
      this.categories = this.categoryService.currentCategories;
    }

    // Listen to category selection changes in order to update the list of entity classes available
    this.filteringForm.get('category').valueChanges.subscribe((val) => {
      // console.log(this.entityClasses);
      // console.log(this.categories);
      const currentCategory = this.categories.filter(
        (category) => category.id === val
      )[0];
      // console.log(currentCategory);

      this.getFilteredEntityClassesForCategory(currentCategory);
    });
  }

  // convenience getter for easy access to form fields
  get formControls() {
    return this.searchForm.controls;
  }

  isApiCallInProgress = false;

  getDealsAroundLocation(): void {
    navigator.geolocation.getCurrentPosition((resp) => {
      this.alertService.clear();
      const radius = 50;
      this.searchService.dealsAroundMeLocation.next({
        latitude: resp.coords.latitude.toString(),
        longitude: resp.coords.longitude.toString(),
        radius: radius.toString(),
      });
      this.router.navigate(['/search-result']);
    });
  }
  searchEntityClass(entityClassName) {
    console.log('searchEntityClass called with', entityClassName);
    this.router.navigate(['/search-result']);

    setTimeout(
      () => (this.searchService.currentEntityClassSearch = entityClassName),
      50
    );
  }

  onSearchSubmit() {
    this.searchService.masterSearch.next({
      keyword: this.formControls.keyword.value,
      city: this.formControls.city.value,
    });
    window.scrollTo({ top: 800, behavior: 'smooth' });
    this.router.navigate(['/search-result']);
  }

  private _filterCities(value: string): City[] {
    const filterValue = value.toLowerCase();

    return this.cities.filter(
      (city) => city.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  getFilteredEntityClassesForCategory(currentCategory: Category) {
    this.selectableEntityClasses = [];
    this.entityClasses.forEach((entityClass) => {
      if (currentCategory.numchild !== 0) {
        currentCategory.children.forEach((subCategory1) => {
          if (
            entityClass.name.toLowerCase() === subCategory1.name.toLowerCase()
          ) {
            this.selectableEntityClasses.push(entityClass);
          }
        });
      }
    });
  }

  async getEntities(pageNumber?: number) {
    this.entityServiceSubscription = await this.searchService
      .getUserEntities(pageNumber)
      .subscribe(
        (response) => {this.constructTheListing(response);
        },
        (errorResponse) => {}
      );
  }



  constructTheListing(response) {
    this.totalPages = [];
    this.entityHashes = [];
    this.entities = response.body.data.results;
    this.nextLink = response.body.data.links.next;
    this.previousLink = response.body.data.links.previous;
    for (
      let i = 1;
      i <= Math.ceil(response.body.data.total / response.body.data.page_size);
      i++
    ) {
      this.totalPages.push(i);
    }
    this.currentPage = response.body.data.page;

    this.entities.forEach((entity) => this.entityHashes.push(entity.hash_1));
    if (this.userProfile !== null) {
      this.checkMatches(this.entityHashes);
    }
  }

  goToPage(pageNumber) {
    this.getEntities(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  checkMatches(entityHashes: Array<string>) {
    /* This function checks if the current entities are matched.*/
    this.correspondenceService.checkMatches(entityHashes).subscribe(
      (response) => {
        response.body.data.forEach((obj) => {
          try {
            this.entities.filter(
              (entity) => entity.hash_1 === obj.entity_hash
            )[0].correspondence = obj.correspondence;
          } catch (err) {
            return;
          }
        });
      },
      (errorResponse) => this.alertService.error(errorResponse.error.error[0])
    );
  }

  toggleCorrespondence(hash: string, create: boolean = true) {
    /* Function used to create/delete the correspondence object between the user and the owner of the entity*/
    if (create === true) {
      this.correspondenceService.createCorrespondence(hash).subscribe(
        (response) => {
          this.alertService.success(response.body.message);
          // We need to check the matches
          this.checkMatches(this.entityHashes);
        },
        (errorResponse) => {
          this.alertService.error(errorResponse.error.error);
          this.router.navigate(['/pricing']);
        }
      );
    } else {
      this.correspondenceService.deleteCorrespondence(hash).subscribe(
        (response) => {
          this.alertService.success(response.body.message);
          // We need to check the matches
          this.checkMatches(this.entityHashes);
        },
        (errorResponse) => {
          this.alertService.error(errorResponse.error.error);
          this.router.navigate(['/pricing']);
        }
      );
    }
  }

  get filteringFormControls() {
    return this.filteringForm.controls;
  }

  onFilterSubmit() {}

  ngOnDestroy() {
    if (this.entityServiceSubscription !== undefined) {
      this.entityServiceSubscription.unsubscribe();
    }
    if (this.searchServiceSubscription !== undefined) {
      this.searchServiceSubscription.unsubscribe();
    }
    if (this.constantSubscription !== undefined) {
      this.constantSubscription.unsubscribe();
    }
    if (this.entityClassSearchSubscription !== undefined) {
      this.entityClassSearchSubscription.unsubscribe();
    }
    if (this.categorySubscription !== undefined) {
      this.categorySubscription.unsubscribe();
    }
    if (this.entityClassesSubscription !== undefined) {
      this.entityClassesSubscription.unsubscribe();
    }
    if (this.countriesSubscription !== undefined) {
      this.countriesSubscription.unsubscribe();
    }
  }
}