<section class="coming-soon">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="logo text-center">
          <a routerLink="/">
            <img src="assets/images/logo/swapapp-logo.png" alt="logo">
          </a>
        </div>
        <div class="content-wrapper">
          <div class="section-header">
            <h1 class="text-custom-white">The application is
              <a routerLink="/coming-soon" class="text-custom-white">Under Development</a>
            </h1>
            <p class="text-custom-white">We are Coming Soon...</p>
          </div>
          <div class="counter">
            <div class="counter-box">
              <div class="inner-box"><span class="text-custom-white" id="cvdays">0</span>
              </div>
              <p class="text-custom-white">Days</p>
            </div>
            <div class="counter-box">
              <div class="inner-box"><span class="text-custom-white" id="cvhours">0</span>
              </div>
              <p class="text-custom-white">Hours</p>
            </div>
            <div class="counter-box">
              <div class="inner-box"> <span class="text-custom-white" id="cvminutes">0</span>
              </div>
              <p class="text-custom-white">Minutes</p>
            </div>
            <div class="counter-box">
              <div class="inner-box"> <span class="text-custom-white" id="cvseconds">0</span>
              </div>
              <p class="text-custom-white">Seconds</p>
            </div>
          </div>
          <div class="newsletter-form">
            <div class="input-group">
              <input type="email" class="form-control form-control-custom" placeholder="Enter your email here">
              <div class="input-group-append">
                <button class="btn-second orange-btn" type="submit">Get Updates</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
