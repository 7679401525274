<div class="container">
<div class="privacypolicymain">
  <h1 class="privacypolicy">Privacy Policy</h1>
  <p class="privacypolicypara">We are a company registered in Germany (registration number HRB 207190) under the name
    HADAD GMBH; the address of
    our registered office is Ahltener Str.15C 31275, Lehrte Germany. SwapApp is committed to respecting the privacy
    rights of visitors to its Site and App. This Privacy Notice explains how we collect, store and use personal data
    about you when you visit the Site or App, buy Products from us or otherwise provide your personal data to us. It
    provides you with details about the types of personal data that we collect from you, how we use your personal data
    and the rights you have to control our use of your personal data. Words used in this Privacy Notice have the same
    meaning as is attributed to them in the SwapApp Terms of Use.</p>
</div>

<div class="policylinkmain">
  <div class="policylink">
    <a >
      <p> What personal information do we collect about you? </p>
    </a>
    <a >
      <p> How do we use your personal information? </p>
    </a>
    <a >
      <p> Disclosing your personal data </p>
    </a>
    <a >
      <p> Marketing preferences </p>
    </a>
    <a >
      <p> International transfers </p>
    </a>
    <a >
      <p> Data retention </p>
    </a>
    <a >
      <p> Your rights </p>
    </a>
    <a >
      <p> Security </p>
    </a>
    <a >
      <p class="theswapappApp"> The SwapApp App </p>
    </a>
  </div>

  <div>
    <div class="privacycontentmain">
      <h1 class="privacyheading">What Personal Information Do We Collect About You?</h1>
      <p class="privacypara">When you access and move around SwapApp, register an Account with SwapApp or purchase a
        Product from SwapApp,
        we may collect some or all of the following personal data about you:</p>
      <ul class="privacypoints">
        <li>A username and password;</li>
        <li>Name, date of birth, age and sex;</li>
        <li>Billing address, email address and phone number(s);</li>
        <li>Your Images and other User Content;</li>
        <li>Correspondence with and from SwapApp;</li>
        <li>Your preferences about receiving communications from SwapApp;</li>
        <li>Information about your use of SwapApp, and your browsing and online purchasing activities; and details we
          may
          ask you to submit to verify your identity.</li>
      </ul>
      <p class="privacypara">
        We may also collect some of this personal data from third parties who have your consent to pass your details
        to
        us.
      </p>
      <p class="privacypara">
        In order to take advantage of some of our Services, you may need to supply us with the personal details of a
        third party (for example, their name and address if you wish to send a Product to them as a gift). We will not
        use this information for anything other than providing the Services for which the information was supplied.
      </p>

      <p class="privacypara">
        We may disclose your first name and last name, to selected third parties, including but not limited to
        Pro-Gallery owners from whom you have purchased items. The purpose of this is so that a record may be kept by
        the third parties of who has purchased Products from them. It is a condition of your use of our Services that
        you agree to this disclosure of this data.</p>
    </div>


    <div class="privacycontentmain">
      <h1 class="privacyheading">How Do We Use Your Personal Information?</h1>
      <p class="privacypara">Your personal data will be collected, processed, stored and used by us, and passed to and
        processed by our
        subsidiary and/or affiliated companies and other data processors acting under contract with us.</p>

      <p class="privacypara"> When you sign up to SwapApp and agree to our terms and conditions, we need to process
        certain data that allows
        SwapApp: </p>

      <ul class="privacypoints">
        <li>To provide the SwapApp service to you in accordance with our agreement;</li>
        <li>To create personalised Products selected by you;</li>
        <li>To associate your Account, and your Product purchases, with you, and to verify your identity;</li>
        <li>If you elect to store an encrypted Payment Method with us, to associate that Payment Method with you when
          you
          place an order;</li>
        <li>To tailor aspects of SwapApp (including its search function and offers) to you;</li>
        <li>To process payments you make for Products;</li>
        <li>To pass your Product order for delivery by third party deliverers engaged by us;</li>
        <li>To provide customer support and improve your customer experience;</li>
        <li>To contact you by email and mobile communication (such as text (SMS) and 'push' notification) in relation
          to
          the products or services you have purchased.</li>
      </ul>

      <p class="privacypara">
        If you’ve told us it’s OK (or unless you’ve asked us not to after a purchase), we would like to contact you to
        provide information about products or services we think might interest you. You may withdraw this consent at
        any time.</p>

      <p class="privacypara">
        For legitimate business reasons we will also process your data for customer satisfaction and customer
        experience improvement purposes, such as by analysing data we hold about you, and combining it with data held
        by third parties, in order to discern your interests, demography and other factors, and in consequence to
        offer goods and services that are likely to have the greatest value to you. We would also use this data in
        connection with the prevention and detection of fraud and other crime. </p>

      <p class="privacypara">
        If you elect to store a Payment Method with us, that Payment Method will not in fact be stored by SwapApp
        itself, but instead it will be stored by a payment service provider under contract with us. The storing
        payment service provider will be required to comply with the Payment Card Industry Data Security Standard (PCI
        DSS). Note that, when using a stored Payment Method to make payment for your SwapApp order, certain techniques
        will be used to assist in maintaining the security of the details of your stored Payment Method. For example,
        not all of the payment card number will be visible to you (typically, all except the final four digits will be
        masked), and you will be required to provide certain information (such as, but not limited to, a CV2 card
        security number) in order to proceed with using that Payment Method. To remove or change a stored Payment
        Method, please follow the procedures specified on the Site or App, or contact our customer services team. </p>

      <p class="privacypara">
        If you are asked to provide a phone number when ordering a Product, note that the phone number will be
        provided to the deliverer of your order and may appear on your order's packaging to assist with the delivery
        process. </p>

      <p class="privacypara">
        We automatically collect anonymous information about your use of SwapApp. For example, we may automatically
        log which parts of SwapApp you access, which web browser you deploy and the website from which you linked to
        SwapApp. You cannot be identified from any of this information. It enables us to compile statistics about the
        use of SwapApp, and to help target aspects of SwapApp and advertising to you more accurately.</p>
    </div>


    <div class="privacycontentmain">
      <h1 class="privacyheading">Disclosing Your Personal Data</h1>

      <p class="privacypara">
        We will not disclose any of your personal data, other than to subsidiary and/or affiliated companies and data
        processors (including deliverers and payment service providers) under contract with us. To enable us to
        provide
        our service, we share data with the following categories of organisations:
      </p>

      <ul class="privacypoints">
        <li>Information technology (hardware and software</li>
        <li>Manufacturers, wholesalers and retailers;</li>
        <li>Delivery and courier services;</li>
        <li>Fraud prevention companies;</li>
      </ul>

      <p class="privacypara">
        We will not share your data with any other categories of organisation without your permission unless: </p>

      <ul class="privacypoints">
        <li>We are legally entitled to do so (for example, pursuant to a court order or for the purposes of prevention
          or
          detection of crime or fraud);</li>
        <li>We are in negotiations with a third party for the sale or purchase of any of SwapApp's business or assets,
          in
          which case we may disclose your personal data to the prospective seller or buyer of such business or assets;
        </li>
        <li>SwapApp, or substantially all of its assets, is acquired by a third party, in which case personal data
          held by
          SwapApp about its customers will be one of the transferred assets; or</li>
        <li>We wish to enforce or apply our Terms of Use and other agreements to which you are party, or to protect
          our
          rights, property, safety, customers, or others.</li>
      </ul>

    </div>


    <div class="privacycontentmain">
      <h1 class="privacyheading">Marketing Preferences</h1>

      <p class="privacypara">
        If you’ve told us it’s OK (or unless you’ve asked us not to after a purchase), we will send you information
        about other products and services. These emails and mobile communications (such as text (SMS) and 'push'
        notification) provide you with information about offers, new Products and other things that we think may
        interest you or SwapApp customers generally. You can change your preferences on the “My Account” section of
        the website or using the link provided on an email and you can opt out of receiving text (SMS) messaging
        alerts at any time by texting STOP to the number which appears in the relevant text (SMS) messaging alert, and
        prevent receipt of 'push' notification messaging alerts by turning off the push function on your mobile
        device. </p>

      <p class="privacypara">
        You can also choose to receive reminders of important dates coming up so you can start planning your next gift
        for a friend or relative. You can control these on the Your Reminders page or in the Preference Centre. </p>

      <p class="privacypara">
        We use advertising and marketing services companies to show you products that might interest you while you’re
        browsing the internet. This is based on either your marketing preferences or your acceptance of cookies on our
        websites. </p>

      <p class="privacypara">
        We will send you service messages relating directly to your orders and purchases from SwapApp (such as order
        and dispatch confirmation emails), and emails which notify you of changes to our Terms of Use, or to policies
        we apply under our Terms of Use. We will also send market research emails to ensure you’re happy with our
        service. </p>

      <p class="privacypara">
        We may also send you emails to remind you of a credit that’s about to expire, photos you haven’t used, items
        you’ve left in your basket or following a purchase. You can opt out of these emails using the link provided.
      </p>

      <p class="privacypara">
        We may also send you communications by post which provide you with information about offers, new Products and
        other things that we think may interest you or SwapApp customers generally. You can notify us of your
        preferences concerning our postal communications here.
      </p>
    </div>


    <div class="privacycontentmain">
      <h1 class="privacyheading">International Transfers</h1>
      <p class="privacypara">
        We may transfer personal data that we collect from you to third party data processors located in countries
        that are outside of the European Economic Area (EEA) and to other SwapApp group companies in connection with
        the above purposes. Please be aware that countries outside the EEA may not offer the same level of data
        protection as the United Kingdom; however, we always require that third party data processors deal with your
        personal data in accordance with this privacy notice. Any transfers are done in accordance with the standards
        laid down by the EU Commission and/or decisions of the European Court of Justice.
      </p>
    </div>


    <div class="privacycontentmain">
      <h1 class="privacyheading">Data Retention</h1>
      <p class="privacypara">
        If you have not logged into your account for 3 years, your personal details and any photos will be deleted. We
        retain other minimal data for tax, anti-fraud and other purposes in accordance with our data retention policy.
      </p>
    </div>


    <div class="privacycontentmain">
      <h1 class="privacyheading">Your Rights</h1>
      <p class="privacypara">
        Under the EU General Data Protection Regulation 2016/679 (GDPR) you have a number of rights you can exercise
        over your data. Your rights are:</p>

      <ul class="privacypoints">
        <li>The right to receive a copy of your data free of charge (known as a Subject Access Request);</li>
        <li>The right to receive certain data in an electronic format that can be given to another provider (a
          portability request);</li>
        <li>The right to have data corrected if it is inaccurate;</li>
        <li>The right to ask us to stop processing or object to processing under certain circumstances;</li>
        <li>The right to ask us to erase data in certain circumstances;</li>
        <li>The right to withdraw consent at any time when that is the legal basis for our processing.</li>
      </ul>

      <p class="privacypara">
        To discuss any of these rights, please contact Customer Services.
      </p>
    </div>


    <div class="privacycontentmain">
      <h1 class="privacyheading">Security</h1>
      <p class="privacypara">
        We employ significant technical and organisational measures to guard against unauthorised or unlawful
        processing
        of your personal data and against accidental loss or destruction of, or damage to, your personal data. We
        believe the measures implemented by our site reduce the likelihood of security problems to a level appropriate
        to the type of data involved.</p>

      <p class="privacypara">
        We have security measures in place to protect our database of Users and access to this database is restricted
        internally. However, there are things we can all do to help stay safe and secure online:
      </p>

      <ul class="privacypoints">
        <li>Ensure no-one else uses SwapApp through your Account;</li>
        <li>Log off or exit from your SwapApp Account when not using it;</li>
        <li>Keep your password or other access information secret;</li>
        <li>Use different passwords for different services you use online;</li>
        <li>Ensure you’re using the most up-to-date versions of your software.</li>
      </ul>
    </div>


    <div class="privacycontentmain">
      <h1 class="privacyheading">The SwapApp App</h1>
      <p class="privacypara">
        If you’ve told us it’s OK, the SwapApp app will use various technologies to:</p>

      <ul class="privacypoints">
        <li>Assess the performance of the app by telling us the products users are buying via the app and other similar
          information.</li>
        <li>Measure the effectiveness of our marketing campaigns by tracking which campaign resulted in a user
          downloading
          the app for instance.</li>
        <li>Provide analytics which helps us understand how people are using the app and monitor any errors that users
          may
          be experiencing. We use this information to identify how and where we can improve the user experience.</li>
      </ul>
    </div>



  </div>
</div>
</div>