import { Component, OnInit } from '@angular/core';
import {CookieService} from "../../core/services/cookie.service";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-aboutus-page',
  templateUrl: './aboutus-page.component.html',
  styleUrls: ['./aboutus-page.component.css']

})
export class AboutusPageComponent implements OnInit {
  chosenLanguage = 'en';
  constructor( private cookieService: CookieService,
               translate: TranslateService) {
    this.chosenLanguage = this.cookieService.LanguageCookie;
  }

  ngOnInit(): void {
  }

}
