<div class="about-app-section my-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-7">
        <small>About</small>
        <h2>Swapapp</h2>
        <p>
          {{'aboutus.companyDescription1' | translate }}
                      
        </p>
        <p>
          {{'aboutus.companyDescription1.1' | translate }}
        </p>
      </div>
      <div class="col-md-5 m-md-0 mt-3 text-center text-md-left">
        <!-- <img src="assets/images/about/about-use-vedio.png" alt="" class="img-fluid"> -->
        <div class="swapappthumbnailmain">
          <video class="aboutusswapappvideo  swapappthumbindex"  [muted]="'muted'" loop controls autoplay>
            <source src="assets/logo_video_de.mp4" type="video/mp4" *ngIf="chosenLanguage === 'de' ">
            <source src="assets/logo_video_en.mp4" type="video/mp4" *ngIf="chosenLanguage === 'en' ">
          </video>
          <!-- <video class="swapappthumbindex" poster="../../../assets/images/about/swapappthumbnail.svg" src=""></video> -->
          <!-- <img class="playbuttonthumbnail" src="../../../assets/images/about/playbutton.svg" alt=""> -->
          <img class="swapappthumbcircle" src="../../../assets/images/about/swapappthumbcircle.svg" alt="">
        </div>
        
      </div>
    </div>
  </div>
</div>