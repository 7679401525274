<!-- Sign up form -->
<div class="modal-body" >
  <div class="form-excerpts" style="margin-top: -20px">
    <table class="m-auto w-100 table-borderless">
      <tr>
        <td>{{'userModal.buttons.signUpText' | translate }}
          <a style="font-weight: bolder;" (click)="onSwitchComponent('sign_up')" href="javascript:void(0)">{{'buttons.signUp' | translate }}</a>
        </td>
      </tr>
      <tr>
        <td colspan="2" style="height:20px">
        </td>
      </tr>
    </table>
  </div>
  <br>
  <form #authForm [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <!-- Email field
    <label for="login-email-field">{{'userModal.loginPage.inputFields.email.label' | translate }}</label> -->
    <div class="input-group mb-4 custom-input-group-height" >
      <input id='login-email-field'
             type="text"
             formControlName="email"
             class="form-control custom-input-group-height"
             [ngClass]="{ 'is-invalid': submitted && form.email.errors }"
             placeholder="{{'userModal.loginPage.inputFields.email.placeholder' | translate }}"/>
      <div class="input-group-append">
         <span class="input-group-text custom-span-input-group" >
           <i class="fas fa-user-circle"></i>
         </span>
      </div>
      <div *ngIf="submitted && form.email.errors" class="invalid-feedback">
        <div *ngIf="form.email.errors.required">{{'userModal.loginPage.inputFields.email.errors.required' | translate }}</div>
        <div *ngIf="form.email.errors.email">{{'userModal.loginPage.inputFields.email.errors.email' | translate }}</div>
      </div>
    </div>
    <!-- Password field
    <label for="login-password-field">{{'userModal.loginPage.inputFields.password.label' | translate }}</label>-->
    <div class="input-group mb-4 custom-input-group-height" >
      <input
        id='login-password-field' style="height: 50px;"
        [type]="fieldTextType ? 'text' : 'password'"
        formControlName="password"
        class="form-control custom-input-group-height"
        [ngClass]="{ 'is-invalid': submitted && form.password.errors }"
        placeholder="{{'userModal.loginPage.inputFields.password.placeholder' | translate }}"/>
      <div class="input-group-append">
         <span class="input-group-text text-center custom-span-input-group" >
           <i class="fa"
             [ngClass]="{
                           'fa-eye-slash': !fieldTextType,
                           'fa-eye': fieldTextType
                         }"
             (click)="toggleFieldTextType()"></i>
         </span>
      </div>
      <div *ngIf="submitted && form.password.errors" class="invalid-feedback">
        <div *ngIf="form.password.errors.required">{{'userModal.loginPage.inputFields.password.errors.required' | translate }}</div>
      </div>

    </div>
    <!-- Acceptance buttons
    <div class="row">
      <div class="col-10">
        <label class="labels" for="policyLogin">{{'buttons.policyButtons.text' | translate }}
          <a routerLink="/privacy-policy" (click)="closeModal()">{{'buttons.policyButtons.privacyPolicy' | translate }}</a></label>
      </div>
      <div class="col-2 text-center">
        <mat-checkbox
          id='policyLogin'
          formControlName="policyLogin"
          name="policyLogin"></mat-checkbox>
      </div>
      <div *ngIf="submitted && form.policyLogin.errors" class="invalid-feedback">
        <div *ngIf="form.policyLogin.errors.required">{{'errors.required' | translate }}</div>
      </div>
      <mat-error class="ml-3 small m-0" *ngIf="submitted && form.policyLogin.errors">{{'errors.booleanRequired' | translate }}</mat-error>
    </div>
    <div class="row mb-2">
      <div class=" col-10 ">
        <label class="labels" for="termsLogin">{{'buttons.policyButtons.text' | translate }}
          <a routerLink="/terms-of-use" (click)="closeModal()">{{'buttons.policyButtons.termsService' | translate }}</a></label>
      </div>
      <div class="col-2 text-center">
        <mat-checkbox
          id='termsLogin'
          formControlName="termsLogin"
          name="termsLogin"
        ></mat-checkbox>
      </div>
      <mat-error class="ml-3 small m-0" *ngIf="submitted && form.termsLogin.errors">{{'errors.booleanRequired' | translate }}</mat-error>
    </div>
    -->
    <div class="form-group">
      <button [disabled]="loading" class="btn-second blue-btn full-width">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{'buttons.logIn' | translate }}
      </button>
      <!--<p> {{'userModal.buttons.loginText' | translate }}
        <em>{{'buttons.logIn' | translate }}</em> {{'userModal.buttons.acceptanceTextLogin' | translate }}
        <a routerLink="/privacy-policy" (click)="closeModal()"> {{'buttons.policyButtons.privacyPolicy' | translate }}</a>
        {{'userModal.buttons.andOur' | translate }}
        <a routerLink="/terms-of-use" (click)="closeModal()"> {{'buttons.policyButtons.termsService' | translate }}</a>
      </p>-->
    </div>

  </form>
  <div class="form-excerpts">
    <table class="m-auto w-100 table-borderless">
      <tr>
        <td>{{'userModal.buttons.forgotPasswordText' | translate }}</td>
        <td class="float-right"><a (click)="onSwitchComponent('recover_password')" href="javascript:void(0)">{{'buttons.recoverPassword' | translate }}</a></td>
      </tr>
      <!--<tr>
        <td>{{'userModal.buttons.signUpText' | translate }}</td>
        <td class="float-right"><a (click)="onSwitchComponent('sign_up')" href="javascript:void(0)" >{{'buttons.signUp' | translate }}</a></td>
      </tr>-->
    </table>
  </div>

</div>





