import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EntityAdditionService } from '../service/entity-addition.service';
import { environment } from '../../../../environments/environment';
import { AlertService } from '../../../core/alert/alert.service';
import { AuthenticationService } from '../../../module-user/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-gallery',
  templateUrl: './add-gallery.component.html',

})
export class AddGalleryComponent implements OnInit {
  @Output() currentComponent: EventEmitter<any> = new EventEmitter<any>();

  images = [];
  formSubmitted = false;
  galleryForm = new FormGroup({
    file: new FormControl('', []),
    fileSource: new FormControl('', []),
  });

  numberOfPicturesExceededErr = '';
  errorMessage = '';

  constructor(private entityAdditionService: EntityAdditionService,
              private alertService: AlertService,
              private authenticationService: AuthenticationService,
              translate: TranslateService) {
    translate.get('errors.numberOfPicturesExceeded').subscribe((res: string) => {
      this.numberOfPicturesExceededErr = res;
    });
    translate.get('entityComponent.addEntity.errorMessage').subscribe((res: string) => {
      this.errorMessage = res;
    });
  }

  get galleryFormControls(){
    return this.galleryForm.controls;
  }

  ngOnInit(): void {
    if (this.entityAdditionService.currentEntity.images !== undefined) {
      // In case of entity update we set the default values to the entity values
      this.entityAdditionService.currentEntity.images.forEach( imageObj => this.images.push(imageObj.original));
      console.log(this.images)
    }
  }

  checkNoPictures(noOfPictures): boolean {
    const currentSubscription = this.authenticationService.currentUserSubscription;
    let valid = true;
    currentSubscription.plan.options.forEach(option => {
      // console.log(option.option_name);
      // console.log(option.option_value);
      // console.log(event.target.files.length.toString());
      if (option.option_code === 'picture_per_item' && parseInt(option.option_value, 10) < noOfPictures){
        this.alertService.error(`${this.numberOfPicturesExceededErr} ${option.option_value}`, true);
        this.images = [];
        valid = false;
      }
    });
    return valid;
  }
  onFileChange(event) {
    /* This function is responsible for setting the image inputted on the form data and reading it in order to
     * show it in the template.
     * 1. It validates the format of the file
     * 2. It sets the image on the form data
     * 3. It reads the image and stores it in a variable used in interpolation for showing it in the browser
     * */



    this.images = [];
    if (event.target.files && event.target.files[0]) {
      const filesAmount = event.target.files.length;


      if (this.checkNoPictures(filesAmount) === true){
        // console.log('go on');
        // 1.
        for (let i = 0; i < filesAmount; i++) {
          const imageFormat = event.target.files[i].name.split('.')[event.target.files[i].name.split('.').length - 1].toUpperCase();
          // 1.
          if (!(environment.acceptedImagesFormat.indexOf(imageFormat) > -1)){
            this.alertService.error(`${this.errorMessage} ${environment.acceptedImagesFormat}`);
            return;
          }
        }
        for (let i = 0; i < filesAmount; i++) {
          // 2.
          this.entityAdditionService.addImageToFormData(`picture_${i + 1}`,  event.target.files[i]);

          // 3.
          const reader = new FileReader();
          // tslint:disable-next-line:no-shadowed-variable
          reader.onload = (event: any) => {
            this.images.push({name: `picture_${i + 1}`, url: event.target.result});

            this.galleryForm.patchValue({
              fileSource: this.images
            });
          };
          reader.readAsDataURL(event.target.files[i]);
        }

      }
    }
  }

  removeImage(index: number, name: string){
    this.images.splice(index, 1);
    this.entityAdditionService.removeImageFromFormData(name);
  }


  onSubmit(){

    this.formSubmitted = true;
    this.entityAdditionService.currentEntity.images = null;

    // console.log('Current entity ');
    // console.log(this.entityAdditionService.entity.value);

    this.images.forEach((image, index) => this.entityAdditionService.image = `picture_${index + 1}`);
    // change the screen
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.currentComponent.emit('showAddLocation');
  }
  

}
