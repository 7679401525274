/* tslint:disable */
export class Notification {
  id: number;
  subject: string;
  body_text: string;
  related_model: string;
  referenced_object: any
  date_read: Date;
  date_sent: Date;
  constructor(message) {
    this.date_sent = message.date_sent;
    this.related_model = message.related_model;
    this.body_text = message.body_text;
    this.subject = message.subject;
    this.id = message.id;
    this.referenced_object = JSON.parse(message.referenced_object);
    this.date_read = message.date_read;
  }

}

