import { Component, OnInit } from '@angular/core';
import {CookieService} from "../../../core/services/cookie.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-about-app-section',
  templateUrl: './about-app-section.component.html',
  styleUrls: ['./about-app-section.component.css']
})
export class AboutAppSectionComponent implements OnInit {
    chosenLanguage = 'en';
  constructor( private cookieService: CookieService,
               translate: TranslateService) {
    this.chosenLanguage = this.cookieService.LanguageCookie;
  }


  ngOnInit(): void {
  }

}
