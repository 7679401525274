import { FormFieldBase } from './base/form-field-base';

export class DateInput extends FormFieldBase<number> {
  type = 'date';
  controlType = 'date';

}



