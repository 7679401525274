import { Component, OnInit } from '@angular/core';
import {CookieService} from "../../core/services/cookie.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-download-page',
  templateUrl: './download-page.component.html',
  styleUrls: ['./download-page.component.css']
})
export class DownloadPageComponent implements OnInit {
  chosenLanguage = 'en';
  constructor( private cookieService: CookieService,
              translate: TranslateService) {
    this.chosenLanguage = this.cookieService.LanguageCookie;
  }

  ngOnInit(): void {
  }

}
