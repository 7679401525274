/* tslint:disable */
export class PasswordReset {
  uid: string;
  token: string;
  new_password1: string;
  new_password2: string;
  constructor(uidb64: string, token: string, newPassword1: string, newPassword2: string) {
    this.uid = uidb64;
    this.token = token;
    this.new_password1 = newPassword1;
    this.new_password2 = newPassword2;
  }
}
