<div class="mobile-menu-second">
  <form [formGroup]="filteringForm" (ngSubmit)="onFilterSubmit()">
    <div class="car-search-form-sec mobile-space">
      <div class="search-page-header-background">
        <div class="">
          <div class="search-page-header-size">
            <!-- Header -->

            <div class="search-page-header">
              <div class="search-page-container">
                <h2>Search</h2>
                <p>
                  {{'header.dealsAroundMeFeature.CoverDescription' | translate }}
                </p>

                <!-- Form -->
                <form [formGroup]="filteringForm" (ngSubmit)="onFilterSubmit()">
                  <!-- Search bar -->
                  <div class="large-search-bar">
                    <!-- Input Group -->
                    <div class="d-flex w-100 align-items-center">
                      <img src="assets/images/icon/Search.png" alt="" />
                      <input
                        type="text"
                        formControlName="mainSearch"
                        placeholder="{{
                          'entityComponent.browse.sideBar.search.inputFields.search.placeholder'
                            | translate
                        }}"
                      />
                    </div>
                    <!-- Buttons -->
                    <div class="d-flex butons-inline align-items-center">
                      <a (click)="resetForms()" class="btn btn-link">
                        <i class="flaticon-reload"></i> Clear</a
                      >

                      <!-- <button class="btn-first blue-btn">Search</button> -->
                    </div>
                  </div>

                  <!-- Categories Buttons -->
                </form>
              </div>
              <div class="tab-content mb-20">
                <div class="tab-pane active">
                  <div class="row">
                    <div class="col-xl col-lg-4 col-sm-6">
                      <mat-form-field style="width: 100%">
                        <mat-label>{{ selectCategoryText }}</mat-label>
                        <mat-select formControlName="category">
                          <mat-option
                            *ngFor="let ctr of categories"
                            [value]="ctr.id"
                          >
                            {{ ctr.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div
                      class="col-xl col-lg-3 col-sm-6"
                      *ngIf="
                        filteringFormControls.category.value !==
                          selectCategoryText &&
                        filteringFormControls.category.value !== ''
                      "
                    >
                      <mat-form-field style="width: 100%">
                        <mat-label>{{ selectMainCategoryText }}</mat-label>
                        <mat-select
                          formControlName="entityClass"
                          panelClass="example-panel-blue"
                        >
                          <mat-option
                            *ngFor="let entityClass of selectableEntityClasses"
                            [value]="entityClass.id"
                          >
                            {{ entityClass.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-xl col-lg-3 col-sm-6">
                      <mat-form-field style="width: 100%">
                        <mat-label> {{ selectCountryText }}</mat-label>
                        <mat-select
                          formControlName="country"
                          panelClass="example-panel-blue"
                        >
                          <mat-option
                            *ngFor="let country of countries"
                            [value]="country.iso_3166_1_a2"
                          >
                            {{ country.printable_name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="col-xl col-lg-3 col-sm-6">
                      <mat-form-field
                        style="width: 100%"
                        *ngIf="
                          filteringFormControls.country.value !==
                            selectCountryText &&
                          filteringFormControls.country.value !== ''
                        "
                      >
                        <mat-label> {{ selectCityText }} </mat-label>
                        <mat-select
                          formControlName="city"
                          panelClass="example-panel-blue"
                        >
                          <mat-option
                            *ngFor="let city of cities"
                            [value]="city.id"
                          >
                            {{ city.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-xl col-lg-3 col-sm-6">
                      <ng-container
                        *ngIf="
                          filteringFormControls.city.value !== selectCityText &&
                          filteringFormControls.city.value !== ''
                        "
                      >
                        <mat-label>{{
                          "entityComponent.browse.inputFields.radius.label"
                            | translate
                        }}</mat-label>
                        <mat-slider
                          style="width: 100%"
                          thumbLabel
                          formControlName="radius"
                          color="primary"
                          min="1"
                          max="1000"
                        ></mat-slider>
                      </ng-container>
                    </div>
                    <div class="col-sm-3">
                      <button
                        (click)="resetForms()"
                        class="btn-second bg-blue full-width"
                      >
                        <i class="flaticon-reload"></i>
                        {{
                          "entityComponent.browse.buttons.clearCriteria"
                            | translate
                        }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- listing -->
    <section class="section-padding listing-with-map listing-with-map-3">
      <div class="container">
        <div class="search-page-h1">
        <h1  >Search Results</h1>
      </div>
        <!-- Sub header -->
        
        <div class="" *ngIf="queryResultsNumber">
        
          <p *ngIf="queryResultsNumber == 1">
            {{ "entityComponent.browse.messages.oneResult" | translate }}
          </p>
          <p *ngIf="queryResultsNumber != 1">
            {{ queryResultsNumber }}
            {{ "entityComponent.browse.messages.multipleResults" | translate }}
          </p>
        </div>
        <div class="clearfix"></div>
        <!-- Sub header -->
        <div class="row">
          <div class="col-12"></div>
        </div>
        <div class="tab-content">
          <div id="listing_list" class="tab-pane active">
            <div class="row">
              <div class="col-lg-9">
                <div class="car-model-layout-list">
                  <div class="row">
                    <div
                      class="col-md-3 item-thumb-border"
                      *ngFor="let entity of entities"
                    >
                      <div class="item-thumb">
                        <a [routerLink]="['/item/detail', entity.hash_1]">
                          <img
                            *ngIf="entity?.cover_image !== null"
                            src="{{ entity?.cover_image }}"
                            alt="image"
                          />
                          <img
                            *ngIf="entity?.cover_image === null"
                            src="https://staging-api.swapapp.de/media/{{ entity.entity_class.default_cover_image }}"
                            alt="image"
                          />
                        </a>
                        <ng-container *ngIf="userProfile !== null">
                          <ng-container
                            *ngIf="
                              entity.creator.pk !== userProfile.profile.user
                            "
                          >
                            <div
                              class="car-description-bottom"
                              *ngIf="!entity.correspondence"
                            >
                              <a
                                (click)="toggleCorrespondence(entity.hash_1)"
                                href="javascript:void(0)"
                                class="action-button like-button"
                              >
                                <i class="fa fa-heart"></i>
                                {{
                                  "entityComponent.browse.buttons.match"
                                    | translate
                                }}
                              </a>
                            </div>

                            <div
                              class="car-description-bottom"
                              *ngIf="entity.correspondence"
                            >
                              <a
                                (click)="
                                  toggleCorrespondence(entity.hash_1, false)
                                "
                                href="javascript:void(0)"
                                class="action-button like-button"
                              >
                                <i class="fa fa-heart"></i>
                                {{
                                  "entityComponent.browse.buttons.unMatch"
                                    | translate
                                }}
                              </a>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                      <div class="item-content">
                        <h2>{{ entity.title }}</h2>
                        <p>
                          <i class="icon-location"></i> {{ entity.city.name }},
                          {{ entity.city.country.iso_3166_1_a2 }}
                        </p>
                      </div>
                      <div class="item-meta">
                        <div class="item-content">
                          <p>{{ entity.description }}</p>
                        </div>
                      </div>
                      <div class="item-price">
                        <i class="icon-moneys mr-2"></i> EUR 12,900,000
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <aside class="col-lg-3 order-first">
                <!-- Search bar side -->
                <div class="side-search-bar">
                  <div class="title-icon">
                    <h3 class="title">
                      <i class=""></i>
                      {{
                        "entityComponent.browse.sideBar.search.title"
                          | translate
                      }}
                    </h3>
                  </div>
                  <div class="widget-search-filter">
                    <div class="widget-content">
                      <mat-form-field style="width: 100%">
                        <mat-label>
                          {{
                            "entityComponent.browse.sideBar.search.inputFields.sort.label"
                              | translate
                          }}
                        </mat-label>
                        <mat-select
                          formControlName="sortBy"
                          panelClass="example-panel-blue"
                        >
                          <mat-option
                            *ngFor="let order of ordering"
                            [value]="order.name"
                          >
                            {{
                              "entityComponent.browse.sideBar.search.inputFields.sort." +
                                order.abbr | translate
                            }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <!-- <div class="form-group">
                        <input
                          formControlName="mainSearch"
                          type="text"
                          class="form-control form-control-custom"
                          placeholder="{{
                            'entityComponent.browse.sideBar.search.inputFields.search.placeholder'
                              | translate
                          }}"
                        />
                      </div> -->

                      <ng-container *ngIf="attributeForm !== null">
                        <!-- NORMAL INPUTS -->

                        <div
                          *ngFor="let attr of entityFormBase; let i = index"
                          class="ml-auto col-lg-12"
                        >
                          <div *ngIf="attr.key.includes('min')">
                            <h4 class="text-center font-weight-bolder">
                              {{ attr.label }}
                            </h4>
                          </div>

                          <ng-container *ngIf="!attr.key.includes('-')">
                            <div
                              class=""
                              *ngIf="
                                attr.key.includes('min') ||
                                attr.key.includes('max')
                              "
                            >
                              <app-dynamic-search-field
                                [formField]="attr"
                                [form]="attributeForm"
                              ></app-dynamic-search-field>
                            </div>

                            <div
                              class="w-100"
                              *ngIf="
                                !attr.key.includes('min') &&
                                !attr.key.includes('max')
                              "
                            >
                              <app-dynamic-search-field
                                [formField]="attr"
                                [form]="attributeForm"
                              ></app-dynamic-search-field>
                            </div>
                          </ng-container>
                        </div>
                        <!-- FORM GROUPS -->
                        <div
                          *ngFor="
                            let fieldParent of groupOfForm;
                            let idx = index
                          "
                        >
                          <div class="w-100">
                            <h4 class="font-weight-bolder">
                              {{ fieldParent.name }}
                            </h4>
                          </div>

                          <div
                            *ngFor="let attr of entityFormBase; let i = index"
                            class=""
                          >
                            <div
                              class="col-lg-4 float-left"
                              *ngIf="attr.key.includes(fieldParent.id + '-')"
                              style="height: 110px"
                            >
                              <ng-container *ngIf="attr.type !== 'radio'">
                                <app-dynamic-form
                                  [formField]="attr"
                                  [form]="attributeForm"
                                ></app-dynamic-form>
                              </ng-container>
                            </div>
                          </div>

                          <div class="clearfix"></div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <!-- Recently viewed items -->
                <div
                  class="side-search-bar h-auto mb-4"
                  *ngIf="recentlyViewed.length > 0"
                >
                  <div class="title-icon">
                    <h5 class="title">
                      <i class="flaticon-eye"></i>
                      {{
                        "entityComponent.browse.sideBar.recentlyViewed.title"
                          | translate
                      }}
                    </h5>
                  </div>
                  <div class="widget-search-filter">
                    <div class="widget-content">
                      <div
                        class="recent-item"
                        *ngFor="let item of recentlyViewed"
                      >
                        <div class="recent-thumbnail-area">
                          <a
                            [routerLink]="['/item/detail', item.hash_1]"
                            class="view"
                          >
                            <div
                              class="item-thumb"
                              *ngIf="item.cover_image !== null"
                            >
                              <img
                                src="{{ item.cover_image }}"
                                class="card-img-top"
                                alt="Thumbnail"
                                style="height: 80px"
                              />
                            </div>
                            <div
                              class="item-thumb"
                              *ngIf="item.cover_image === null"
                            >
                              <img
                                src="{{
                                  item.entity_class.default_cover_image
                                }}"
                                class="card-img-top"
                                alt="Thumbnail"
                                style="height: 80px"
                              />
                            </div>
                          </a>
                          <!--./ item-thumb -->
                        </div>
                        <div class="content-entry-wrap">
                          <div class="recent-content">
                            <div class="recent-meta">
                              <div class="recent-location">
                                <a
                                  href="javascript:void(0)"
                                  class="small text-light-black"
                                  >{{ item.categories[0].name }}</a
                                >
                                <p>{{ item.title }}</p>
                                <p class="small">
                                  <i class="fas fa-map-marker-alt"></i> &nbsp;
                                  &nbsp;{{ item.city.name }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
          <div id="listing_grid" class="tab-pane fade">
            <div class="row">
              <div class="col-lg-12">
                <div class="right-search-filter-box">
                  <div class="car-model-sec">
                    <div class="row">
                      <div
                        class="col-xl-4 col-lg-4 col-md-6 col-sm-6"
                        *ngFor="let entity of entities"
                      >
                        <div class="car-model text-center">
                          <div class="car-sec car-bottom-space">
                            <div class="car-title">
                              <h4 class="text-light-black">
                                {{ entity.title }}
                              </h4>
                            </div>

                            <img
                              *ngIf="entity.cover_image !== null"
                              src="{{ entity.cover_image }}"
                              class="img-fluid full-width"
                              alt="image"
                              style="height: 270px; width: 400px"
                            />
                            <img
                              *ngIf="entity.cover_image === null"
                              src="{{
                                entity.entity_class.default_cover_image
                              }}"
                              class="img-fluid full-width"
                              alt="image"
                              style="height: 270px; width: 400px"
                            />

                            <div class="car-details">
                              <p *ngIf="chosenLanguage == 'de'">
                                category: {{ entity.entity_class.name.de }}
                              </p>
                              <p *ngIf="chosenLanguage == 'en'">
                                category: {{ entity.entity_class.name.en }}
                              </p>
                              <div class="car-location">
                                <i class="fas fa-map-marker-alt"></i>
                                {{ entity.city.name }},
                                {{ entity.city.country.iso_3166_1_a2 }}
                              </div>
                              <p class="text-dark-grey">
                                {{ entity.description }}
                              </p>
                              <a
                                *ngIf="userProfile"
                                [routerLink]="['item/detail', entity.hash_1]"
                                class="view"
                              >
                                {{
                                  "entityComponent.browse.buttons.viewItem"
                                    | translate
                                }}
                              </a>
                            </div>
                            <ng-container *ngIf="userProfile !== null">
                              <ng-container
                                *ngIf="
                                  entity.creator.pk !== userProfile.profile.user
                                "
                              >
                                <div
                                  class="car-description-bottom"
                                  *ngIf="!entity.correspondence"
                                >
                                  <a
                                    (click)="
                                      toggleCorrespondence(entity.hash_1)
                                    "
                                    href="javascript:void(0)"
                                    class="text-custom-white"
                                  >
                                    <i class="fa fa-heart"></i>
                                    {{
                                      "entityComponent.browse.buttons.match"
                                        | translate
                                    }}
                                  </a>
                                </div>

                                <div
                                  class="car-description-bottom"
                                  *ngIf="entity.correspondence"
                                >
                                  <a
                                    (click)="
                                      toggleCorrespondence(entity.hash_1, false)
                                    "
                                    href="javascript:void(0)"
                                    class="text-custom-white"
                                  >
                                    <i class="fa fa-heart"></i>
                                    {{
                                      "entityComponent.browse.buttons.unMatch"
                                        | translate
                                    }}
                                  </a>
                                </div>
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12" *ngIf="totalPages">
            <nav class="custom-pagination" *ngIf="totalPages.length > 1">
              <ul class="pagination">
                <li class="page-item" *ngIf="previousLink">
                  <a
                    class="page-link"
                    href="javascript:void(0)"
                    (click)="goToPage(currentPage - 1)"
                    >{{ "pagination.previous" | translate }}</a
                  >
                </li>
                <li
                  class="page-item"
                  *ngFor="let page of totalPages"
                  [ngClass]="{ active: currentPage == page }"
                >
                  <a
                    class="page-link"
                    href="javascript:void(0)"
                    (click)="goToPage(page)"
                    >{{ page }}</a
                  >
                </li>
                <li class="page-item" *ngIf="nextLink">
                  <a
                    class="page-link"
                    href="javascript:void(0)"
                    (click)="goToPage(currentPage + 1)"
                    >{{ "pagination.next" | translate }}</a
                  >
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </section>
    <!-- listing -->
  </form>
</div>

<!-- Logo Section -->
<!-- <section class="p-relative">
    <div class="main-slider home-2">
      <div class="slider-item">
        <img  *ngIf="chosenLanguage === 'de'"  src="assets/images/banner/home_banner_de.jpg" alt="Slider" class="rounded full-width">
        <img  *ngIf="chosenLanguage === 'en'"  src="assets/images/banner/home_banner_en.jpg" alt="Slider" class="rounded full-width">
      </div>
    </div>
  </section> -->
<!-- Header -->
