
import { CookieService } from '../../../../core/services/cookie.service';
import { Component, OnDestroy, Input, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { CorrespondenceService } from '../../../../module-communication/services/correspondence.service';
import { AlertService } from '../../../../core/alert/alert.service';
import { Profile } from '../../../../module-user/models/profile';
import { AuthenticationService } from '../../../../module-user/services/authentication.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


import { Entity } from '../../../../module-entity/models/entity';
import { SearchService } from 'src/app/module-search/services/search.service';

@Component({
  selector: 'listing-item',
  templateUrl: './listing-item.component.html',
  styleUrls: ['./listing-item.component.css']
})
export class ListingItemComponent implements OnInit {
  @Input() item;
  chosenLanguage = 'en';

  userProfile: Profile = null;


  entities: Array<Entity> = [];
  entityHashes = [];


  constructor(
    private cookieService: CookieService,
    private correspondenceService: CorrespondenceService,
    private alertService: AlertService,
    private router: Router,
    private authenticationService: AuthenticationService,
    translate: TranslateService,
    private searchService: SearchService,

  ) {
    this.chosenLanguage = this.cookieService.LanguageCookie;
  }

  ngOnInit(): void {

    this.authenticationService.currentUserSubject.subscribe(userProfile => {
      this.userProfile = userProfile;
      if (this.userProfile !== null) {
      }
    });
  }

checkMatches(entityHashes: Array<string>) {
  console.log('entities', this.entities);
  console.log('Hash entities', this.entityHashes);

    /* This function checks if the current entities are matched.*/
    this.correspondenceService.checkMatches(entityHashes).subscribe(
      response => {
        response.body.data.forEach(obj => {
          try {
            this.entities.filter(entity => entity.hash_1 === obj.entity_hash)[0].correspondence = obj.correspondence;

          } catch (err) {
            return;
          }
        });

      },
      errorResponse => this.alertService.error(errorResponse.error.error[0])
    );
  }

  getEntities(pageNumber?: number) {
    this.searchService.getUserEntities(pageNumber).subscribe(response =>
      this.constructTheListing(response), errorResponse => {
    });
  }

  constructTheListing(response) {
    this.entityHashes = [];
    this.entities = response.body.data.results;
    this.entities.forEach(entity => this.entityHashes.push(entity.hash_1));
    if (this.userProfile !== null) {
      this.checkMatches(this.entityHashes);
    }
  }

toggleCorrespondence(hash: string, create: boolean = true) {

 console.log('CreateList', create)
 this.searchService.matchUnmatchBoolean.next(create);
  this.searchService.mySubject.next(hash)

  }

  returnEntity(entArray, toFind) {
    let object = entArray?.attribute_values;
    const found = object.find((obj) => {
      return obj?.attribute?.code == toFind;
    });
    return found?.value_integer;
  }


}