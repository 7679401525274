// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  acceptedImagesFormat: ['PNG', 'JPG', 'JPEG', 'BITMAP'],
  configSettings: {
    apiURL:  'https://staging-api.swapapp.de/api/',
    stripePublicKey: 'pk_test_51M70nwKkB9vj5i3yd13dPUbJcXVRhpjihvioaFRB3aLfEPl1F4GyuL32gtKWSjT9upSh8bpxLnz56NGBfv7wB0Ux004USQfDZi',
    domainName: 'www.staging.swapapp.de/'
  },
  // Old Version
  // firebase: {
  //   apiKey: 'AIzaSyC9a7cQZbuHnPXPF3yiJQu-vAtGKI2owsA',
  //   authDomain: 'swapapp-mobile.firebaseapp.com',
  //   projectId: 'swapapp-mobile',
  //   storageBucket: 'swapapp-mobile.appspot.com',
  //   messagingSenderId: '1079054259887',
  //   appId: '1:1079054259887:web:6c75a301df9ef89003a65a',
  //   measurementId: 'G-16K83V41M6'
  // }
   // New Version
   firebase: {
    apiKey: "AIzaSyAroUXRM4I7NGbbEjubnu998rngjx5sb_Q",
    authDomain: "swapappstaging.firebaseapp.com",
    projectId: "swapappstaging",
    storageBucket: "swapappstaging.appspot.com",
    messagingSenderId: "391564427193",
    appId: "1:391564427193:web:38c7a6ede6ef9d83860068",
    measurementId: "G-54NRF98NND"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
