import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { CategoryResponse } from './responses/categoryResponse';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private apiBaseURL = environment.configSettings.apiURL;
  public categories: BehaviorSubject<any>;
  constructor(private http: HttpClient) { }

  getCategories() {
    return this.http.get<CategoryResponse>(`${this.apiBaseURL}v1/entity/category/`, { observe: 'response' });
  }

  public get currentCategories() {
    if (this.categories != null) {
      return this.categories.value;
    }
    return null;
  }
  set category(val: any){
    this.categories = new BehaviorSubject<any>(val);
  }


}
