import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CorrespondenceListingResponse } from './responses/correspondenceListingResponse';
import { BaseResponse } from '../../core/services/responses/baseResponse';
import { CreationResponse } from './responses/creationResponse';
import { CorrespondenceMatchResponse } from './responses/correspondenceMatchResponse';
import { UpdateResponse } from './responses/updateResponse';



@Injectable({
  providedIn: 'root'
})
export class CorrespondenceService {
  private apiBaseURL = environment.configSettings.apiURL;

  constructor(private http: HttpClient) { }

  getCorrespondences(page?: number) {
    if (page) {
      return this.http.get<CorrespondenceListingResponse>(`${this.apiBaseURL}v1/communication/correspondence/?page=${page}`,
        {observe: 'response'});
    }
    return this.http.get<CorrespondenceListingResponse>(`${this.apiBaseURL}v1/communication/correspondence/`,
      {observe: 'response'});
  }
  getCorrespondenceByEntityHash(entityHash: string) {
    return this.http.get<BaseResponse>(`${this.apiBaseURL}v1/communication/correspondence/entity/${entityHash}`,
      {observe: 'response'});
  }

  checkMatches(entityHashes: Array<string>) {
    return this.http.post<CorrespondenceMatchResponse>(`${this.apiBaseURL}v1/communication/correspondence/check/`,
      {entity_hashes: entityHashes},  {observe: 'response'});
  }

  createCorrespondence(hash: string) {
    return this.http.post<CreationResponse>(`${this.apiBaseURL}v1/communication/correspondence/`,
      {entity_hash: hash},  {observe: 'response'});
  }

  // tslint:disable-next-line:variable-name
  updateCorrespondence(id: number, approval: string){
    return this.http.put<UpdateResponse>(`${this.apiBaseURL}v1/communication/correspondence/correspondence/${id}`,
      { approval },  {observe: 'response'});
  }


  deleteCorrespondence(hash: string) {

    return this.http.delete<BaseResponse>(`${this.apiBaseURL}v1/communication/correspondence/entity/${hash}`,
     {observe: 'response'});
  }
}
