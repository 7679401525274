import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AlertService } from '../../core/alert/alert.service';
import { AuthenticationService } from '../../module-user/services/authentication.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserService } from '../../module-user/services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionGuardService implements CanActivate {

  validSubscriptionNeededErr = '';
  loggedInErr = '';

  constructor(private router: Router,
              private alertService: AlertService,
              private authenticationService: AuthenticationService,
              private userService: UserService,
              translate: TranslateService) {

    translate.get('errors.validSubscriptionNeeded').subscribe((res: string) => {
      this.validSubscriptionNeededErr = res;
    });
    translate.get('errors.loginRequired').subscribe((res: string) => {
      this.loggedInErr = res;
    });
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {


    if (this.authenticationService.currentUserSubject.value === null) {
      return this.userService.getUserProfile().pipe(
        map(response => {
          return true;
      }), catchError(error => {
          return this.returnToRoute(this.loggedInErr, '/');
      }));
    }

    if (this.authenticationService.currentUserSubject.value.has_valid_subscription === false) {
      return this.returnToRoute(this.validSubscriptionNeededErr, '/pricing');
    }

    return of(true);
  }

  returnToRoute(message: string, route: string){
    this.alertService.error(message, true);
    this.router.navigate([route], );
    return of(false);
  }

}

