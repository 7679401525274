import {Component, Input, OnInit} from '@angular/core';
import {FormFieldBase} from '../../core/models/forms/base/form-field-base';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-dynamic-search-field',
  templateUrl: './dynamic-search-field.component.html',
  styleUrls: ['./dynamic-search-field.component.css']
})
export class DynamicSearchFieldComponent implements OnInit {

  @Input() formField: FormFieldBase<any>;
  @Input() form: FormGroup;
  @Input() radioKey = null;
  constructor() { }

  ngOnInit(): void {
  }

  get isValid() { return this.form.controls[this.formField.key].valid; }
}
