import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
})
export class PaginationComponent implements OnInit {

  totalPages: Array<number> = [];
  previousLink: string;
  nextLink: string;
  currentPage: number;


  constructor() { }

  ngOnInit(): void {
  }

}
