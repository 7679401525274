import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AlertService } from '../../../core/alert/alert.service';
import { AuthenticationService } from '../../services/authentication.service';
import { JWTToken } from '../../models/jwt-token';
import { LocalStorageService } from '../../services/local-storage.service';
import { mergeMapTo } from 'rxjs/operators';
import { AngularFireMessaging } from '@angular/fire/messaging';
import {TranslateService} from '@ngx-translate/core';

//#region Methods which are defined in the .js files.
declare function close_user_modal(): any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  returnUrl: string;
  show       = false;
  loading    = false;
  submitted  = false;

  fieldTextType: boolean;

  firebaseToken: string = null;

  @Output() switchComponent: EventEmitter<string> = new EventEmitter();
  fillInAddressMessage = '';
  fillInCommunicationPreferencesMessage = '';
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private localStorageService: LocalStorageService,
    private angularFireMessaging: AngularFireMessaging,
    translate: TranslateService
  ){
    translate.get('userModal.loginPage.fillAddressMessage').subscribe((res: string) => {
      this.fillInAddressMessage = res;
    });
    translate.get('userModal.loginPage.fillPreferencesMessage').subscribe((res: string) => {
      this.fillInCommunicationPreferencesMessage = res;
    });
  }

  // convenience getter for easy access to form fields
  get form() { return this.loginForm.controls; }


  ngOnInit() {
    // We need to retrieve the notification token from firebase, so we can proceed to login,
    // even if the token is not available, the request goes thorough with the notification token null
    if (!('Notification' in window)) {
      // this.alertService.error('This browser does not support desktop notification');
      console.error('This browser does not support desktop notification');
    }// Let's check whether notification permissions have already been granted

    this.angularFireMessaging.requestPermission
      .pipe(mergeMapTo(this.angularFireMessaging.tokenChanges))
      .subscribe(
        (token) => {
          // console.log('Permission granted!    Token:', token);
          this.firebaseToken = token;
          // console.log(token);
        },
        (error) => {
          // this.alertService.error('Please, turn on your notification in order to receive push up notifications');
          console.error(error);
        },
      );

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  onSwitchComponent(name: string) {
    this.switchComponent.emit(name);
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  closeModal(){
    close_user_modal(); // Function call
  }

  onSubmit() {
    this.localStorageService.removeJwt();

    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.authenticationService.login(this.form.email.value, this.form.password.value, this.firebaseToken)
      .subscribe(
        response => {
          /* 1. stop the loading
           * 2. stop the submission
           * 3. hide the modal
           * 4. set the jwt on the local storage
           * 5. show the response message on the screen
           * 6. reset the form
           * 7. navigate to homepage */
          console.log(response.body);
          this.loading = false;
          this.submitted = false;
          close_user_modal(); // Function call
          const jwtToken = new JWTToken(response.body.data.access, response.body.data.refresh);
          this.authenticationService.currentJWTTokenSubject.next(jwtToken);
          this.authenticationService.setCurrentUserSubscription(response.body.data.subscription);
          this.authenticationService.setNoItemsOwned(response.body.data.no_items_owned);
          this.localStorageService.setJwtToken(jwtToken);
          this.alertService.success(response.body.message, true);
          this.loginForm.reset();

          if (response.body.data.user.has_valid_address === false){
            this.router.navigate(['user', {showAddress: true}]);
            this.alertService.success(this.fillInAddressMessage);
          } else if (response.body.data.user.has_notification_preferences === false){
            this.router.navigate(['user', {showPreferences: true}]);
            this.alertService.success(this.fillInCommunicationPreferencesMessage);
          } else {
            this.router.navigate([this.returnUrl]);
          }

        },
        error => {
          // console.log(error);
          this.alertService.error(error.error.error);
          this.loading = false;
        });


  }
}
