<form [formGroup]="searchForm" (ngSubmit)="onSearchSubmit()">

  <!-- Desktop Search -->
  <div class="searchbox d-none d-md-flex" >
    <div class="d-flex w-100">

      <div class="s-field-container w-100" style="max-width:500px;">
        <label for="keyword">{{'header.searchLabel' | translate }}</label>
        <input type="text" class=" form-control" placeholder="{{'header.searchPlaceholder' | translate}}"
          formControlName="keyword">
      </div>

      <div class="seprater-line" ></div>

      <div class="s-field-container w-100" style="max-width:500px;">
        <label for="city">{{'header.yourCityLabel' | translate }}</label>
        <ng-container *ngIf="cities">
          <input type="text" class="form-control" placeholder="{{'header.yourCity' | translate }}"
            formControlName="city" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option  *ngFor="let city of filteredCities | async" [value]="city.name">
              {{city.name}}
            </mat-option>
          </mat-autocomplete>
        </ng-container>
      </div>

    </div>

    <button class="btn-second bg-blue" type="submit">
      <span class="icon-search-normal"></span>
      {{'buttons.search' | translate }}
    </button>
  </div>

  <!-- Mobile Search -->
  <div class="d-md-none d-block w-100 mobile-search-header">

    <div class="mb-3">
      <input type="text" class=" form-control" placeholder="{{'header.searchPlaceholder' | translate}}"
        formControlName="keyword">
    </div>

    <div class="mb-3">
      <ng-container *ngIf="cities">
        <input type="text" class="form-control" placeholder="{{'header.yourCity' | translate }}" formControlName="city"
          [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let city of filteredCities | async" [value]="city.name">
            {{city.name}}
          </mat-option>
        </mat-autocomplete>
      </ng-container>
    </div>

    <button class="btn-second bg-blue w-100 btn-block" type="submit">
      <span class="icon-search-normal"></span>
      {{'buttons.search' | translate }}
    </button>

  </div>


</form>