<div class="top-contact float-right">
  <ul>

    <li class="dropdown">
      <ng-container *ngFor="let language of availableLanguages">
        <a href="javascript:void(0)" class="text-dark-grey" data-toggle="dropdown"
          *ngIf="language.abbr === chosenLanguage">
          <img src="{{language.flag}}" class="rounded-circle" alt="{{language.name}}">
          <span class="d-none d-md-flex">
            {{language.abbr}}
            <i class="icon-arrow-down"></i>
          </span>
        </a>
      </ng-container>

      <ul class="dropdown-menu" style="width: 80px;">
        <li *ngFor="let language of availableLanguages">
          <a href="javascript:void(0)" class="text-dark-grey" (click)="changeLanguage(language.abbr)">
            <img src="{{language.flag}}" class="rounded-circle" alt="{{language.name}}">{{language.abbr}}
          </a>
        </li>
      </ul>

    </li>

    <li class="dropdown">
      <a href="javascript:void(0)" class="text-dark-grey" data-toggle="dropdown">
        {{'header.help' | translate }}
        <i class="icon-arrow-down d-none d-md-inline"></i>
      </a>
      <ul class="dropdown-menu">
        <li><a routerLink="/contact" class="text-dark-grey">{{'header.contact' | translate }}</a>
        </li>
        <li><a routerLink="/about-us" class="text-dark-grey ">{{'header.aboutUs' | translate }}</a>
        </li>
      </ul>
    </li>

    <!-- Notification -->
    <li *ngIf="userProfile">
      <app-notification-center></app-notification-center>
    </li>

    <!-- User Profile -->
    <li class="dropdown  d-none d-md-block ">
      
      <a *ngIf="userProfile" style=" cursor: pointer;" class="text-dark-grey" data-toggle="dropdown">

        <img  *ngIf="!userProfile.profile.profile_picture" src="assets/images/user/user-default.jpg" class="rounded-circle" alt="Sign in">
        <img *ngIf="userProfile.profile.profile_picture" src="{{ userProfile.profile.profile_picture }}" class="rounded-circle" alt="Sign in">
                    
        {{ userProfile.first_name }}
        {{ userProfile.last_name }}
      </a>
      <ul class="dropdown-menu" style="width: 180px;">
        <li>
          <a routerLink="/user" class="text-dark-grey">
            <i class="fas"></i>{{'userMenu.accountSettings' | translate }}</a>
        </li>
        <!-- <li>
          <a routerLink="/user/password/change" class="text-dark-grey">
            <i class="fas"></i>{{'userMenu.changePassword' | translate }}</a>
        </li> -->

        <li>
          <a routerLink="/terms-of-use" class="text-dark-grey">
            <i class="fas"></i>{{'userMenu.terms' | translate }}</a>
        </li>

        <li>
          <a routerLink="/subscriptions" class="text-dark-grey">
            <i class="far"></i> {{'userMenu.mySubscriptions' | translate }}</a>
        </li>

        <ng-container *ngIf="userProfile">
          <li *ngIf="userProfile.has_valid_subscription">
            <a routerLink="/referrals" class="text-dark-grey">
              <i class="fas"></i> &nbsp;{{'userMenu.referrals' | translate }}</a>
          </li>
        </ng-container>
        <li>
          <a href="javascript:void(0)" class="text-dark-grey" routerLink="/user/listing">
            <i class="fas"></i> {{'userMenu.myItems' | translate }}</a>
        </li>
        <li>
          <a href="javascript:void(0)" class="text-dark-grey" routerLink="/item/add">
            <i class="fas fa-plus  menu-icons-alignment"></i> {{'userMenu.postItem' | translate }}</a>
        </li>
        <li>
          <a href="javascript:void(0)" class="text-dark-grey" routerLink="/user/logout">
            <i class="fas fa-sign-out-alt menu-icons-alignment"></i> {{'userMenu.logout' | translate }}</a>
        </li>

      </ul>
    </li>
    <li class="d-none d-md-block ml-3" *ngIf="!userProfile">
      <button class="btn-first blue-btn" data-target="#register" data-toggle="modal">
        {{'buttons.login' | translate }}
      </button>
    </li>
  </ul>
</div>