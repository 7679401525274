<div class="step-tab-panel">
  <div class="tab-from-content">
    <div class="title-icon">
      <h4 class="title rubik fw-600">
        <i class=""></i> {{'entityComponent.addEntity.steps.confirmation.subtitle' | translate }}
      </h4>
    </div>
    <form #addLocation [formGroup]="addConfirmation"  (ngSubmit)="onSubmit()">
      <div class="container">
        <div class="row mt-3 ">
          <div class="offset-md-1 col-md-5 text-center">
            <label class="labels" for="policy">{{'buttons.policyButtons.text' | translate }}
              <a routerLink="/cookie-policy" class="text-light-black">
                {{'buttons.policyButtons.privacyPolicy' | translate }}
              </a>
            </label>
          </div>
          <div class="col-md-6 text-center">
              <mat-checkbox id='policy' formControlName="policyConfirmation" name="policyConfirmation" required ></mat-checkbox>
          </div>
          <div class="text-center col-12">
            <mat-error class="ml-3 small m-0" *ngIf="formSubmitted && addConfirmationFormControls.policyConfirmation.errors">{{'errors.booleanRequired' | translate }}</mat-error>
          </div>

        </div>
        <div class="row mt-3 ">
          <div class="offset-md-1 col-md-5 text-center">
            <label class="labels" for="terms">{{'buttons.policyButtons.text' | translate }}
              <a routerLink="/terms-of-use" class="text-light-black">
                {{'buttons.policyButtons.termsService' | translate }}
              </a>
            </label>
          </div>
          <div class="col-md-6 text-center">
            <mat-checkbox id='terms' formControlName="termsConfirmation" name="termsConfirmation" required></mat-checkbox>
          </div>
          <div class="text-center col-12">
            <mat-error class="ml-3 small m-0" *ngIf="formSubmitted && addConfirmationFormControls.termsConfirmation.errors">{{'errors.booleanRequired' | translate }}</mat-error>
          </div>
        </div>
      </div>
      <div class="step-footer step-tab-pager mt-5 justify-content-center">
        <input  type="submit" class="btn-second blue-btn" style="width: 200px;"
               value="{{'entityComponent.addEntity.buttons.saveItem' | translate }}"/>

      </div>
    </form>
  </div>
</div>
