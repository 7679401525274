import { Component, OnDestroy, Input, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { AuthenticationService } from '../../../module-user/services/authentication.service';
import { Profile } from '../../../module-user/models/profile';
import { Subscription } from 'rxjs';
import { PlanModel } from 'src/app/module-subscription/models/planModel';
import { SubscriptionService } from 'src/app/module-subscription/services/subscription.service';
import { AlertService } from '../../../core/alert/alert.service';
import { MatDialog } from '@angular/material/dialog';
import {CookieService} from '../../../core/services/cookie.service';
import { TranslateService } from '@ngx-translate/core';
import {VoucherComponent} from '../../../module-subscription/voucher/voucher.component';
import {PaymentComponent} from '../../../module-payment/payment/payment.component';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
})
export class SubscriptionsComponent implements OnInit, OnDestroy {
  @Input() isMobileScreen = false;
  loading = false;
  currentUser: Profile = null;
  subscribedPlans: Array<PlanModel> = [];
  allPlans: Array<PlanModel> = [];
  chosenLanguage = 'en';
  alreadySubscribedText = '';

  constructor(
    private subscriptionService: SubscriptionService,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    translate: TranslateService,
    private cookieService: CookieService,
    private route: Router,
  ) { this.chosenLanguage = this.cookieService.LanguageCookie;
    // Getting translation for selection
     translate.get('errors.alreadySubscribed').subscribe((res: string) => {
      this.alreadySubscribedText = res;
    });}

  ngOnInit(): void {
    this.authenticationService.currentUserSubject.subscribe(userProfile => {
      this.currentUser = userProfile;
      if (this.currentUser) {
        this.getSubscribedPlans();
      } else {
        this.getAllPlans();
      }
    });
  }

  getSubscribedPlans(): void {
    this.subscriptionService.getUserSubscriptions().subscribe(
      response => {
        this.subscribedPlans = response.body.data.results.map(subscription => subscription.plan);
      },
      error => {
        console.error(error);
      }
    );
  }

  getAllPlans(): void {
    this.subscriptionService.getSubscriptionPlans().subscribe(
      response => {
        this.allPlans = response.body.data.filter(plan => plan.subscription_type === 'web');
      },
      error => {
        console.error(error);
      }
    );
  }

  openVoucher(plan: PlanModel): void {
    // Your logic to open voucher dialog here
    if (this.currentUser.has_valid_subscription === true){
      this.alertService.error(this.alreadySubscribedText);
    } else {
      const dialogRef = this.dialog.open(VoucherComponent, {
        maxWidth: '450px',
        width: '100%',
      });
      dialogRef.afterClosed().subscribe(message => {
        this.openPayment(plan, message);
      });
    console.log('Opening voucher for plan:', plan);
  }
}
openPayment(plan: PlanModel, message: string): void {
  // console.log(plan);

  // starts subscription payment flow by setting up de subscription in the backend
  this.subscriptionService.setupSubscription(plan.ui).subscribe(response => {
    const dialogRef = this.dialog.open(PaymentComponent, {
      maxWidth: '450px',
      width: '100%',
      data: {
        discount: response.body.data.voucher_discount,
        message: `${message}`,
        plan,
        priceId: response.body.data.price_id,
        subscriberCustomerId: response.body.data.subscriber_customer_id,
        userSubscriptionUI: response.body.data.user_subscription_ui
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // console.log(result);
    });

  }, errorResponse => {
    // console.log(errorResponse);
    this.alertService.error(errorResponse.error.error);
    // in case of address not set, error 406 is returned, the user is redirected to the address page
    if (errorResponse.status === 406){
      this.route.navigate(['/user', {showAddress: true}]);
    }

  });

}
  ngOnDestroy() {}

}
