import { Component, OnDestroy, OnInit } from '@angular/core';
import { EntityService } from '../../module-entity/services/entity.service';
import { Entity } from '../../module-entity/models/entity';
import { Subscription } from 'rxjs';
import { Profile } from '../../module-user/models/profile';
import { AlertService } from '../../core/alert/alert.service';
import { UserService } from '../../module-user/services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { SubscriptionService } from '../../module-subscription/services/subscription.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogService } from '../../core/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-user-listing',
  templateUrl: './user-listing.component.html',
  styleUrls: ['./user-listing.component.css']

})
export class UserListingComponent implements OnInit, OnDestroy {
  loading = false;

  entityServiceSubscription: Subscription;

  jwtSubscription: Subscription; // assign subscription to the jwt in order to show if the user is authenticated
  // profile
  userProfile: Profile = null; // the actual user profile from backend

  entities: Array<Entity> = [];
  totalPages: Array<number> = [];
  previousLink: string;
  nextLink: string;
  currentPage: number;

  NoItemsOwned: number;

  numberOfItemsExceededErr = '';
  validSubscriptionNeededErr = '';

  confirmationModalTitle = '';
  confirmationText = '';
  confirmationButtonAcceptText = '';
  confirmationButtonCancelText = '';

  constructor(private entityService: EntityService,
              private alertService: AlertService,
              private userService: UserService,
              private authenticationService: AuthenticationService,
              private subscriptionService: SubscriptionService,
              private confirmationDialogService: ConfirmationDialogService,
              translate: TranslateService
              ) {
    translate.get('errors.numberOfItemsExceeded').subscribe((res: string) => {
      this.numberOfItemsExceededErr = res;
    });
    translate.get('errors.validSubscriptionNeeded').subscribe((res: string) => {
      this.validSubscriptionNeededErr = res;
    });
    translate.get('userComponent.itemsListing.confirmationModal.title').subscribe((res: string) => {
      this.confirmationModalTitle = res;
    });
    translate.get('userComponent.itemsListing.confirmationModal.confirmationText').subscribe((res: string) => {
      this.confirmationText = res;
    });


    translate.get('userComponent.itemsListing.confirmationModal.buttons.accept').subscribe((res: string) => {
      this.confirmationButtonAcceptText = res;
    });
    translate.get('userComponent.itemsListing.confirmationModal.buttons.cancel').subscribe((res: string) => {
      this.confirmationButtonCancelText = res;
    });

  }

  ngOnInit(): void {


    this.NoItemsOwned = this.authenticationService.currentNoItemsOwned;

       // check if the user is authenticated by checking the current jwt from the local storage
    this.jwtSubscription = this.authenticationService.currentJWTTokenSubject.subscribe(token => {
      if (token != null ){

          this.userService.getUserProfile().subscribe(
          response => {
            this.authenticationService.setCurrentUser(response.body.data);
          }, error => this.alertService.error(error.message));
      }
    });
    this.authenticationService.currentUserSubject.subscribe(userProfile => this.userProfile = userProfile);


    if (this.NoItemsOwned === null || this.NoItemsOwned === undefined){

      this.subscriptionService.getValidSubscription().subscribe(response => {
        this.authenticationService.setCurrentUserSubscription(response.body.data.subscription);
        this.authenticationService.setNoItemsOwned(response.body.data.no_items_owned);
        this.NoItemsOwned = response.body.data.no_items_owned;
      }, error => {
        this.alertService.error(error.error.error, true);
      });
    }

    this.getEntities();
  }

  goToPage(pageNumber){
    this.getEntities(pageNumber);
  }

  getEntities(pageNumber?: number){
    this.entityServiceSubscription = this.entityService.getUserEntities(pageNumber).subscribe(response =>
        this.constructTheListing(response),
      errorResponse => {
        console.log(errorResponse);
      });
  }

  constructTheListing(response){
      this.totalPages = [];
      this.entities = response.body.data.results;
      this.nextLink = response.body.data.links.next;
      this.previousLink = response.body.data.links.previous;
      for (let i = 1; i <= Math.ceil(response.body.data.total / response.body.data.page_size); i++){
        this.totalPages.push(i);
      }
      this.currentPage = response.body.data.page;
    }

  ngOnDestroy() {
    this.entityServiceSubscription.unsubscribe();
  }

  deleteItem(entityHash: string){

    this.confirmationDialogService.confirm(
      this.confirmationModalTitle,
      this.confirmationText,
      this.confirmationButtonAcceptText,
      this.confirmationButtonCancelText
    )
      .then((confirmed) => {
        // User confirmed:
        // console.log('User confirmed:', confirmed);
        if (confirmed === true){
          this.entityService.deleteEntityByHash(entityHash).subscribe(response => {
            this.alertService.success(response.body.message, false);
            // decrement the number of items owned by the user on deletion
            this.authenticationService.decrementNoItemsOwned();
            this.getEntities();
          }, errorResponse => {this.alertService.error(errorResponse.body.message); });
        }
      })
      .catch(() => {
        // User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
      });
  }

  toggleEntityVisibility(entityHash: string, isPublic: boolean) {
    /* This function is responsible for setting the entity visibility in the search results.
     * It uses an entity service in order to update is_public property of the entity.
     * After the request is successful it shows the message and it refreshes the current entities.
     **/

    this.loading = true;

    if (this.checkSubscriptionPlanOptions(isPublic) === true){
      this.entityService.updateEntityByHashPartial(entityHash, {is_public: isPublic}).subscribe(
        response => {
          // this.alertService.success(response.body.message, false);
          // refresh the entities
          this.loading = false;

          if (isPublic === true){
            // increment the number of items owned by the user on setting item to show
            this.authenticationService.incrementNoItemsOwned();
          }else {
            // decrement the number of items owned by the user on setting item to hide
            this.authenticationService.decrementNoItemsOwned();
          }

          this.goToPage(this.currentPage);
        }, errorResponse => {
          this.alertService.error(errorResponse.error.error);
          this.loading = false;
        });
    }else {
      this.loading = false;
    }
  }

  checkSubscriptionPlanOptions(isPublic: boolean){
    const currentSubscription = this.authenticationService.currentUserSubscription;
      // set the attributes on the entity
    let passed = true;
    if (currentSubscription === undefined || currentSubscription === null) {
        this.alertService.error(this.validSubscriptionNeededErr, true);
        passed = false;
      } else  {
        currentSubscription.plan.options.forEach(option => {
          if (option.option_code === 'number_of_items' && isPublic === true &&
            parseInt(option.option_value, 10) <= this.authenticationService.currentNoItemsOwned) {
            this.alertService.error(this.numberOfItemsExceededErr, true);
            passed = false;
          }
        });
      }
    return passed;
    }
    
}



