import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlertService } from './alert.service';
import swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
})
export class AlertComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  message: any;

  successTitle = '';
  errorTitle = '';
  constructor(private alertService: AlertService,
              translate: TranslateService) {
    translate.get('core.alert.successTitle').subscribe((res: string) => {
      this.successTitle = res;
    });
    translate.get('core.alert.errorTitle').subscribe((res: string) => {
      this.errorTitle = res;
    });
  }

  ngOnInit(): void {

    // subscribe to alert component messages
    this.subscription = this.alertService.getAlert()
      .subscribe(response => {
        switch (response && response.type) {
          case 'success':
            swal.fire({
              title: this.successTitle,
              text: response.message,
              icon: 'success',
            });

            break;
          case 'error':
            swal.fire({
              icon: 'error',
              title: this.errorTitle,
              text: response.message,
            });
            break;
        }

        this.message = response;
      });
  }

  ngOnDestroy(): void {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }
}
