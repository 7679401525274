import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CookieService {

  LANGUAGE_COOKIE = 'lang';

  AVAILABLE_LANGUAGES = ['en', 'de'];
  DEFAULT_LANG = 'en';

  chosenLanguage: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor() {}

  private static getCookie(name: string) {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  private static setCookie(name: string, value: string, expireDays: number, path: string = '') {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  private static deleteCookie(name) {
    CookieService.setCookie(name, '', -1);
  }

  private setDefaultLanguageCookie(){
    const lang = window.navigator.languages ? window.navigator.languages[0] : null;
    let shortLang = lang.split('-')[0];
    if (!this.AVAILABLE_LANGUAGES.includes(shortLang)){
      shortLang = this.DEFAULT_LANG;
    }

    if (CookieService.getCookie(this.LANGUAGE_COOKIE) === '') {
      this.changeLanguage(shortLang);
    }
    return this.LanguageCookie;
  }

  changeLanguage(lang: string){
    this.LanguageCookie = lang;
    window.location.reload();
  }

  get LanguageCookie() {
    let langCookie = CookieService.getCookie(this.LANGUAGE_COOKIE);
    if (langCookie === ''){
      langCookie = this.setDefaultLanguageCookie();
    }
    this.chosenLanguage.next(langCookie);
    return langCookie;
  }
  set LanguageCookie(lang: string) {
    CookieService.setCookie(this.LANGUAGE_COOKIE, lang, 60);
  }

}
