import { Component, OnInit } from '@angular/core';

import { Profile } from '../../../module-user/models/profile';
import { AuthenticationService } from '../../../module-user/services/authentication.service';



@Component({
  selector: 'app-become-seller-section',
  templateUrl: './become-seller-section.component.html',
  styleUrls: ['./become-seller-section.component.css']
})
export class BecomeSellerSectionComponent implements OnInit {


  userProfile: Profile = null; // the actual user profile from backend
  showClass = false;
 
  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.authenticationService.currentUserSubject.subscribe(userProfile => this.userProfile = userProfile);
    var clientWidth = document.documentElement.clientWidth;
    if (clientWidth > 575.98) {
      this.showClass = true;
    }
  }



}
