import { Component, Input, OnInit } from '@angular/core';
import { Profile } from '../../../../module-user/models/profile';
import { LanguageService } from '../../../services/language.service';
import { Language } from '../../../models/language';
import { CookieService } from '../../../services/cookie.service';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.css', ],

})
export class DropdownMenuComponent implements OnInit {
  @Input() userProfile: Profile = null;
  availableLanguages: Array<Language> = [];
  chosenLanguage = 'en';
  constructor(private languageService: LanguageService,
              private cookieService: CookieService) {}

  ngOnInit(): void {
    this.languageService.getAvailableLanguages().subscribe(
      response => { this.availableLanguages = response.body.data;
      }, error => console.error(error.message));

    this.cookieService.chosenLanguage.subscribe(chosenLanguage => this.chosenLanguage = chosenLanguage);
  }


  changeLanguage(lang: string){
    this.cookieService.changeLanguage(lang);
  }

}
