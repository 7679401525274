import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Profile } from '../../../module-user/models/profile';
import { AlertService } from '../../alert/alert.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SearchService } from '../../../module-search/services/search.service';
import { Router } from '@angular/router';

//#region Methods which are defined in the .js files.
declare function close_header_menu(): any;
declare function open_header_menu(): any;

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.css']
})
export class MobileMenuComponent implements OnInit, OnChanges {


  searchForm: FormGroup;
  title = 'SwapApp';
  // showAccount = false;
  @Input() userProfile: Profile = null;
  @Input() categories: any;

  fixedTop = false;

  constructor(private alertService: AlertService,
    private formBuilder: FormBuilder,
    private searchService: SearchService,
    private router: Router) { }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      keywordMobile: ['', []],

    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(this.categories);
    //  todo set the menu for mobile
  }

  openMenu() {
    open_header_menu();
  }
  closeMenu() {
    close_header_menu();
  }

  // convenience getter for easy access to form fields
  get formControls() { return this.searchForm.controls; }

  onSearchSubmitMobile() {
    console.log(this.formControls.keywordMobile.value);
    this.searchService.masterSearch.next({
      keyword: this.formControls.keywordMobile.value,
      city: null
    });
    this.router.navigate(['/']);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.scrollY > 30) {
      this.fixedTop = true;
    }else{
      this.fixedTop = false;
    }
  }

}
