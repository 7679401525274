<!-- faqs -->
<section class="section-padding faqs">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-header">
          <div class="text-wrapper text-center">
            <h2 class="fw-500 no-margin font-weight-bolder">
              {{ 'termsPage.title' | translate }}
            </h2>
            <span class="line-grey"></span><span class="line-blue"></span><span class="line-grey"></span>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="section-header">
          <div class="text-wrapper text-center">
            <h4 class="fw-500 no-margin font-weight-bolder">
              {{'termsPage.1' | translate }}
            </h4>
            <p class="text-dark-grey">
              {{'termsPage.1_1' | translate }}
            </p>
             <p class="text-dark-grey">
              {{'termsPage.1_2' | translate }}
            </p>
             <p class="text-dark-grey">
               {{'termsPage.1_3' | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="section-header">
          <div class="text-wrapper text-center">
            <h4 class="fw-500 no-margin font-weight-bolder">
              {{'termsPage.2' | translate }}
            </h4>
            <p class="text-dark-grey">
              {{'termsPage.2_1' | translate }}
            </p>
             <p class="text-dark-grey">
              {{'termsPage.2_2' | translate }}
            </p>
             <p class="text-dark-grey">
               {{'termsPage.2_3' | translate }}
            </p>
            <p class="text-dark-grey">
               {{'termsPage.2_4' | translate }}
            </p>
            <p class="text-dark-grey">
               {{'termsPage.2_5' | translate }}
            </p>
            <p class="text-dark-grey">
               {{'termsPage.2_6' | translate }}
            </p>

          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="section-header">
          <div class="text-wrapper text-center">
            <h4 class="fw-500 no-margin font-weight-bolder">
              {{'termsPage.3' | translate }}
            </h4>
            <p class="text-dark-grey">
              {{'termsPage.3_1' | translate }}
            </p>
             <p class="text-dark-grey">
              {{'termsPage.3_2' | translate }}
            </p>
             <p class="text-dark-grey">
               {{'termsPage.3_3' | translate }}
            </p>
            <p class="text-dark-grey">
               {{'termsPage.3_4' | translate }}
            </p>
            <p class="text-dark-grey">
               {{'termsPage.3_5' | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="section-header">
          <div class="text-wrapper text-center">
            <h4 class="fw-500 no-margin font-weight-bolder">
              {{'termsPage.4' | translate }}
            </h4>
            <p class="text-dark-grey">
              {{'termsPage.4_1' | translate }}
            </p>
             <p class="text-dark-grey">
              {{'termsPage.4_2' | translate }}
            </p>
             <p class="text-dark-grey">
               {{'termsPage.3_3' | translate }}
            </p>
            <p class="text-dark-grey">
               {{'termsPage.4_4' | translate }}
            </p>
            <p class="text-dark-grey">
               {{'termsPage.4_5' | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="section-header">
          <div class="text-wrapper text-center">
            <h4 class="fw-500 no-margin font-weight-bolder">
              {{'termsPage.5' | translate }}
            </h4>
            <p class="text-dark-grey">
              {{'termsPage.5_1' | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="section-header">
          <div class="text-wrapper text-center">
            <h4 class="fw-500 no-margin font-weight-bolder">
              {{'termsPage.6' | translate }}
            </h4>
            <p class="text-dark-grey">
              {{'termsPage.6_1' | translate }}
            </p>
            <p class="text-dark-grey">
              {{'termsPage.6_2' | translate }}<br><br>
              {{'termsPage.6_2_1' | translate }}<br>
              {{'termsPage.6_2_2' | translate }}
            </p>
            <br>
            <p class="text-dark-grey">
              {{'termsPage.6_3' | translate }}<br><br>
              {{'termsPage.6_3_1' | translate }}<br>
              {{'termsPage.6_3_2' | translate }}<br>
              {{'termsPage.6_3_3' | translate }}<br>
              {{'termsPage.6_3_4' | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="section-header">
          <div class="text-wrapper text-center">
            <h4 class="fw-500 no-margin font-weight-bolder">
              {{'termsPage.7' | translate }}
            </h4>
            <p class="text-dark-grey">
              {{'termsPage.7_1' | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="section-header">
          <div class="text-wrapper text-center">
            <h4 class="fw-500 no-margin font-weight-bolder">
              {{'termsPage.8' | translate }}
            </h4>
            <p class="text-dark-grey">
              {{'termsPage.8_1' | translate }}
            </p>
            <p class="text-dark-grey">
              {{'termsPage.8_2' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
