import { Component, Input, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-job-slider-section',
  templateUrl: './job-slider-section.component.html',
  styleUrls: ['./job-slider-section.component.css']
})
export class JobSliderSectionComponent implements OnInit {
  @Input() title;
  @Input() data = [1, 2, 3, 4, 5, 6, 7, 8];
  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    $(document).ready(function () {
      var sliderName = $('.job-slider');
      $('.job-slider-left').click(function () {
        sliderName.slick('slickPrev');
      })
      $('.job-slider-right').click(function () {
        sliderName.slick('slickNext');
      })

      sliderName.slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        draggable: true,
        autoplay: false,
        arrows: false,
        dots: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 4,
            }
          },
          {
            breakpoint: 881,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      });
    });
  }
}
