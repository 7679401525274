import { Component, HostListener, OnInit } from '@angular/core';
import { CorrespondenceService } from '../services/correspondence.service';
import { Notification } from '../models/notification';
import { AlertService } from '../../core/alert/alert.service';
import { Profile } from '../../module-user/models/profile';
import { AuthenticationService } from '../../module-user/services/authentication.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { MessagingService } from '../services/messaging.service';
import { NotificationService } from '../services/notification.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.css']
})
export class NotificationCenterComponent implements OnInit {
  notifications: Array<Notification> = [];

  userProfile: Profile = null;

  unreadCount = 0;

  totalPages: Array<number> = [];
  previousLink: string;
  nextLink: string;
  currentPage: number;

  noEntityMessage: string;
  constructor(
              private correspondenceService: CorrespondenceService,
              private alertService: AlertService,
              private authenticationService: AuthenticationService,
              private angularFireMessaging: AngularFireMessaging,
              private messagingService: MessagingService,
              private notificationService: NotificationService,
              translate: TranslateService) {
    translate.get('communicationComponent.notificationCenter.noEntityMessage').subscribe((res: string) => {
      this.noEntityMessage = res;
    });
  }



  ngOnInit(): void {
    /*
     *  We need to subscribe to the user profile and the push up notifications.
     *  The push up notification is a stringified notification from the backend
     *
     * */

    this.authenticationService.currentUserSubject.subscribe(userProfile => this.userProfile = userProfile);

    this.messagingService.currentMessage.subscribe(message => {
      if (message !== undefined && message !== null) {
        const newNotification = new Notification(message);
        this.notifications.unshift(newNotification);
        this.unreadCount += 1;
        // this.getNotifications(null, true);
      }
    });

    this.getNotifications();
  }

  getNotifications(pageNumber?: number, top?: boolean, refreshListing?: boolean){
    // Reset the notification if no page number was specified
    if (pageNumber === undefined){
      this.notifications = [];
      this.unreadCount = 0;
    }

    this.notificationService.getNotifications(pageNumber).subscribe(response => {
      this.constructTheListing(response, top, refreshListing);
      // console.log(response.body.data);
    }, error => console.error(error));
  }

  constructTheListing(response, top?: boolean, refreshListing?: boolean){
    this.totalPages = [];
    this.unreadCount = response.body.data.unread_count;
    this.nextLink = response.body.data.links.next;
    this.currentPage = response.body.data.page;
    this.previousLink = response.body.data.links.previous;

    for (let i = 1; i <= Math.ceil(response.body.data.total / response.body.data.page_size); i++){
      this.totalPages.push(i);
    }

    if (refreshListing === true){
      this.notifications = [];
    }

    if (response.body.data.results != null ) {
      response.body.data.results.forEach(notification => {
        // we only add objects if they are not in the list
        const filteredObjects = this.notifications.filter( currentNotification => currentNotification.id === notification.id);
        if ( filteredObjects.length === 0){
          if (top === true){
            this.notifications.unshift(notification);
          }else{
            this.notifications.push(notification);
          }
        }
      });
    }

    this.currentPage = response.body.data.page;
  }

  @HostListener('scroll', ['$event']) // for scroll events of the current element
  onScroll(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      // When the user scrolls to the bottom of the notification center, it loads older correspondences
      // console.log('loaded');
      if ( this.totalPages.includes(this.currentPage + 1) ){
        this.getNotifications(this.currentPage + 1);
      }
    }
  }

  modifyApprovalCorrespondence(notificationId: number, id: number, approval: string){
    this.markNotificationAsRead(notificationId);
    this.correspondenceService.updateCorrespondence(id, approval).subscribe( response => {
      this.alertService.success(response.body.message);
      this.getNotifications(undefined, false, true);
    }, errorResponse => this.alertService.error(errorResponse.error.error));

  }

  markNotificationAsRead(id: number){
    this.notificationService.markAsRead(id, new Date()).subscribe( response => this.getNotifications()
     , error => console.error(error));
  }

  markAllCorrespondenceAsRead(){
    const notificationIds = [];
    this.notifications.forEach(notification => notificationIds.push(notification.id) );
    this.notificationService.markAllAsRead(notificationIds).subscribe(response =>
    {
      this.getNotifications();
      this.alertService.success(response.body.message);
    }, error => console.error(error));

  }

  showEntityRemoved(notificationId: number){
    // If the entity was deleted, still show the notification, but when clicked pop up the message that the entity was removed
    this.notificationService.markAsRead(notificationId, new Date()).subscribe( response => this.getNotifications()
      , error => console.error(error));
    this.alertService.error(this.noEntityMessage);
  }

  getLengthOfDict(dictToCheck){
    return Object.keys(dictToCheck).length;
  }

}
