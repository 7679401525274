<div class="about-app-section">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-12 mb-md-5 mb-2">
        <small>Explore</small>
        <h2>  {{'header.ourProducts' | translate }}</h2>
        <p>

          {{'header.ourProductsDescription' | translate }}
           </div>
    </div>
  </div>
</div>