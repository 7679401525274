import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { AddressService } from '../../../module-address/services/address.service';
import {Subscription} from 'rxjs';
import { Country } from '../../../module-address/models/country';
import { City} from '../../../module-address/models/city';
import { EntityAdditionService } from '../service/entity-addition.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FieldsValidators } from '../../../module-user/validators/fields-validators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',

})
export class AddLocationComponent implements OnInit, OnDestroy {
  @Output() currentComponent: EventEmitter<any> = new EventEmitter<any>();
  addLocationForm: FormGroup;
  addressSubscription: Subscription;
  availableCountries: Array<Country>;
  availableCities: Array<City>;

  selectCountryText = '';
  selectCityText = '';

  constructor(private addressService: AddressService,
              private entityAdditionService: EntityAdditionService,
              private formBuilder: FormBuilder,
              translate: TranslateService) {
    translate.get('entityComponent.addEntity.steps.location.inputFields.country.selectText').subscribe((res: string) => {
      this.selectCountryText = res;
    });
    translate.get('entityComponent.addEntity.steps.location.inputFields.city.selectText').subscribe((res: string) => {
      this.selectCityText = res;
    });
  }

  ngOnInit(): void {
    this.addLocationForm = this.formBuilder.group({
      country: [this.selectCountryText, [
        FieldsValidators.oneValueHasToBeChangedValidator(this.selectCountryText, { sameValue: true })
      ]],
      city: [this.selectCityText, [
        FieldsValidators.oneValueHasToBeChangedValidator(this.selectCityText, { sameValue: true })
      ]],
    });

    this.addressService.getAvailableCountriesAsSubject();

    this.addressSubscription = this.addressService.availableCountries.subscribe(availableCountries =>
      this.availableCountries = availableCountries);

    // Listen to country changes and add cities accordingly
    this.addLocationForm.get('country').valueChanges.subscribe(val => {
      this.addressService.getCities(val).subscribe(response => this.availableCities = response.body.data,
          errorResponse => console.error(errorResponse) );
    });

    if (this.entityAdditionService.currentEntity.city !== undefined){
      // In case of entity update we set the default values to the entity values
      this.addressService.getCities(this.entityAdditionService.currentEntity.city.country).subscribe(response => {
        this.availableCities = response.body.data;
      }, errorResponse => {
        console.log(errorResponse.body.data);
      });
      this.addLocationFormControls.country.setValue(this.entityAdditionService.currentEntity.city.country);
      this.addLocationFormControls.city.setValue(this.entityAdditionService.currentEntity.city.id);
    }

  }

  // convenience getter for easy access to form fields
  get addLocationFormControls() { return this.addLocationForm.controls; }

  onSubmit(){

    // set the attributes on the entity
    this.entityAdditionService.city = parseInt(this.addLocationFormControls.city.value, 10);

    if ( this.addLocationFormControls.country.value === this.selectCountryText || this.addLocationFormControls.country.value === '' ||
      this.addLocationFormControls.city.value === this.selectCityText || this.addLocationFormControls.city.value === '') {
        return;
    }

    // console.log('Current entity ');
    // console.log(this.entityAdditionService.entity.value);

    // change the screen
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.currentComponent.emit('showAddConfirmation');

  }
  ngOnDestroy() {
    this.addressSubscription.unsubscribe();
  }

}
