<!-- <div class="mobile-menu-second "  *ngIf="userProfile">
</div> -->
<!-- <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button> -->
<mat-sidenav-container class="example-sidenav-container">
  <mat-sidenav style="width: 100%">
    <ng-template [ngTemplateOutlet]="menuList"></ng-template>
  </mat-sidenav>

    <mat-sidenav-content>
      <div class="container">
        <div class="row tab-panel" [ngStyle]="{'margin-top': isMobileScreen?'-15px':'40px' }">
          <div class="col-md-4 col-lg-3 pr-0" *ngIf="!isMobileScreen">
            <ng-template [ngTemplateOutlet]="menuList"></ng-template>
          </div>
          <div class="col-sm-12 col-md-8 col-lg-9">
            <div class="row" style="margin-top: 15px;" *ngIf="isMobileScreen">
              <div class="col-12 p-0">
                <div class="breadcrumb profile  pt-3 pb-3" (click)="sidenav.toggle()">
                  <span>
                    <i class="fa fa-chevron-left"></i>
                    <span>{{activeScreen | translate}}</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="tab-content"  [ngStyle]="{'margin-top': isMobileScreen?'0px':'-15px' ,'padding': isMobileScreen?'0px':'40px', 'padding-top':isMobileScreen?'0px':'30px','border-width':isMobileScreen?'0':'1px'}" >
              <app-profile-general
                [isMobileScreen]="isMobileScreen"
                [hidden]="!showGeneral"
                [genderChoices]="constants.gender"
                [employmentChoices]="constants.employmentStatuses"
                [userProfile]="userProfile">
                <div class="ball-container">
                  <div class="ball ball-one"></div>
                  <div class="shadow shadow-one"></div>
                </div>
              </app-profile-general>
              <app-profile-address
              [isMobileScreen]="isMobileScreen"
                [hidden]="!showAddress"
                [countryChoices]="constants.country"
                [titleChoices]="constants.title">
                <div class="ball-container">
                  <div class="ball ball-one"></div>
                  <div class="shadow shadow-one"></div>
                </div>
              </app-profile-address>
              <app-profile-preferences
              [isMobileScreen]="isMobileScreen"
                [hidden]="!showPreferences">
                <div class="ball-container">
                  <div class="ball ball-one"></div>
                  <div class="shadow shadow-one"></div>
                </div>
              </app-profile-preferences>
              <app-profile-notification
              [isMobileScreen]="isMobileScreen"
                [hidden]="!showNotifications">
                <div class="ball-container">
                  <div class="ball ball-one"></div>
                  <div class="shadow shadow-one"></div>
                </div>
              </app-profile-notification>
              <app-password-change 
              [isMobileScreen]="isMobileScreen"
              [hidden]="!showChangePassword">
              </app-password-change>
              <app-subscriptions
              [isMobileScreen]="isMobileScreen"
               [hidden]="!showSubscription">
              </app-subscriptions>
            </div>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
</mat-sidenav-container>
  
<ng-template #menuList>
  <div class="tab-menus-panel" [ngStyle]="{'height':isMobileScreen?'82vh':'90%', 'margin-top': '-15px'}">
    <ul class="nav nav-tabs">
      <li class="nav-item" >
        <a class="nav-link"
           href="javascript:void(0)"
           [ngClass]="{'active' : showGeneral}"
           (click)="changeComponent('profile_general')">
            <svg style="margin-right: 10px;" width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
              <path d="M22 12C22 6.49 17.51 2 12 2C6.49 2 2 6.49 2 12C2 14.9 3.25 17.51 5.23 19.34C5.23 19.35 5.23 19.35 5.22 19.36C5.32 19.46 5.44 19.54 5.54 19.63C5.6 19.68 5.65 19.73 5.71 19.77C5.89 19.92 6.09 20.06 6.28 20.2C6.35 20.25 6.41 20.29 6.48 20.34C6.67 20.47 6.87 20.59 7.08 20.7C7.15 20.74 7.23 20.79 7.3 20.83C7.5 20.94 7.71 21.04 7.93 21.13C8.01 21.17 8.09 21.21 8.17 21.24C8.39 21.33 8.61 21.41 8.83 21.48C8.91 21.51 8.99 21.54 9.07 21.56C9.31 21.63 9.55 21.69 9.79 21.75C9.86 21.77 9.93 21.79 10.01 21.8C10.29 21.86 10.57 21.9 10.86 21.93C10.9 21.93 10.94 21.94 10.98 21.95C11.32 21.98 11.66 22 12 22C12.34 22 12.68 21.98 13.01 21.95C13.05 21.95 13.09 21.94 13.13 21.93C13.42 21.9 13.7 21.86 13.98 21.8C14.05 21.79 14.12 21.76 14.2 21.75C14.44 21.69 14.69 21.64 14.92 21.56C15 21.53 15.08 21.5 15.16 21.48C15.38 21.4 15.61 21.33 15.82 21.24C15.9 21.21 15.98 21.17 16.06 21.13C16.27 21.04 16.48 20.94 16.69 20.83C16.77 20.79 16.84 20.74 16.91 20.7C17.11 20.58 17.31 20.47 17.51 20.34C17.58 20.3 17.64 20.25 17.71 20.2C17.91 20.06 18.1 19.92 18.28 19.77C18.34 19.72 18.39 19.67 18.45 19.63C18.56 19.54 18.67 19.45 18.77 19.36C18.77 19.35 18.77 19.35 18.76 19.34C20.75 17.51 22 14.9 22 12ZM16.94 16.97C14.23 15.15 9.79 15.15 7.06 16.97C6.62 17.26 6.26 17.6 5.96 17.97C4.44 16.43 3.5 14.32 3.5 12C3.5 7.31 7.31 3.5 12 3.5C16.69 3.5 20.5 7.31 20.5 12C20.5 14.32 19.56 16.43 18.04 17.97C17.75 17.6 17.38 17.26 16.94 16.97Z" [attr.fill]="showGeneral?'#6F93C6':'#828282'"/>
              <path d="M12 6.92993C9.93 6.92993 8.25 8.60993 8.25 10.6799C8.25 12.7099 9.84 14.3599 11.95 14.4199C11.98 14.4199 12.02 14.4199 12.04 14.4199C12.06 14.4199 12.09 14.4199 12.11 14.4199C12.12 14.4199 12.13 14.4199 12.13 14.4199C14.15 14.3499 15.74 12.7099 15.75 10.6799C15.75 8.60993 14.07 6.92993 12 6.92993Z" [attr.fill]="showGeneral?'#6F93C6':'#828282'"/>
              </svg>                      
            {{'userComponent.profile.generalTab.tabTitle' | translate }}
          </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           href="javascript:void(0)"
           [ngClass]="{'active' : showAddress}"
           (click)="changeComponent('profile_address')" >
           
          <svg width="18" height="22" viewBox="0 0 18 22" [attr.fill]="showAddress?'#6F93C6':'#828282'"  xmlns="http://www.w3.org/2000/svg">
            <path d="M17.6191 7.45C16.5691 2.83 12.5391 0.75 8.99912 0.75C8.99912 0.75 8.99912 0.75 8.98912 0.75C5.45912 0.75 1.41912 2.82 0.369119 7.44C-0.800881 12.6 2.35912 16.97 5.21912 19.72C6.27912 20.74 7.63912 21.25 8.99912 21.25C10.3591 21.25 11.7191 20.74 12.7691 19.72C15.6291 16.97 18.7891 12.61 17.6191 7.45ZM8.99912 12.46C7.25912 12.46 5.84912 11.05 5.84912 9.31C5.84912 7.57 7.25912 6.16 8.99912 6.16C10.7391 6.16 12.1491 7.57 12.1491 9.31C12.1491 11.05 10.7391 12.46 8.99912 12.46Z" />
            </svg>
            {{'userComponent.profile.addressTab.tabTitle' | translate }} </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           href="javascript:void(0)"
           [ngClass]="{'active' : showPreferences}"
           (click)="changeComponent('profile_preferences')" >
            <svg width="19" height="19" viewBox="0 0 19 19" [attr.fill]="showPreferences?'#6F93C6':'#828282'" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.5 0C4.26 0 0 4.26 0 9.5C0 14.74 4.26 19 9.5 19C14.74 19 19 14.74 19 9.5C19 4.26 14.74 0 9.5 0ZM9.5 11.75H6.5C6.09 11.75 5.75 11.41 5.75 11C5.75 10.59 6.09 10.25 6.5 10.25H9.5C9.91 10.25 10.25 10.59 10.25 11C10.25 11.41 9.91 11.75 9.5 11.75ZM12.5 8.75H6.5C6.09 8.75 5.75 8.41 5.75 8C5.75 7.59 6.09 7.25 6.5 7.25H12.5C12.91 7.25 13.25 7.59 13.25 8C13.25 8.41 12.91 8.75 12.5 8.75Z"/>
              </svg>
           {{'userComponent.profile.searchPreferenceTab.tabTitle' | translate }} </a>
      </li>

      <li class="nav-item">
        <a class="nav-link"
           href="javascript:void(0)"
           [ngClass]="{'active' : showNotifications}"
           (click)="changeComponent('profile_notifications')" >

            <svg width="24" height="24" viewBox="0 0 24 24" [attr.fill]="showNotifications?'#6F93C6':'#828282'" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.1892 14.0598L19.0592 12.1798C18.8092 11.7698 18.5892 10.9798 18.5892 10.4998V8.6298C18.5892 4.9998 15.6392 2.0498 12.0192 2.0498C8.38923 2.0598 5.43923 4.9998 5.43923 8.6298V10.4898C5.43923 10.9698 5.21923 11.7598 4.97923 12.1698L3.84923 14.0498C3.41923 14.7798 3.31923 15.6098 3.58923 16.3298C3.85923 17.0598 4.46923 17.6398 5.26923 17.8998C6.34923 18.2598 7.43923 18.5198 8.54923 18.7098C8.65923 18.7298 8.76923 18.7398 8.87923 18.7598C9.01923 18.7798 9.16923 18.7998 9.31923 18.8198C9.57923 18.8598 9.83923 18.8898 10.1092 18.9098C10.7392 18.9698 11.3792 18.9998 12.0192 18.9998C12.6492 18.9998 13.2792 18.9698 13.8992 18.9098C14.1292 18.8898 14.3592 18.8698 14.5792 18.8398C14.7592 18.8198 14.9392 18.7998 15.1192 18.7698C15.2292 18.7598 15.3392 18.7398 15.4492 18.7198C16.5692 18.5398 17.6792 18.2598 18.7592 17.8998C19.5292 17.6398 20.1192 17.0598 20.3992 16.3198C20.6792 15.5698 20.5992 14.7498 20.1892 14.0598ZM12.7492 9.9998C12.7492 10.4198 12.4092 10.7598 11.9892 10.7598C11.5692 10.7598 11.2292 10.4198 11.2292 9.9998V6.89981C11.2292 6.4798 11.5692 6.1398 11.9892 6.1398C12.4092 6.1398 12.7492 6.4798 12.7492 6.89981V9.9998Z" />
              <path d="M14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01Z"/>
            </svg>
           {{'userComponent.profile.communicationTab.tabTitle' | translate }} </a>
      </li>

      <li class="nav-item">
        <a class="nav-link"
           href="javascript:void(0)"
           [ngClass]="{'active' : showChangePassword}"
           (click)="changeComponent('profile_change_password')" >
            <svg width="20" height="20" viewBox="0 0 20 20" [attr.fill]="showChangePassword?'#6F93C6':'#828282'" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.28 7.53V6.28C16.28 3.58 15.63 0 10 0C4.37 0 3.72 3.58 3.72 6.28V7.53C0.92 7.88 0 9.3 0 12.79V14.65C0 18.75 1.25 20 5.35 20H14.65C18.75 20 20 18.75 20 14.65V12.79C20 9.3 19.08 7.88 16.28 7.53ZM10 16.74C8.33 16.74 6.98 15.38 6.98 13.72C6.98 12.05 8.34 10.7 10 10.7C11.66 10.7 13.02 12.06 13.02 13.72C13.02 15.39 11.67 16.74 10 16.74ZM5.35 7.44C5.27 7.44 5.2 7.44 5.12 7.44V6.28C5.12 3.35 5.95 1.4 10 1.4C14.05 1.4 14.88 3.35 14.88 6.28V7.45C14.8 7.45 14.73 7.45 14.65 7.45H5.35V7.44Z" />
            </svg>
           {{'userComponent.changePassword.tabTitle' | translate }} </a>
      </li>

      <li class="nav-item">
        <a class="nav-link"
           href="javascript:void(0)"
           [ngClass]="{'active' : showSubscription}"
           (click)="changeComponent('profile_subscription')" >
           <svg width="24" height="24" viewBox="0 0 24 24" [attr.fill]="showSubscription?'#6F93C6':'#828282'" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 22H7C6.59 22 6.25 21.66 6.25 21.25C6.25 20.84 6.59 20.5 7 20.5H17C17.41 20.5 17.75 20.84 17.75 21.25C17.75 21.66 17.41 22 17 22Z" />
            <path d="M20.3502 5.52004L16.3502 8.38004C15.8202 8.76004 15.0602 8.53004 14.8302 7.92004L12.9402 2.88004C12.6202 2.01004 11.3902 2.01004 11.0702 2.88004L9.17022 7.91004C8.94022 8.53004 8.19022 8.76004 7.66022 8.37004L3.66022 5.51004C2.86022 4.95004 1.80022 5.74004 2.13022 6.67004L6.29022 18.32C6.43022 18.72 6.81022 18.98 7.23022 18.98H16.7602C17.1802 18.98 17.5602 18.71 17.7002 18.32L21.8602 6.67004C22.2002 5.74004 21.1402 4.95004 20.3502 5.52004ZM14.5002 14.75H9.50022C9.09022 14.75 8.75022 14.41 8.75022 14C8.75022 13.59 9.09022 13.25 9.50022 13.25H14.5002C14.9102 13.25 15.2502 13.59 15.2502 14C15.2502 14.41 14.9102 14.75 14.5002 14.75Z" />
            </svg>
           {{'subscriptionsComponent.subscriptionsTab.tabTitle' | translate }} </a>
      </li>

    </ul>
    <div class="menu-footer" *ngIf="userProfile && !userProfile.has_valid_subscription">
      <img src="assets/images/profile/folder.png"/>
      <div>
        <h4>{{'subscriptionsComponent.subscriptionsTab.titleUpgrade' | translate }}</h4>
        <p>{{'subscriptionsComponent.subscriptionsTab.upgradeText' | translate }}</p>
      </div>
    </div>
  </div>
</ng-template>

