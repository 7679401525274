


<div class="downloadourappbackground">
  <div class="container">
    <div class="downloadourappflex">
      <div class="downloadourappmain">
        <img class="downloadourapptriangle" src="../../../assets/images/about/downloadourapptriangle.svg" alt="">
        <p class="aboutthe">{{'aboutus.download' | translate }}</p>
        <h1 class="swapappteam">{{'aboutus.ourApp' | translate }}</h1>
        <p class="swapappteampara visitusmaxwidth">{{'aboutus.visitUs' | translate }} <a href=""><span class="swapsite"> www.swapapp.de </span></a>
           {{'aboutus.companyDescription2' | translate }}
        </p>

        <div class="checkflex">
          <div>
            <p><img src="../../../assets/images/about/check.svg" alt=""> {{'aboutus.NoCommission' | translate }}</p>
            <p><img src="../../../assets/images/about/check.svg" alt=""> {{'aboutus.247Agent' | translate }}</p>
          </div>
          <div>
            <p><img src="../../../assets/images/about/check.svg" alt="">  {{'aboutus.noProvision' | translate }}</p>
            <p><img src="../../../assets/images/about/check.svg" alt="">  {{'aboutus.globalPlatform' | translate }}</p>
          </div>
        </div>

        <div class="storeflex">
          <div>
            <a href=""><img src="../../../assets/images/about/Playstore.svg" alt=""></a>
          </div>

          <div>
            <a href=""><img src="../../../assets/images/about/Applestore.svg" alt=""></a>
          </div>
        </div>
      </div>
      <div>
        <img class="iphone" src="../../../assets/images/about/iphone.png" alt="">
      </div>
      <div class="storeflexresponsive">
        <div>
          <a href=""><img src="../../../assets/images/about/Playstore.svg" alt=""></a>
        </div>

        <div>
          <a href=""><img src="../../../assets/images/about/Applestore.svg" alt=""></a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="howitworksmain">
    <p class="aboutthe">{{'aboutus.getRegisteredToday' | translate }}</p>
    <h1 class="swapappteam">{{'aboutus.howItWorks' | translate }}</h1>
    
    <p class="howitworkspara">
      {{'aboutus.companyDescription3' | translate }}    </p>
  </div>

  <div class="tryforfreeflex">
    <div class="tryforfreemain">
      <img class="numberone" src="../../../assets/images/about/1.svg" alt="">
      <img class="iconimage" src="../../../assets/images/about/tryforfree.svg" alt="">
      <p class="tryforfreesectionheading"> {{'aboutus.try' | translate }} </p>
      <p class="tryforfreesectionpara">{{'aboutus.tryFree' | translate }}</p>
    </div>
    <div class="unlockallfeaturesflex">
      <img class="numbers" src="../../../assets/images/about/2.svg" alt="">
      <img class="iconimage" src="../../../assets/images/about/unlockallfeatures.svg" alt="">
      <p class="tryforfreesectionheading"> {{'aboutus.unlock' | translate }} </p>
      
      <p class="tryforfreesectionpara unlockallfeaturesmaxwidth">
        {{'aboutus.unlockPotential' | translate }}
      </p>
    </div>
    
  </div>

</div>