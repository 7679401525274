import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
})
export class CookiePolicyComponent implements OnInit {
  constructor(translate: TranslateService) {
  }

  ngOnInit(): void {
  }

}

