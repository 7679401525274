import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VoucherService } from '../../voucher/services/voucher.service';
import { AlertService } from '../../../core/alert/alert.service';
import { Router } from '@angular/router';
import { ReferralService } from '../services/referral.service';
import { ModifyReferral } from '../models/modifyReferral';
import { ReferralModel } from '../models/referralModel';

@Component({
  selector: 'app-modify-referral',
  templateUrl: './modify-referral.component.html',
})
export class ModifyReferralComponent implements OnInit {
  message: string = null;

  errorMessage: string = null;
  modifyReferralForm: FormGroup;
  submitted = false;


  constructor(public dialogRef: MatDialogRef<ModifyReferralComponent>,
              @Inject(MAT_DIALOG_DATA) public inputData: { referral: ReferralModel },
              private fb: FormBuilder,
              private voucherService: VoucherService,
              private alertService: AlertService,
              private route: Router,
              private referralService: ReferralService) {}


  ngOnInit(): void {
    this.modifyReferralForm = this.fb.group({
      oldEmail: [{value: this.inputData.referral.email, disabled: true},  [Validators.required, Validators.email]],
      newEmail: ['', [Validators.required, Validators.email]],
    });
  }

  get form() { return this.modifyReferralForm.controls; }


  modifyReferral(){
    this.submitted = true;
    // stop here if form is invalid
    if (!this.form.newEmail.valid) {return; }
    // do the update
    this.referralService.modifyReferral(new ModifyReferral(this.inputData.referral.email, this.form.newEmail.value)).
    subscribe(response => {
      this.inputData.referral = response.body.data;
      this.exit(response.body.message);
    }, error => this.errorMessage = error.error.error);
  }

  exit(message?: string){
    this.dialogRef.close(this.inputData.referral);
    if (message !== undefined){
      this.alertService.success(message, true);
    }
  }

}
