/* tslint:disable */
export class Address {
  /* Address model used for Address requests and responses.
  * All of the address responses contain all of the properties,
  * whereas the requests must implemented less properties */
  id: number;
  title: string;
  first_name: string;
  last_name: string;
  line1: string;
  line2: string;
  line3: string;
  line4: string;
  city: string;
  state: string;
  postcode: string;
  search_text: string;
  phone_number: string;
  notes: string;
  is_default_for_billing: boolean;
  hash: string;
  num_orders_as_billing_address: number;
  date_created: Date;
  country: string;
  user: number;
  constructor(title: string,
              first_name: string,
              last_name: string,
              line1: string,
              line2: string,
              line3: string,
              line4: string,
              state: string,
              city: string,
              postcode: string,
              phone_number: string,
              is_default_for_billing: boolean,
              country: string,
              hash?: string,
              id?: number,
              user?: number,
              notes?: string,
              search_text?: string,
              date_created?: Date,
              num_orders_as_billing_address?: number,
              ) {

    this.title = title;
    this.first_name = first_name;
    this.last_name = last_name;
    this.line1 = line1;
    this.line2 = line2;
    this.line3 = line3;
    this.line4 = line4;
    this.city = city;
    this.state = state;
    this.postcode = postcode;
    this.phone_number = phone_number;
    this.is_default_for_billing = is_default_for_billing;
    this.country = country;

    if(hash) this.hash = hash;
    if(user) this.user = user;
    if(id) this.id = id;
    if(notes) this.notes=notes;
    if(num_orders_as_billing_address)  this.num_orders_as_billing_address = num_orders_as_billing_address;
    if(date_created) this.date_created = date_created;
    if(search_text) this.search_text = search_text;
  }



}


