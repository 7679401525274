import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PreferenceService } from '../../services/preference.service';
import { AlertService } from '../../../core/alert/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SearchPreference } from '../../models/searchPreference';
import { AddressService } from '../../../module-address/services/address.service';
import { EntityService } from '../../../module-entity/services/entity.service';
import { SearchPreferenceService } from '../../services/search-preference.service';
import { FormControlService } from '../../../core/services/form-control.service';
import { Subscription } from 'rxjs';
import { NumberInput } from '../../../core/models/forms/number-input';
import { FieldsValidators } from '../../validators/fields-validators';
import { EntityClass } from '../../../module-entity/models/entityClass';
import { Category } from '../../../module-entity/models/category';
import { CategoryService } from '../../../module-entity/services/category.service';
import { Country } from '../../../module-address/models/country';
import { City } from '../../../module-address/models/city';
import { SearchPreferenceAttribute } from '../../models/searchPreferenceAtrribute';
import { ConstantsService } from '../../../core/services/constants.service';
import { TranslateService } from '@ngx-translate/core';
import { DropdownInput } from '../../../core/models/forms/dropdown-input';
import { Router } from '@angular/router';


@Component({
  selector: 'app-profile-preferences',
  templateUrl: './profile-preferences.component.html',
})
export class ProfilePreferencesComponent implements OnInit, OnDestroy{
  @Input() isMobileScreen=false;
  searchPreferences: SearchPreference = null;
  // just one search preference at the moment.

  searchPreferenceForm: FormGroup;

  dynamicSearchPreferenceForm: FormGroup = null;
  entityFormBase = [];

  searchPreferenceFormSubmitted = false;

  availableCountries: Array<Country>;
  allCities: Array<City>;
  availableCities: Array<City>;

  entityClasses: Array<EntityClass> = [];
  selectableEntityClasses: Array<EntityClass>;
  categories: Array<Category> = [];
  // Subscriptions
  entityClassesSubscription: Subscription;
  categorySubscription: Subscription;
  addressSubscription: Subscription;



  selectCategoryText = '';
  selectMainCategoryText = '';
  selectCountryText = '';
  selectCityText = '';
  minHigherMaxErr = '';

  constructor(private preferenceService: PreferenceService,
              private alertService: AlertService,
              private formBuilder: FormBuilder,
              private addressService: AddressService,
              private entityService: EntityService,
              private formControlService: FormControlService,
              private categoryService: CategoryService,
              private searchPreferenceService: SearchPreferenceService,
              private constantsService: ConstantsService,
              translate: TranslateService,
              private router: Router) {
    // Getting translation for selection
    translate.get('userComponent.profile.searchPreferenceTab.inputFields.category.selectText').subscribe((res: string) => {
      this.selectCategoryText = res;
    });
    translate.get('userComponent.profile.searchPreferenceTab.inputFields.mainCategory.selectText').subscribe((res: string) => {
      this.selectMainCategoryText = res;
    });
    translate.get('userComponent.profile.searchPreferenceTab.inputFields.country.selectText').subscribe((res: string) => {
      this.selectCountryText = res;
    });
    translate.get('userComponent.profile.searchPreferenceTab.inputFields.city.selectText').subscribe((res: string) => {
      this.selectCityText = res;
    });

    translate.get('userComponent.profile.searchPreferenceTab.inputFields.errors.minHigherMax').subscribe((res: string) => {
      this.minHigherMaxErr = res;
    });
  }



  ngOnInit(): void {
    // Build the form
    this.searchPreferenceForm = this.formBuilder.group({
      category: [this.selectCategoryText, [
        FieldsValidators.oneValueHasToBeChangedValidator(this.selectCategoryText, {sameValue: true})
      ]],
      entityClass: [this.selectMainCategoryText, [
        FieldsValidators.oneValueHasToBeChangedValidator(this.selectMainCategoryText, {sameValue: true})
      ]],
      country: [this.selectCountryText, [
        FieldsValidators.oneValueHasToBeChangedValidator(this.selectCountryText, {sameValue: true}),
        Validators.required
      ]],
      city: [this.selectCityText, [
        FieldsValidators.oneValueHasToBeChangedValidator(this.selectCityText, {sameValue: true}),
        Validators.required
      ]],
      radius: [30, [
        Validators.required
      ]],
    });

    // get all cities
    if ( this.constantsService.currentConstants === null || this.constantsService.currentConstants === undefined){
      this.constantsService.getConstants().subscribe(response => {
        this.constantsService.constants = response.body.data;
        this.allCities = response.body.data.city;
        this.loadSearchPreference();
      }, error => console.error(error));
    } else{ this.allCities = this.constantsService.currentConstants.city; this.loadSearchPreference(); }

    // get the countries
    this.addressService.getAvailableCountriesAsSubject();
    this.addressSubscription = this.addressService.availableCountries.subscribe(availableCountries =>
      this.availableCountries = availableCountries);

    // get categories and classes
    this.loadCategoriesAndClasses();

    // get preferences
    this.searchPreferenceService.getSearchPreferences().subscribe(response => {
        if (response.body.data[0] !== undefined){
          this.searchPreferences = response.body.data[0];
          this.loadSearchPreference();
        }}, errorResponse => console.error(errorResponse));


    // add listeners to cat and country changes
    this.searchPreferenceForm.get('category').valueChanges.subscribe(val => this.filterEntityClasses(val));
    this.searchPreferenceForm.get('country').valueChanges.subscribe(val => {
      this.addressService.getCities(val).subscribe(response => this.availableCities = response.body.data,
        errorResponse => console.error(errorResponse.body.data));
    });
    this.searchPreferenceForm.get('entityClass').valueChanges.subscribe(val => {
      if ( this.entityClasses !== null ){this.createDynamicForm(val); }
    });




  }

  // convenience getter for easy access to form fields
  get searchPreferenceFormControls() { return this.searchPreferenceForm.controls; }

  // convenience getter for easy access to form fields
  get dynamicFormControl() { return this.dynamicSearchPreferenceForm.controls; }

  loadSearchPreference(){
    try {
      if (this.searchPreferences !== null) {
        // set category
        const currentEntityClass = this.entityClasses.filter(entityClass => entityClass.id === this.searchPreferences.entity_class)[0];
        if (this.categories !== undefined && this.categories !== null &&
          this.entityClasses !== null && this.entityClasses !== undefined) {
          this.categories.forEach(category => {
            category.children.forEach(child => {
              if (child.name === currentEntityClass.name) {
                this.searchPreferenceFormControls.category.setValue(category.id);
              }
            });
          });
        }
        // set country
        if (this.allCities !== undefined) {
          this.allCities.forEach(city => {
            if (city.id === this.searchPreferences.city) { this.searchPreferenceFormControls.country.setValue(city.country); }
          });
        }
        this.searchPreferenceFormControls.entityClass.setValue(this.searchPreferences.entity_class);
        this.searchPreferenceFormControls.city.setValue(this.searchPreferences.city);
        this.searchPreferenceFormControls.radius.setValue(this.searchPreferences.radius);


        currentEntityClass.attributes.forEach(attr => {
            for (const formControlsKey in this.dynamicFormControl) {
              if (attr.can_be_used_in_preferences === true && formControlsKey.includes(attr.code.toString())){
                this.searchPreferences.search_preference_attributes.forEach(searchPreferenceAttr => {

                  if (searchPreferenceAttr.code === attr.code) {
                    // console.log(searchPreferenceAttr);
                    // console.log(formControlsKey);
                    if (formControlsKey.includes('min')){
                      this.dynamicFormControl[formControlsKey].setValue(searchPreferenceAttr.value_min);
                    }else if (formControlsKey.includes('max')){
                      this.dynamicFormControl[formControlsKey].setValue(searchPreferenceAttr.value_max);
                    } else if (formControlsKey.includes('text-')){
                      this.dynamicFormControl[formControlsKey].setValue(searchPreferenceAttr.value);
                    }
                  }
                });
              }
            }
          }
        );
      }
    }catch (err) {
      this.router.navigate(['/']);
      window.location.reload();
    }

  }

  loadCategoriesAndClasses() {
    this.entityService.getEntityClasses();
    this.entityClassesSubscription = this.entityService.entityClasses.subscribe(entityClasses => {
      this.entityClasses = entityClasses;
      if (entityClasses !== undefined && entityClasses !== null) {
        // Set the categories and the classes options
        if (this.categoryService.currentCategories === null) {
          // Getting the current categories. If they are already loaded we get them from the service subject
          this.categorySubscription = this.categoryService.getCategories().subscribe(response => {
            this.categoryService.category = response.body.data;
            this.categories = response.body.data;
          }, error => console.error(error));
        } // categories are already loaded by a different component
        else {
          this.categories = this.categoryService.currentCategories;
        }
      }
    });
  }

  filterEntityClasses(categoryId: number, entityClassId?: number) {
    if (this.categories !== undefined && this.categories !== null &&
      this.entityClasses !== null && this.entityClasses !== undefined) {
      const currentCategory = this.categories.filter(category => category.id === categoryId)[0];
      this.selectableEntityClasses = [];
      if (currentCategory !== undefined){
        this.entityClasses.forEach(entityClass => {
          if (currentCategory.numchild !== 0) {
            currentCategory.children.forEach(subCategory1 => {
              if (entityClass.name.toLowerCase() === subCategory1.name.toLowerCase()) {
                this.selectableEntityClasses.push(entityClass);
              }
            });
          }
        });
      }

      if (entityClassId !== undefined) {
        this.searchPreferenceFormControls.entityClass.setValue(entityClassId);
      }
    }
  }

  createDynamicForm(entityClassId: number) {
    this.entityFormBase = [];
    const currentClass = this.entityClasses.filter(entityClass => entityClass.id === entityClassId)[0];
    if (currentClass != null) {
      currentClass.attributes.forEach(attr => {
        if (attr.can_be_used_in_preferences === true) {
          if (attr.type === 'integer') {
            // Integer dynamic field with min and max filtering
            let numberInput = new NumberInput({key: `min_${attr.code.toString()}`, label: `Min ${attr.name}`, required: true, });
            this.entityFormBase.push(numberInput);
            numberInput = new NumberInput({key: `max_${attr.code.toString()}`, label: `Max ${attr.name}`, required: true, });
            this.entityFormBase.push(numberInput);
          } else if (attr.type === 'float') {
            // Float dynamic field with min and max filtering
            let numberInput = new NumberInput({key: `min_${attr.code.toString()}`, label: `Min ${attr.name}`, required: true, step: 1});
            this.entityFormBase.push(numberInput);
            numberInput = new NumberInput({key: `max_${attr.code.toString()}`, label: `Max ${attr.name}`, required: true, step: 1});
            this.entityFormBase.push(numberInput);
          } else if (attr.type === 'text' && attr.choices !== null){
            // text type with choices. unpacking them
            const attrChoices = [];
            attr.choices.split(',').forEach(choice => {
              attrChoices.push({key: choice, value: choice});
            });
            // console.log(attr.code.toString());
            const dropDown = new DropdownInput({key: 'text-' + attr.code.toString(), label: attr.name, options: attrChoices,
              required: attr.required, value: ''});
            this.entityFormBase.push(dropDown);
          }
        }
      });
    }
    // console.log(this.entityFormBase);
    this.dynamicSearchPreferenceForm = null;
    this.dynamicSearchPreferenceForm = this.formControlService.toFormGroup(this.entityFormBase);
  }

  onPreferenceFormSubmit() {
    this.searchPreferenceFormSubmitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if forms are invalid
    if (this.searchPreferenceForm.invalid || this.dynamicSearchPreferenceForm.invalid) {
      this.searchPreferenceForm.markAllAsTouched();
      return;
    }



    let searchPreferencesAttrs = [];
    const currentClass = this.entityClasses.filter(entityClass => entityClass.id ===
      this.searchPreferenceFormControls.entityClass.value)[0];

    if (currentClass != null) {
      let valMin;
      let valMax;
      let value;
      currentClass.attributes.forEach(attr => {
        if (attr.can_be_used_in_preferences === true) {
          valMin = null;
          valMax = null;
          value = null;
          Object.keys(this.dynamicFormControl).forEach(key => {
            if (key.includes(`min_${attr.code.toString()}`)) {
              valMin = parseFloat(this.dynamicFormControl[key].value);
            }
            else if (key.includes(`max_${attr.code.toString()}`)) {
              valMax = parseFloat(this.dynamicFormControl[key].value);
            } else if ( key === (`${attr.code.toString()}`) || key === ('text-' + `${attr.code.toString()}`) ) {
              value = this.dynamicFormControl[key].value.toString();
            }
          });

          if (valMin !== null && valMax !== null && valMax < valMin){
            this.alertService.clear();
            this.alertService.error(this.minHigherMaxErr);
            searchPreferencesAttrs = [];
            return;
          }

          searchPreferencesAttrs.push(new SearchPreferenceAttribute(
            attr.type,
            attr.code,
            valMin,
            valMax,
            value
          ));
        }
      });

      if (searchPreferencesAttrs.length !== 0){
          const searchPreference = new SearchPreference(
            this.searchPreferenceFormControls.entityClass.value,
            this.searchPreferenceFormControls.city.value,
            this.searchPreferenceFormControls.radius.value,
            searchPreferencesAttrs
          );

          // Create search preference

          if (this.searchPreferences === null){
            // Create search preference
            this.searchPreferenceService.createSearchPreference(searchPreference).subscribe(response => {
              this.alertService.success(response.body.message);
              this.searchPreferences = response.body.data;
              this.searchPreferenceFormSubmitted = false;
            }, errorResponse => {
              // console.error(errorResponse.error);
              this.alertService.error(errorResponse.error);
              this.searchPreferenceFormSubmitted = false;
            });
          } else {
            // update search preference
            this.searchPreferenceService.updateSearchPreference(this.searchPreferences.hash_pref, searchPreference).subscribe(response => {
              this.alertService.success(response.body.message);
              this.searchPreferences = response.body.data;
              this.searchPreferenceFormSubmitted = false;
            }, errorResponse => {
              this.alertService.error(errorResponse.error.error);
              this.searchPreferenceFormSubmitted = false;
            });
          }
        }
      }
  }

  removeSearchPreference(){
    if (this.searchPreferences !== null){
      this.searchPreferenceService.deleteSearchPreference(this.searchPreferences.hash_pref).subscribe(response => {
        this.alertService.success(response.body.message);
        this.searchPreferences = null;
        this.dynamicSearchPreferenceForm = null;
        this.searchPreferenceForm.reset();
      }, errResponse => this.alertService.error(errResponse.error.error));
    }
  }
  ngOnDestroy() {
    this.entityClassesSubscription.unsubscribe();
    this.addressSubscription.unsubscribe();
  }

}
