<h1 mat-dialog-title class="text-center">
  {{'subscriptionsComponent.referralPage.addReferrals.title' | translate }}
</h1>
<h3 mat-dialog-title class="text-center text-danger">{{message}}</h3>
<h3 mat-dialog-title class="text-center text-danger">{{errorMessage}}</h3>
<form novalidate (ngSubmit)="createReferrals()" [formGroup]="referralForm">
  <label for="login-email1-field">
    {{'subscriptionsComponent.referralPage.addReferrals.inputFields.email1.label' | translate }}
  </label>
  <div class="input-group mb-4 custom-input-group-height" >
  <!-- Email 1-->
    <input id='login-email1-field'
           type="text"
           formControlName="email1"
           class="form-control custom-input-group-height"
           [ngClass]="{ 'is-invalid': submitted && form.email1.errors }"
           placeholder="{{'subscriptionsComponent.referralPage.addReferrals.inputFields.email1.placeholder' | translate }}"
    />
    <div class="input-group-append">
         <span class="input-group-text custom-span-input-group" >
           <i class="fas fa-user-circle"></i>
         </span>
    </div>
    <div *ngIf="submitted && form.email1.errors" class="invalid-feedback">
      <div *ngIf="form.email1.errors.required">{{'subscriptionsComponent.referralPage.addReferrals.inputFields.email1.errors.required' | translate }}</div>
      <div *ngIf="form.email1.errors.email1">{{'subscriptionsComponent.referralPage.addReferrals.inputFields.email1.errors.email' | translate }}</div>
    </div>
  </div>
  <!--  Email 2 -->
  <label for="login-email2-field">
    {{'subscriptionsComponent.referralPage.addReferrals.inputFields.email2.label' | translate }}
  </label>
  <div class="input-group mb-4 custom-input-group-height" >

    <input id='login-email2-field'
           type="text"
           formControlName="email2"
           class="form-control custom-input-group-height"
           [ngClass]="{ 'is-invalid': submitted && form.email2.errors }"
           placeholder="{{'subscriptionsComponent.referralPage.addReferrals.inputFields.email2.placeholder' | translate }}"
    />
    <div class="input-group-append">
         <span class="input-group-text custom-span-input-group" >
           <i class="fas fa-user-circle"></i>
         </span>
    </div>
    <div *ngIf="submitted && form.email2.errors" class="invalid-feedback">
      <div *ngIf="form.email2.errors.required">{{'subscriptionsComponent.referralPage.addReferrals.inputFields.email2.errors.required' | translate }}</div>
      <div *ngIf="form.email2.errors.email2">{{'subscriptionsComponent.referralPage.addReferrals.inputFields.email2.errors.email' | translate }}</div>
    </div>
  </div>

  <!--  Email 3 -->
  <label for="login-email3-field">
    {{'subscriptionsComponent.referralPage.addReferrals.inputFields.email3.label' | translate }}
  </label>
  <div class="input-group mb-4 custom-input-group-height" >

    <input id='login-email3-field'
           type="text"
           formControlName="email3"
           class="form-control custom-input-group-height"
           [ngClass]="{ 'is-invalid': submitted && form.email3.errors }"
           placeholder="{{'subscriptionsComponent.referralPage.addReferrals.inputFields.email3.placeholder' | translate }}"
    />
    <div class="input-group-append">
         <span class="input-group-text custom-span-input-group" >
           <i class="fas fa-user-circle"></i>
         </span>
    </div>
    <div *ngIf="submitted && form.email3.errors" class="invalid-feedback">
      <div *ngIf="form.email3.errors.required">{{'subscriptionsComponent.referralPage.addReferrals.inputFields.email3.errors.required' | translate }}</div>
      <div *ngIf="form.email3.errors.email3">{{'subscriptionsComponent.referralPage.addReferrals.inputFields.email3.errors.email' | translate }}</div>
    </div>
  </div>

  <!--  Email 4 -->
  <label for="login-email4-field">
    {{'subscriptionsComponent.referralPage.addReferrals.inputFields.email4.label' | translate }}
  </label>
  <div class="input-group mb-4 custom-input-group-height" >

    <input id='login-email4-field'
           type="text"
           formControlName="email4"
           class="form-control custom-input-group-height"
           [ngClass]="{ 'is-invalid': submitted && form.email4.errors }"
           placeholder="{{'subscriptionsComponent.referralPage.addReferrals.inputFields.email4.placeholder' | translate }}"
    />
    <div class="input-group-append">
         <span class="input-group-text custom-span-input-group" >
           <i class="fas fa-user-circle"></i>
         </span>
    </div>
    <div *ngIf="submitted && form.email4.errors" class="invalid-feedback">
      <div *ngIf="form.email4.errors.required">{{'subscriptionsComponent.referralPage.addReferrals.inputFields.email4.errors.required' | translate }}</div>
      <div *ngIf="form.email4.errors.email4">{{'subscriptionsComponent.referralPage.addReferrals.inputFields.email4.errors.email' | translate }}</div>
    </div>
  </div>
  <!--  Email 5 -->
  <label for="login-email5-field">
    {{'subscriptionsComponent.referralPage.addReferrals.inputFields.email5.label' | translate }}
  </label>
  <div class="input-group mb-4 custom-input-group-height" >

    <input id='login-email5-field'
           type="text"
           formControlName="email5"
           class="form-control custom-input-group-height"
           [ngClass]="{ 'is-invalid': submitted && form.email5.errors }"
           placeholder="{{'subscriptionsComponent.referralPage.addReferrals.inputFields.email5.placeholder' | translate }}"
    />
    <div class="input-group-append">
         <span class="input-group-text custom-span-input-group" >
           <i class="fas fa-user-circle"></i>
         </span>
    </div>
    <div *ngIf="submitted && form.email5.errors" class="invalid-feedback">
      <div *ngIf="form.email5.errors.required">{{'subscriptionsComponent.referralPage.addReferrals.inputFields.email5.errors.required' | translate }}</div>
      <div *ngIf="form.email5.errors.email5">{{'subscriptionsComponent.referralPage.addReferrals.inputFields.email5.errors.email' | translate }}</div>
    </div>
  </div>
</form>
<ng-container *ngIf="inputData.showExitMessageOnCancellation">
  <button (click)="exit(true)"  class="btn btn-primary rounded small float-left">{{'subscriptionsComponent.referralPage.addReferrals.buttons.cancellation' | translate }}</button>
</ng-container>
<ng-container  *ngIf="!inputData.showExitMessageOnCancellation">
  <button (click)="exit()" class="btn btn-primary rounded small float-left">{{'subscriptionsComponent.referralPage.addReferrals.buttons.cancellation' | translate }}</button>
</ng-container>

<button (click)="createReferrals()"  class="btn btn-warning rounded small float-right" >{{'subscriptionsComponent.referralPage.addReferrals.buttons.confirmation' | translate }}</button>


