import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AlertService } from '../../../core/alert/alert.service';
import { AuthenticationService } from '../../services/authentication.service';

declare function close_user_modal(): any;

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  loading    = false;
  submitted  = false;

  @Output() switchComponent: EventEmitter<string> = new EventEmitter();

  constructor(private formBuilder: FormBuilder,
              private alertService: AlertService,
              private authenticationService: AuthenticationService) { }

  ngOnInit(): void {

    this.forgotPasswordForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }

  // convenience getter for easy access to form fields
  get form() { return this.forgotPasswordForm.controls; }

  onSwitchComponent(name: string) {
    this.switchComponent.emit(name);
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.loading = true;

    this.authenticationService.recoverPassword(this.form.email.value)
      .subscribe(
        response => {
          /* 1. stop the loading
           * 2. stop the submission
           * 3. hide the modal
           * 4. show the response message on the screen
           * 5. reset the form
           * 6. change the modal back to sign in */
          this.loading = false;
          this.submitted = false;
          close_user_modal();
          this.alertService.success(response.body.message, true);

          this.forgotPasswordForm.reset();
          this.switchComponent.emit('sign_in');
        },
        error => {
          this.alertService.error(error.error.error);
          this.loading = false;
        });

  }



}
