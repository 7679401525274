<!-- Start top bar -->
<div class="">
  <div class="">

    <!-- New Topbar  -->
    <div class="top-bar d-none d-md-block ">
      <div class="container">
        <div class="row">
          <div class="col-md-7">
            <div class="d-flex align-items-center">
              <a href="javascript:void(0)" routerLink="/" class="site-logo">
                <img src="assets/images/logo/sm-logo.png" alt="Logo">
              </a>
              <!-- Main Navigation -->
              <ul class="main-navigation">
                <li>
                  <a href="javascript:void(0)" routerLink="/" routerLinkActive="active">
                    {{'header.home' | translate }}
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" routerLink="/pricing" routerLinkActive="active">
                    {{'header.pricing' | translate }}
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" routerLink="/about-us" routerLinkActive="active">
                    {{'header.aboutUs' | translate }}
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" routerLink="/download" routerLinkActive="active">
                    {{'header.download' | translate }}
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" routerLink="/contact" routerLinkActive="active">
                    {{'header.contact' | translate }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-5">
            <app-dropdown-menu [userProfile]="userProfile"></app-dropdown-menu>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile Topbar -->
    <app-mobile-menu [userProfile]="userProfile" [categories]="categories"></app-mobile-menu>
    <!-- End Topbar -->
  </div>
</div>
<!-- End Header -->