

  <div>
    <div class="modal-header">
      <h4 class="modal-title">{{ title }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-center">
      {{ message }}
    </div>
    <hr>
    <div class="modal-body text-center">

       <button type="button" [ngClass]="{' btn-danger': !reverseColours, 'btn-primary': reverseColours}" class="btn mr-1" (click)="decline()">{{ btnCancelText }}</button>
       <button type="button"  [ngClass]="{'btn-primary ml-1': !reverseColours, 'btn-danger': reverseColours}" class="btn ml-1"  (click)="accept()">{{ btnOkText }}</button>

    </div>
  </div>


