<app-header [ngbCollapse]="!showHeader"></app-header>



<app-user-modal></app-user-modal>


<app-alert></app-alert>


<router-outlet *ngIf="globalLoading === false"></router-outlet>

<app-global-loading *ngIf="globalLoading === true"></app-global-loading>

<div class="clearfix"></div>

<app-footer [ngbCollapse]="!showFooter"></app-footer>
