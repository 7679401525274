import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AlertService } from '../../core/alert/alert.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{
  /* This service checks if the user is authenticated or not.
   * If the user is not authenticated, it redirects it to home and creates an alert
   */

  constructor(private router: Router, private alertService: AlertService, private authenticationService: AuthenticationService) { }
  canActivate() {
    // It checks if the user is authenticated by getting the current jwt token from local storage
    if (this.authenticationService.currentTokenValue != null) {
      return true;
    } else {
      this.alertService.error('You must be authenticated to proceed', true);
      this.router.navigate(['/'], );
      return false;
    }
  }
}
