<div class="tab-pane" *ngIf="userAddresses">
  <h2 *ngIf="!isMobileScreen" class="pane-heading">
    {{ "userComponent.profile.addressTab.title" | translate }}
  </h2>
  <div class="row tab-pane-content">
    <div class="col-sm-12 col-md-7 col-lg-7">
      <form [formGroup]="addressForm" (ngSubmit)="onAddressFormSubmit()">
        <div class="form-group row col-sm-6">
          <label class="field-label" for="address-title-field">{{
            "userComponent.profile.addressTab.inputFields.title.label"
              | translate
          }}</label>
          <select
            class="form-control"
            formControlName="title"
            id="address-title-field"
          >
            <option value="" disabled selected>
              {{
                "userComponent.profile.addressTab.inputFields.title.selectText"
                  | translate
              }}
            </option>
            <option *ngFor="let title of titleChoices" [value]="title.abbr">
              {{ title.name }}
            </option>
          </select>
        </div>

        <div class="form-group row">
          <div class="col-sm-6">
            <label class="field-label" for="address-first-name-field">
              {{
                "userComponent.profile.addressTab.inputFields.firstName.label"
                  | translate
              }}
            </label>
            <input
              id="address-first-name-field"
              [ngClass]="{
                'is-invalid':
                  addressFormSubmitted && userAddressForm.firstName.errors
              }"
              formControlName="firstName"
              name="firstName"
              class="form-control"
              type="text"
              placeholder="{{
                'userComponent.profile.addressTab.inputFields.firstName.placeholder'
                  | translate
              }}"
            />
            <div
              *ngIf="addressFormSubmitted && userAddressForm.firstName.errors"
              class="invalid-feedback"
            >
              <div *ngIf="userAddressForm.firstName.errors.required">
                {{
                  "userComponent.profile.addressTab.inputFields.firstName.errors.required"
                    | translate
                }}
              </div>
              <div *ngIf="userAddressForm.firstName.errors.minLength">
                {{
                  "userComponent.profile.addressTab.inputFields.firstName.errors.minLength"
                    | translate
                }}
              </div>
              <ng-container
                *ngIf="userAddressForm.firstName.errors.backendError"
              >
                <div
                  *ngFor="
                    let errorMessage of userAddressForm.firstName.errors
                      .backendError
                  "
                >
                  {{ errorMessage }}
                </div>
              </ng-container>
            </div>
          </div>

          <div class="col-sm-6">
            <label class="field-label" for="address-last-name-field">
              {{
                "userComponent.profile.addressTab.inputFields.lastName.label"
                  | translate
              }}
            </label>
            <input
              id="address-last-name-field"
              [ngClass]="{
                'is-invalid':
                  addressFormSubmitted && userAddressForm.lastName.errors
              }"
              formControlName="lastName"
              name="lastName"
              class="form-control"
              type="text"
              placeholder="{{
                'userComponent.profile.addressTab.inputFields.lastName.placeholder'
                  | translate
              }}"
            />
            <div
              *ngIf="addressFormSubmitted && userAddressForm.lastName.errors"
              class="invalid-feedback"
            >
              <div *ngIf="userAddressForm.lastName.errors.required">
                {{
                  "userComponent.profile.addressTab.inputFields.lastName.errors.required"
                    | translate
                }}
              </div>
              <div *ngIf="userAddressForm.lastName.errors.minLength">
                {{
                  "userComponent.profile.addressTab.inputFields.lastName.errors.minLength"
                    | translate
                }}
              </div>
              <ng-container
                *ngIf="userAddressForm.lastName.errors.backendError"
              >
                <div
                  *ngFor="
                    let errorMessage of userAddressForm.lastName.errors
                      .backendError
                  "
                >
                  {{ errorMessage }}
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class="field-label" for="address-line1-field">
            {{
              "userComponent.profile.addressTab.inputFields.line1.label"
                | translate
            }}
          </label>
          <textarea
            id="address-line1-field"
            rows="3"
            [ngClass]="{
              'is-invalid': addressFormSubmitted && userAddressForm.line1.errors
            }"
            formControlName="line1"
            name="line1"
            class="form-control"
            type="text"
            placeholder="{{
              'userComponent.profile.addressTab.inputFields.line1.placeholder'
                | translate
            }}"
          >
          </textarea>
          <div
            *ngIf="addressFormSubmitted && userAddressForm.line1.errors"
            class="invalid-feedback"
          >
            <div *ngIf="userAddressForm.line1.errors.required">
              {{
                "userComponent.profile.addressTab.inputFields.line1.errors.required"
                  | translate
              }}
            </div>
            <div *ngIf="userAddressForm.line1.errors.minLength">
              {{
                "userComponent.profile.addressTab.inputFields.line1.errors.minLength"
                  | translate
              }}
            </div>
            <ng-container *ngIf="userAddressForm.line1.errors.backendError">
              <div
                *ngFor="
                  let errorMessage of userAddressForm.line1.errors.backendError
                "
              >
                {{ errorMessage }}
              </div>
            </ng-container>
          </div>
        </div>

        <div class="form-group">
          <label class="field-label" for="address-line2-field">
            {{
              "userComponent.profile.addressTab.inputFields.line2.label"
                | translate
            }}
          </label>
          <input
            id="address-line2-field"
            [ngClass]="{
              'is-invalid': addressFormSubmitted && userAddressForm.line2.errors
            }"
            formControlName="line2"
            name="line2"
            class="form-control"
            type="text"
            placeholder="{{
              'userComponent.profile.addressTab.inputFields.line2.placeholder'
                | translate
            }}"
          />
          <div
            *ngIf="addressFormSubmitted && userAddressForm.line2.errors"
            class="invalid-feedback"
          >
            <div *ngIf="userAddressForm.line2.errors.required">
              {{
                "userComponent.profile.addressTab.inputFields.line2.errors.required"
                  | translate
              }}
            </div>
            <ng-container *ngIf="userAddressForm.line2.errors.backendError">
              <div
                *ngFor="
                  let errorMessage of userAddressForm.line2.errors.backendError
                "
              >
                {{ errorMessage }}
              </div>
            </ng-container>
          </div>
        </div>

        <!-- <div class="form-group">
          <label class="field-label" for="address-line3-field">
            {{'userComponent.profile.addressTab.inputFields.line3.label' | translate }}
          </label>
          <input id='address-line3-field'
                 [ngClass]="{ 'is-invalid': addressFormSubmitted && userAddressForm.line3.errors }"
                 formControlName="line3"
                 name="line3"
                 class="form-control"
                 type="text"
                 placeholder="{{'userComponent.profile.addressTab.inputFields.line3.placeholder' | translate }}">
          <div *ngIf="addressFormSubmitted && userAddressForm.line3.errors" class="invalid-feedback">
            <ng-container *ngIf="userAddressForm.line3.errors.backendError">
              <div *ngFor="let errorMessage of userAddressForm.line3.errors.backendError">{{ errorMessage }}</div>
            </ng-container>
          </div>
        </div> -->

        <!-- <div class="form-group">
          <label class="field-label" for="address-line4-field">
            {{'userComponent.profile.addressTab.inputFields.line4.label' | translate }}
          </label>
          <input id='address-line4-field'
                 [ngClass]="{ 'is-invalid': addressFormSubmitted && userAddressForm.line4.errors }"
                 formControlName="line4"
                 name="line4"
                 class="form-control"
                 type="text"
                 placeholder="{{'userComponent.profile.addressTab.inputFields.line4.placeholder' | translate }}">
          <div *ngIf="addressFormSubmitted && userAddressForm.line4.errors" class="invalid-feedback">
            <ng-container *ngIf="userAddressForm.line4.errors.backendError">
              <div *ngFor="let errorMessage of userAddressForm.line4.errors.backendError">{{ errorMessage }}</div>
            </ng-container>
          </div>
        </div> -->

        <div class="form-group row">
          <div class="col-sm-6">
            <label class="field-label" for="address-phoneNumber-field">
            {{
              "userComponent.profile.addressTab.inputFields.phone.label"
                | translate
            }}
          </label>
          <input
            id="address-phoneNumber-field"
            [ngClass]="{
              'is-invalid':
                addressFormSubmitted && userAddressForm.phoneNumber.errors
            }"
            formControlName="phoneNumber"
            name="phoneNumber"
            class="form-control"
            type="text"
            placeholder="{{
              'userComponent.profile.addressTab.inputFields.phone.placeholder'
                | translate
            }}"
          />
          <div
            *ngIf="addressFormSubmitted && userAddressForm.phoneNumber.errors"
            class="invalid-feedback"
          >
            {{
              "userComponent.profile.addressTab.inputFields.phone.errors.pattern"
                | translate
            }}

            <ng-container
              *ngIf="userAddressForm.phoneNumber.errors.backendError"
            >
              <div
                *ngFor="
                  let errorMessage of userAddressForm.phoneNumber.errors
                    .backendError
                "
              >
                {{ errorMessage }}
              </div>
            </ng-container>
          </div>
          </div>
          <div class="col-sm-6">
            <label class="field-label" for="address-city-field">
              {{
                "userComponent.profile.addressTab.inputFields.city.label"
                  | translate
              }}
            </label>
            <input
              id="address-city-field"
              [ngClass]="{
                'is-invalid':
                  addressFormSubmitted && userAddressForm.city.errors
              }"
              formControlName="city"
              name="city"
              class="form-control"
              type="text"
              placeholder="{{
                'userComponent.profile.addressTab.inputFields.city.placeholder'
                  | translate
              }}"
            />
            <div
              *ngIf="addressFormSubmitted && userAddressForm.city.errors"
              class="invalid-feedback"
            >
              <div *ngIf="userAddressForm.city.errors.required">
                {{
                  "userComponent.profile.addressTab.inputFields.city.errors.required"
                    | translate
                }}
              </div>
              <ng-container *ngIf="userAddressForm.city.errors.backendError">
                <div
                  *ngFor="
                    let errorMessage of userAddressForm.city.errors.backendError
                  "
                >
                  {{ errorMessage }}
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-6">
            <label class="field-label" for="address-state-field">
              {{
                "userComponent.profile.addressTab.inputFields.state.label"
                  | translate
              }}
            </label>
            <input
              id="address-state-field"
              [ngClass]="{
                'is-invalid':
                  addressFormSubmitted && userAddressForm.state.errors
              }"
              formControlName="state"
              name="state"
              class="form-control"
              type="text"
              placeholder="{{
                'userComponent.profile.addressTab.inputFields.state.placeholder'
                  | translate
              }}"
            />
          </div>

          <div class="col-sm-6">
            <label class="field-label" for="address-postcode-field">
              {{
                "userComponent.profile.addressTab.inputFields.postcode.label"
                  | translate
              }}
            </label>
            <input
              id="address-postcode-field"
              [ngClass]="{
                'is-invalid':
                  addressFormSubmitted && userAddressForm.postcode.errors
              }"
              formControlName="postcode"
              name="postcode"
              class="form-control"
              type="text"
              placeholder="{{
                'userComponent.profile.addressTab.inputFields.postcode.placeholder'
                  | translate
              }}"
            />
            <div
              *ngIf="addressFormSubmitted && userAddressForm.postcode.errors"
              class="invalid-feedback"
            >
              <div *ngIf="userAddressForm.postcode.errors.required">
                {{
                  "userComponent.profile.addressTab.inputFields.postcode.errors.required"
                    | translate
                }}
              </div>
              <ng-container
                *ngIf="userAddressForm.postcode.errors.backendError"
              >
                <div
                  *ngFor="
                    let errorMessage of userAddressForm.postcode.errors
                      .backendError
                  "
                >
                  {{ errorMessage }}
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="form-group">
         
          <label class="field-label" for="address-country-field">
            {{
              "userComponent.profile.addressTab.inputFields.country.label"
                | translate
            }}
          </label>
          <!-- <input
            id="address-country-field"
            [ngClass]="{
              'is-invalid':
                addressFormSubmitted && userAddressForm.country.errors
            }"
            formControlName="country"
            name="country"
            class="form-control"
            type="text"
            placeholder="{{
              'userComponent.profile.addressTab.inputFields.country.selectText'
                | translate
            }}"
          />
          <div
            *ngIf="addressFormSubmitted && userAddressForm.country.errors"
            class="invalid-feedback"
          >
            <div *ngIf="userAddressForm.country.errors.required">
              {{
                "userComponent.profile.addressTab.inputFields.country.errors.required"
                  | translate
              }}
            </div>
            <ng-container *ngIf="userAddressForm.country.errors.backendError">
              <div
                *ngFor="
                  let errorMessage of userAddressForm.country.errors
                    .backendError
                "
              >
                {{ errorMessage }}
              </div>
            </ng-container>
          </div> -->
        
            <mat-form-field style="width: 100%; color: white;">
              <mat-label>{{'userComponent.profile.addressTab.inputFields.country.selectText' | translate }}</mat-label>
              <mat-select formControlName="country" id="address-country-field"
                          panelClass="example-panel-blue" >
                <mat-option *ngFor="let ctr of countryChoices" [value]="ctr.iso_3166_1_a2" >
                  {{ ctr.printable_name }}
                </mat-option>
              </mat-select>
              <mat-error>
                {{'userComponent.profile.addressTab.inputFields.country.errors.required' | translate }}
              </mat-error>
            </mat-form-field>
      
        </div>

        <div class="row mt-5">
          <div class="col-lg-6 col-md-8 col-sm-12">
            <button class="btn btn-primary">
              {{
                "userComponent.profile.addressTab.confirmationButton"
                  | translate
              }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-5 tab-pane-image" *ngIf="!isMobileScreen">
      <div>
        <img src="assets/images/profile/address.svg" />
      </div>
    </div>
  </div>
</div>
