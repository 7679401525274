import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { ActivationToken } from '../models/ativation_token';
import { AlertService } from '../../core/alert/alert.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-activate',
  template: '',
})
export class ActivateComponent implements OnInit, OnDestroy {
  private activationSubscription: Subscription;
  alertMessage: string;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              private alertService: AlertService,
              translate: TranslateService) {
    translate.get('activate.alert').subscribe((res: string) => {
      this.alertMessage = res;
    });
  }

  ngOnInit(): void {
    this.activationSubscription = this.route.params.subscribe(params => {
      this.userService.activate(new ActivationToken(params.uid64, params.token))
        .subscribe(
          response => {
            this.alertService.success(this.alertMessage, true);
            this.router.navigate(['/']);
          },
          error => {
            this.alertService.error(error.error.error);
            this.router.navigate(['/']);
          });
    });

  }
  ngOnDestroy() {
    this.activationSubscription.unsubscribe();
  }

}
