import {Component, OnDestroy, OnInit} from '@angular/core';
import { AuthenticationService} from '../../module-user/services/authentication.service';
import {Observable, Subscription} from 'rxjs';
import { CategoryService } from '../../module-entity/services/category.service';
import { AlertService } from '../alert/alert.service';

import { Profile } from '../../module-user/models/profile';
import { UserService } from '../../module-user/services/user.service';
import { SearchService } from '../../module-search/services/search.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ConstantsService } from '../services/constants.service';
import { City } from '../../module-address/models/city';
import { Category } from '../../module-entity/models/category';
import { map, startWith } from 'rxjs/operators';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  categorySubscription: Subscription;
  jwtSubscription: Subscription; // assign subscription to the jwt in order to show if the user is authenticated
  // profile
  userProfile: Profile = null; // the actual user profile from backend



  categories: Array<Category>;
  cities: Array<City>;

  filteredCities: Observable<Array<City>>;

  searchForm: FormGroup;

  stateCtrl = new FormControl();
  availableLanguages: any;


  constructor(private authenticationService: AuthenticationService,
              private categoryService: CategoryService,
              private alertService: AlertService,
              private userService: UserService,
              private searchService: SearchService,
              private formBuilder: FormBuilder,
              private router: Router,
              private constantsService: ConstantsService
  ) {}
  private _filterCities(value: string): City[] {
    const filterValue = value.toLowerCase();

    return this.cities.filter(city => city.name.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit(): void {


    this.searchForm = this.formBuilder.group({
      keyword: ['', []],
      city: ['', []]
    });

    this.filteredCities = this.formControls.city.valueChanges
      .pipe(
        startWith(''),
        map(city => city ? this._filterCities(city) : this.cities.slice())
      );

    if ( this.constantsService.currentConstants === null || this.constantsService.currentConstants === undefined){
      this.constantsService.getConstants().subscribe(response => {
        this.constantsService.constants = response.body.data;
        this.cities = response.body.data.city;
      }, error => console.log(error));
    }else{
      this.cities = this.constantsService.currentConstants.body.data.city;
    }

    // check if the user is authenticated by checking the current jwt from the local storage
    this.jwtSubscription = this.authenticationService.currentJWTTokenSubject.subscribe(token => {
      if (token != null ){

          this.userService.getUserProfile().subscribe(
          response => {
            this.authenticationService.setCurrentUser(response.body.data);
          }, error => this.alertService.error(error.message));
      }
    });
    this.authenticationService.currentUserSubject.subscribe(userProfile => this.userProfile = userProfile);

    // Load the categories from the current subject
    if ( this.categoryService.currentCategories === null ){
      // Getting the current categories. If they are already loaded we get them from the service subject
      this.categorySubscription = this.categoryService.getCategories().subscribe(response => {
        this.categoryService.category = response.body.data;
        this.categories = response.body.data;
      }, error => console.error(error) );
    }else{
      // categories are already loaded by a different component
      this.categories = this.categoryService.currentCategories;
    }

  }

  // convenience getter for easy access to form fields
  get formControls() { return this.searchForm.controls; }



  refresh(): void {
    window.location.reload();
  }

  getDealsAroundLocation(): void
  {
    navigator.geolocation.getCurrentPosition(resp => {
        this.alertService.clear();
        const radius = 50;
        this.searchService.dealsAroundMeLocation.next({
          latitude: resp.coords.latitude.toString(),
          longitude: resp.coords.longitude.toString(),
          radius: radius.toString()
        });
        this.router.navigate(['/']);
        window.scrollTo({ top: 800, behavior: 'smooth'});
    });
  }

  onSearchSubmit(){
      this.searchService.masterSearch.next({
        keyword: this.formControls.keyword.value,
        city: this.formControls.city.value
      });
      window.scrollTo({ top: 800, behavior: 'smooth' });
      this.router.navigate(['/']);
  }


  

  searchEntityClass(entityClassName){
    this.router.navigate(['/']);
    setTimeout(() => this.searchService.currentEntityClassSearch = entityClassName, 50);
  }

  ngOnDestroy() {
    this.jwtSubscription.unsubscribe();
    this.categorySubscription.unsubscribe();
  }
}
