<h1 mat-dialog-title class="text-center">
  {{'paymentComponent.title' | translate }}
</h1>
<h3 mat-dialog-title class="text-center text-success" *ngIf="userData.message !== ''">{{userData.message}}</h3>
<h3 mat-dialog-title class="text-center text-danger" *ngIf="stripeErrorMessage">{{stripeErrorMessage}}</h3>

<div class="mobile-menu-second">

  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="">
          <div class=" text-center">
            <p>{{userData.plan.plan_name}}</p>
<!--            {{userData.plan.interval_count}} x-->
            <p *ngIf="userData.discount === null"> {{'paymentComponent.amountToBePaid' | translate }} {{userData.plan.current_price}} € /  {{userData.plan.interval.interval_name}}</p>
<!--            {{userData.plan.currency}}-->
            <p *ngIf="userData.discount" class="text-success">
              {{'paymentComponent.activeVoucherFor' | translate }}  {{userData.discount}} %
            </p>
            <p *ngIf="userData.discount !== null">
              {{'paymentComponent.amountToBePaid' | translate }} <s>{{userData.plan.current_price}}</s> {{ userData.plan.current_price - (userData.plan.current_price * userData.discount)/100 }}
              {{userData.plan.currency}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <form novalidate (ngSubmit)="createToken()" [formGroup]="stripeForm">
          <input type="text"
                 formControlName="name"
                 class="form-control mb-2"
                 placeholder="{{ 'paymentComponent.inputFields.name.placeholder' | translate }}">
          <br />
          <ngx-stripe-card
            [options]="cardOptions"
            [elementsOptions]="elementsOptions"
          ></ngx-stripe-card>
          <br />
          <div class="d-flex justify-content-center">
            <button type="submit" class="btn btn-primary mt-2 text-center">
              <img src="assets/gifs/loading.gif"  alt="loading" style="width: 20px;" *ngIf="loading" />
              {{'paymentComponent.buttons.pay' | translate }}
            </button>
          </div>

        </form>
      </div>
    </div>
  </div>

</div>
