import { Injectable } from '@angular/core';
import { FormFieldBase } from '../models/forms/base/form-field-base';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormControlService {
  constructor() { }

  toFormGroup(formAttributes: FormFieldBase<any>[]){
    const group: any = {};
    formAttributes.forEach( attr => {
      group[attr.key] = attr.required ? new FormControl(attr.value || '', Validators.required) :
        new FormControl(attr.value || '');
    });
    return new FormGroup(group);
  }
}
