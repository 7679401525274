import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-entity-create',
  templateUrl: './entity-create.component.html',
})
export class EntityCreateComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
