<div class="step-tab-panel">
  <div class="tab-from-content">
    <!-- <div class="title-icon"> -->
      <div class="">
      <h1 class="title rubik fw-600" style="text-align: center;">
        <i class=""></i>
        {{'entityComponent.addEntity.steps.properties.subtitle' | translate }}
      </h1>
    </div>
    <form  (ngSubmit)="onSubmit()" [formGroup]="form" *ngIf="form">
      <!-- NORMAL INPUTS -->


        <div *ngFor="let attr of entityFormBase; let i = index" class="text-center ml-auto">


          <ng-container *ngIf="attr.key === 'title'">
            <div class="col-lg-12 form-group p-relative col-lg-12" *ngIf="!attr.key.includes('-')">
              <div class="col-lg-12">
                <h3 class="title rubik fw-600"  style="text-align: left;">{{'entityComponent.addEntity.steps.properties.inputFields.title' | translate }}</h3>
              </div>
              <input  [formControlName]="attr.key" class="form-control" [id]="attr.key" [type]="attr.type">
              <div class="errorMessage text-danger" *ngIf="!form.controls.title.valid">{{'entityComponent.addEntity.steps.properties.inputFields.title' | translate }} {{'errors.req' | translate }}</div>
            </div>
          </ng-container>
          <ng-container *ngIf="attr.key === 'description'">
            <div class="col-lg-12 form-group p-relative col-lg-12 " *ngIf="!attr.key.includes('-')">
              <div class="col-lg-12">
                <h3 class="title rubik fw-600" style=" text-align: left;">{{'entityComponent.addEntity.steps.properties.inputFields.description' | translate }}</h3>
              </div>
              <textarea  [formControlName]="attr.key" class="form-control" style="height: 200px;
              resize: both;" [id]="attr.key" rows="5"></textarea>
              <div class="errorMessage text-danger hight-500 " *ngIf="!form.controls.description.valid">{{'entityComponent.addEntity.steps.properties.inputFields.description' | translate }} {{'errors.req' | translate }}</div>
            </div>
          </ng-container>
          <ng-container *ngIf="attr.key === 'price'">
            <div class="col-lg-12 form-group p-relative col-lg-12 " *ngIf="!attr.key.includes('-')">
              <div class="col-lg-12">
                <h3 class="title rubik fw-600" style=" text-align: left;">{{'entityComponent.addEntity.steps.properties.inputFields.price' | translate }}</h3>
              
              </div>
              <input  [formControlName]="attr.key" class="form-control" [id]="attr.key" [type]="attr.type">
              <div class="errorMessage text-danger" *ngIf="!form.controls.price.valid">{{'entityComponent.addEntity.steps.properties.inputFields.price' | translate }} {{'errors.req' | translate }}</div>
            </div>
          </ng-container>

          <ng-container  *ngIf="attr.key != 'title'  && attr.key != 'description'  && attr.key != 'price'">
            <div class="col-lg-6 float-left" *ngIf="!attr.key.includes('-')">
              <app-dynamic-form [formField]="attr" [form]="form"></app-dynamic-form>
            </div>
          </ng-container>

        </div>





      <div class="clearfix"></div>
      <!-- FORM GROUPS -->
      <div *ngFor="let fieldParent of groupOfForm let idx = index"  >
        <div class="title-icon col-lg-12">
          <h3 class="title rubik fw-600" style="font-weight: bold;">
            <i class=""></i>
            {{ fieldParent.name }}
          </h3>
        </div>



        <div *ngFor="let attr of entityFormBase; let i = index"  class="text-center ml-auto">
          <div class="col-lg-4 float-left" *ngIf="attr.key.includes(fieldParent.id + '-')">
            <ng-container *ngIf="attr.type === 'radio'">
              <!--For a radio, there is need of a parent ID.-->
              <app-dynamic-form [formField]="attr" [form]="form" [radioKey]="fieldParent.id"></app-dynamic-form>
            </ng-container>
            <ng-container *ngIf="attr.type !== 'radio'">
              <app-dynamic-form [formField]="attr" [form]="form"></app-dynamic-form>
            </ng-container>

          </div>
        </div>

        <div class="clearfix"></div>
      </div>


      <div class="title-icon col-lg-12">
        <h3 class="title rubik fw-600" style="font-weight: bold;">
          <i class=""></i>
          {{'entityComponent.addEntity.steps.properties.inputFields.coverImage.title' | translate }}
        </h3>
      </div>

      <div class="d-flex justify-content-center mb-3">
        <div *ngIf='coverImage' class="col-lg-3">
          <img  [src]="coverImage"  style="margin: 3px;">
        </div>
      </div>

      <div class="custom-file">
        <input type="file" (change)="onFileChange($event)" class="custom-file-input" id="validatedCustomFile" required>
        <label class="custom-file-label" for="validatedCustomFile">
          {{'entityComponent.addEntity.steps.properties.inputFields.coverImage.label' | translate }}
        </label>
        <div class="invalid-feedback">
          {{'entityComponent.addEntity.steps.properties.inputFields.coverImage.errors.invalid' | translate }}
        </div>
      </div>

      <div class="step-footer step-tab-pager justify-content-center" >
        <input type="submit" [disabled]="!form.valid" class="btn-second blue-btn" style="width: 200px;"
               value="{{'entityComponent.addEntity.buttons.next' | translate }}"/>
      </div>

    </form>

  </div>
</div>

