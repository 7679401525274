<!-- Privacy template -->
<section class="section-padding faqs">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-header">
          <div class="text-wrapper text-center">
            <h2 class="fw-500 no-margin font-weight-bolder">
              {{'cookiePage.tittle' | translate }}
            </h2>
            <span class="line-grey"></span><span class="line-blue"></span><span class="line-grey"></span>
          </div>
        </div>
      </div>
     <div class="col-md-12">
        <div class="section-header">
          <div class="text-wrapper text-center">
            <h4 class="fw-500 no-margin font-weight-bolder">
              {{'cookiePage.sub1' | translate }}
            </h4>
            <p class="text-dark-grey">
              {{'cookiePage.how1' | translate }}
            </p>
            <p class="text-dark-grey">
              {{'cookiePage.how2' | translate }}
            </p>
            <p class="text-dark-grey">
              {{'cookiePage.how3' | translate }}
            </p>
          </div>
        </div>
      </div>
    <div class="col-md-12">
        <div class="section-header">
          <div class="text-wrapper text-center">
            <h4 class="fw-500 no-margin font-weight-bolder">
              {{'cookiePage.sub2' | translate }}
            </h4>
            <p class="text-dark-grey">
              {{'cookiePage.type' | translate }}
            </p>
          </div>
        </div>
      </div>
    <div class="col-md-12">
        <div class="section-header">
          <div class="text-wrapper text-center">
            <h4 class="fw-500 no-margin font-weight-bolder">
              {{'cookiePage.sub4' | translate }}
            </h4>
            <p class="text-dark-grey">
              {{'cookiePage.func1' | translate }}
            </p>
            <p class="text-dark-grey">
              {{'cookiePage.func2' | translate }}
            </p>
            <p class="text-dark-grey">
              {{'cookiePage.func3' | translate }}
            </p>
            <p class="text-dark-grey">
              {{'cookiePage.func4' | translate }}
            </p>
          </div>
        </div>
      </div>
    <div class="col-md-12">
        <div class="section-header">
          <div class="text-wrapper text-center">
            <h4 class="fw-500 no-margin font-weight-bolder">
              {{'cookiePage.securityTitle' | translate }}
            </h4>
            <p class="text-dark-grey">
              {{'cookiePage.security1' | translate }}
            </p>
             <p class="text-dark-grey">
               {{'cookiePage.security2' | translate }}
            </p>
             <p class="text-dark-grey">
               {{'cookiePage.security3' | translate }}
            </p>
          </div>
        </div>
      </div>
    <div class="col-md-12">
        <div class="section-header">
          <div class="text-wrapper text-center">
            <h4 class="fw-500 no-margin font-weight-bolder">
              {{'cookiePage.analyticsTitle' | translate }}
            </h4>
            <p class="text-dark-grey">
              {{'cookiePage.analytics1' | translate }}
            </p>
             <p class="text-dark-grey">
               {{'cookiePage.analytics2' | translate }}
            </p>
             <p class="text-dark-grey">
               {{'cookiePage.analytics3' | translate }}
            </p>
          </div>
        </div>
      </div>
    <div class="col-md-12">
        <div class="section-header">
          <div class="text-wrapper text-center">
            <h4 class="fw-500 no-margin font-weight-bolder">
              {{'cookiePage.personalizationTitle' | translate }}
            </h4>
            <p class="text-dark-grey">
              {{'cookiePage.personalisation1' | translate }}
            </p>
             <p class="text-dark-grey">
               {{'cookiePage.personalisation2' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
        <div class="section-header">
          <div class="text-wrapper text-center">
            <h4 class="fw-500 no-margin font-weight-bolder">
              {{'cookiePage.sub3' | translate }}
            </h4>
            <p class="text-dark-grey">
              {{'cookiePage.managing' | translate }}
            </p>
          </div>
        </div>
      </div>
  </div>
</section>
