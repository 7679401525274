import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../core/alert/alert.service';
import { UserService } from '../../services/user.service';
import { FieldsValidators } from '../../validators/fields-validators';
import { PasswordChange } from '../../models/passwordChange';


@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
})
export class PasswordChangeComponent implements OnInit {
  @Input() isMobileScreen=false;
  passwordChangeForm: FormGroup;
  loading    = false;
  submitted  = false;

  fieldTextType: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private alertService: AlertService,
              private userService: UserService) { }

  ngOnInit(): void {
    this.passwordChangeForm = this.formBuilder.group({
      oldPassword: [null, [Validators.required, ]],
      password:  [null, [Validators.compose([
        // 1. Password Field is Required
        Validators.required,
        // 2. check whether the entered password has a number
        FieldsValidators.patternValidator(/\d/, { hasNumber: true }),
        // 3. check whether the entered password has upper case letter
        FieldsValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        // 4. check whether the entered password has a lower-case letter
        FieldsValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        // 5. Has a minimum length of 8 characters
        Validators.minLength(8)

      ])]],
      password1:  [null, Validators.compose([Validators.required])]
    }, {
      // check whether our password and confirm password match
      validator: FieldsValidators.passwordMatchValidator
    });

  }


  // convenience getter for easy access to form fields
  get form() { return this.passwordChangeForm.controls; }



  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.passwordChangeForm.invalid) {
      return;
    }

    // stop here if the password don't match
    if (this.form.password.value !== this.form.password1.value){
      return;
    }

    this.loading = true;
    this.route.params.subscribe(params => {
      this.userService.passwordChange(
        new PasswordChange(
          this.form.oldPassword.value,
          this.form.password.value,
          this.form.password1.value
      ))
        .subscribe(
          response => {
            // response.body
            this.alertService.success(response.body.message, true);
            this.router.navigate(['/']);
            this.loading = false;
          },
          errResponse => {
            console.log(errResponse);
            this.setErrors(errResponse.error.error);
            this.loading = false;
          });
    });
  }

  private setErrors(error: any){
    if (error.old_password) { this.form.oldPassword.setErrors({backendError: error.old_password.old_password }); }
    if (error.password) { this.form.password.setErrors({backendError: error.password}); }
    if (error.password1) { this.form.password1.setErrors({backendError: error.password1}); }
  }

}
