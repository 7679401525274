<div class="tab-pane">
  <h2 class="pane-heading" [ngClass]="{'mt-3':isMobileScreen}">
    {{'subscriptionsComponent.subscriptionsTab.title' | translate }}
  </h2>
  <ng-container *ngIf="currentUser && subscribedPlans.length === 0 && allPlans.length > 0">
    <!-- Show the subtitle only if the user doesn't have a subscription -->
    <p>{{ 'pricingPage.subtitle' | translate }}</p>
  </ng-container>
  <div class="tab-pane-content subscription" *ngIf="currentUser && subscribedPlans.length > 0">
    <div *ngFor="let plan of subscribedPlans" class="mt-2">
      <div class="card">
        <div class="card-body">
          <h3 class="card-title">{{ plan.plan_name}}</h3>
          <div class="price">
            <h1 class="amount">{{plan.currency=='EUR' ? '€':'£'}}{{plan.current_price}}</h1>
            <p class="card-text period">/{{ plan.interval?.interval_name}}</p>
          </div>
          <ul>
            <li class="d-flex" *ngFor="let feature of plan?.options">
              <span>
                <i class="fa fa-check"></i>
              </span>
              <span>{{feature?.option_value}} {{ feature?.option_name}}</span>
            </li>
          </ul>
        </div>
        <div class="card-footer">
          <button class="btn btn-light subscribed" disabled>
            Subscribed
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane-content subscription" *ngIf="currentUser && subscribedPlans.length === 0 && allPlans.length > 0">
    <div *ngFor="let plan of allPlans" class="mt-2">
      <div class="card">
        <div class="card-body">
          <h3 class="card-title">{{ plan.plan_name}}</h3>
          <div class="price">
            <h1 class="amount">{{plan.currency=='EUR' ? '€':'£'}}{{plan.current_price}}</h1>
            <p class="card-text period">/{{ plan.interval?.interval_name}}</p>
          </div>
          <ul>
            <li class="d-flex" *ngFor="let feature of plan?.options">
              <span>
                <i class="fa fa-check"></i>
              </span>
              <span>{{feature?.option_value}} {{ feature?.option_name}}</span>
            </li>
          </ul>
        </div>
        <div class="card-footer">
          <button class="btn btn-light" (click)="openVoucher(plan)">
            Get Started
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
