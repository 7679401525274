<div class="mobile-menu-second" *ngIf="subscriptions">
  <!-- listing -->
  <section class="section-padding listing-with-map listing-with-map-3"  style="min-height: 600px;">
    <div class="container">
      <!-- Sub header -->
      <div class="row" >
        <div class="col-md-12">
          <div class="section-header">
            <div class="text-wrapper text-center">
              <h2 class="fw-500 no-margin font-weight-bolder">
                {{'subscriptionsComponent.subscriptionListingPage.title' | translate }}
              </h2>
              <span class="line-grey"></span><span class="line-blue"></span><span class="line-grey"></span>
            </div>
          </div>
        </div>
      </div>


      <div class="alert alert-warning text-center" role="alert" *ngIf="subscriptions.length === 0">
        <span>
          {{'subscriptionsComponent.subscriptionListingPage.noSubscriptionMessage' | translate }}
        </span>
      </div>

      <ng-container *ngIf="!loading">
        <div class="row" *ngIf="subscriptions.length !== 0">
          <div class="col-lg-12 ">

            <div class="d-flex justify-content-center" *ngFor="let subscription of subscriptions">
              <div class="card mt-2" style="width: 26rem; height: 13rem;" >
                <div class="card-body ">
                  <h2 class="card-title font-weight-bolder text-center">{{subscription.plan.plan_name}}</h2>
                  <table class="m-auto w-100 table-borderless" cellspacing="0" cellpadding="0">
                    <tr>
                      <td>{{'subscriptionsComponent.subscriptionListingPage.subscriptionTable.state' | translate }}:</td>
                      <td class="float-right">{{subscription.state.state_name}}</td>
                    </tr>
                    <tr>
                      <td>{{'subscriptionsComponent.subscriptionListingPage.subscriptionTable.price' | translate }}:</td>
                      <td class="float-right">{{subscription.plan.current_price}} {{subscription.plan.currency}}</td>
                    </tr>
                    <tr>
                      <td> {{'subscriptionsComponent.subscriptionListingPage.subscriptionTable.subscribed' | translate }}:</td>
                      <td class="float-right">{{subscription.date_subscribed | date:'dd-MM-yyyy HH:mm:ss'}}</td>
                    </tr>
                    <tr>
                      <td>{{'subscriptionsComponent.subscriptionListingPage.subscriptionTable.validTo' | translate }}:</td>
                      <td class="float-right">{{subscription.valid_to | date:'dd-MM-yyyy HH:mm:ss'}}</td>
                    </tr>
                  </table>
                  <div id="management-bttns" class="mt-2">
                    <ng-container *ngIf="subscription.state.state_code === 'COMPLETED'">
                      <div class="d-flex justify-content-center">

                        <ng-container *ngIf="subscription.plan.subscription_type !== 'web' ">
                          <a class="mr-4" href="javascript:void(0);" (click)="sendInvoiceToCustomer(subscription.ui)">
                            <i class="fas fa-file-invoice fa-2x item-links"></i>
                          </a>
                        </ng-container>
                        <ng-container *ngIf="subscription.plan.subscription_type === 'web' ">
                          <a class="m-1" target="_blank"  href="{{ subscription.stripe_user_invoice_link.hosted_invoice_url}}"
                             title="{{'subscriptionsComponent.subscriptionListingPage.toolPits.getInvoice' | translate }}">
                            <i class="fas fa-file-invoice fa-2x item-links"></i>
                          </a>
                        </ng-container>

                        <a class="ml-4" href="javascript:void(0);" (click)="toggleSubscriptionState(subscription.state.state_code, subscription.ui)"
                           title="{{'subscriptionsComponent.subscriptionListingPage.toolPits.cancel' | translate }}">
                          <i class="fas fa-money-bill-wave fa-2x item-links"></i>
                        </a>
                      </div>

                    </ng-container>
                    <ng-container *ngIf="subscription.state.state_code !== 'COMPLETED' && subscription.state.state_code !== 'CANCELED'">
                      <div class="d-flex justify-content-center">

                      </div>
                    </ng-container>
                    <ng-container *ngIf="subscription.state.state_code === 'CANCELED'">
                      <div class="d-flex justify-content-center ">
                        <ng-container *ngIf="subscription.plan.subscription_type !== 'web' ">
                          <a class="mr-4" href="javascript:void(0);" (click)="sendInvoiceToCustomer(subscription.ui)">
                            <i class="fas fa-file-invoice fa-2x  item-links"></i>
                          </a>
                        </ng-container>
                        <ng-container *ngIf="subscription.plan.subscription_type === 'web' ">
                          <a class="m-1s" target="_blank"  href="{{ subscription.stripe_user_invoice_link.hosted_invoice_url}}"
                            
                            title="{{'subscriptionsComponent.subscriptionListingPage.toolPits.getInvoice' | translate }}">
                            <i class="fas fa-file-invoice fa-2x  item-links"></i>
                          </a>
                        </ng-container>
                        <a class="m-1 " href="javascript:void(0);" (click)="toggleSubscriptionState(subscription.state.state_code, subscription.ui)"
                           title="{{'subscriptionsComponent.subscriptionListingPage.toolPits.reactivate' | translate }}">
                          <i class="fas fa-play fa-2x  item-links"></i>
                        </a>
                      </div>
                    </ng-container>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>

        <div class="col-12" *ngIf="totalPages.length > 1 && subscriptions.length !== 0">
          <nav class="custom-pagination" >
            <ul class="pagination">
              <li class="page-item " *ngIf="previousLink">
                <a class="page-link" href="javascript:void(0)" (click)="goToPage(currentPage - 1)">
                  {{'pagination.previous' | translate }}
                </a>
              </li>
              <li class="page-item" *ngFor="let page of totalPages" [ngClass]="{'active' : currentPage==page}">
                <a class="page-link" href="javascript:void(0)" (click)="goToPage(page)">{{ page }}</a>
              </li>
              <li class="page-item" *ngIf="nextLink">
                <a class="page-link" href="javascript:void(0)" (click)="goToPage(currentPage + 1)">
                  {{'pagination.next' | translate }}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </ng-container>

      <div class="ball-container" *ngIf="loading">
        <div class="ball ball-one"></div>
        <div class="shadow shadow-one"></div>
      </div>

    </div>
  </section>



</div>
