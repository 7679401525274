import {Component, OnDestroy, OnInit} from '@angular/core';
import { SubscriptionService } from '../services/subscription.service';
import { PlanModel } from '../models/planModel';
import { MatDialog } from '@angular/material/dialog';
import {PaymentComponent} from '../../module-payment/payment/payment.component';
import {AlertService} from '../../core/alert/alert.service';
import {AuthenticationService} from '../../module-user/services/authentication.service';
import {Profile} from '../../module-user/models/profile';
import {Subscription} from 'rxjs';
import {VoucherComponent} from '../voucher/voucher.component';
import { TranslateService } from '@ngx-translate/core';
import {CookieService} from '../../core/services/cookie.service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
})
export class PricingComponent implements OnInit, OnDestroy {
  // Used to show the loading gif
  loading = false;

  userProfileSubscription: Subscription;
  currentPlans: Array<PlanModel> = [];

  currentUser: Profile = null;
  alreadySubscribedText = '';
  chosenLanguage = 'en';

  constructor(private subscriptionService: SubscriptionService,
              public dialog: MatDialog,
              private alertService: AlertService,
              private authenticationService: AuthenticationService,
              translate: TranslateService,
              private cookieService: CookieService,
              private route: Router) {
     this.chosenLanguage = this.cookieService.LanguageCookie;
    // Getting translation for selection
     translate.get('errors.alreadySubscribed').subscribe((res: string) => {
      this.alreadySubscribedText = res;
    });
  }

  ngOnInit(): void {
    this.subscriptionService.getSubscriptionPlans().subscribe(response =>
      response.body.data.forEach(plan => {
        if (plan.subscription_type === 'web'){
          this.currentPlans.push(plan);
        }
      }),
        errorResponse => console.error(errorResponse));

    this.userProfileSubscription = this.authenticationService.currentUserSubject.subscribe(
      userProfile => this.currentUser = userProfile);
  }

  openVoucher(plan: PlanModel) {

    if (this.currentUser.has_valid_subscription === true){
      this.alertService.error(this.alreadySubscribedText);
    } else {
      const dialogRef = this.dialog.open(VoucherComponent, {
        maxWidth: '450px',
        width: '100%',
      });
      dialogRef.afterClosed().subscribe(message => {
        this.openPayment(plan, message);
      });
    }
  }

  openPayment(plan: PlanModel, message: string): void {
    // console.log(plan);

    // starts subscription payment flow by setting up de subscription in the backend
    this.subscriptionService.setupSubscription(plan.ui).subscribe(response => {
      const dialogRef = this.dialog.open(PaymentComponent, {
        maxWidth: '450px',
        width: '100%',
        data: {
          discount: response.body.data.voucher_discount,
          message: `${message}`,
          plan,
          priceId: response.body.data.price_id,
          subscriberCustomerId: response.body.data.subscriber_customer_id,
          userSubscriptionUI: response.body.data.user_subscription_ui
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        // console.log('The dialog was closed');
        // console.log(result);
      });

    }, errorResponse => {
      // console.log(errorResponse);
      this.alertService.error(errorResponse.error.error);
      // in case of address not set, error 406 is returned, the user is redirected to the address page
      if (errorResponse.status === 406){
        this.route.navigate(['/user', {showAddress: true}]);
      }

    });

  }
  ngOnDestroy() { this.userProfileSubscription.unsubscribe(); }

}
