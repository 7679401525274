import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalLoadingService {
  globalLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }

  get globalLoadingValue(){
    return this.globalLoading.value;
  }

  set globalLoadingValue(val: boolean){
    this.globalLoading.next(val);
  }

}
