import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthenticationService} from '../../module-user/services/authentication.service';
import { Router } from '@angular/router';
import { JWTToken } from '../../module-user/models/jwt-token';
import { CookieService } from '../services/cookie.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /* This class intercepts the http requests that come to the frontend and it
   * sets the authorization header to each in order to pass the JWT  authorization on the backend */

  private refreshingInProgress: boolean;
  private accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  constructor(private authenticationService: AuthenticationService, private router: Router, private cookieService: CookieService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentToken = this.authenticationService.currentTokenValue;
    // todo to handle all of the static requests that don't need authorization token in case of a logout
    // this is handled by refreshing the page now, but this should be changed.

    return next.handle(this.addAuthorizationAndLanguage(request, currentToken)).pipe(
      catchError(err => {
        // in case of 401 http error
        if (err instanceof HttpErrorResponse && err.status === 401) {
          // get refresh tokens

          // if there are tokens then send refresh token request
          if (currentToken.refreshToken && currentToken.jwtToken) {
            return this.refreshToken(request, next);
          }

          // otherwise logout and redirect to login page
          return this.logoutAndRedirect(err);
        }

        // in case of 403 http error (refresh token failed)
        if (err instanceof HttpErrorResponse && err.status === 403) {
          // logout and redirect to login page
          if (request.url.includes('api/v1/language') === true || request.url.includes('api/v1/constant')){
            // if the constants were not loaded properly reload the page
            window.location.reload();
          }
          return this.logoutAndRedirect(err);
        }
        // if error has status neither 401 nor 403 then just return this error
        return throwError(err);
      })
    );

  }
  private addAuthorizationAndLanguage(request: HttpRequest<any>, jwtToken: JWTToken): HttpRequest<any> {
    if (jwtToken) {
      request = request.clone({
        setHeaders: {
          SwapAppAuth: `Authorization ${jwtToken.jwtToken}`
        }
      });
    }
    request = request.clone({
      setHeaders: {
        'Accept-Language': this.cookieService.LanguageCookie
      }
    });
    return request;
  }
  private logoutAndRedirect(err): Observable<HttpEvent<any>> {
    this.authenticationService.logout();
    this.router.navigateByUrl('/');
    err.message = err.error.error;
    return throwError(err);
  }
  private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.refreshingInProgress) {
      this.refreshingInProgress = true;
      this.accessTokenSubject.next(null);
      return this.authenticationService.refreshToken().pipe(
        switchMap((res) => {
          this.refreshingInProgress = false;
          this.accessTokenSubject.next(res.access);
          // repeat failed request with new token
          const newToken = this.authenticationService.currentTokenValue;
          return next.handle(this.addAuthorizationAndLanguage(request, new JWTToken(res.access, newToken.refreshToken)));
        })
      );
    } else {

      // wait while getting new token
      return this.accessTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => {
          // repeat failed request with new token
          const newToken = this.authenticationService.currentTokenValue;
          return next.handle(this.addAuthorizationAndLanguage(request, new JWTToken(token, newToken.refreshToken)));
        }));
    }
  }
}
