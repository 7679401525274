import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-add-confirmation',
  templateUrl: './add-confirmation.component.html',
})
export class AddConfirmationComponent implements OnInit {
  @Output() currentComponent: EventEmitter<any> = new EventEmitter<any>();

  formSubmitted = false;

  addConfirmation: FormGroup;
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.addConfirmation = this.formBuilder.group({
      policyConfirmation: [null, [Validators.requiredTrue]],
      termsConfirmation: [null, [Validators.requiredTrue]],
    });
  }

  // convenience getter for easy access to form fields
  get addConfirmationFormControls() { return this.addConfirmation.controls; }

  onSubmit(){
    this.formSubmitted = true;

    // stop here if form is invalid
    if (this.addConfirmationFormControls.invalid) {
      return;
    }
    if (this.addConfirmationFormControls.policyConfirmation.value !== true ||
      this.addConfirmationFormControls.termsConfirmation.value !== true){
      return;
    }
    // change the screen
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.currentComponent.emit('saveItem');

  }

}
