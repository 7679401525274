
  <div [formGroup]="form" class="w-100 text-center" *ngIf="!formField.key.includes('-parent')" >
    <div class="w-100">
      <h3 class="title rubik fw-600" style="text-align: left;">{{formField.label}}</h3>
    </div>
    <div [ngSwitch]="formField.controlType" class="form-group p-relative w-100 text-center" style="height: 70px;">

      <input *ngSwitchCase="'textbox'" [formControlName]="formField.key" class="form-control"
             [id]="formField.key" [type]="formField.type">

      <mat-form-field style="width: 100%;" *ngSwitchCase="'dropdown'"  >
        <mat-label> {{'buttons.select' | translate }}  {{formField.label.toLowerCase()}}</mat-label>
        <mat-select  id="entityClass-field"
                     formControlName="{{formField.key}}"
                     panelClass="example-panel-blue">
          <mat-option *ngFor="let opt of formField.options" [value]="opt.key">
            {{opt.value}}
          </mat-option>
        </mat-select>
        <mat-error >{{'errors.required' | translate }}</mat-error>
      </mat-form-field>

      <input  *ngSwitchCase="'file'" [formControlName]="formField.key" class="form-control"
             [id]="formField.key" [type]="formField.type">

      <input  *ngSwitchCase="'number'" [formControlName]="formField.key" class="form-control"
              [id]="formField.key"
              [type]="formField.type"
              [min]="formField.min"
              [step]="formField.step"
              [max]="formField.max">

      <mat-checkbox   *ngSwitchCase="'CheckBox'"
                     [id]="formField.key"
                     [formControlName]="formField.key"></mat-checkbox>


      <ng-container *ngIf="radioKey">
        <input  *ngSwitchCase="'radio'"
                formControlName="9-parent"
                [id]="formField.key"
                [value]="formField.key"
                type="radio" >
      </ng-container>

      <input  *ngSwitchCase="'date'"
              [formControlName]="formField.key"
              [type]="formField.type"
              class="form-control form-control-custom">

      <div class="errorMessage text-danger" *ngIf="!isValid">{{formField.label}} {{'errors.req' | translate }}</div>
    </div>
  </div>




