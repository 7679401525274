import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SearchResponse } from './responses/searchResponse';

import { BehaviorSubject, Subject } from 'rxjs';
import { EntityClass } from '../../module-entity/models/entityClass';




@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private apiBaseURL = environment.configSettings.apiURL;

  entityClassSearch: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  mySubject = new Subject<any>();
  matchUnmatchBoolean = new Subject<boolean>();
  public masterSearch: BehaviorSubject<{
    keyword: string,
    city: string
  }> = new BehaviorSubject<{keyword: string; city: string}>(null);

  public dealsAroundMeLocation: BehaviorSubject<{latitude: string, longitude: string, radius: string}> =
    new BehaviorSubject<{latitude: string, longitude: string, radius: string}>(null);



  constructor(private http: HttpClient) { }

  set currentEntityClassSearch(entityClassName: string){
    this.entityClassSearch.next(entityClassName);
  }

  getUserEntities(page?: number) {
    if (page) {
      return this.http.get<SearchResponse>(`${this.apiBaseURL}v1/search/entity/?page=${page}`, {observe: 'response'});
    }
    return this.http.get<SearchResponse>(`${this.apiBaseURL}v1/search/entity/`, { observe: 'response' });
  }
  doMasterSearch(keyword: string, city?: string){
    let query = '';
    if (keyword !== '' && keyword !== undefined){
      query += `?search=${keyword}`;
    }
    if (city !== undefined && city !== ''){
      if (query !== ''){query += `&city=${city}`; } else {query += `?city=${city}`; }
    }
    // console.log(query);

    return this.http.get<SearchResponse>(`${this.apiBaseURL}v1/search/entity/${query}`, { observe: 'response' });
  }

  doSearch(keyword?: string, categoryName?: EntityClass, city?: { radius: string, lat: string, lon: string }, country?: string,
           ordering?: string, attributesFilter?: Map<string, {gte: any, lte: any, exact: any}>){
    let query = '';

    // console.log(`country searched for ${country}`);

    if (keyword !== '' && keyword !== null){ query += `?search=${keyword}`; }
    if ( categoryName !== null){
      if (query !== ''){query += `&entity_class=${categoryName.slug}`; }
      else {query += `?entity_class=${categoryName.slug}`; }
    }
    if (city !== null){

      let radiusKm = city.radius;
      if (radiusKm === undefined || radiusKm == null || radiusKm === '' ){ radiusKm = '1'; }
      if (query !== ''){query += `&location__geo_distance=${radiusKm}km__${city.lat}__${city.lon}`; }
      else{query += `?location__geo_distance=${radiusKm}km__${city.lat}__${city.lon}`; }
    } else if (country !== null){
      if (query !== ''){query += `&country=${country}`; }
      else{query += `?country=${country}`; }
    }

    if ( attributesFilter !== undefined && attributesFilter !== null ) {
        for (const attributeCode of Array.from(attributesFilter.keys())) {
          const attrValues = attributesFilter.get(attributeCode);
          if (attrValues.exact === null || attrValues.exact === undefined) {
            if (attrValues.gte !== null) {
              if (query !== '') { query += `&${attributeCode}__gte=${attrValues.gte}`; }
              else { query += `?${attributeCode}__gte=${attrValues.gte}`; }
            }
            if (attrValues.lte !== null) {
              if (query !== '') {query += `&${attributeCode}__lte=${attrValues.lte}`; }
              else { query += `?${attributeCode}__lte=${attrValues.lte}`; }
            }
          } else {
            // All of the spaces need to be replaced with _
            const attrValue = attrValues.exact.replaceAll(' ', '_');
            if (query !== '') {query += `&${attributeCode}=${attrValue}`; }
            else {query += `?${attributeCode}=${attrValue}`; }
          }
        }
    }

    if ( ordering !== null){
      if (query !== ''){query += `&ordering=${ordering}`; } else {query += `?ordering=${ordering}`; }
    }

    return this.http.get<SearchResponse>(`${this.apiBaseURL}v1/search/entity/${query}`, { observe: 'response' });
  }
}
