import { Component, Input, OnInit} from '@angular/core';
import { Address} from '../../../module-address/models/address';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AlertService } from '../../../core/alert/alert.service';
import { AddressService } from '../../../module-address/services/address.service';
import { FieldsValidators } from '../../validators/fields-validators';
import { Constant } from '../../models/constant';
import { Country } from '../../../module-address/models/country';
import {UserService} from '../../services/user.service';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-profile-address',
  templateUrl: './profile-address.component.html',
})
export class ProfileAddressComponent implements OnInit {
  @Input() isMobileScreen=false;
  // Constants
  @Input() titleChoices: Array<Constant>;
  @Input() countryChoices: Array<Country>;

  userAddresses: Array<Address>;
  addressForm: FormGroup;
  addressFormSubmitted  = false;

  loading    = false;
  fillInCommunicationPreferencesMessage = '';
  constructor(private alertService: AlertService,
              private formBuilder: FormBuilder,
              private addressService: AddressService,
              private userService: UserService,
              private authenticationService: AuthenticationService,
              private router: Router,
              translate: TranslateService) {
    translate.get('userModal.loginPage.fillPreferencesMessage').subscribe((res: string) => {
      this.fillInCommunicationPreferencesMessage = res;
    });
  }

  ngOnInit(): void {
    // Build the form and validators
    this.addressForm = this.formBuilder.group({
      title: [null, [
      ]],
      firstName: ['', [
        Validators.required,
        Validators.minLength(3)
      ]],
      lastName: ['', [
        Validators.required,
        Validators.minLength(3)
      ]],
      line1: ['', [
        Validators.required,
        Validators.minLength(3)
      ]],
      line2: ['', []],
      line3: ['', []],
      line4: ['', []],
      city: ['', [
        Validators.required,
        Validators.minLength(3)
      ]],
      state: ['', []],
      postcode: ['', [
        Validators.required,
        Validators.minLength(3)
      ]],
      phoneNumber: ['', [
        Validators.required,
        Validators.minLength(10),
        // Validators.pattern('[- +()0-15]+')
      ]],
      country: ['Select the country', [
        Validators.required,
        FieldsValidators.oneValueHasToBeChangedValidator('Select the country', { sameValue: true })
      ]],
      isDefaultForBilling: [false, []],
    });
    // get the addresses of the current user
    this.addressService.getUserAddresses().subscribe(
      response => {
        this.userAddresses = response.body.data;
        if (this.userAddresses.length === 0) {
          // If there is no address the first name and last name fields init values will
          // be the values from the user profile
          // this.userAddressForm.firstName.setValue(this.userProfile.first_name);
          // this.userAddressForm.lastName.setValue(this.userProfile.last_name);
        }else{
          this.userAddressForm.title.setValue(this.userAddresses[0].title);
          this.userAddressForm.firstName.setValue(this.userAddresses[0].first_name);
          this.userAddressForm.lastName.setValue(this.userAddresses[0].last_name);
          this.userAddressForm.line1.setValue(this.userAddresses[0].line1);
          this.userAddressForm.line2.setValue(this.userAddresses[0].line2);
          this.userAddressForm.line3.setValue(this.userAddresses[0].line3);
          this.userAddressForm.line4.setValue(this.userAddresses[0].line4);
          this.userAddressForm.city.setValue(this.userAddresses[0].city);
          this.userAddressForm.state.setValue(this.userAddresses[0].state);
          this.userAddressForm.postcode.setValue(this.userAddresses[0].postcode);
          this.userAddressForm.phoneNumber.setValue(this.userAddresses[0].phone_number);
          this.userAddressForm.country.setValue(this.userAddresses[0].country);
          this.userAddressForm.isDefaultForBilling.setValue(this.userAddresses[0].is_default_for_billing);
        }

      },
      error => {
        this.alertService.error(error.message);
      });
  }

  get userAddressForm() { return this.addressForm.controls; }

  onAddressFormSubmit(){
    this.addressFormSubmitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.addressForm.invalid) {
      return;
    }

    this.loading = true;

    if (this.userAddresses.length === 0 ){
      this.addressService.createAddress(this.getAddressObject()).subscribe( response => {
        // this.alertService.success(response.body.message, true);
        this.userAddresses[0] = response.body.data;
        // we need to check that the address is set in order to let the user add to the listing
        // reset the user information
        this.userService.getUserProfile().subscribe(
          resp => {
            this.authenticationService.setCurrentUser(resp.body.data);
            if (resp.body.data.has_notification_preferences === false) {
              window.location.replace('user;showNotifications=true');
            }
          },
          error => {
            this.alertService.error(error.message);
          });
      }, response => {
        this.setErrors(response.error.error);
      });
    }else{
      this.addressService.updateAddress(
        this.getAddressObject(), this.userAddresses[0].hash).subscribe( response => {
        this.alertService.success(response.body.message, true);
        this.userAddresses[0] = response.body.data;

      }, response => {
          this.setErrors(response.error.error);
      });
    }
    this.loading = false;
  }
  private setErrors(error: any){
      if (error.first_name) { this.userAddressForm.firstName.setErrors({backendError: error.first_name}); }
      else if (error.last_name) { this.userAddressForm.lastName.setErrors({backendError: error.last_name}); }
      else if (error.line1) { this.userAddressForm.line1.setErrors({backendError: error.line1}); }
      else if (error.line2) { this.userAddressForm.line2.setErrors({backendError: error.line2}); }
      else if (error.line3) { this.userAddressForm.line3.setErrors({backendError: error.line3}); }
      else if (error.line4) { this.userAddressForm.line4.setErrors({backendError: error.line4}); }
      else if (error.city) { this.userAddressForm.city.setErrors({backendError: error.city}); }
      else if (error.state) { this.userAddressForm.state.setErrors({backendError: error.state}); }
      else if (error.postcode) { this.userAddressForm.postcode.setErrors({backendError: error.postcode}); }
      else if (error.phone_number) { this.userAddressForm.phoneNumber.setErrors({backendError: error.phone_number}); }
      else if (error.is_default_for_billing) {
        this.userAddressForm.is_default_for_billing.setErrors({backendError: error.is_default_for_billing}); }
      else if (error.country) { this.userAddressForm.country.setErrors({backendError: error.country}); }
  }
  private getAddressObject(){
    return new Address(
      this.userAddressForm.title.value,
      this.userAddressForm.firstName.value,
      this.userAddressForm.lastName.value,
      this.userAddressForm.line1.value,
      this.userAddressForm.line2.value,
      this.userAddressForm.line3.value,
      this.userAddressForm.line4.value,
      this.userAddressForm.state.value,
      this.userAddressForm.city.value,
      this.userAddressForm.postcode.value,
      this.userAddressForm.phoneNumber.value,
      this.userAddressForm.isDefaultForBilling.value,
      this.userAddressForm.country.value
    );
  }
}
