<div class="step-tab-panel">
  <div class="col-lg-12">
    <div class="">
      <h2 class="title rubik fw-600" style="text-align: center;">
        <i class=""></i>
        {{'entityComponent.addEntity.steps.category.subtitle' | translate }}
      </h2>
    </div>
    <form [formGroup]="addCatForm" (ngSubmit)="onSubmit()">
      <div class="row text-center ml-auto">
        <div class="col-lg-6 float-left">
          <div>
            <h3 class="title rubik fw-600" style="align-self: start;">
              {{'entityComponent.addEntity.steps.category.inputFields.category.label' | translate }}
            </h3>
          </div>
          <div class="col-lg-12" >
            <div class="form-group p-relative" style="text-align: left;">
              <mat-form-field style="width: 100%;">
                <mat-label>{{'entityComponent.addEntity.steps.category.inputFields.category.selectText' | translate }}</mat-label>
                <mat-select formControlName="category" >
                  <mat-option *ngFor="let ctr of categories" [value]="ctr.id">
                    {{ctr.name}}
                  </mat-option>
                </mat-select>
                <mat-error>{{'entityComponent.addEntity.steps.category.inputFields.category.errors.required' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-lg-6 " *ngIf="addCategoryFormControls.category.value !== selectCategoryText && addCategoryFormControls.category.value !== ''">
          <div>
            <h3 class="title rubik fw-600" style=" align-self: start;">
              {{'entityComponent.addEntity.steps.category.inputFields.mainCategory.label' | translate }}
            </h3>
          </div>
          <div class="col-lg-12">
            <div class="form-group p-relative " style="width: auto; text-align: left;" >
              <mat-form-field style="width: 100%;">
                <mat-label>
                  {{'entityComponent.addEntity.steps.category.inputFields.mainCategory.selectText' | translate }}
                </mat-label>
                <mat-select formControlName="entityClass"
                            panelClass="example-panel-blue">
                  <mat-option *ngFor="let ent of selectableEntityClasses" [value]="ent.id">
                    {{ent.name}}
                  </mat-option>
                </mat-select>
                <mat-error >
                  {{'entityComponent.addEntity.steps.category.inputFields.mainCategory.errors.required' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="step-footer step-tab-pager right justify-content-center ">
        <input type="submit" class="btn-second blue-btn" style="width: 200px; "
               value="{{'entityComponent.addEntity.buttons.next' | translate }}"/>
      </div>
    </form>
  </div>
</div>
