import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { UserService } from './services/user.service';
import { AlertService } from '../core/alert/alert.service';
import { Profile } from './models/profile';
import { FormBuilder} from '@angular/forms';
import { AddressService } from '../module-address/services/address.service';
import { Country } from '../module-address/models/country';
import { Constant } from './models/constant';
import {ConstantsService} from '../core/services/constants.service';
import {Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { SubscriptionService } from '../module-subscription/services/subscription.service';
import { Subscription } from 'rxjs';
import { SubscriptionModel } from '../module-subscription/models/subscriptionModel';
import { PlanModel } from '../module-subscription/models/planModel';

@Component({
  selector: 'app-module-user',
  templateUrl: './module-user.component.html',
})
export class ModuleUserComponent implements OnInit {
  // profile
  userProfile: Profile; // the actual user profile from backend
  activeScreen='';
  showGeneral = true;
  showAddress = false;
  showPreferences = false;
  showNotifications = false;
  showChangePassword=false;
  showSubscription=false;
  subscriptionServiceSubscription: Subscription;
  loading = false;
  preferences = false;
  fieldTextType: boolean;
  subscriptions: Array<SubscriptionModel> = null;
  totalPages: Array<number> = [];
  previousLink: string;
  nextLink: string;
  currentPage: number;
  currentPlans: Array<PlanModel> = [];
  Plans: PlanModel;
  constants: {
    title: Array<Constant>,
    gender: Array<Constant>,
    country: Array<Country>,
    employmentStatuses: Array<Constant>,
  } = { title: [], gender: [], country: [], employmentStatuses: [] };
  constructor(private router: Router,
              private userService: UserService,
              private alertService: AlertService,
              private formBuilder: FormBuilder,
              private addressService: AddressService,
              private constantsService: ConstantsService,
              private route: ActivatedRoute,
              private observer: BreakpointObserver,
              private subscriptionService: SubscriptionService) {

    if ( this.constantsService.currentConstants === null || this.constantsService.currentConstants === undefined){
      this.constantsService.getConstants().subscribe(response => {
        this.constantsService.constants = response.body.data;
        this.constants = response.body.data;
      }, error => {
        console.error(error);
      });
    }else{
      this.constants = this.constantsService.currentConstants;
    }
    this.userService.getUserProfile().subscribe(
      response => {
        this.userProfile = response.body.data;
      },
      error => {
        this.alertService.error(error.message);
      });

  }

  ngOnInit(): void {

    this.getSubscriptions();
    // this is to capture the showPreferences parameter passed with the router when the button on the home menu is clicked
    // in case this was passed, we need to open the search preferences tab
    const showPreferecesParam = this.route.snapshot.paramMap.get('showPreferences');
    if (showPreferecesParam)
    {
      this.changeComponent('profile_preferences');
    }

    const redirectToAddress = this.route.snapshot.paramMap.get('showAddress');
    if (redirectToAddress){
      this.changeComponent('profile_address');
    }

    const redirectToCommunicationPreferences = this.route.snapshot.paramMap.get('showNotifications');
    if (redirectToCommunicationPreferences){
      this.changeComponent('profile_notifications');
    }


  }

  uploadProfilePicture(event){
    if (event.target.files && event.target.files.length) {
      const formData = new FormData();
      formData.append('profile_picture', event.target.files[0]);
      this.userService.updateUserProfile(formData).subscribe(
        response => {
          /* 1. show the response message on the screen
           * 2. set the new entries on the user profile
            */
          this.userProfile = response.body.data;
          this.alertService.success(response.body.message, true);

        },
        error => {
          this.alertService.error(error.error.error);
          this.loading = false;
        });

    }
  }

  public getSubscriptions(pageNumber?: number){
    this.subscriptionServiceSubscription = this.subscriptionService.getUserSubscriptions(pageNumber).subscribe(response =>
        this.constructTheListing(response),
      errorResponse => console.error(errorResponse));
      
  }
  constructTheListing(response){
    // console.log(response.body.data.results);
    this.totalPages = [];
    this.subscriptions = response.body.data.results.plans;
    

    this.nextLink = response.body.data.links.next;
    this.previousLink = response.body.data.links.previous;
    for (let i = 1; i <= Math.ceil(response.body.data.total / response.body.data.page_size); i++){
      this.totalPages.push(i);
    }
    this.currentPage = response.body.data.page;
  }
  changeComponent(event: string){
    /* This function is listening to the events emitted by the children of this component.
    * It is used in order to change the content of the modal dinamically
    * @param event, it represents the name of the component that needs to be changed */
    if(this.isMobileScreen){
      this.sidenav.close()
    }
    switch (event) {
      case 'profile_general': {
        this.showGeneral = true;
        this.showAddress = false;
        this.showPreferences = false;
        this.showNotifications = false;
        this.showChangePassword=false;
        this.showSubscription=false;
        this.activeScreen='userComponent.profile.generalTab.tabTitle';
        break;
      }
      case 'profile_address': {
        this.showGeneral = false;
        this.showAddress = true;
        this.showPreferences = false;
        this.showNotifications = false;
        this.showChangePassword=false;
        this.showSubscription=false;
        this.activeScreen='userComponent.profile.addressTab.tabTitle';
        break;
      }
      case 'profile_preferences': {
        this.showGeneral = false;
        this.showAddress = false;
        this.showPreferences = true;
        this.showNotifications = false;
        this.showChangePassword=false;
        this.showSubscription=false;
        this.activeScreen='userComponent.profile.searchPreferenceTab.tabTitle';
        break;
      }case 'profile_notifications': {
        this.showGeneral = false;
        this.showAddress = false;
        this.showPreferences = false;
        this.showNotifications = true;
        this.showChangePassword=false;
        this.showSubscription=false;
        this.activeScreen='userComponent.profile.communicationTab.tabTitle';
        break;
      }
      case 'profile_change_password': {
        this.showGeneral = false;
        this.showAddress = false;
        this.showPreferences = false;
        this.showNotifications = false;
        this.showChangePassword=true;
        this.showSubscription=false;
        this.activeScreen='userComponent.changePassword.tabTitle';
        break;
      }
      case 'profile_subscription': {
        this.showGeneral = false;
        this.showAddress = false;
        this.showPreferences = false;
        this.showNotifications = false;
        this.showChangePassword=false;
        this.showSubscription=true;
        this.activeScreen='subscriptionsComponent.subscriptionsTab.tabTitle';
        break;
      }
    }

  }
  isMobileScreen=false;
  @ViewChild(MatSidenav)
 sidenav!: MatSidenav;
 ngAfterViewInit() {
  this.observer.observe(['(max-width: 767px)']).subscribe((res) => {
    if (res.matches) {
      this.isMobileScreen=true;
      this.sidenav.mode = 'over';
      this.sidenav.close();

    } else {
      // this.sidenav.mode = 'side';
      // this.sidenav.open();
      this.isMobileScreen=false;
      this.sidenav.mode = 'over';
      this.sidenav.close();
    }
  });
}

}
