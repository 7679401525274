import { Component, Input, OnInit } from '@angular/core';
import { PreferenceService } from '../../services/preference.service';
import { Preference } from '../../models/preference';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertService } from '../../../core/alert/alert.service';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ConfirmationDialogService } from '../../../core/confirmation-dialog/confirmation-dialog.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-profile-notification',
  templateUrl: './profile-notification.component.html',
})
export class ProfileNotificationComponent implements OnInit {
  @Input() isMobileScreen=false;
  userPreference: Preference = undefined;

  preferenceForm: FormGroup;
  preferenceFormSubmitted  = false;
  loading    = false;
  fillInCommunicationPreferencesMessage = '';

  constructor(private preferenceService: PreferenceService,
              private alertService: AlertService,
              private formBuilder: FormBuilder,
              private userService: UserService,
              private confirmationDialogService: ConfirmationDialogService,
              private authenticationService: AuthenticationService,
              translate: TranslateService) {
    translate.get('userModal.loginPage.fillPreferencesMessage').subscribe((res: string) => {
      this.fillInCommunicationPreferencesMessage = res;
    });
  }


  ngOnInit(): void {


    this.preferenceForm = this.formBuilder.group({
      emailCommunication: [false, []],
      showEmail:  [false, []],
      showAddressPhoneNumber:  [false, []],
      showLastName:  [false, []],
      showFirstName:  [false, []],
      showPostCode:  [false, []],
      showCountry:  [false, []],
      showState:  [false, []],
      showAddressLine1:  [false, []],
      showEmploymentStatus:  [false, []],
    });

    this.preferenceService.getPreference().subscribe(response => {
      this.userPreference = response.body.data[0];

      if (this.userPreference !== undefined){
          this.preferenceFormControls.emailCommunication.setValue(this.userPreference.email_communication);
          this.preferenceFormControls.showEmail.setValue(this.userPreference.show_email);
          this.preferenceFormControls.showAddressPhoneNumber.setValue(this.userPreference.show_address_phone_number);
          this.preferenceFormControls.showLastName.setValue(this.userPreference.show_last_name);
          this.preferenceFormControls.showFirstName.setValue(this.userPreference.show_first_name);
          this.preferenceFormControls.showPostCode.setValue(this.userPreference.show_post_code);
          this.preferenceFormControls.showCountry.setValue(this.userPreference.show_country);
          this.preferenceFormControls.showState.setValue(this.userPreference.show_state);
          this.preferenceFormControls.showAddressLine1.setValue(this.userPreference.show_address_line_1);
          this.preferenceFormControls.showEmploymentStatus.setValue(this.userPreference.show_employment_status);
        }
      },
        error => console.error(error));
  }

  // convenience getter for easy access to form fields
  get preferenceFormControls() { return this.preferenceForm.controls; }


  onPreferenceFormSubmit(){
    this.preferenceFormSubmitted = true;

    // reset alerts on submit
    this.alertService.clear();

    this.loading = true;
    if (this.userPreference === undefined){
      this.preferenceService.createPreference(new Preference(this.preferenceFormControls)).subscribe(response => {
        this.alertService.success(response.body.message);
        this.userPreference = response.body.data;
        // reset the user information
        this.userService.getUserProfile().subscribe(
          resp => {
            this.authenticationService.setCurrentUser(resp.body.data);
          },
          error => {
            // todo handle errors
            this.alertService.error(error.message);
          });
      }, error => console.error(error));
    }else{
      this.preferenceService.updatePreference(new Preference(this.preferenceFormControls)).subscribe(response => {
        this.alertService.success(response.body.message);
        this.userPreference = response.body.data;
      }, error => console.error(error));
    }

    this.loading = false;

  }



}
