import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { City } from 'src/app/module-address/models/city';
import { Category } from 'src/app/module-entity/models/category';
import { SearchService } from 'src/app/module-search/services/search.service';
import { ConstantsService } from '../../services/constants.service';

@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.css']
})
export class HeaderSearchComponent implements OnInit {
  categories: Array<Category>;
  cities: Array<City>;

  filteredCities: Observable<Array<City>>;
  searchForm: FormGroup;
  get formControls() { return this.searchForm.controls; }

  constructor(
    private searchService: SearchService,
    private formBuilder: FormBuilder,
    private router: Router,
    private constantsService: ConstantsService
  ) { }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      keyword: ['', []],
      city: ['', []]
    });

    this.filteredCities = this.formControls.city.valueChanges
      .pipe(
        startWith(''),
        map(city => city ? this._filterCities(city) : this.cities?.slice())
      );

    // Get Cities
    if (!this.constantsService.currentConstants?.body?.data) {
      this.constantsService.getConstants().subscribe(response => {
        this.constantsService.constants = response.body.data;
        this.cities = response.body.data?.city;
      }, error => console.log(error));
    } else {
      this.cities = this.constantsService.currentConstants.body.data?.city;
    }
  }

  private _filterCities(value: string): City[] {
    const filterValue = value.toLowerCase();
    return this.cities?.filter(city => city?.name?.toLowerCase()?.indexOf(filterValue) === 0) || [];
  }

onSearchSubmit() {
  console.log('Search Parameters:', {
    keyword: this.formControls.keyword.value,
    city: this.formControls.city.value
  });
  this.searchService.masterSearch.next({
    keyword: this.formControls.keyword.value,
    city: this.formControls.city.value
  });
  this.router.navigate(['/search-result']);
}
}
