/* tslint:disable */
export class SearchPreferenceAttribute {
  type: string;
  code: string;
  value_min: number;
  value_max: number;
  value: string;
  constructor(type: string, code: string, valueMin: number, valueMax: number, value: string) {
    this.type = type;
    this.code = code;
    this.value_min = valueMin;
    this.value_max = valueMax;
    this.value = value;
  }
}
