<div class="search-page">
  <!-- Header -->
  <div class="search-page-header mb-5">
    <div class="container">
      <h2>Search</h2>
      <p>Lorem ipsum dolor sit amet consectetur. Ipsum in scelerisque a eros molestie turpis. Enim auctor pellentesque
        nam nisl luctus quis.</p>

      <!-- Form -->
      <form>
        <!-- Search bar -->
        <div class="large-search-bar">
          <!-- Input Group -->
          <div class="d-flex w-100 align-items-center">
            <img src="assets/images/icon/Search.png" alt="">
            <input type="text" placeholder="Search by Keywords(watch, apartment, jewellery, car, bike)">
          </div>
          <!-- Buttons -->
          <div class="d-flex butons-inline align-items-center">
            <a class="btn btn-link">Clear</a>
            <button class="btn-first blue-btn"> Search </button>
          </div>
        </div>

        <!-- Categories Buttons -->
        <div class="d-flex align-items-center category-btn">
          <button class="active">All(4.2k)</button>
          <button>Real Estate(1.2k)</button>
          <button>Luxury(984)</button>
          <button>Vehicle(687)</button>
          <button>Jobs(1.1k)</button>
        </div>
      </form>
    </div>
  </div>
  <!-- Body -->
  <div class="search-page-body">

    <app-real-esatte-slider title="Real Estate"></app-real-esatte-slider>
    <div class="my-100">
      <app-luxury-slider title="Luxury"></app-luxury-slider>
    </div>
    <div class="my-100">
      <app-vehicle-slider title="Vehicle"></app-vehicle-slider>
    </div>
    <div class="my-100">
      <app-job-slider-section title="Jobs"></app-job-slider-section>
    </div>

    <app-become-seller-section></app-become-seller-section>

  </div>
</div>