import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-entity-edit',
  templateUrl: './entity-edit.component.html'
})
export class EntityEditComponent implements OnInit, OnDestroy {
  title = 'Edit your item';
  entityHash: string;
  routerSubscription: Subscription;
  constructor(private route: ActivatedRoute){ }

  ngOnInit(): void {
    this.routerSubscription = this.route.params.subscribe(params => {
      this.entityHash = params.hash;
    }, error => console.log(error) );
  }
  ngOnDestroy() { this.routerSubscription.unsubscribe(); }
}
