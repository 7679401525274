import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { EntityResponse} from './responses/entityResponse';
import { EntityClassResponse } from './responses/entityClassResponse';
import { EntityListingResponse } from './responses/entityListingResponse';
import { BaseResponse } from '../../core/services/responses/baseResponse';
import { BehaviorSubject } from 'rxjs';
import { EntityClass } from '../models/entityClass';
import { Entity } from '../models/entity';


@Injectable({
  providedIn: 'root'
})
export class EntityService {
  private apiBaseURL = environment.configSettings.apiURL;

  entityClasses: BehaviorSubject<Array<EntityClass>> = new BehaviorSubject<Array<EntityClass>>(null);

  recentlyViewed: BehaviorSubject<Array<Entity>> = new BehaviorSubject<Array<Entity>>(new Array<Entity>());

  RECENTLY_VIEWED_ITEMS = 5;

  constructor(private http: HttpClient) { }


  public get recentlyViewedItems(): Array<Entity> {
    /* This function returns the last 5 items seen */
    if (this.recentlyViewed.value != null){
      return this.recentlyViewed.value;
    }
  }
  addToRecentlyViewed(entity: Entity){
    const itemsList = this.recentlyViewedItems;
    const filteredItem = itemsList.filter(item => item.hash_1 === entity.hash_1);
    if (filteredItem.length === 0){
      if (itemsList.length >= this.RECENTLY_VIEWED_ITEMS){ itemsList.pop(); }
      itemsList.push(entity);
    }
  }

  getEntityClasses(){
    if (this.entityClasses.value === null) {
      this.getEntityClass().subscribe(response => {
        this.entityClasses.next(response.body.data);
        return response.body.data;
      }, error => console.error(error));
    }
    return this.entityClasses.value;
  }

  getEntityByHash(hash: string) {
    return this.http.get<EntityResponse>(`${this.apiBaseURL}v1/entity/entity/${hash}`, { observe: 'response' });
  }

  updateEntityByHash(hash: string, formData: FormData) {
    return this.http.put<EntityResponse>(`${this.apiBaseURL}v1/entity/entity/${hash}`, formData, { observe: 'response' });
  }

  updateEntityByHashPartial(hash: string, entityPartialData: any) {
    return this.http.patch<EntityResponse>(`${this.apiBaseURL}v1/entity/entity/${hash}`, entityPartialData, { observe: 'response' });
  }

  deleteEntityByHash(hash: string) {
    return this.http.delete<BaseResponse>(`${this.apiBaseURL}v1/entity/entity/${hash}`, { observe: 'response' });
  }

  getEntityClass() {
    return this.http.get<EntityClassResponse>(`${this.apiBaseURL}v1/entity/entityClass/`, { observe: 'response' });
  }

  createEntity(formData: FormData) {
    return this.http.post<EntityResponse>(`${this.apiBaseURL}v1/entity/entity/`, formData, { observe: 'response' });
  }

  getUserEntities(page?: number) {
    if (page) {
      return this.http.get<EntityListingResponse>(`${this.apiBaseURL}v1/entity/entity?page=${page}`, {observe: 'response'});
    }
    return this.http.get<EntityListingResponse>(`${this.apiBaseURL}v1/entity/entity/`, {observe: 'response'});
  }
}
