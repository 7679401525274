import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Entity} from '../../models/entity';
import {EntityClass} from '../../models/entityClass';

@Injectable({
  providedIn: 'root'
})
export class EntityAdditionService {

  private entityClass: BehaviorSubject<EntityClass>;

  public entity: BehaviorSubject<Entity>;

  private formData: BehaviorSubject<FormData>;

  constructor() { }

  public get currentEntityClass() {
    if (this.entityClass != null) {
      return this.entityClass.value;
    }
    return null;
  }

  public get currentEntity() {
    if (this.entity != null) {
      return this.entity.value;
    }
    return null;
  }

  public get currentFormDataAndEntity() {
    /* This function is used in order to retrieve the entity and form data created in memory
     * In case form data doesn't exist, when updating without changing images. It creates it and appends the entity
     * It sets the values to null before returning */
    if (this.formData === undefined || this.formData === null) {
      this.formData = new BehaviorSubject<any>(new FormData());
    }
    this.formData.value.append('entity', JSON.stringify(this.entity.value));
    const formDataValue = this.formData.value;

    this.formData.next(null);
    this.entity.next(null);
    this.formData = null;
    this.entity = null;

    return formDataValue;

  }

  addImageToFormData(name: string, file: any){
    /* This function adds an image to the form data used in the API for entity create/update
    * If the formData is not initialized, it initializes with an empty form data
    * If the image was previously assigned, it deletes it and appends the new image
    *   to the form data*/
    if (this.formData === undefined || this.formData === null){
      this.formData = new BehaviorSubject<any>(new FormData());
    }
    if (this.formData.value.get(name) !== null){
      this.formData.value.delete(name);
    }
    this.formData.value.append(name, file);
  }


  removeImageFromFormData(name: string){
    if (this.formData.value.get(name) !== null){
      this.formData.value.delete(name);
    }
  }


  createEntity(){
    this.entity = new BehaviorSubject<Entity>(new Entity(null));
  }

  set Entity(entity: Entity){
    this.entity = new BehaviorSubject<Entity>(entity);
  }

  // add categories
  set categories(val: {categories: Array<number>, entity_class: number}){
    this.entity.value.categories = val.categories;
    this.entity.value.entity_class = val.entity_class;

  }

  initAllAttributes(){
    this.entity.value.initAttributes();
  }

  // add attributes
  set attribute(val: {id: number, type: string, value: any}){
    this.entity.value.Attribute = val;
  }
  set cover_image(val: any){
    this.entity.value.Cover_image = val;
  }
  set title(val: string){
    this.entity.value.title = val;
  }
  set description(val: string){
    this.entity.value.description = val;
  }
  set price(val: number){
    this.entity.value.price = val;
  }
  // add gallery
  set image(val: any){
    this.entity.value.Image = val;
  }
  // add location
  set city(val: number){
    this.entity.value.city = val;
  }

  set setEntityClass(entityClass: EntityClass){
    this.entityClass = new BehaviorSubject<EntityClass>(entityClass);
  }
}
