import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {CreateSubscriptionResponse} from './responses/createSubscriptionResponse';
import { BaseResponse } from '../../core/services/responses/baseResponse';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private apiBaseURL = environment.configSettings.apiURL;

  constructor(private http: HttpClient) { }

  createStripeSubscription(customerId, paymentMethodId, priceId, userSubscriptionId){
    return this.http.post<CreateSubscriptionResponse>(`${this.apiBaseURL}v1/payment/create-stripe-subscription/`,
      {
        customer_id: customerId,
        payment_method_id: paymentMethodId,
        price_id: priceId,
        user_subscription_ui: userSubscriptionId
      }, { observe: 'response' });
  }

  cancelSubscription(subscriptionUi: string){
    return this.http.post<BaseResponse>(`${this.apiBaseURL}v1/payment/cancel_subscription/`,
      {ui: subscriptionUi}, { observe: 'response' });
  }

  sendInvoiceToCustomer(subscriptionUi: string){
    return this.http.post<BaseResponse>(`${this.apiBaseURL}v1/payment/send_invoice_to_customer/`,
      {ui: subscriptionUi}, { observe: 'response' });
  }

  reactivateSubscription(subscriptionUi: string){
    return this.http.put<BaseResponse>(`${this.apiBaseURL}v1/payment/reactivate_subscription/`,
      {ui: subscriptionUi}, { observe: 'response' });
  }
}
