<!-- faqs -->
<section class="section-padding faqs">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-header">
          <div class="text-wrapper text-center">
            <h3 class="fw-500 no-margin">Most Common Asked Questions</h3>
            <span class="line-grey"></span><span class="line-blue"></span><span class="line-grey"></span>
            <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="faqs-tab">
          <ul class="nav nav-pills" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" data-toggle="pill" href="#general">  <span>
                      <i class="flaticon-question"></i>
                    </span>
                <p class="no-margin text-light-black fw-500">General</p>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="pill" href="#engine">  <span>
                      <i class="flaticon-car-engine"></i>
                    </span>
                <p class="no-margin text-light-black fw-500">Engine</p>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="pill" href="#mechanical">  <span>
                      <i class="flaticon-car-parts"></i>
                    </span>
                <p class="no-margin text-light-black fw-500">Mechanical</p>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="pill" href="#navigation">  <span>
                      <i class="flaticon-car-1"></i>
                    </span>
                <p class="no-margin text-light-black fw-500">Navigation</p>
              </a>
            </li>
          </ul>
        </div>
        <div class="tab-content custom-accordion">
          <div id="general" class="tab-pane active">
            <div class="card">
              <div class="card-header">
                <h5 class="text-light-black no-margin title">How do i get finance support for my business?</h5>
              </div>
              <div class="card-body none">
                <h5 class="text-light-black">How to get Started?</h5>
                <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="text-light-black no-margin title">How do i knew about any better opportunity</h5>
              </div>
              <div class="card-body none">
                <h5 class="text-light-black">How to get Started?</h5>
                <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="text-light-black no-margin title">Does Startup allows to share user data?</h5>
              </div>
              <div class="card-body none">
                <h5 class="text-light-black">How to get Started?</h5>
                <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="text-light-black no-margin title">What is the average cost for team project yearly ?</h5>
              </div>
              <div class="card-body none">
                <h5 class="text-light-black">How to get Started?</h5>
                <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="text-light-black no-margin title">How to invite people in any active project ?</h5>
              </div>
              <div class="card-body none">
                <h5 class="text-light-black">How to get Started?</h5>
                <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
              </div>
            </div>
          </div>
          <div id="engine" class="tab-pane fade">
            <div class="card">
              <div class="card-header">
                <h5 class="text-light-black no-margin title">How do i get finance support for my business?</h5>
              </div>
              <div class="card-body none">
                <h5 class="text-light-black">How to get Started?</h5>
                <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="text-light-black no-margin title">How do i knew about any better opportunity</h5>
              </div>
              <div class="card-body none">
                <h5 class="text-light-black">How to get Started?</h5>
                <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="text-light-black no-margin title">Does Startup allows to share user data?</h5>
              </div>
              <div class="card-body none">
                <h5 class="text-light-black">How to get Started?</h5>
                <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="text-light-black no-margin title">What is the average cost for team project yearly ?</h5>
              </div>
              <div class="card-body none">
                <h5 class="text-light-black">How to get Started?</h5>
                <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="text-light-black no-margin title">How to invite people in any active project ?</h5>
              </div>
              <div class="card-body none">
                <h5 class="text-light-black">How to get Started?</h5>
                <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
              </div>
            </div>
          </div>
          <div id="mechanical" class="tab-pane fade">
            <div class="card">
              <div class="card-header">
                <h5 class="text-light-black no-margin title">How do i get finance support for my business?</h5>
              </div>
              <div class="card-body none">
                <h5 class="text-light-black">How to get Started?</h5>
                <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="text-light-black no-margin title">How do i knew about any better opportunity</h5>
              </div>
              <div class="card-body none">
                <h5 class="text-light-black">How to get Started?</h5>
                <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="text-light-black no-margin title">Does Startup allows to share user data?</h5>
              </div>
              <div class="card-body none">
                <h5 class="text-light-black">How to get Started?</h5>
                <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="text-light-black no-margin title">What is the average cost for team project yearly ?</h5>
              </div>
              <div class="card-body none">
                <h5 class="text-light-black">How to get Started?</h5>
                <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="text-light-black no-margin title">How to invite people in any active project ?</h5>
              </div>
              <div class="card-body none">
                <h5 class="text-light-black">How to get Started?</h5>
                <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
              </div>
            </div>
          </div>
          <div id="navigation" class="tab-pane fade">
            <div class="card">
              <div class="card-header">
                <h5 class="text-light-black no-margin title">How do i get finance support for my business?</h5>
              </div>
              <div class="card-body none">
                <h5 class="text-light-black">How to get Started?</h5>
                <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="text-light-black no-margin title">How do i knew about any better opportunity</h5>
              </div>
              <div class="card-body none">
                <h5 class="text-light-black">How to get Started?</h5>
                <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="text-light-black no-margin title">Does Startup allows to share user data?</h5>
              </div>
              <div class="card-body none">
                <h5 class="text-light-black">How to get Started?</h5>
                <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="text-light-black no-margin title">What is the average cost for team project yearly ?</h5>
              </div>
              <div class="card-body none">
                <h5 class="text-light-black">How to get Started?</h5>
                <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="text-light-black no-margin title">How to invite people in any active project ?</h5>
              </div>
              <div class="card-body none">
                <h5 class="text-light-black">How to get Started?</h5>
                <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
