import { Component, OnInit } from '@angular/core';
import {FormBuilder,  FormGroup, Validators} from '@angular/forms';
import { AlertService } from '../../core/alert/alert.service';
import { UserService } from '../services/user.service';
import { PasswordReset } from '../models/passwordReset';
import { ActivatedRoute, Router} from '@angular/router';
import { FieldsValidators } from '../validators/fields-validators';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
})
export class PasswordResetComponent implements OnInit {
  passwordResetForm: FormGroup;
  loading    = false;
  submitted  = false;

  fieldTextType: boolean;


  constructor(private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private alertService: AlertService,
              private userService: UserService) { }

  ngOnInit(): void {
    this.passwordResetForm = this.formBuilder.group({
      password:  [null, [Validators.compose([
        // 1. Password Field is Required
        Validators.required,
        // 2. check whether the entered password has a number
        FieldsValidators.patternValidator(/\d/, { hasNumber: true }),
        // 3. check whether the entered password has upper case letter
        FieldsValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        // 4. check whether the entered password has a lower-case letter
        FieldsValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        // 5. Has a minimum length of 8 characters
        Validators.minLength(8)

      ])]],
      password1:  [null, Validators.compose([Validators.required])]
    }, {
        // check whether our password and confirm password match
        validator: FieldsValidators.passwordMatchValidator
      });

  }

  // convenience getter for easy access to form fields
  get form() { return this.passwordResetForm.controls; }



  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.passwordResetForm.invalid) {
      return;
    }

    // stop here if the password don't match
    if (this.form.password.value !== this.form.password1.value){
      return;
    }

    this.loading = true;
    this.route.params.subscribe(params => {
      this.userService.resetPassword(
        new PasswordReset(
          params.uid64,
          params.token,
          this.form.password.value,
          this.form.password1.value
        ))
        .subscribe(
          response => {
            // response.body
            this.alertService.success(response.body.message, true);
            this.router.navigate(['/']);
            this.loading = false;
          },
          error => {
            for (const key in error.error.error) {
              if (error.error.error[key]){
                this.alertService.error(error.error.error[key][0]);
              }
            }
            this.loading = false;
          });
    });

  }
}
