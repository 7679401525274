import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GetVouchersResponse } from '../responses/getVouchersResponse';
import { SetupVoucherResponse } from '../responses/setupVoucherResponse';


@Injectable({
  providedIn: 'root'
})
export class VoucherService {
  private apiBaseURL = environment.configSettings.apiURL;
  constructor(private http: HttpClient) { }

  getVouchers() {
    return this.http.get<GetVouchersResponse>(`${this.apiBaseURL}v1/subscription/voucher/`, { observe: 'response' });
  }

  attachVoucherToUser(hashKey: string) {
    return this.http.post<SetupVoucherResponse>(`${this.apiBaseURL}v1/subscription/voucher/`, {hash_key: hashKey},
      { observe: 'response' });
  }
}
