import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReferralService } from '../services/referral.service';
import { ReferralModel } from '../models/referralModel';
import { Subscription } from 'rxjs';
import { ReferralComponent } from '../referral.component';
import { MatDialog } from '@angular/material/dialog';
import { ModifyReferralComponent } from '../modify-referral/modify-referral.component';
import { AlertService } from '../../../core/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-referral-listing',
  templateUrl: './referral-listing.component.html',
  styleUrls: ['./referral-listing.component.css', ]
})
export class ReferralListingComponent implements OnInit, OnDestroy {
  loading = false;
  referralSubscription: Subscription;
  referrals: Array<ReferralModel> = new Array<ReferralModel>();

  alreadySignedUPErr = '';
  alreadySubscribedErr = '';
  confirmationMessage = '';

  constructor(private referralService: ReferralService,
              public dialog: MatDialog,
              private alertService: AlertService,
              translate: TranslateService) {
    translate.get('subscriptionsComponent.referralPage.errors.alreadySignedUp').subscribe((res: string) => {
      this.alreadySignedUPErr = res;
    });
    translate.get('subscriptionsComponent.referralPage.errors.alreadySubscribed').subscribe((res: string) => {
      this.alreadySubscribedErr = res;
    });
    translate.get('subscriptionsComponent.referralPage.addReferrals.confirmationMessage').subscribe((res: string) => {
      this.confirmationMessage = res;
    });
  }

  ngOnInit(): void {
    this.referralSubscription = this.referralService.getReferrals().subscribe(response => {
      this.referrals = response.body.data;
    }, error => console.error(error));
  }

  addReferrals() {
    this.dialog.open(ReferralComponent, {
      maxWidth: '450px',
      width: '100%',
      data: {
        redirectToHomepage: true,
        exitMessage: this.confirmationMessage,
        showExitMessageOnCancellation: false
      }
    });

  }

  modifyReferral(referral: ReferralModel){
    if (referral.account_created === true){
      this.alertService.error(this.alreadySignedUPErr);
      return;
    }
    if (referral.subscription_created === true){
      this.alertService.error(this.alreadySubscribedErr);
      return;
    }
    const dialogRef = this.dialog.open(ModifyReferralComponent, {
      maxWidth: '450px',
      width: '100%',
      data: {referral}
    });


    dialogRef.afterClosed().subscribe(resultedReferral => {
      if (resultedReferral !== undefined) {
        this.referrals = this.referrals.filter(ref => ref.email !== referral.email);
        this.referrals.push(resultedReferral);
      }
    });
  }
  ngOnDestroy() { this.referralSubscription.unsubscribe(); }
}
