<div class="listing-slider-section">
  <div class="container">
    <div class="header-section d-flex align-items-center justify-content-between">
      <h2>{{title}}</h2>
      <div class="d-flex justify-content-end my-4">
        <button class="btn-fill-sm blue-btn">See all</button>
        <div class="slider-navigation ">
          <a href="javascript:void(0);" class="slide-arrow left-arrow job-slider-left">
            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 15L2 8L9 1" stroke-width="2" stroke-linecap="round" />
            </svg>
          </a>
          <a href="javascript:void(0);" class="slide-arrow right-arrow job-slider-right">
            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 15L8 8L0.999999 1" stroke-width="2" stroke-linecap="round" />
            </svg>
          </a>
        </div>
      </div>
    </div>
    <div class="body-section">
      <div class="listing-slider job-slider">
        <div class="item-card" *ngFor="let item of data">
          <div class="item-thumb-inline">
            <img src="assets/images/company/1.png" alt="">

            <!-- Like Button -->
            <a href="javascript:void(0)" class="action-button like-button">
              <img src="assets/images/icon/Heart.png" alt="">
            </a>
            <!-- Liked -->
            <!-- <a href="javascript:void(0)" class="action-button like-button">
                  <img src="assets/images/icon/fill-Heart.png" alt="">
                </a> -->
          </div>
          <div class="item-content">
            <h2>Looking for a cinematographer our resort</h2>
            <p> Lorem ipsum dolor sit amet consectetur. Vestibulum et nibh sed sagittis est semper lorem pulvinar.
              Ac vel felis elementum elit nisi massa lorem...</p>
          </div>
          <div class="item-meta">
            <p class="mb-0"> <i class="icon-location"></i> Address goes here</p>
          </div>
          <div class="item-price">
            <i class="icon-moneys mr-2"></i> € 80/h - €120/h
          </div>
        </div>
      </div>
    </div>
  </div>