import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { JWTToken } from '../models/jwt-token';

import { environment } from '../../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { Profile } from '../models/profile';
import { LoginResponse } from './responses/loginResponse';
import { SubscriptionModel } from '../../module-subscription/models/subscriptionModel';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private apiBaseURL = environment.configSettings.apiURL;

  public currentUserSubject: BehaviorSubject<Profile> = new BehaviorSubject<Profile>(null);

  public currentJWTTokenSubject: BehaviorSubject<JWTToken>;

  public currentJWT: Observable<JWTToken>;

  public currentSubscription: BehaviorSubject<SubscriptionModel> = new BehaviorSubject<SubscriptionModel>(null);
  public noItemsOwned: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(private http: HttpClient,
              private localStorageService: LocalStorageService) {
    // We get the authorization token from the localstorage, if exists
    this.currentJWTTokenSubject = new BehaviorSubject<JWTToken>(this.localStorageService.getJwtToken);
    this.currentJWT = this.currentJWTTokenSubject.asObservable();
  }



  public get currentNoItemsOwned(): number {
    /* This function returns the current JWT token */
    if (this.noItemsOwned.value != null){
      return this.noItemsOwned.value;
    }
  }
  setNoItemsOwned(noItems: number){
    this.noItemsOwned.next(noItems);
  }

  incrementNoItemsOwned(){
    this.noItemsOwned.next(this.noItemsOwned.value + 1);
  }

  decrementNoItemsOwned(){
    if (this.noItemsOwned.value > 0){
      this.noItemsOwned.next(this.noItemsOwned.value - 1);
    }
  }

  public get currentUserSubscription(): SubscriptionModel {
    /* This function returns the current subscription  */
    if (this.currentSubscription.value != null){
      return this.currentSubscription.value;
    }
  }

  setCurrentUserSubscription(subscription: SubscriptionModel){
    this.currentSubscription.next(subscription);
  }

  public get currentTokenValue(): JWTToken {
    /* This function returns the current JWT token */
    if (this.currentJWTTokenSubject.value != null){
      return this.currentJWTTokenSubject.value;
    }
  }

  setCurrentUser(userProfile: Profile){
    this.currentUserSubject.next(userProfile);
  }

  // tslint:disable-next-line:variable-name
  login(email, password, notification_token) {
    /* This function is used in order to log in, by doing a POST request specified endpoint */
    this.currentJWTTokenSubject.next(null);
    return this.http.post<LoginResponse>(`${(this.apiBaseURL)}v1/user/login/`, { email, password, notification_token }, { observe: 'response' });
  }

  logout() {
    /* This function is used in order to log out, by removing currentJWT from local storage */
    this.localStorageService.removeJwt();
    this.currentJWTTokenSubject.next(null);
    localStorage.removeItem('cookieAllow');
    this.currentUserSubject.next(null);
    this.currentSubscription.next(null);
    this.noItemsOwned.next(null);
  }
  recoverPassword(email){
    /* This function is used in order to send a POST request to the backend in order to get a password recover email */
    return this.http.post<any>(`${(this.apiBaseURL)}v1/user/password/reset/`, { email }, { observe: 'response' });
  }
  refreshToken(): Observable<{access: string, refresh: string }>{
    /* This method refresh the JWT, by requesting a new access token using the refresh token saved in Local Storage*/
    if (this.currentJWTTokenSubject.value == null){
      return;
    }
    const currentToken = this.currentJWTTokenSubject.value;
    const refresh = currentToken.refreshToken;
    return this.http.post<any>(`${(this.apiBaseURL)}v1/user/refresh-token/`,
      { refresh }).pipe(
      tap(response => {
        this.localStorageService.removeJwt();
        this.localStorageService.setJwtToken(new JWTToken(response.access, currentToken.refreshToken));
        return response;
      })
    );
  }

}
