/* tslint:disable */
export class PasswordChange {
  old_password: string;
  password: string;
  password2: string;
  constructor(oldPassword: string, password: string, password2: string) {
    this.old_password = oldPassword;
    this.password = password;
    this.password2 = password2;
  }
}
