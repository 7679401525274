import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AlertService} from '../../../core/alert/alert.service';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from '../../services/authentication.service';
import { FieldsValidators } from '../../validators/fields-validators';

declare function close_user_modal(): any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  

  fieldTextType: boolean;

  @Output() switchComponent: EventEmitter<string> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      policy:      ['', [Validators.requiredTrue ]],
      terms:       ['', [Validators.requiredTrue ]],
      over18:      ['', [Validators.requiredTrue ]],
      privateUse:   ['', [Validators.requiredTrue ]],
      first_name:  ['', [Validators.required]],
      last_name:   ['', [Validators.required]],
      username:    ['', [Validators.required]],
      password:    ['',  [Validators.compose([
        // 1. Password Field is Required
        Validators.required,
        // 2. check whether the entered password has a number
        FieldsValidators.patternValidator(/\d/, { hasNumber: true }),
        // 3. check whether the entered password has upper case letter
        FieldsValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        // 4. check whether the entered password has a lower-case letter
        FieldsValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        // 5. Has a minimum length of 8 characters
        Validators.minLength(8)
      ])]],
      password1:   ['', Validators.compose([Validators.required])],
      email:       ['', [Validators.compose([
        // 1. Email Field is Required
        Validators.required,
        // 2. Email Field is an email
        Validators.email
      ])]]
    }, {
      // check whether our password and confirm password match
      validator: FieldsValidators.passwordMatchValidator
    });
  }

  closeModal(){
    close_user_modal();
  }
  // convenience getter for easy access to form fields
  get form() { return this.registerForm.controls; }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  onSwitchComponent(name: string) {
    this.switchComponent.emit(name);
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      console.log(this.registerForm.value);
      return;
    }


    this.loading = true;
    this.userService.register(this.registerForm.value)
      .subscribe(
        response => {
          /* 1. stop the loading
           * 2. stop the submission
           * 3. hide the modal
           * 4. show the response message on the screen
           * 5. reset the form
           * 6. change the modal back to sign in */
          this.loading = false;
          this.submitted = false;
          close_user_modal();
          this.alertService.success(response.body.message, true);
          this.registerForm.reset();
          this.switchComponent.emit('sign_in');
          this.router.navigate(['/']);
        },
        errResponse => {
          this.setErrors(errResponse.error.error);
          this.loading = false;
        });

  }

  private setErrors(error: any){
    console.log(error);
    if (error.first_name) { this.form.first_name.setErrors({backendError: error.first_name}); }
    if (error.last_name) { this.form.last_name.setErrors({backendError: error.last_name}); }
    if (error.username) { this.form.username.setErrors({backendError: error.username}); }
    if (error.password) { this.form.password.setErrors({backendError: error.password}); }
    if (error.password1) { this.form.password1.setErrors({backendError: error.password1}); }
    if (error.email) { this.form.email.setErrors({backendError: error.email}); }
    if (error.non_field_errors) { this.alertService.error(error.non_field_errors[0], true); }
  }
}
