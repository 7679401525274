<div class="listing-slider-section">
  <div class="container">
    <div class="header-section d-flex align-items-center justify-content-between">
      <h2>{{title}}</h2>
      <div class="d-flex justify-content-end my-4">
        <button  routerLink="/search-result" class="btn-fill-sm blue-btn">See all</button>
        <div class="slider-navigation ">
          <a href="javascript:void(0);" class="slide-arrow left-arrow rs-slider-left">
            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 15L2 8L9 1" stroke-width="2" stroke-linecap="round" />
            </svg>
          </a>
          <a href="javascript:void(0);" class="slide-arrow right-arrow rs-slider-right">
            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 15L8 8L0.999999 1" stroke-width="2" stroke-linecap="round" />
            </svg>
          </a>
        </div>
      </div>
    </div>
    <div class="body-section" *ngIf="!loading">
      <div class="listing-slider rs-slider">
        <div class="item-card" *ngFor="let item of filterData">
          <listing-item [item]="item"></listing-item>
        </div>
      </div>
    </div>
  </div>