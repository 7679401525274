import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { AlertService } from '../../core/alert/alert.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-logout',
  template: '',

})
export class LogoutComponent implements OnInit, OnDestroy {
  sub: Subscription;
  constructor(private route: ActivatedRoute,
              private userService: UserService,
              private authenticationService: AuthenticationService,
              private router: Router,
              private alertService: AlertService) { }

  ngOnInit(): void {

    this.sub = this.route.params.subscribe(params => {
      this.userService.logout()
        .subscribe(
          response => {
            this.alertService.success(response.body.message, true);
            this.authenticationService.logout();
            this.router.navigate(['/']);
          },
          error => {
            this.alertService.error(error.error.error);
            this.router.navigate(['/']);
          });
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
