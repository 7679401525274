import { FormFieldBase } from './base/form-field-base';

export class NumberInput extends FormFieldBase<number> {
  type = 'number';
  controlType = 'number';
  step: number;
  min: number;
  max: number;

  constructor(options: {
    value?: any;
    key?: any;
    label?: string;
    required?: boolean;
    order?: number;
    controlType?: string;
    type?: string;
    options?: {key: string, value: string}[];
    step?: number;
    min?: number;
    max?: number;
  } = {}) {
    super(options);
    this.step = options.step || 1;
    this.min = options.min || 0;
    this.max = options.max || 99999;
  }
}



