import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { EntityService } from '../../services/entity.service';
import { FormGroup } from '@angular/forms';
import { EntityClass } from '../../models/entityClass';
import { EntityAdditionService } from '../service/entity-addition.service';
import { DropdownInput } from '../../../core/models/forms/dropdown-input';
import { FormControlService } from '../../../core/services/form-control.service';
import { TextboxInput } from '../../../core/models/forms/textbox-input';
import { NumberInput } from '../../../core/models/forms/number-input';
import { CheckboxInput } from '../../../core/models/forms/checkbox-input';
import { environment } from '../../../../environments/environment';
import { AlertService } from '../../../core/alert/alert.service';
import { DateInput } from '../../../core/models/forms/date-input';
import { RadioInput } from '../../../core/models/forms/radio-input';
import { MockInput } from '../../../core/models/forms/mock-input';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-add-properties',
  templateUrl: './add-properties.component.html',

})
export class AddPropertiesComponent implements OnInit, OnChanges {
  @Output() currentComponent: EventEmitter<any> = new EventEmitter<any>();

  @Input() entityClass: EntityClass;
  form: FormGroup;
  entityFormBase = [];
  coverImage;

  entityAttributes = new Map<string, {
    id: any,
    type: string,
    value: any
  }>();

  groupOfForm: Array<{id: string, name: string}> = [];

  errorMessage = '';

  constructor(private entityService: EntityService,
              private entityAdditionService: EntityAdditionService,
              private formControlService: FormControlService,
              private alertService: AlertService,
              translate: TranslateService) {
    translate.get('entityComponent.addEntity.errorMessage').subscribe((res: string) => {
      this.errorMessage = res;
    });
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    /*
     * This method creates and initializes the form.
     * If the entityClass is input in the component then it starts creating the base form fields and
     * dynamically it creates on input field for each attribute of the entity.
     * 1. It creates the base fields (Title, Description)
     * 2. It creates the entity input fields dynamically according to their type and requirements.
     * 3. It gets the form.
     * 4. In case of an update it sets the fields to the current entity that is being updated.
     */

    // Reinitialise all of the values in case of going back
    // this.form = null;
    this.groupOfForm = [];
    this.entityFormBase = [];
    // console.log('reinit attributes');
    this.entityAttributes = new Map<string, {
      id: any,
      type: string,
      value: any
    }>();

    // console.log(this.entityAttributes );


    if (this.entityClass != null){

      // console.log(this.entityClass);
      // 1.
      this.entityFormBase.push(
        new TextboxInput({
          key: 'title',
          label: 'Title',
          required: true
        })
      );
      this.entityFormBase.push(
        new TextboxInput({
          key: 'description',
          label: 'Description',
          required: true
        })
      );
      this.entityFormBase.push(
        new NumberInput({
          key: 'price',
          label: 'Price',
          required: true
        })
      );

      // 2.
      this.entityClass.attributes.forEach(attr => {
        // Set the form field id in order to get it later
        this.entityAttributes.set(attr.id.toString(), {
          id: attr.id.toString(),
          type: attr.type,
          value: null
        });

        if (attr.choices != null && attr.type !== 'boolean'){
          /* This function creates and sets the form fields dynamically accordingly to the attribute type:
           * Any type with choices - > Dropdown input
           * Type integer -> number input, default step=1, min=0
           * Type float   -> number input, default step=0.5, min=0
           * Type boolean -> checkbox input
           * Type date    -> date input
           */
          const attrChoices = [];
          attr.choices.split(',').forEach(choice => {
            attrChoices.push({key: choice, value: choice});
          });
          const dropDown = new DropdownInput({key: attr.id.toString(), label: attr.name, options: attrChoices,
            required: attr.required, value: ''});
          this.entityFormBase.push(dropDown);
        } else if (attr.type === 'integer'){
          const numberInput = new NumberInput({key: attr.id.toString(), label: attr.name, required: attr.required });
          this.entityFormBase.push(numberInput);
        } else if (attr.type === 'float'){
          const numberInput = new NumberInput({key: attr.id.toString(), label: attr.name, required: attr.required, step: 0.5});
          this.entityFormBase.push(numberInput);
        } else if (attr.type === 'boolean') {
          if (attr.choices != null){
            // Form groups of checkboxes or radios

            const choices = attr.choices.split(',');

            choices.forEach((choice, index) => {
              // add the form group parent to the list
              if (index === 0 ){
                this.groupOfForm.push({id: attr.id.toString(), name: attr.name});
                if (choice === 'radio' ){
                  // for radio we need a parent in order to set the formField
                  const radInput = new MockInput({key: `${attr.id}-parent`, label: choice});
                  this.entityFormBase.push(radInput);
                }
              }
              if (choices[0] === 'checkbox' && index !== 0 ){
                const checkBox = new CheckboxInput({key: `${attr.id}-${index}`, label: choice});
                this.entityFormBase.push(checkBox);
              }else if (choices[0] === 'radio' && index !== 0){
                const radioInput = new RadioInput({key: `${attr.id}-${index}`, label: choice});
                this.entityFormBase.push(radioInput);
              }
            });
          }else{
            // Normal checkbox field
            const checkBox = new CheckboxInput({key: attr.id.toString(), label: attr.name});
            this.entityFormBase.push(checkBox);
          }

        } else if (attr.type === 'date') {
          const dateInput = new DateInput({key: attr.id.toString(), label: attr.name});
          this.entityFormBase.push(dateInput);
        } else  {
          const textInput = new TextboxInput({key: attr.id.toString(), label: attr.name, required: attr.required});
          this.entityFormBase.push(textInput);
        }
      });

      // 3.
      this.form = this.formControlService.toFormGroup(this.entityFormBase);

      // 4.
      if (this.entityAdditionService.currentEntity.title !== undefined){
        // In case of entity update we set the default values to the entity values
        this.formControls.title.setValue(this.entityAdditionService.currentEntity.title);
        this.formControls.description.setValue(this.entityAdditionService.currentEntity.description);
         this.formControls.price.setValue(this.entityAdditionService.currentEntity.price);
        this.coverImage = this.entityAdditionService.currentEntity.cover_image;
        this.entityAdditionService.currentEntity.attribute_values.forEach(attr => {
          for (const formControlsKey in this.formControls) {
            if (parseInt(formControlsKey, 10) === attr.attribute.id ){
              switch (attr.attribute.type) {
                case 'text': {
                  this.formControls[formControlsKey].setValue(attr.value_text);
                  break;
                }
                case 'integer': {
                  this.formControls[formControlsKey].setValue(attr.value_integer);
                  break;
                }
                case 'float': {
                  this.formControls[formControlsKey].setValue(attr.value_float);
                  break;
                }
                case 'boolean': {
                  if (attr.value_boolean === '0'){ this.formControls[formControlsKey].setValue(''); }
                  else if (attr.value_boolean === '1'){ this.formControls[formControlsKey].setValue('1'); }
                  else {

                    const values = attr.value_boolean.split(',');
                    const index = parseInt(formControlsKey.split('-')[1], 10) - 1;
                    // todo implement group of radios
                    // console.log('values');
                    // console.log(values);
                    // console.log('index');
                    // console.log(index);
                    // console.log('formControlsKey');
                    // console.log(formControlsKey);
                    // console.log(parseInt(values[index],10));

                    if ( parseInt(values[index], 10) === 0){
                      // console.log('nothing');
                      this.formControls[formControlsKey].setValue(''); }
                    else if (parseInt(values[index], 10) === 1){
                      // console.log('1');
                      this.formControls[formControlsKey].setValue('1'); }
                    }

                  break;
                }
                case 'date': {
                  this.formControls[formControlsKey].setValue(attr.value_date);
                  break;
                }
              }
            }
          }
        });
      }
    }

  }


  get formControls() { return this.form.controls; }

  onFileChange(event) {
    /* This function is responsible for setting the image inputted on the form data and reading it in order to
    * show it in the template.
    * 1. It validates the format of the file
    * 2. It sets the image on the form data
    * 3. It reads the image and stores it in a variable used in interpolation for showing it in the browser
    * */
    if (event.target.files && event.target.files[0]) {

      const imageFormat = event.target.files[0].name.split('.')[event.target.files[0].name.split('.').length - 1].toUpperCase();
      // 1.
      if (!(environment.acceptedImagesFormat.indexOf(imageFormat) > -1)){
        this.alertService.error(`${this.errorMessage} ${environment.acceptedImagesFormat}`);
        return;
      }
      // 2.
      this.entityAdditionService.addImageToFormData('cover_image',  event.target.files[0]);
      const filesAmount = event.target.files.length;
      // 3.
      for (let i = 0; i < filesAmount; i++) {
        const reader = new FileReader();

        // tslint:disable-next-line:no-shadowed-variable
        reader.onload = (event: any) => {
          this.coverImage = event.target.result;
          // this.galleryForm.patchValue({
          //   fileSource: this.images
          // });
        };

        reader.readAsDataURL(event.target.files[i]);
      }

    }
  }

  onSubmit() {

    // stop here if form is invalid
    if (this.form.invalid) {
      // console.log(this.form);
      return;
    }

    let currentId = null;
    let aux = '';

    // tslint:disable-next-line:forin
    for ( const field in this.form.value){
      const entityAttr = this.entityAttributes.get(field);
      // Set the attribute values
      if ( field != null && entityAttr !== undefined){ this.entityAttributes.get(field).value = this.form.getRawValue()[field]; }
      // Find all of the form group for checkboxes and radios
      if (field.includes('-')){
        // Find the group id
        const groupParentId = this.groupOfForm.filter(value => value.id === field.split('-')[0])[0].id;
        if (groupParentId !== currentId){
          // set the group to the attribute as a single value. Ex. '0,0,0'
          if ( currentId !== null && aux !== '') { this.entityAttributes.get(currentId).value = aux; aux = ''; }
          currentId = groupParentId; aux = '';  } else { aux = aux + ',';  }
        // todo radio input takes the values wrong
        if (this.form.getRawValue()[field] === '' ||
          this.form.getRawValue()[field] === 'false' ||
          this.form.getRawValue()[field] === false ||
          this.form.getRawValue()[field] === undefined
        ){ aux = aux + '0'; } else { aux = aux + '1';  }

      }
    }
    // In case the group is at the end
    if ( currentId !== null && aux !== '') { this.entityAttributes.get(currentId).value = aux; }


    this.entityAdditionService.cover_image = 'cover_image';


    this.entityAdditionService.description = this.form.getRawValue().description;

    this.entityAdditionService.price = this.form.getRawValue().price;
    this.entityAdditionService.title = this.form.getRawValue().title;

    // Reset the attributes
    this.entityAdditionService.attribute = null;



    // set the attributes on the entity
    this.entityAttributes.forEach(value => {
      value.id = parseInt(value.id, 10);
      this.entityAdditionService.attribute = value;
    });

    // console.log('Setting attributes ');
    // console.log(this.entityAdditionService.attribute);
    //
    // console.log('Current entity ');
    // console.log(this.entityAdditionService.entity.value);

    // change the screen
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.currentComponent.emit('showAddGallery');

  }

}
