import { Component, OnDestroy, OnInit} from '@angular/core';
import { UserService } from '../../services/user.service';
import { ActivatedRoute, Router} from '@angular/router';
import { Subscription } from 'rxjs';
import { UserInformation } from '../../models/userInformation';
import { AlertService } from '../../../core/alert/alert.service';

@Component({
  selector: 'app-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.css']
})
export class ProfileDetailComponent implements OnInit, OnDestroy {
  paramsSubscription: Subscription;
  userProfile: UserInformation;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              private alertService: AlertService) { }

  ngOnInit(): void {
      this.paramsSubscription = this.route.params.subscribe(params => {
      this.userService.getUserInformation(params.username, params.entityHash).subscribe(response => {
        this.userProfile = response.body.data;
        // console.log(response.body.data);
      }, errorResponse => {
        this.alertService.error(errorResponse.error.error, true);
        this.router.navigate(['/']);
      });
    });
  }

    ngOnDestroy(): void { this.paramsSubscription.unsubscribe(); }

}
