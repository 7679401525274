<div class="user-page-sec p-relative" *ngIf="userProfile">
  <div class="main-image-top p-relative">
    <img
      src="assets/images/banner/subbanner.jpg"
      class="img-fluid full-width bg-blue"
      alt="#"
    />
    <div class="overlay" style="background-color: #6f93c6">
      <h1 class="profile-title">
        {{ "entityComponent.detail.ownerTable.title" | translate }}
      </h1>
    </div>
  </div>
  <div class="user-page-details-sec">
    <div class="container-fluid">
      <div class="row">
        <div class="offset-lg-1 col-lg-10">
          <div class="user-about">
            <div class="row">
              <div class="col-md-6">
                <div class="user-left-wrapper">
                  <div class="user-img">
                    <img
                      *ngIf="!userProfile.profile_image"
                      src="assets/images/user/user-default.jpg"
                      class="rounded-circle"
                      alt="#"
                    />
                    <img
                      *ngIf="userProfile.profile_image"
                      src="{{ userProfile.profile_image }}"
                      class="rounded-circle"
                      alt="#"
                      style="height: 120px; width: 120px"
                    />
                  </div>
                  <div class="user-details">
                    <div class="user-details-top">
                      <h2
                        class="title text-custom-white"
                        *ngIf="userProfile.last_name"
                      >
                        {{ userProfile.last_name }}
                        <span style="background: #007bff"
                          ><i class="fas fa-check"></i
                        ></span>
                      </h2>
                      <span
                        ><a href="javascript:void(0);" class="title text-light"
                          ><i class="fas fa-link"></i> @{{
                            userProfile.username
                          }}
                        </a></span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="user-right-wrapper">
                  <div class="star-rating">
                    <!-- TODO: Add star rating here -->
                  </div>
                  <div class="comment-details">
                    <ul style="height: 50px">
                      <!-- TODO: Add user details here -->
                    </ul>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="space-85"></div>
  <div class="main-image-sec">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8" style="min-height: 500px">
          <div class="card card-custom">
            <div class="card-header">
              <h2 class="card-title">
                {{ "entityComponent.detail.ownerTable.title" | translate }}
              </h2>
            </div>
            <div class="card-body">
              <table class="table table-borderless table-striped text-center">
                <tbody>
                  <tr *ngIf="userProfile.username">
                    <td>
                      {{
                        "entityComponent.detail.ownerTable.username" | translate
                      }}
                    </td>
                    <td>{{ userProfile.username }}</td>
                  </tr>
                  <tr *ngIf="userProfile.email">
                    <td>
                      {{
                        "entityComponent.detail.ownerTable.email" | translate
                      }}
                    </td>
                    <td>{{ userProfile.email }}</td>
                  </tr>
                  <tr *ngIf="userProfile.first_name">
                    <td>
                      {{
                        "entityComponent.detail.ownerTable.firstName"
                          | translate
                      }}
                    </td>
                    <td>{{ userProfile.first_name }}</td>
                  </tr>
                  <tr *ngIf="userProfile.last_name">
                    <td>
                      {{
                        "entityComponent.detail.ownerTable.lastName"
                          | translate
                      }}
                    </td>
                    <td>{{ userProfile.last_name }}</td>
                  </tr>
                  <tr *ngIf="userProfile.phone_number">
                    <td>
                      {{
                        "entityComponent.detail.ownerTable.phone" | translate
                      }}
                    </td>
                    <td>{{ userProfile.phone_number }}</td>
                  </tr>
                  <tr *ngIf="userProfile.post_code">
                    <td>
                      {{
                        "entityComponent.detail.ownerTable.postcode" | translate
                      }}
                    </td>
                    <td>{{ userProfile.post_code }}</td>
                  </tr>
                  <tr *ngIf="userProfile.state">
                    <td>
                      {{
                        "entityComponent.detail.ownerTable.state" | translate
                      }}
                    </td>
                    <td>{{ userProfile.state }}</td>
                  </tr>
                  <tr *ngIf="userProfile.employment_status">
                    <td>
                      {{
                        "entityComponent.detail.ownerTable.employmentStatus"
                          | translate
                      }}
                    </td>
                    <td>{{ userProfile.employment_status }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>
