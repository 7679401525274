

<div class="step-tab-panel" id="step2">
  <div class="tab-from-content">
    <div class="title-icon">
      <h2 class="title rubik fw-600"><i class=""></i>
        {{'entityComponent.addEntity.steps.gallery.subtitle' | translate }}
      </h2>
    </div>
    <div class="row mb-3">
      <div *ngFor='let imgObj of images; let i = index' class="col-lg-3">
        <div style="position:relative;">
          <button class="close AClass text-danger" (click)="removeImage(i, imgObj.name)" >
         
            <span>&times;</span>
          </button>
          <img  [src]="imgObj.url ? imgObj.url : imgObj"  style="margin: 3px;">
        </div>

      </div>
    </div>
    <div class="p-relative drag-drop-sec">
      <form class="drag-drop" [formGroup]="galleryForm" (ngSubmit)="onSubmit()">
        <div class="row clearfix">
          <div class="col-12">
            <div class="form-group">
              <input
                type="file"
                multiple
                formControlName="file"
                (change)="onFileChange($event)"
              >

              <div class="add-gallery-text text-center"> <i class="far fa-image"></i>
                <span class="drag-label">
                  {{'entityComponent.addEntity.steps.gallery.inputFields.images.placeholder' | translate }}
                </span>
              </div>
            </div>

          </div>
        </div>
        <div class="step-footer step-tab-pager mt-5 justify-content-center">
          <button type="submit"
                  class="btn-second blue-btn" style="width: 200px;">
            {{'entityComponent.addEntity.buttons.next' | translate }}
          </button>
          <input type="submit"
                 class="btn-second blue-btn"  value="{{'entityComponent.addEntity.buttons.next' | translate }}"/>
        </div>
      </form>
    </div>
  </div>
</div>
