<!-- Desktop Menu -->
<div  (window:resize)="onResize($event)" id="menu-style-2" class="bg-blue" style="height: 60px;">
  <div class="container">
    <div class="row" >
      <div *ngIf="is_mobile"  class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-10" >
        <div class="category-sec-head bg-custom-black p-relative">
          <span class="black-sepreta"></span>
          <button class="text-custom-white full-width bg-transparent fw-600 category-button" id="categoryheadaccordionbtn" (click)="openMenu()">
            <i class="flaticon-menu"></i>{{'header.allCategories' | translate }}
          </button>

          <div id="accordion" class="categoryheadaccordion" style="width: 270px;">
            <div class="card" *ngFor="let category of categories">
              <div class="card-header" id="headingOne1">
                <h5 class="mb-0 rubik">
                  <button class="collapsed" data-toggle="collapse" [attr.data-target] = "'#cat-' +  category.id"  aria-expanded="true" aria-controls="accordion">
                    <i class="{{category.icon}}"></i>{{ category.name }}
                  </button>
                </h5>
              </div>

              <div id="cat-{{category.id}}" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body sub-header-menu-links scrollLink">
                  <ul>
                    <li *ngFor="let child of category.children">
                      <a href="javascript:void(0)" (click)="searchEntityClass( child.name )">
                        <i class="{{child.icon}}"></i>&nbsp;{{ child.name }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!is_mobile"  class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-10" >
        <div class="category-sec-head bg-custom-black p-relative">
          <span class="black-sepreta"></span>
          <button class="text-custom-white full-width bg-transparent fw-600 category-button" id="categoryheadaccordionbtn1" (click)="openMenu()">
            <i class="flaticon-menu"></i>{{'header.menu' | translate }}
          </button>
          <div id="accordion1" class="categoryheadaccordion" style="width: 270px;">
            <div class="card">
              <div class="card-header">
                <h5 class="mb-0 rubik">
                  <a href="javascript:void(0)" routerLink="/">
                    <button class="text-light-black fw-600">
                      <i class=""></i>{{'header.home' | translate }}
                    </button>
                  </a>
                </h5>
              </div>
              <div class="card-header">
                <h5 class="mb-0 rubik">
                    <a href="javascript:void(0)" routerLink="/pricing">
                      <button class="text-light-black fw-600" style="content: ''">
                        <i class=""></i>{{'header.pricing' | translate }}
                      </button>
                    </a>
                </h5>
              </div>
              <div class="card-header">
                <h5 class="mb-0 rubik">
                    <a href="javascript:void(0)" routerLink="/about-us">
                      <button class="text-light-black fw-600">
                        <i class=""></i>{{'header.aboutUs' | translate }}
                      </button>
                    </a>
                </h5>
              </div>
              <div class="card-header">
                <h5 class="mb-0 rubik">
                    <a href="javascript:void(0)" routerLink="/download">
                      <button class="text-light-black fw-600">
                        <i class=""></i>{{'header.download' | translate }}
                      </button>
                    </a>
                </h5>
              </div>
              <div class="card-header">
                <h5 class="mb-0 rubik">
                    <a href="javascript:void(0)" routerLink="/contact">
                      <button class="text-light-black fw-600">
                        <i class=""></i>{{'header.contact' | translate }}
                      </button>
                    </a>
                </h5>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-8 col-sm-6 col-2">
        <div class="navigation" >
          <nav class="d-none d-md-block">
            <ul>
                <li class="menu-item dropdownbtn" style="font-size: 85%" *ngFor="let category of categories"><a href="javascript:void(0)" class="text-custom-white fw-600 dropdown-toggle">{{ category.name }}</a>
                  <ul class="dropdown-menu">
                    <li *ngFor="let child of category.children">
                      <a href="javascript:void(0)" (click)="searchEntityClass( child.name )" class="text-light-black">
                        <i class="{{child.icon}}"></i>&nbsp;{{ child.name }}</a>
                    </li>
                  </ul>
                </li>
            </ul>
          </nav>
          <button class="d-block d-md-none mt-2" type="button" id="mobile-open-nav">
            <i class="flaticon-menu"></i>
          </button>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-12">
        <div class="shipping-content">
          <p class="no-margin" style="height: 0px;">
            <a routerLink="javascript:void(0)" *ngIf="userProfile" class="text-custom-white no-margin fw-500 text-right" (click)="openSearchTab()">{{'header.automaticSearch' | translate }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Desktop Menu -->

