import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AlertService } from '../../../core/alert/alert.service';
import { AuthenticationService } from '../../../module-user/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';



@Injectable({
  providedIn: 'root'
})
export class ListingItemGuardService implements CanActivate {


  preferencesErrorText = '';
  addressErrorText = '';
  constructor(private router: Router,
              private alertService: AlertService,
              private authenticationService: AuthenticationService,
              translate: TranslateService) {
    // Getting translation for selection
    translate.get('errors.preferences').subscribe((res: string) => {
      this.preferencesErrorText = res;
    });
    translate.get('errors.address').subscribe((res: string) => {
      this.addressErrorText = res;
    });
  }

  canActivate() {

    if (this.authenticationService.currentUserSubject.value === null){
      // User is not logged in
      this.router.navigate(['/'], );
      return false;
    }

    if (this.authenticationService.currentUserSubject.value.has_notification_preferences === false) {
      // User doesn't have communication preferences set
      return this.returnToRoute(this.preferencesErrorText, '/user');
    }
    if (this.authenticationService.currentUserSubject.value.has_valid_address === false) {
      // User doesn't have billing address set
      return this.returnToRoute(this.addressErrorText, '/user');
    }

    return true;
  }

  returnToRoute(message: string, route: string){
    this.alertService.error(message, true);
    this.router.navigate([route], );
    return false;
  }

}
