import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { GetPreferenceResponse } from './responses/getPreferenceResponse';
import { CreatePreferenceResponse } from './responses/createPreferenceResponse';
import { Preference} from '../models/preference';
import { UpdatePreferenceResponse } from './responses/updatePreferenceResponse';
import { GetSearchPreferenceResponse } from './responses/getSearchPreferenceResponse';
import { SearchPreference } from '../models/searchPreference';
import { CreateSearchPreferenceResponse } from './responses/createSearchPreferenceResponse';
import { UpdateSearchPreferenceResponse } from './responses/updateSearchPreferenceResponse';
import {BehaviorSubject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PreferenceService {

  userPreference: BehaviorSubject<Preference> = new BehaviorSubject<Preference>(null);

  private apiBaseURL = environment.configSettings.apiURL;

  constructor(private http: HttpClient) { }


  set currentUserPreference(preference: Preference){
    this.userPreference.next(preference);
  }

  getPreference() {
    return this.http.get<GetPreferenceResponse>(`${this.apiBaseURL}v1/user/preference/`, { observe: 'response' });
  }
  createPreference(preference: Preference) {
    return this.http.post<CreatePreferenceResponse>(`${this.apiBaseURL}v1/user/preference/`, preference, { observe: 'response' });
  }
  updatePreference(preference: Preference) {
    return this.http.put<UpdatePreferenceResponse>(`${this.apiBaseURL}v1/user/preference/`, preference, { observe: 'response' });
  }


}


