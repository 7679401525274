<div class="mobile-menu-second">
  <!-- listing -->
  <section class="section-padding listing-with-map listing-with-map-3" style="min-height: 600px;">
    <div class="container">
      <!-- Sub header -->
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <div class="text-wrapper text-center">
              <h2 class="fw-500 no-margin font-weight-bolder">
                {{'subscriptionsComponent.referralPage.listingPage.title' | translate }}
              </h2>
              <span class="line-grey"></span><span class="line-blue"></span><span class="line-grey"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center " *ngIf="referrals.length  === 0">
        <button class="btn-second blue-btn rounded" (click)="addReferrals() ">
          {{'subscriptionsComponent.referralPage.listingPage.buttons.addReferrals' | translate }}
        </button>
      </div>


      <ng-container *ngIf="!loading && referrals.length  > 0">
        <div class="row">
          <div class="col-lg-12">

            <div class="d-flex justify-content-center" *ngFor="let referral of referrals">
              <div class="card mt-3" style="width: 26rem; height: 11rem;">
                <div class="card-body ">
                  <h2 class="card-title font-weight-bolder text-center">{{referral.email}}</h2>
                  <table class="m-auto w-100 table-borderless" cellspacing="0" cellpadding="0">
                    <tr>
                      <td>{{'subscriptionsComponent.referralPage.listingPage.table.hasAccount' | translate }}</td>
                      <td class="float-right">
                        <i class="fa fa-times-circle text-danger" *ngIf="referral.account_created === false"></i>
                        <i class="fa fa-check-circle text-success" *ngIf="referral.account_created === true"></i>
                      </td>
                    </tr>
                    <tr>
                      <td> {{'subscriptionsComponent.referralPage.listingPage.table.subscribed' | translate }}</td>
                      <td class="float-right">
                        <i class="fa fa-times-circle text-danger" *ngIf="referral.subscription_created === false"></i>
                        <i class="fa fa-check-circle text-success" *ngIf="referral.subscription_created === true"></i>
                      </td>
                    </tr>

                  </table>
                  <div id="management-bttns" class="mt-2">
                    <div class="d-flex justify-content-center "  >
                      <a href="javascript:void(0);" (click)="modifyReferral(referral)">
                        <i class="fas fa-cog fa-2x  text-dark"></i>
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>


<!--            <table class="text-center small shadow-sm table table-striped">-->
<!--              <thead>-->
<!--                <th>{{'subscriptionsComponent.referralPage.listingPage.table.email' | translate }}</th>-->
<!--                <th>{{'subscriptionsComponent.referralPage.listingPage.table.hasAccount' | translate }}</th>-->
<!--                <th>{{'subscriptionsComponent.referralPage.listingPage.table.subscribed' | translate }}</th>-->
<!--                <th>{{'subscriptionsComponent.referralPage.listingPage.table.modify' | translate }}</th>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr *ngFor="let referral of referrals">-->
<!--                <th>{{referral.email}}</th>-->
<!--                <th>-->
<!--                  <i class="fa fa-times-circle text-danger" *ngIf="referral.account_created === false"></i>-->
<!--                  <i class="fa fa-check-circle text-success" *ngIf="referral.account_created === true"></i>-->
<!--                </th>-->
<!--                <th>-->
<!--                  <i class="fa fa-times-circle text-danger" *ngIf="referral.subscription_created === false"></i>-->
<!--                  <i class="fa fa-check-circle text-success" *ngIf="referral.subscription_created === true"></i>-->
<!--                </th>-->
<!--                <th>-->
<!--                  <a href="javascript:void(0);" (click)="modifyReferral(referral)">-->
<!--                    <i class="fas fa-cog text-dark"></i>-->
<!--                  </a>-->
<!--                </th>-->
<!--              </tr>-->
<!--              </tbody>-->


<!--            </table>-->

          </div>
        </div>


      </ng-container>

      <div class="ball-container" *ngIf="loading">
        <div class="ball ball-one"></div>
        <div class="shadow shadow-one"></div>
      </div>

    </div>
  </section>
</div>
