<div class="become-seller-section">
  <div class="container">
    <div class="bs-card">
      <img src="assets/images/sell-ev-agent.png" alt="">
      <div class="row">
        <div class="col-md-8">
          <h2>{{'footer.sellEverything' | translate }}</h2>
          <p>{{'footer.becomeSeller' | translate }}</p>

          <ng-container *ngIf="userProfile === null">
            <button class="become-seller-btn" data-target="#register" data-toggle="modal">
              {{'footer.becomeSellerNow' | translate }}
            </button>
          </ng-container>
          <ng-container *ngIf="userProfile !== null">
            <button  class="become-seller-btn" routerLink="/item/add">
              {{'footer.becomeSellerNow' | translate }}
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
