import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class FieldsValidators {

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }
  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password').value; // get password from our password form control
    const confirmPassword: string = control.get('password1').value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('password1').setErrors({ NoPasswordMatch: true });
    }
  }

  static dateRangeValidator(min: Date, max: Date): ValidatorFn {
    return control => {
      if (!control.value) { return null; }

      const dateValue = new Date(control.value);

      if (min && dateValue < min) {
        return { message: 'The date needs to be higher than ' + min};
      }

      if (max && dateValue > max) {
        return { message: 'The date needs to be lower than ' + max };
      }
      control.setErrors({ InvalidDate: true });
      return null;
    };
  }
  static oneValueHasToBeChangedValidator(initialValue: any, error: ValidationErrors): ValidatorFn {
    return control => {
      if (control.value !== initialValue) {
        return null;
      }else{
        return error;
      }
    };
  }

}
