import {Component, HostListener, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Profile} from '../../../module-user/models/profile';
import {SearchService} from '../../../module-search/services/search.service';
import {Router} from '@angular/router';
import {ModuleUserComponent} from '../../../module-user/module-user.component';

//#region Methods which are defined in the .js files.
declare function close_header_menu(): any;
declare function open_header_menu(): any;
@Component({
  selector: 'app-desktop-menu',
  templateUrl: './desktop-menu.component.html',
})
export class DesktopMenuComponent implements OnInit, OnChanges {
  @Input() categories: any;
  @Input() userProfile: Profile = null;
  is_mobile = false;

  constructor( private searchService: SearchService,
               private router: Router) { }

  ngOnInit(): void {
    if (window.innerWidth <= 768) { // 360 768px portrait
      this.is_mobile = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.is_mobile = false;
    if (event.target.innerWidth <= 768) { // 768px portrait
      this.is_mobile = true;
    }
  }

  openMenu(){
    open_header_menu();
  }

  searchEntityClass(entityClassName){
    close_header_menu();
    this.router.navigate(['/']);
    setTimeout(() => this.searchService.currentEntityClassSearch = entityClassName, 50);
  }


  ngOnChanges(changes: SimpleChanges) {}

  openSearchTab(){
    this.router.navigate(['user', {showPreferences: true}]);
  }

}

