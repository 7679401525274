<div class="container mt-5">
  <div class="justify-content-center mt-5">
    <div class="col-lg-6 m-auto mt-5">

      <div class="card text-center">
        <form #passwordReset [formGroup]="passwordResetForm" (ngSubmit)="onSubmit()">
          <div class="card-header">
            {{'passwordReset.title' | translate }}
          </div>
          <div class="card-body">
            <label for="reset-password-field">{{'passwordReset.password' | translate }}</label>
              <div class="input-group mb-3">
                <input id='reset-password-field'
                       [type]="fieldTextType ? 'text' : 'password'"
                       formControlName="password"
                       class="form-control custom-input-group-height"
                       [ngClass]="{ 'is-invalid': submitted && form.password.errors }"
                       placeholder="{{'passwordReset.desiredPass' | translate }}"
                />
                <div class="input-group-append">
                  <span class="input-group-text custom-span-input-group">
                    <i
                      class="fa"
                      [ngClass]="{
                                    'fa-eye-slash': !fieldTextType,
                                    'fa-eye': fieldTextType
                                  }"
                      (click)="toggleFieldTextType()"
                    ></i>
                  </span>
                </div>
                <div *ngIf="submitted && form.password.errors" class="invalid-feedback">
                  <div *ngIf="form.password.errors.required">{{'passwordReset.fieldRequired' | translate }}</div>
                  <div *ngIf="form.password.errors.hasNumber">{{'passwordReset.containsNumber' | translate }}</div>
                  <div *ngIf="form.password.errors.hasCapitalCase">{{'passwordReset.containsCapital' | translate }}</div>
                  <div *ngIf="form.password.errors.hasSmallCase">{{'passwordReset.containsSmall' | translate }}</div>
                  <div *ngIf="form.password.errors.minLength">{{'passwordReset.minimumCharacters' | translate }}</div>
                </div>
              </div>
              <label for="reset-password1-field">{{'passwordReset.repeatPassword' | translate }}</label>
              <div class="input-group">

                <input id='reset-password1-field'
                       [type]="fieldTextType ? 'text' : 'password'"
                       formControlName="password1"
                       class="form-control custom-input-group-height"
                       [ngClass]="{ 'is-invalid': submitted && form.password1.errors }"
                       placeholder="{{'passwordReset.repeatPassword' | translate }}"
                />
                <div class="input-group-append">
                  <span class="input-group-text custom-span-input-group">
                    <i
                      class="fa"
                      [ngClass]="{
                                    'fa-eye-slash': !fieldTextType,
                                    'fa-eye': fieldTextType
                                  }"
                      (click)="toggleFieldTextType()"
                    ></i>
                  </span>
                </div>
                <div *ngIf="submitted && form.password1.errors" class="invalid-feedback">
                  <div *ngIf="form.password1.errors.required">{{'passwordReset.fieldRequired' | translate }}</div>
                  <div *ngIf="form.password1.errors.NoPasswordMatch">{{'passwordReset.doNotMatch' | translate }}</div>

                </div>
              </div>



          </div>
          <div class="card-footer text-muted">
            <button [disabled]="loading" class="btn-second blue-btn full-width">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              {{'passwordReset.changePassword' | translate }}
            </button>
          </div>
        </form>
      </div>


    </div>
  </div>
</div>
