import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ContactUs } from '../models/ContactUs';
import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class ContactusService {
  private apiBaseURL = environment.configSettings.apiURL;

  constructor(private http: HttpClient) { }

  sendContactus(contactus: ContactUs) {
    return this.http.post<any>(`${this.apiBaseURL}v1/communication/contactus/`, contactus, { observe: 'response' });
  }
}
