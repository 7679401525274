/* tslint:disable */

export class Preference {
  id: number;
  email_communication: boolean;
  show_email: boolean;
  show_address_phone_number: boolean;
  show_last_name: boolean;
  show_first_name: boolean;
  show_post_code: boolean;
  show_country: boolean;
  show_state: boolean;
  show_address_line_1: boolean;
  show_employment_status: boolean;

  constructor(formControls) {
    this.email_communication = formControls.emailCommunication.value;
    this.show_email = formControls.showEmail.value;
    this.show_address_phone_number = formControls.showAddressPhoneNumber.value;
    this.show_last_name = formControls.showLastName.value;
    this.show_first_name = formControls.showFirstName.value;
    this.show_post_code= formControls.showPostCode.value;
    this.show_country = formControls.showCountry.value;
    this.show_state = formControls.showState.value;
    this.show_address_line_1 = formControls.showAddressLine1.value;
    this.show_employment_status = formControls.showEmploymentStatus.value;
  }
}
