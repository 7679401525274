/* tslint:disable */
import { SearchPreferenceAttribute } from "./searchPreferenceAtrribute";

export class SearchPreference {
  hash_pref: string;
  entity_class: number;
  city: number;
  radius: number;
  search_preference_attributes: Array<SearchPreferenceAttribute>;
  constructor(
              entityClass: number,
              city: number,
              radius: number,
              searchPreferences: Array<SearchPreferenceAttribute>,
              hashPref ?: string
  ) {
    this.hash_pref = hashPref;
    this.entity_class = entityClass;
    this.city = city;
    this.radius = radius;
    this.search_preference_attributes = searchPreferences;
  }
}
