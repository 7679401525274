<div class="container" *ngIf="entity">

  <button (click)="goBack()" class="back-button-detail-page">
    <i class="fa fa-arrow-left"></i> Back
  </button>

  <div class="posteddatebackground" *ngIf="userProfile.username === entity.creator.username">
    <div class="posteddateflex">
      <div>
        <div>
          <h1 class="posteddate">
            {{ entity.date_created | date : "medium" }}
          </h1>
          <p class="posteddateheading">Posted Date</p>
        </div>

        <div class="viewsflex">
          <div>
            <p class="viewsnums">
              {{ entity.city.name }}, {{ entity.city.country }}
            </p>
            <p class="viewmulti">
              <img
                src="../../../assets/images/listing/locationlist.svg"
                alt=""
              />
              Location
            </p>
          </div>
          <div>
            <p class="viewsnums">{{ entity.entity_class.name }}</p>
            <p class="viewmulti">
              <img
                src="../../../assets/images/listing/categoryicon.svg"
                alt=""
              />
              {{
                "entityComponent.addEntity.steps.category.inputFields.mainCategory.label"
                  | translate
              }}
            </p>
          </div>
        </div>
      </div>

      <div class="allbtnflexres">
        <div>
          <button href="javascript:void(0)" class="active">
            <a href="javascript:void(0)" class="text-dark-grey">
              <img src="../../../assets/images/listing/activeball.svg" alt="" />
            </a>
            <a
              href="javascript:void(0);"
              *ngIf="!entity.is_public"
              class="text-danger ml-2"
            >
              {{
                "userComponent.itemsListing.itemsFeatures.hidden" | translate
              }}
              <i class="fas fa-lg"></i>
            </a>
            <a
              href="javascript:void(0);"
              *ngIf="entity.is_public"
              class="text-success ml-2"
            >
              {{
                "userComponent.itemsListing.itemsFeatures.public" | translate
              }}
              <i class="fas fa-lg"></i>
            </a>
          </button>
        </div>
        <div class="editdeleteflex">
          <button [routerLink]="['/item/edit', entity.hash_1]" class="editview">
            <img src="../../../assets/images/listing/editbtn.svg" alt="" /><span
              class="responsivebtn"
              >Edit</span
            >
          </button>
          <button
            href="javascript:void(0);"
            (click)="deleteItem(entity.hash_1)"
            class="delete"
          >
            <img src="../../../assets/images/listing/trash.svg" alt="" />
            <span class="responsivebtn">Delete</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- =========== -->

  <div class="container">
    <div class="mainflex">
      <div>
        <div class="watch1flex" >
          <!-- =========== -->
          <!-- =========== -->

          <!-- ///In Mobile View Images -->
          <div class="row  row-slider-section-1" *ngIf="entity.cover_image">  
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"> 
              <div ngxSlickItem class="watchresmain">
                <img class="slider-imge-class" style="width: 100% !important;" src="{{ entity.cover_image }}" alt="">
              </div>
              <div ngxSlickItem class="watchresmain" *ngFor="let image of entity.images">
                <img class="slider-imge-class" style="width: 100% !important;" src="{{ image.original }}" alt="">
              </div>
            </ngx-slick-carousel>  
          </div>
          
          <!-- =========== -->
          <!-- =========== -->
          <!-- ///In Desktop View Images -->

          <div class="watchnone">
            <img class="watch1" [src]="entity.cover_image ? entity.cover_image : entity.entity_class.default_cover_image" alt="" />
          </div>
          
          <div class="watchnone" *ngIf="entity.images && entity.images.length > 0">
            <img class="watch2" [src]="entity.images[0].original" alt="" />
          
            <div class="watch2flex">
              <ng-container *ngFor="let image of entity.images.slice(1, 4)">
                <div *ngIf="image">
                  <img class="watch3" [src]="image.original" alt="" />
                </div>
              </ng-container>
            </div>
          </div>
          
        </div>
        <div class="respricemargin">
          <h1 class="resprice">€ {{ entity.price }}</h1>
          <h1 class="productheading">{{ entity.title }}</h1>
          <p class="porductdescription">{{ entity.description }}</p>
        </div>
      </div>

      <div class="pricedetails">
        <div class="pricebackground">
          <h1 class="price">€ {{ entity.price }}</h1>
        </div>
        <div class="productdetailsmain">
          <p class="productheadingmultipleuse">Product Details</p>

          <div class="catergorywatchmultiflex onltcategory">
            <div class="categoryiconmulti">
              <img
                src="../../../assets/images/listing/categoryicon.svg"
                alt=""
              />
              <p class="categorymultipleuse">Category</p>
            </div>
            <div>
              <p class="watchmultipleuse">{{ entity.categories[0].name }}</p>
            </div>
          </div>
          <div class="catergorywatchmultiflex">
            <div class="categoryiconmulti">
              <img src="../../../assets/images/listing/usedicon.svg" alt="" />
              <p class="categorymultipleuse">Post Date</p>
            </div>
            <div>
              <p>{{ entity.date_created | date : "shortDate" }}</p>
            </div>
          </div>
          <div class="catergorywatchmultiflex conditionbordernone">
            <div class="categoryiconmulti">
              <img
                src="../../../assets/images/listing/conditionicon.svg"
                alt=""
              />
              <p class="categorymultipleuse">Condition</p>
            </div>
            <div>
              <p class="watchmultipleuse">8/10</p>
            </div>
          </div>
          <div class="saveNshareflex" style="display: flex; justify-content: space-between; align-items: center;">
            <div style="display: flex; align-items: center;">
              <p class="productheadingmultipleuse">Save & Share</p>
            </div>
          
            <div style="display: flex; align-items: center; justify-content: center; width: 100px;">
              <ng-container *ngIf="userProfile.username !== entity.creator.username">
                <ng-container *ngIf="correspondence === null">
                  <button type="button" (click)="toggleCorrespondence(entity.hash_1)" class="btn btn-primary btn-sm m-10">
                    {{'entityComponent.detail.buttons.match' | translate }}
                  </button>
                </ng-container>
                <ng-container *ngIf="correspondence !== null">
                  <button type="button" (click)="toggleCorrespondence(entity.hash_1, false)" class="btn btn-primary btn-sm m-1">
                    {{'entityComponent.detail.buttons.unMatch' | translate }}
                  </button>
                </ng-container>
              </ng-container>
            </div>
          
            <div style="display: flex; align-items: center;">
              <img src="../../../assets/images/listing/shareicon.svg" alt="" (click)="showPopup()" />
              <!-- Popup -->
              <div class="popup" *ngIf="isPopupVisible">
                <div class="popup-content">
                  <div class="popup-header">
                    <h2>Share Link</h2>
                    <button class="close-button" (click)="closePopup()">X</button>
                  </div>
                  <div class="popup-body">
                    <p>{{ link }}</p>
                    <p *ngIf="isCopied" class="copied-message">Link copied to clipboard!</p>
                  </div>
                  <div class="popup-footer">
                    <button class="copy-button" (click)="copyToClipboard()">Copy to Clipboard</button>
                    <button class="cancel-button" (click)="closePopup()">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          
        </div>
      </div>
    </div>
  </div>

  <!-- Start of propoerties section -->

  <div class="col-lg-10 col-sm-12 mb-4">
    <!--Attributes -->
    <div class="clearfix"></div>
    <div class="text-left">
      <!-- --------------------- ----------   --------------------- -->
      <div class="title-icon col-lg-12 mb-4">
        <h3 class="productheading">
          {{ "entityComponent.detail.itemSpecs.properties" | translate }}
        </h3>
      </div>

      <!-- Normal attributes -->
      <ng-container *ngFor="let attr of entity.attribute_values" class="mb-1">
        <ng-container
          *ngIf="
            (attr.attribute.type === 'text' && attr.value_text !== '') ||
            (attr.attribute.type === 'integer' &&
              attr.value_integer !== null) ||
            (attr.attribute.type === 'float' && attr.value_float !== null) ||
            (attr.attribute.type === 'date' && attr.value_float !== null)
          "
        >
          <div class="card float-left m-1" style="margin-left: 10px">
            <div class="m-2">
              {{ attr.attribute.name }}:
              <ng-container [ngSwitch]="attr.attribute.type">
                <span *ngSwitchCase="'float'" class="card-text text-bolder">{{
                  attr.value_float
                }}</span>
                <span *ngSwitchCase="'text'" class="card-text text-bolder">{{
                  attr.value_text
                }}</span>
                <span *ngSwitchCase="'integer'" class="card-text text-bolder">{{
                  attr.value_integer
                }}</span>
                <span *ngSwitchCase="'boolean'" class="card-text text-bolder">{{
                  attr.value_boolean
                }}</span>
                <span *ngSwitchCase="'date'" class="card-text text-bolder">{{
                  attr.value_date | date : "MM/dd/yyyy"
                }}</span>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <!-- Normal Checkbox -->

      <ng-container *ngFor="let attr of entity.attribute_values" class="mb-1">
        <ng-container
          *ngIf="
            attr.attribute.type === 'boolean' &&
            attr.attribute.choices === null &&
            attr.value_boolean === '1'
          "
        >
          <div class="card float-left m-1 mb-2">
            <div class="m-2">
              {{ attr.attribute.name }}:
              <ng-container [ngSwitch]="attr.attribute.type">
                <span *ngSwitchCase="'float'" class="card-text text-bolder">{{
                  attr.value_float
                }}</span>
                <span *ngSwitchCase="'text'" class="card-text text-bolder">{{
                  attr.value_text
                }}</span>
                <span *ngSwitchCase="'integer'" class="card-text text-bolder">{{
                  attr.value_integer
                }}</span>
                <span *ngSwitchCase="'boolean'" class="card-text text-bolder">
                  <i
                    class="fa fa-times-circle text-danger"
                    *ngIf="attr.value_boolean === '0'"
                  ></i>
                  <i
                    class="fa fa-check-circle text-success"
                    *ngIf="attr.value_boolean === '1'"
                  ></i>
                </span>
                <span *ngSwitchCase="'date'" class="card-text text-bolder">{{
                  attr.value_date | date : "MM/dd/yyyy"
                }}</span>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="clearfix mt-2"></div>

      <!-- Group of checkbox -->
      <ng-container *ngFor="let key of getKeys()">
        <div style="margin-top: 5%">
          <div class="title-icon col-lg-12 mt-4">
            <h3 class="title rubik fw-600 font-weight-bolder text-left mt-4">
              {{ key }}
            </h3>
          </div>

          <div *ngFor="let value of getValues(key)">
            <ng-container *ngIf="value.value === '1'">
              <div class="card float-left m-1 mb-2">
                <div class="m-2">
                  {{ value.choice }}:
                  <i
                    class="fa fa-times-circle text-danger"
                    *ngIf="value.value === '0'"
                  ></i>
                  <i
                    class="fa fa-check-circle text-success"
                    *ngIf="value.value === '1'"
                  ></i>
                </div>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="getNoTrueValues(key) === 0">
            No {{ key }}
          </ng-container>
        </div>

        <div class="clearfix"></div>
      </ng-container>
    </div>
  </div>
  <!-- End of Feature Section -->

  <app-become-seller-section></app-become-seller-section>

  <!-- Loading ball -->
  <div class="ball-container" *ngIf="!entity">
    <div class="ball ball-one"></div>
    <div class="shadow shadow-one"></div>
  </div>
</div>
