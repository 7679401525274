import {Component, OnDestroy, OnInit} from '@angular/core';
import { SubscriptionService } from '../services/subscription.service';
import {Subscription} from 'rxjs';

import { SubscriptionModel } from '../models/subscriptionModel';
import { PaymentService } from '../../module-payment/services/payment.service';
import { AlertService } from '../../core/alert/alert.service';
import { ConfirmationDialogService } from '../../core/confirmation-dialog/confirmation-dialog.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-subscription-listing',
  templateUrl: './subscription-listing.component.html',
  styleUrls: ['subscription-listing.component.css', ]
})
export class SubscriptionListingComponent implements OnInit, OnDestroy {
  loading = false;

  subscriptionServiceSubscription: Subscription;

  subscriptions: Array<SubscriptionModel> = null;
  totalPages: Array<number> = [];
  previousLink: string;
  nextLink: string;
  currentPage: number;

  confirmationModalTitle = '';
  confirmationCancelMsg = '';
  confirmationReactivateMsg = '';

  confirmationButtonAcceptCancellationText = '';
  confirmationButtonAcceptReactivationText = '';
  confirmationButtonCancelText = '';

  constructor(private subscriptionService: SubscriptionService,
              private paymentService: PaymentService,
              private alertService: AlertService,
              private confirmationDialogService: ConfirmationDialogService,
              translate: TranslateService) {
    translate.get('subscriptionsComponent.subscriptionListingPage.confirmationModal.title').subscribe((res: string) => {
      this.confirmationModalTitle = res;
    });
    translate.get('subscriptionsComponent.subscriptionListingPage.confirmationModal.cancelSubscription').subscribe((res: string) => {
      this.confirmationCancelMsg = res;
    });
    translate.get('subscriptionsComponent.subscriptionListingPage.confirmationModal.reactivateSubscription').subscribe((res: string) => {
      this.confirmationReactivateMsg = res;
    });

    translate.get('subscriptionsComponent.subscriptionListingPage.confirmationModal.buttons.cancel').subscribe((res: string) => {
      this.confirmationButtonCancelText = res;
    });
    translate.get('subscriptionsComponent.subscriptionListingPage.confirmationModal.buttons.acceptCancellation').subscribe((res: string) => {
      this.confirmationButtonAcceptCancellationText = res;
    });
    translate.get('subscriptionsComponent.subscriptionListingPage.confirmationModal.buttons.acceptReactivation').subscribe((res: string) => {
      this.confirmationButtonAcceptReactivationText = res;
    });
  }

  ngOnInit(): void {

    this.getSubscriptions();
  }

  goToPage(pageNumber){
    this.getSubscriptions(pageNumber);
  }

  public toggleSubscriptionState(subscriptionState: string, subscriptionUi: string) {
    if (subscriptionState === 'COMPLETED'){
      // cancel
      this.confirmationDialogService.confirm(
        this.confirmationModalTitle,
        this.confirmationCancelMsg,
        this.confirmationButtonAcceptCancellationText,
        this.confirmationButtonCancelText
      )
        .then((confirmed) => {
          // User confirmed:
          // console.log('User confirmed:', confirmed);
          if (confirmed === true){
            this.cancelSubscription(subscriptionUi);
          }
        })
        .catch(() => {
          // User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)
          // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
        });
    } else {
      // reactivate
      this.confirmationDialogService.confirm(
        this.confirmationModalTitle,
        this.confirmationReactivateMsg,
        this.confirmationButtonAcceptReactivationText,
        this.confirmationButtonCancelText
      )
        .then((confirmed) => {
          // User confirmed:
          // console.log('User confirmed:', confirmed);
          if (confirmed === true){
            this.reactivateSubscription(subscriptionUi);
          }
        })
        .catch(() => {
          // User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)
          // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
        });
    }

  }

  getSubscriptions(pageNumber?: number){
    this.subscriptionServiceSubscription = this.subscriptionService.getUserSubscriptions(pageNumber).subscribe(response =>
        this.constructTheListing(response),
      errorResponse => console.error(errorResponse));
  }

  constructTheListing(response){
    // console.log(response.body.data.results);
    this.totalPages = [];
    this.subscriptions = response.body.data.results;

    this.nextLink = response.body.data.links.next;
    this.previousLink = response.body.data.links.previous;
    for (let i = 1; i <= Math.ceil(response.body.data.total / response.body.data.page_size); i++){
      this.totalPages.push(i);
    }
    this.currentPage = response.body.data.page;
  }

  sendInvoiceToCustomer(subscriptionUi: string){
    this.loading = true;
    this.paymentService.sendInvoiceToCustomer(subscriptionUi).subscribe(response => {
        this.alertService.success(response.body.message);
        this.loading = false;
      }, error => { this.alertService.error(error.error.error); this.loading = false; console.error(error); });
  }

  reactivateSubscription(subscriptionUi: string){
    this.loading = true;
    this.paymentService.reactivateSubscription(subscriptionUi).subscribe(response => {
      // show message
      this.alertService.success(response.body.message);
      // refresh current objects after 2800 ms, leave hook time to update the backend subscription
      setTimeout(() => {
        this.goToPage(this.currentPage);
        this.loading = false;
      }, 2800);
    }, error => {
      this.alertService.error(error.error);
      this.loading = false;
    });
  }

  cancelSubscription(subscriptionUi: string){
    this.loading = true;
    this.paymentService.cancelSubscription(subscriptionUi).subscribe(response => {
        // show message
        this.alertService.success(response.body.message);
        // refresh current objects after 2800 ms, leave hook time to update the backend subscription
        setTimeout(() => {
          this.goToPage(this.currentPage);
          this.loading = false;
        }, 3500);
    }, error => {
      this.alertService.error(error.error.error);
      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.subscriptionServiceSubscription.unsubscribe();
  }


}
