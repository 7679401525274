import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AddressesResponse } from './responses/addressesResponse';
import { CountryResponse } from './responses/countryResponse';
import { AddressResponse } from './responses/addressResponse';
import { Address } from '../models/address';
import { CityResponse } from './responses/cityResponse';
import { BehaviorSubject } from 'rxjs';
import { Country } from '../models/country';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private apiBaseURL = environment.configSettings.apiURL;

  availableCountries: BehaviorSubject<Array<Country>> = new BehaviorSubject<Array<Country>>(null);

  constructor(private http: HttpClient) { }


  getAvailableCountriesAsSubject(){
    if (this.availableCountries.value === null) {
      this.getAvailableCountries().subscribe(response => {
        this.availableCountries.next(response.body.data);
        return response.body.data;
      }, error => console.error(error));
    }
    return this.availableCountries.value;
  }

  getUserAddresses() {
    return this.http.get<AddressesResponse>(`${this.apiBaseURL}v1/address/address/`, { observe: 'response' });
  }
  getCountries() {
    return this.http.get<CountryResponse>(`${this.apiBaseURL}v1/address/country/`, { observe: 'response' });
  }
  getCities(countryName?: string) {
    if (countryName){
      return this.http.post<CityResponse>(`${this.apiBaseURL}v1/address/city/`, { country: countryName }, { observe: 'response' });
    }
    return this.http.post<CityResponse>(`${this.apiBaseURL}v1/address/city/`, {}, { observe: 'response' });
  }
  getAvailableCountries() {
    return this.http.get<CountryResponse>(`${this.apiBaseURL}v1/address/available_countries/`, { observe: 'response' });
  }
  createAddress(address: Address) {
    return this.http.post<AddressResponse>(`${this.apiBaseURL}v1/address/address/`, address, { observe: 'response' });
  }
  updateAddress(address: Address, hash: string) {
    return this.http.put<AddressResponse>(`${this.apiBaseURL}v1/address/address/${hash}/`, address, { observe: 'response' });
  }
}
