import {Component, Input, OnChanges,  OnInit, SimpleChanges} from '@angular/core';
import { EntityAdditionService } from './service/entity-addition.service';
import { EntityClass } from '../models/entityClass';
import { AlertService } from '../../core/alert/alert.service';
import { Router } from '@angular/router';
import { EntityService } from '../services/entity.service';
import { Entity } from '../models/entity';
import { isNumeric } from 'rxjs/internal-compatibility';
import { AuthenticationService } from '../../module-user/services/authentication.service';
import { SubscriptionService } from '../../module-subscription/services/subscription.service';
import { SubscriptionModel } from '../../module-subscription/models/subscriptionModel';
import { TranslateService } from '@ngx-translate/core';
import {GlobalLoadingService} from '../../core/global-loading/global-loading.service';
import {SearchService} from '../../module-search/services/search.service';

@Component({
  selector: 'app-add-entity',
  templateUrl: './add-entity.component.html'
})
export class AddEntityComponent implements OnInit, OnChanges{
  @Input() editMode: boolean;
  @Input() title: string;
  @Input() entityHash: string;

  entityToEdit: Entity; // this is used for editing
  // in order to load the subject in all of the components

  entityClass: EntityClass;

  displayComponents = {
    showAddCategory: true,
    showAddProperties: false,
    showAddGallery: false,
    showAddLocation: false,
    showAddConfirmation: false,
  };


  validSubscriptionNeededErr = '';
  numberOfItemsExceededErr = '';


  constructor(private entityAdditionService: EntityAdditionService,
              private entityService: EntityService,
              private alertService: AlertService,
              private router: Router,
              private authenticationService: AuthenticationService,
              private subscriptionService: SubscriptionService,
              private globalLoadingService: GlobalLoadingService,
              private searchService: SearchService,
              translate: TranslateService) {
    translate.get('errors.validSubscriptionNeeded').subscribe((res: string) => {
      this.validSubscriptionNeededErr = res;
    });
    translate.get('errors.numberOfItemsExceeded').subscribe((res: string) => {
      this.numberOfItemsExceededErr = res;
    });

  }


  ngOnInit(): void {
    // This is used when creating an entity
    this.entityAdditionService.createEntity();


    const currentSubscription = this.authenticationService.currentUserSubscription;
    const NoItemsOwned = this.authenticationService.currentNoItemsOwned;


    if (NoItemsOwned === null || NoItemsOwned === undefined){

      this.subscriptionService.getValidSubscription().subscribe(response => {
        this.authenticationService.setCurrentUserSubscription(response.body.data.subscription);
        this.authenticationService.setNoItemsOwned(response.body.data.no_items_owned);
        this.checkSubscription(
          response.body.data.subscription,
          response.body.data.no_items_owned);
      }, error => {
        this.alertService.error(error.error.error, true);
        this.router.navigate(['/']);
      });
    }else {
      this.checkSubscription(currentSubscription, NoItemsOwned);
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.entityHash !== undefined) {
      // This is used when updating an entity
      this.entityService.getEntityByHash(this.entityHash).subscribe(response => {
        this.entityAdditionService.Entity = new Entity(response.body.data);

        this.entityToEdit = response.body.data;
      }, errorResponse => {
        console.log(errorResponse);
      });
    }
  }


  checkSubscription(currentSubscription?: SubscriptionModel, NoItemsOwned?: number){

    if (currentSubscription === undefined || currentSubscription === null) {
      currentSubscription = this.authenticationService.currentUserSubscription;
    }

    if (this.editMode === false || this.editMode === undefined) {
      // set the attributes on the entity
      if (currentSubscription === undefined || currentSubscription === null) {
        this.alertService.error(this.validSubscriptionNeededErr, true);
        this.router.navigate(['/']);
      } else {
        currentSubscription.plan.options.forEach(option => {
          if (option.option_code === 'number_of_items' && parseInt(option.option_value, 10) <= NoItemsOwned) {
            this.alertService.error(this.numberOfItemsExceededErr, true);
            this.router.navigate(['/']);
          }
        });
      }
    }
  }




  changeComponent(event: string) {
    /* This function is listening to the events emitted by the children of this component.
    * It is used in order to change the content of the modal dynamically
    * @param event, it represents the name of the component that needs to be changed */
    if (event in this.displayComponents) {
      let valid = true;
      switch (event) {
        case 'showAddProperties': {
          if (this.entityAdditionService.currentEntity.categories === undefined) {
            valid = false;
          }
          this.entityClass = this.entityAdditionService.currentEntityClass;
          break;
        }
        case 'showAddGallery': {
          if (this.entityAdditionService.currentEntity.attribute_values === undefined) {
            valid = false;
          }
          break;
        }
        case 'showAddLocation': {
          if (this.entityAdditionService.currentEntity.images === undefined) {
            valid = false;
          }
          break;
        }
        case 'showAddConfirmation': {
          if (this.entityAdditionService.currentEntity.city === undefined) {
            valid = false;
          }
          break;
        }
      }
      if (valid === true) {
        // tslint:disable-next-line:forin
        for (const key in this.displayComponents) {
          this.displayComponents[key] = key === event;
        }
      }
    } else if (event === 'saveItem'){

      this.globalLoadingService.globalLoadingValue = true;

      if (this.editMode === true){
        // set the attributes on the entity

        this.entityAdditionService.currentEntity.attribute_values.forEach( (attrValue, index) => {
          if (!isNumeric(this.entityAdditionService.currentEntity.attribute_values[index].attribute) ){
            this.entityAdditionService.currentEntity.attribute_values[index].attribute = attrValue.attribute.id;
          }
        });
        if (!isNumeric(this.entityAdditionService.currentEntity.categories[0]) ){
          this.entityAdditionService.currentEntity.categories = [this.entityAdditionService.currentEntity.categories[0].id];
        }
        if (!isNumeric(this.entityAdditionService.currentEntity.entity_class) ){
          this.entityAdditionService.currentEntity.entity_class = this.entityAdditionService.currentEntity.entity_class.id;
        }
        if (!isNumeric( this.entityAdditionService.currentEntity.city) ){
          this.entityAdditionService.currentEntity.city = this.entityAdditionService.currentEntity.city.id;
        }

        this.entityService.updateEntityByHash(this.entityHash, this.entityAdditionService.currentFormDataAndEntity).subscribe( response => {
          this.alertService.success(response.body.message, true);
          // stop global loading
          this.globalLoadingService.globalLoadingValue = false;
          // refresh listing
          this.searchService.masterSearch.next({
            keyword: '',
            city: ''
          });
        }, errorResponse => {
          console.log(errorResponse);
          this.globalLoadingService.globalLoadingValue = false;
          this.alertService.error(errorResponse.error.error, true);
        });
      }else{
        this.entityService.createEntity(this.entityAdditionService.currentFormDataAndEntity).subscribe( response => {
          this.alertService.success(response.body.message, true);
          // stop global loading
          this.globalLoadingService.globalLoadingValue = false;
          // refresh listing
          this.searchService.masterSearch.next({
            keyword: '',
            city: ''
          });
        }, errorResponse => {
          console.log(errorResponse); this.globalLoadingService.globalLoadingValue = false;
          this.alertService.error(errorResponse.error.error, true);
        });
      }


      this.router.navigate(['/']);

      
    }
  }

}
