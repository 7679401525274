<div class="contact-us-page">
  <div class="contactus-page-header">
    <div class="container">
      <div class="contact-us-page-row">
      <div class="row">
        <div class="col-lg-6 bg-white">

          <div class="page-h-content">
            <h2>
              {{'contactUsPage.title' |
                  translate }}

              <img src="assets/images/stars.png" alt="">
            </h2>
            <p>
              {{'contactUsPage.description' |
                  translate }}
            </p>
          </div>

          <form [formGroup]="contactusForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <input id='full_name-field' type="text" class="form-control form-control-custom"
                formControlName="full_name" [ngClass]="{ 'is-invalid': submitted && form.full_name.errors }"
                placeholder="{{'contactUsPage.inputFields.name.placeHolder' | translate }}" />
              <div *ngIf="submitted && form.full_name.errors" class="invalid-feedback">
                <div *ngIf="form.full_name.errors.required">{{'contactUsPage.inputFields.name.errors.required' |
                  translate }}</div>
              </div>
            </div>
            <div class="form-group">
              <input id='sender_email-field' type="email" class="form-control form-control-custom"
                formControlName="sender_email" [ngClass]="{ 'is-invalid': submitted && form.sender_email.errors }"
                placeholder="{{'contactUsPage.inputFields.email.placeHolder' | translate }}">
              <div *ngIf="submitted && form.sender_email.errors" class="invalid-feedback">
                <div *ngIf="form.sender_email.errors.required">{{'contactUsPage.inputFields.email.errors.required' |
                  translate }}</div>
                <div *ngIf="form.sender_email.errors.email">{{'contactUsPage.inputFields.email.errors.email' |
                  translate }}</div>

              </div>
            </div>
            <div class="form-group">
              <input id='subject-field' type="text" class="form-control form-control-custom" formControlName="subject"
                [ngClass]="{ 'is-invalid': submitted && form.subject.errors }"
                placeholder="{{'contactUsPage.inputFields.subject.placeHolder' | translate }}">
              <div *ngIf="submitted && form.subject.errors" class="invalid-feedback">
                <div *ngIf="form.subject.errors.required">{{'contactUsPage.inputFields.subject.errors.required' |
                  translate }}</div>
              </div>
            </div>
            <div class="form-group">
              <textarea id='message-field' class="form-control form-control-custom" formControlName="message" rows="7"
                [ngClass]="{ 'is-invalid': submitted && form.message.errors }"
                placeholder="{{'contactUsPage.inputFields.message.placeHolder' | translate }}"></textarea>
              <div *ngIf="submitted && form.message.errors" class="invalid-feedback">
                <div *ngIf="form.message.errors.required">{{'contactUsPage.inputFields.message.errors.required' |
                  translate }}</div>
              </div>
            </div>
            <div class="form-group">
              <button [disabled]="loading" class="btn-second blue-btn full-width">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                {{'buttons.submit' | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    </div>
  </div>
  <!-- <app-swapapp-section></app-swapapp-section> -->
</div>