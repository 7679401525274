import { Component, Input, OnInit} from '@angular/core';
import { Profile } from '../../models/profile';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UserService } from '../../services/user.service';
import { AlertService } from '../../../core/alert/alert.service';
import { FieldsValidators } from '../../validators/fields-validators';
import { Constant } from '../../models/constant';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from '../../../core/confirmation-dialog/confirmation-dialog.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-profile-general',
  templateUrl: './profile-general.component.html',
})
export class ProfileGeneralComponent implements OnInit {
  @Input() isMobileScreen=false;
  profileForm: FormGroup;
  profileFormSubmitted = false;

  loading = false;


  confirmationModalTitle = '';
  confirmationMsg = '';
  confirmationButtonAcceptText = '';
  confirmationButtonCancelText = '';

  @Input() userProfile: Profile;
  @Input() genderChoices: Array<Constant>;
  @Input() employmentChoices: Array<Constant>;

  constructor(private userService: UserService,
              private alertService: AlertService,
              private authenticationService: AuthenticationService,
              private confirmationDialogService: ConfirmationDialogService,
              private router: Router,
              private formBuilder: FormBuilder,
              translate: TranslateService) {
    translate.get('userComponent.profile.generalTab.confirmationModalTitle').subscribe((res: string) => {
      this.confirmationModalTitle = res;
    });
    translate.get('userComponent.profile.generalTab.confirmationMsg').subscribe((res: string) => {
      this.confirmationMsg = res;
    });
    translate.get('userComponent.profile.generalTab.confirmationButtonAcceptText').subscribe((res: string) => {
      this.confirmationButtonAcceptText = res;
    });
    translate.get('userComponent.profile.generalTab.confirmationButtonCancelText').subscribe((res: string) => {
      this.confirmationButtonCancelText = res;
    });
  }

  ngOnInit(): void {
    

    const maxDateAllowed = new Date();
    const maxYear = maxDateAllowed.getFullYear() - 18;
    maxDateAllowed.setFullYear(maxYear);

    this.profileForm = this.formBuilder.group({
      email: [{value: '', disabled: true}, [
        Validators.required,
        Validators.email
      ]],
      firstName: ['', [
        Validators.required,
        Validators.minLength(3)
      ]],
      lastName: ['', [
        Validators.required,
        Validators.minLength(3)
      ]],
      birthDate: ['', [Validators.required,
        FieldsValidators.dateRangeValidator(new Date('1910-01-01'), maxDateAllowed)]],
      gender: [null, [
        // Validators.required,
        // FieldsValidators.oneValueHasToBeChangedValidator('Select gender', {sameValue: true})
      ]],
      employmentStatus: [null, [
        // Validators.required,
        // FieldsValidators.oneValueHasToBeChangedValidator('Select employment status', {sameValue: true})
      ]],
    });

    if (this.userProfile) {
      console.log(this.userProfile,'this is the user profile')
      this.userProfileForm.email.setValue(this.userProfile.email);
      this.userProfileForm.firstName.setValue(this.userProfile.first_name);
      this.userProfileForm.lastName.setValue(this.userProfile.last_name);
      this.userProfileForm.birthDate.setValue(this.userProfile.profile.birth_date);
      if (this.userProfile.profile.gender != null) {
        this.userProfileForm.gender.setValue(this.userProfile.profile.gender);
      }
      if (this.userProfile.profile.employment_status != null) {
        this.userProfileForm.employmentStatus.setValue(this.userProfile.profile.employment_status);
      }
    }

  }

    uploadProfilePicture(event){
    if (event.target.files && event.target.files.length) {
      const formData = new FormData();
      formData.append('profile_picture', event.target.files[0]);
      this.userService.updateUserProfile(formData).subscribe(
        response => {
          /* 1. show the response message on the screen
           * 2. set the new entries on the user profile
            */
          this.userProfile = response.body.data;
          this.alertService.success(response.body.message, true);

        },
        error => {
          this.alertService.error(error.error.error);
          this.loading = false;
        });

    }
  }

  // convenience getter for easy access to form fields
  get userProfileForm() {
    return this.profileForm.controls;
  }

  onProfileFormSubmit() {
    this.profileFormSubmitted = true;

    // reset alerts on submit
    this.alertService.clear();


    // stop here if form is invalid
    if (this.profileForm.invalid) {
      return;
    }

    this.loading = true;

    this.userService.updateUserProfile({
      first_name: this.userProfileForm.firstName.value,
      last_name: this.userProfileForm.lastName.value,
      profile: {
        gender: this.userProfileForm.gender.value,
        birth_date: this.userProfileForm.birthDate.value,
        employment_status: this.userProfileForm.employmentStatus.value
      }
    }).subscribe(
      response => {
        /* 1. show the response message on the screen
         * 2. set the new entries on the user profile
         * 4. stop the loading
         * 5. stop the submission */
        this.alertService.success(response.body.message, true);
        this.userProfile = response.body.data;
        this.loading = false;
        this.profileFormSubmitted = false;
      },
      error => {
        this.alertService.error(error.error.error);
        this.loading = false;
      });
  }


  closeAccount() {

    this.confirmationDialogService.confirm(
      this.confirmationModalTitle,
      this.confirmationMsg,
      this.confirmationButtonAcceptText,
      this.confirmationButtonCancelText,
      true,
      'lg'
    )
      .then((confirmed) => {
        // User confirmed:
        // close the account at this point
        if (confirmed === true){
          this.userService.closeAccount().subscribe(
            response => {

              this.alertService.success(response.body.message, true);
              this.loading = false;
              this.authenticationService.logout();
              this.router.navigate(['/']);
            },
            error => {
              this.alertService.error(error.error.error);
              this.loading = false;
            });
        }
      })
      .catch(() => {
        // User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
      });


  }
}
